export default {
  clinicsListData(state) {
    return state.list.data
  },
  clinicsListError(state) {
    return state.list.error
  },
  clinicsSingleData(state) {
    return state.single.data
  },
  getClinicsListLoading(state) {
    return state.list.loading
  },
  getClinicDirections(state) {
    return state.directions.data
  }
}
