export default {
  getLoading(state) {
    return state.loading
  },
  getPromotionsData(state) {
    return state.data?.content
  },
  getPromotionFilters(state) {
    return state.filters
  },
  getPromotionStories(state) {
    return state.stories
  },
  getPromotionError(state) {
    return state.error
  },
  getCounter(state) {
    return state.counter
  },
  getUpdateCountersStatus(state) {
    return state.updateCountersStatus
  },
  paginationInfo(state) {
    return {
      totalPages: state.data?.totalPages,
      size: state.data?.size
    }
  }
}
