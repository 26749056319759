<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.6128 1.91239C10.786 2.2145 11.0969 2.3998 11.4327 2.3998C12.0163 2.3998 12.9395 2.3998 13.5279 2.3998C13.8695 2.3998 14.1852 2.59214 14.3564 2.90332C14.6415 3.42397 15.0845 4.23061 15.3715 4.75529C15.5456 5.07251 15.5428 5.46425 15.3629 5.77744C15.0855 6.26385 14.6693 6.99194 14.3899 7.48036C14.2072 7.8006 14.2072 8.1994 14.3899 8.51964C14.6693 9.00806 15.0855 9.73615 15.3629 10.2226C15.5428 10.5358 15.5456 10.9275 15.3715 11.2447C15.0845 11.7694 14.6415 12.576 14.3564 13.0967C14.1852 13.4079 13.8695 13.6002 13.5279 13.6002C12.9395 13.6002 12.0163 13.6002 11.4327 13.6002C11.0969 13.6002 10.786 13.7855 10.6128 14.0876C10.3755 14.5035 10.0359 15.0967 9.79863 15.5116C9.62547 15.8147 9.31453 16 8.97872 16C8.4267 16 7.5733 16 7.02128 16C6.68547 16 6.37453 15.8147 6.20137 15.5116C5.9641 15.0967 5.62446 14.5035 5.3872 14.0876C5.21403 13.7855 4.9031 13.6002 4.56729 13.6002C3.98369 13.6002 3.06046 13.6002 2.47207 13.6002C2.13052 13.6002 1.81481 13.4079 1.64355 13.0967C1.35845 12.576 0.91549 11.7694 0.628475 11.2447C0.454352 10.9275 0.457222 10.5358 0.637085 10.2226C0.914534 9.73615 1.33071 9.00806 1.61007 8.51964C1.7928 8.1994 1.7928 7.8006 1.61007 7.48036C1.33071 6.99194 0.914534 6.26385 0.637085 5.77744C0.457222 5.46425 0.454352 5.07251 0.628475 4.75529C0.91549 4.23061 1.35845 3.42397 1.64355 2.90332C1.81481 2.59214 2.13052 2.3998 2.47207 2.3998C3.06046 2.3998 3.98369 2.3998 4.56729 2.3998C4.9031 2.3998 5.21403 2.2145 5.3872 1.91239C5.62446 1.49648 5.9641 0.903323 6.20137 0.488419C6.37453 0.185297 6.68547 0 7.02128 0C7.5733 0 8.4267 0 8.97872 0C9.31453 0 9.62547 0.185297 9.79863 0.488419C10.0359 0.903323 10.3755 1.49648 10.6128 1.91239ZM8 4C10.208 4 12 5.792 12 8C12 10.208 10.208 12 8 12C5.792 12 4 10.208 4 8C4 5.792 5.792 4 8 4Z"
      fill="url(#paint0_linear_1664_229170)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1664_229170"
        x1="1.35277"
        y1="24.058"
        x2="21.6979"
        y2="13.6088"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'SettingsIcon'
}
</script>
