import { tracker } from '../model/tracker.service'

const DEFAULT_EVENT_TYPE = 'click'

export const vTrackerPlugin = {
  install(app) {
    app.prototype.$tracker = (slug) => {
      return tracker.track(slug)
    }

    const _eventFn = (slug) => {
      tracker.track(slug)
    }

    app.directive('tracker', {
      bind(el, binding) {
        const eventType = binding.arg || DEFAULT_EVENT_TYPE
        el.addEventListener(eventType, _eventFn.bind(null, binding.value))
      },

      unbind(el, binding) {
        const eventType = binding.arg || DEFAULT_EVENT_TYPE
        el.removeEventListener(eventType, _eventFn)
      }
    })
  }
}
