export default {
  citiesData(state) {
    return state.list.data
  },
  selectedCity(state) {
    return state?.selected
  },
  selectedCityData(state) {
    return (state.list?.data || []).find((city) => city.id === state.selected)
  }
}
