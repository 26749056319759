<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1 7.025C1 7.017 1 7.008 1 7C1 3.137 4.137 0 8 0C11.863 0 15 3.137 15 7C15 7.008 15 7.017 15 7.025C14.984 12.256 9.95 15.378 8.359 15.934C8.253 15.971 8.126 16 8 16C7.874 16 7.753 15.976 7.641 15.934C6.05 15.378 1.016 12.256 1 7.025V7.025V7.025ZM8 3.27847C10.0543 3.27847 11.7215 4.94572 11.7215 7C11.7215 9.05428 10.0543 10.7215 8 10.7215C5.94572 10.7215 4.27847 9.05428 4.27847 7C4.27847 4.94572 5.94572 3.27847 8 3.27847Z"
      fill="#228462"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2213_8055"
        x1="1.79592"
        y1="24.058"
        x2="21.3097"
        y2="14.7039"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'LocateIcon'
}
</script>
