import state from './timeslotsState'
import mutations from './timeslotsMutations'
import getters from './timeslotsGetters'
import actions from './timeslotsActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
