export default {
  setDiagnosticsData(state, payload) {
    state.list.data = payload
  },
  updateDiagnosticsData(state, payload) {
    state.list.data.diagnostics.content = [
      ...state.list.data?.diagnostics?.content,
      ...payload.diagnostics?.content
    ]
  },
  setDiagnosticById(state, payload) {
    state.single.data = payload
  },
  setListLoading(state) {
    state.list.loading = !state.list.loading
  },
  setSingleLoading(state) {
    state.single.loading = !state.single.loading
  },
  setListError(state, payload) {
    state.list.error = payload
  },
  setSingleError(state, payload) {
    state.single.error = payload
  },
  setPaginationData(state, payload) {
    state.list.pagination = payload
  },
  setSortData(state, payload) {
    state.sortingValues = payload
  },
  setFiltersData(state, payload) {
    state.filters.data = payload
  },
  clearDiagnosticsRequestState(state) {
    state.single.error = null
    state.single.loading = false
  }
}
