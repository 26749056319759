export default {
  getListLoading(state) {
    return state.list.loading
  },
  getListError(state) {
    return state.list.error
  },
  getSingleLoading(state) {
    return state.single.loading
  },
  getSingleError(state) {
    return state.single.error
  },
  getFiltersData(state) {
    return state.filters.data
  },
  getAnalysesData(state) {
    return state.list.data?.content || []
  },
  getSingleAnalysisData(state) {
    return state.single.data
  },
  getClinicsListByAnalysis(state) {
    return state.clinics.data
  },
  paginationInfo(state) {
    return {
      totalPages: state.list.pagination?.totalPages,
      size: state.list.pagination?.size
    }
  }
}
