<template>
  <SmModal size="lg" name="cancel-modal" title="Отменить прием?">
    <template v-slot:body>
      <div class="line"></div>
      <div class="sm-modal__body">
        <div class="sm-modal__body__icon"><UserIcon /></div>
        <div class="sm-modal__body__content">
          Если вы отмените прием, то ваша бронь будет аннулирована. Если вас не
          устраивает время, вы можете перенести запись на более удобное время.
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="modal-buttons-wrapper">
        <Button
          :classes="['admission-btn', '']"
          :onClick="rescheduleHandler"
          icon="change-time"
        >
          Перенести прием
        </Button>
        <Button
          :classes="'admission-btn loader'"
          :icon="'cancel'"
          :loading="loading"
          secondary
          :onClick="() => cancelHandler(id)"
        >
          Отменить прием
        </Button>
        <Button :classes="'admission-btn'" :onClick="closeModal" secondary>
          Не отменять
        </Button>
      </div>
    </template>
  </SmModal>
</template>
<script>
import SmModal from '@/components/modal'
import Button from '@/components/button'
import UserIcon from '@/assets/icons/user'
import { mapActions } from 'vuex'
export default {
  name: 'CancelAdmission',
  components: { UserIcon, Button, SmModal },
  props: {
    id: String,
    afterCloseHandler: { type: Function, default: () => {} },
    rescheduleHandler: { type: Function, default: () => {} }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    ...mapActions('appointment', ['cancelAppointment']),
    closeModal() {
      this.$modals.hide('cancel-modal')
    },
    cancelHandler(id) {
      this.loading = true
      this.cancelAppointment(id)
        .then(({ data }) => {
          if (data?.success) {
            this.afterCloseHandler()
            this.closeModal()
          }
        })
        .finally(() => (this.loading = false))
    }
  }
}
</script>
