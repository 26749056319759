import { apiV1 } from '@/config/request'

export default {
  async getFaq({ commit, dispatch }) {
    commit('setListLoading')
    try {
      await dispatch('getFaqCategories')
      const {
        data: { faq }
      } = await new apiV1({ auth: true }).get('/faq/')
      commit('setFaqData', faq?.content)
      commit('setListLoading')
    } catch (e) {
      commit('setListLoading')
      commit('setListError', `${e}`)
    }
  },
  async getFaqCategories({ commit }) {
    commit('setCategoriesLoading')
    try {
      const {
        data: { categories }
      } = await new apiV1({ auth: true }).get('faq/categories')
      commit('setCategories', categories)
      commit('setCategoriesLoading')
    } catch (e) {
      commit('setCategoriesLoading')
      commit('setCategoriesError', `${e}`)
    }
  }
}
