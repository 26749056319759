<template>
  <SmModal size="md" name="reschedule-modal" title="Вы уже записаны на прием">
    <template v-slot:body>
      <div class="line"></div>
      <div class="sm-modal__body">
        <div class="sm-modal__body__icon"><CalendarIcon /></div>
        <div class="sm-modal__body__content">
          4 июня в 15:00 вы записаны на прием к терапевту Жадину П.М. по адресу:
          ул. Лесная, д. 57, стр. 1
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="modal-buttons-wrapper">
        <Button :onClick="goToVisits" class="reschedule-btn" secondary>
          Перенести прием
        </Button>
        <Button :onClick="closeModal" class="sign-btn">
          Записаться на другое время
        </Button>
      </div>
    </template>
  </SmModal>
</template>

<script>
import SmModal from '@/components/modal'
import Button from '@/components/button'
import CalendarIcon from '@/assets/icons/calendar'
import { mapActions } from 'vuex'

export default {
  name: 'RescheduleModal',
  components: { CalendarIcon, Button, SmModal },
  props: { info: Object },
  methods: {
    ...mapActions('appointment', ['cancelAppointment']),
    closeModal() {
      this.$modals.hide('cancel-modal')
    },
    goToVisits() {
      this.$router.push('/upcoming')
    }
  }
}
</script>
