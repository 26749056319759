<template>
  <div class="map-controls">
    <div class="map-controls__button-group">
      <span class="map-controls__button" @click="zoom(true)">
        <GeoPlusIcon />
      </span>
      <span class="map-controls__button" @click="zoom(false)">
        <MinusIcon />
      </span>
    </div>
    <div
      class="map-controls__button map-controls__button--block"
      @click="geolocation"
    >
      <GeolocationIcon />
    </div>
  </div>
</template>
<script>
import GeolocationIcon from '@/assets/icons/geolocation'
import MinusIcon from '@/assets/icons/minus'
import GeoPlusIcon from '@/assets/icons/geo-plus'
export default {
  name: 'MapControls',
  props: {
    zoom: {
      type: Function,
      default: () => {}
    },
    geolocation: {
      type: Function,
      default: () => {}
    }
  },
  components: { GeoPlusIcon, MinusIcon, GeolocationIcon }
}
</script>
<style scoped lang="scss">
@import 'style';
</style>
