import auth from './auth'
import account from './account'
import faq from './faq'
import analyses from './analyses'
import diagnostics from './diagnostics'
import subways from './subways'
import promotions from '@/store/modules/promotions'
import appointment from './appointment'
import visits from './visits'
import doctors from './doctors'
import qualifications from './qualifications'
import cities from './cities'
import clinics from './clinics'
import timeslots from './timeslots'
import specialities from './specialities'
import biomaterials from './biomaterials'

export default {
  auth,
  account,
  faq,
  analyses,
  diagnostics,
  doctors,
  promotions,
  subways,
  appointment,
  visits,
  qualifications,
  cities,
  clinics,
  timeslots,
  specialities,
  biomaterials
}
