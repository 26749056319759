var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
    'btn',
    _vm.secondary ? 'btn-secondary' : 'btn-primary',
    ( _obj = {}, _obj[_vm.icon] = _vm.icon && !_vm.loading, _obj ),
    _vm.classes
  ],attrs:{"disabled":_vm.disabled || _vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.onClick.apply(null, arguments)}}},[_vm._t("default"),(_vm.loading)?_c('Preloader',{class:['preloader', _vm.secondary ? 'light' : 'dark']}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }