import { apiV1 } from '@/config/request'

export default {
  async getPromotions(
    { commit, rootState },
    { cityId = rootState.cities.selected, page, size }
  ) {
    commit('setLoading')
    try {
      const {
        data: { promotions }
      } = await new apiV1({ auth: true }).get('promo/promotions', {
        params: { cityId, page, size }
      })
      commit('setPromotionsData', promotions)
      commit('setLoading')
    } catch (e) {
      commit('setLoading')
      commit('setError', `${e}`)
    }
  },
  async getFilters({ commit }) {
    try {
      const {
        data: { directions }
      } = await new apiV1({ auth: true }).get('promo/promotions/directions')
      commit('setPromotionsFilters', directions)
    } catch (e) {
      commit('setError', `${e}`)
    }
  },
  async getFilteredPromotions(
    { commit, rootState },
    {
      cityId = rootState.cities.selected,
      size,
      page,
      merge = false,
      ...payload
    }
  ) {
    commit('setLoading')
    try {
      const {
        data: { promotions }
      } = await new apiV1({ auth: true }).post(
        'promo/promotions/filter',
        {
          cityId,
          ...payload
        },
        { params: { size, page } }
      )

      if (!merge) {
        commit('setPromotionsData', promotions)
      } else {
        commit('updatePromotionsData', promotions)
      }

      commit('setLoading')
    } catch (e) {
      commit('setError', `${e}`)
      commit('setLoading')
    }
  },
  async getStories({ commit, rootState }) {
    commit('setLoading')
    try {
      const {
        data: { stories }
      } = await new apiV1({ auth: true }).get('/promo/stories', {
        params: {
          cityId: rootState.cities.selected
        }
      })
      commit('setStoriesData', stories)
      commit('setLoading')
    } catch (e) {
      commit('setLoading')
      commit('auth_error', `${e}`)
    }
  },
  async getPromotionsCounters(
    { commit, rootState },
    {
      accountId = rootState.account.account.id,
      patientId = rootState.account.patient.id
    }
  ) {
    commit('setLoading')
    console.log('getPromotionsCountgetPromotionsCountgetPromotionsCount')
    try {
      const {
        data: {
          unread: { promo }
        }
      } = await new apiV1({ auth: true }).get(
        `/counters/getGlobal?accountId=${accountId}&patientId=${patientId}`
      )
      commit('setCounter', promo)
      commit('setLoading')
    } catch (e) {
      commit('setLoading')
      commit('setError', `${e}`)
    }
  },
  async updatePromotionsCounters(
    { commit, rootState },
    { patientId = rootState.account.patient.id, id }
  ) {
    commit('setLoading')
    try {
      const {
        data: { status }
      } = await new apiV1({ auth: true }).put('counters/entityReadGlobal', {
        patientId,
        id
      })
      commit('setUpdateCountersStatus', status)
      commit('setLoading')

      return status
    } catch (e) {
      commit('setLoading')
      commit('setError', `${e}`)
    }
  }
}
