<template>
  <section class="main-page">
    <SmSlider
      v-if="getPromotionStories"
      :space-between="24"
      slides-per-view="auto"
      :pagination="true"
      title="Специально для вас"
      :breakpoints="{
        1367: {
          spaceBetween: 24
        },
        1024: {
          spaceBetween: 19
        },
        320: {
          spaceBetween: 6
        }
      }"
    >
      <swiper-slide
        v-for="story in currentPromotionStories"
        :key="story.id"
        class="slide"
      >
        <a :href="story.url" target="_blank">
          <img alt="alt" :src="`${getBaseUrl}/${story.image}`" />
        </a>
      </swiper-slide>
    </SmSlider>
    <Navbar class="main-navbar" :options="routes" />
    <div class="content">
      <router-view></router-view>
    </div>
    <SmModal size="md" name="sm-modal" v-if="show">
      <template v-slot:body>
        <div class="line"></div>
        <div class="sm-modal__body">
          <div class="sm-modal__body__icon"><DocumentIcon /></div>
          <div class="sm-modal__body__content">
            К сожалению, вы давно не пользовались нашими услугами и вам
            необходимо обновить договор, доступ к приложению, рекомендациям,
            истории посещений, пожалуйста, обновите договор на оказание
            медицинских услуг. Для этого обратитесь в
            <router-link class="linkTo" to="/" custom v-slot="{ navigate }">
              <span @click="navigate" role="link"> регистратуру клиники</span>
            </router-link>
            . <br /><br />
            Вы можете записаться на приём, и во время визита мы обновим договор.
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <Button :onClick="closeModal" icon="close" class="sm-modal__close-btn">
          Закрыть окно
        </Button>
      </template>
    </SmModal>
  </section>
</template>

<script>
import SmSlider from '@/components/slider'
import Navbar from '@/components/navbar'
import SmModal from '@/components/modal'
import Button from '@/components/button'
import DocumentIcon from '@/assets/icons/document'
import { mapGetters } from 'vuex'
import { parseISO, getYear } from 'date-fns'
import { SwiperSlide } from 'swiper-vue2'

import { mapActions } from 'vuex'

export default {
  name: 'MainPage',
  components: {
    Navbar,
    DocumentIcon,
    Button,
    SmSlider,
    SmModal,
    SwiperSlide
  },
  data() {
    return {
      counters: null
    }
  },
  async mounted() {
    const accountId = this.getAccountInfo.id
    const patientId = this.getPatientInfo.id
    this.counters = await this.getCounters({
      accountId,
      patientId
    })
    window.scrollTo(0, 0)
    let sessionModalShow = sessionStorage.getItem('modal-show')
    if (this.show && !sessionModalShow) {
      this.showModal()
    }
  },
  methods: {
    ...mapActions('account', ['getCounters']),
    closeModal() {
      this.$modals.hide('sm-modal')
    },
    showModal() {
      sessionStorage.setItem('modal-show', 'true')
      this.$modals.show('sm-modal')
    }
  },
  computed: {
    ...mapGetters('account', [
      'getSignInDate',
      'getAge',
      'getPatientInfo',
      'getAccountInfo'
    ]),
    ...mapGetters('promotions', ['getPromotionStories']),
    routes() {
      return [
        {
          name: 'Предстоящие записи',
          to: 'upcoming',
          counter: this.counters?.appointments
        },
        {
          name: 'История посещений',
          to: 'history',
          counter: this.counters?.visits
        },
        { name: 'Анализы', to: 'analyses', counter: this.counters?.analyses },
        {
          name: 'Исследования',
          to: 'diagnostics',
          counter: this.counters?.diagnostics
        }
      ]
    },
    show() {
      return this.getSignInDate
        ? getYear(parseISO(this.getSignInDate)) < 2018
        : false
    },
    currentPromotionStories() {
      let result = []
      if (this.getAge < 18) {
        result = this.getPromotionStories?.filter(
          (stories) => stories.forChildren
        )
      } else {
        result = this.getPromotionStories
      }
      return [...result].sort((a, b) => (a.priority ?? 0) - (b.priority ?? 0))
    },
    getBaseUrl() {
      return process.env.VUE_APP_API_BASE_URL
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
.main-navbar::v-deep {
  .navbar {
    width: 100%;
  }
  @include media-breakpoint-down(sm) {
    .navbar {
      width: initial;
    }
  }
}
</style>
