<template>
  <form class="auth-form">
    <p class="auth-form__title">Введите телефон, чтобы продолжить</p>
    <form class="auth-form__inner" @submit.prevent="auth">
      <SmInput
        type="phone"
        id="phone"
        placeholder="Введите ваш номер телефона"
        :value="this.phone"
        :onChange="(e) => (this[e.target.name] = e.target.value)"
        :classes="'auth-input'"
        :onClick="() => (this['phone'] = '+7' + this.phone.replace('+7', ''))"
        v-mask="['+7 (###) ###-##-##']"
        :invalid="validateState('phone')"
      />
      <div class="auth-form__captcha" v-show="showCaptcha">
        <vue-recaptcha ref="recaptcha" :sitekey="sitekey" @verify="register" />
      </div>
      <Button
        :loading="getLoading"
        :classes="'auth-form__btn'"
        icon="arrow"
        :onClick="auth"
        :disabled="getLoading || (showCaptcha && !successCaptcha)"
      >
        Продолжить
      </Button>
      <div class="has-error" v-if="this.invalidPhone">
        <ErrorToast
          v-if="this.$v.$invalid"
          text="Введите корректный номер телефона"
        />
      </div>
      <div class="has-error" v-else-if="errorAuth.message && !showCaptcha">
        <ErrorToast v-if="errorAuth" :text="errorAuth.message" />
      </div>
      <div
        class="has-error"
        v-if="this.$v.$invalid && this.$v.$anyError && !this.invalidPhone"
      >
        <ErrorToast v-if="this.$v.$invalid" :text="'Обязательное поле'" />
      </div>
    </form>
  </form>
</template>

<script>
import Button from '@/components/button'
import SmInput from '@/components/input'
import { mapGetters, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import ErrorToast from '@/components/errorToast'
import { mask } from 'vue-the-mask'
import { VueRecaptcha } from 'vue-recaptcha'
import { TRACKER_SLUGS } from '@/models/tracker'

export default {
  name: 'AuthByPhone',
  components: { SmInput, Button, ErrorToast, VueRecaptcha },
  directives: { mask },
  data() {
    return {
      phone: '',
      sitekey: '6Lfm8kckAAAAAIF3HC1MEiJQbRWSW2IhBW_b-g0c',
      successCaptcha: false
    }
  },
  validations: {
    phone: {
      required,
      phoneLength: (value) => value.replace(/\D/gi, '').length === 11
    }
  },
  methods: {
    ...mapActions('auth', ['signInWithPhone', 'clearAuthError']),
    async register(recaptchaToken) {
      this.successCaptcha = true
      const isBack = !!this.$route.query.back
      this.signInWithPhone({
        data: {
          phone: this.phone.replace(/\D/g, ''),
          birthday: undefined,
          recaptchaToken
        },
        isBack
      })
    },
    auth() {
      this.$v.$touch()
      this.$tracker(TRACKER_SLUGS.AUTH_PHONE)
      if (!this.$v.$invalid) {
        const isBack = !!this.$route.query.back
        this.signInWithPhone({
          data: {
            phone: this.phone.replace(/\D/g, ''),
            birthday: undefined
          },
          isBack
        })
      }
    },
    validateState(name) {
      const { $dirty, $error, $model, $invalid } = this.$v[name]
      if ($model === null) return null
      if (!$model.length && !$invalid) return null
      if ($model.replace(/\D/gi, '').length < 11) return null
      return $dirty ? $error : null
    }
  },
  computed: {
    ...mapGetters('auth', ['errorAuth', 'getLoading']),
    showCaptcha() {
      return (
        this.errorAuth?.errorCode && this.errorAuth?.errorCode === 'needCaptcha'
      )
    },
    invalidPhone() {
      return (
        this.$v['phone'].$model.replace(/\D/gi, '').length < 11 &&
        this.$v['phone'].$model.length &&
        this.$v.$invalid &&
        this.$v.$anyError
      )
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.clearAuthError()
    })
  }
}
</script>
<style lang="scss" scoped>
@import '../style.scss';
.auth-form__captcha {
  margin-bottom: 1rem;
}
</style>
