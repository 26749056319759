/*eslint-disable*/
import AuthLayout from '@/layouts/authLayout'
import AuthMain from '@/pages/auth/authMain'
import AuthByPhone from '@/pages/auth/phone'
import AuthByLogin from '@/pages/auth/login'
import AuthBlocked from '@/pages/auth/authBlocked'
import PasswdRecovery from '@/pages/auth/passwdRecovery'
import SmsConfirm from '@/pages/auth/smsConfirm'
import NewPassword from '@/pages/auth/newPassword'
import Registration from '@/pages/auth/phone/registration'
import AuthOffer from '@/pages/auth/authOffer'
import AuthExtra from '@/pages/auth/authExtra'
import AuthError from '@/pages/auth/authError'
import MainLayout from '@/layouts/mainLayout'
import MainPage from '@/pages/main/MainPage'
import MedicalInfo from '@/pages/medicalInfo'
import Analyses from '@/pages/analyses'
import Diagnostics from '@/pages/diagnostics'
import AnalysisInfo from '@/pages/analyses/analysisSingle'
import DiagnosticsInfo from '@/pages/diagnostics/diagnosticSingle'
import FaqPage from '@/pages/faq'
import Appointments from '@/pages/appointment'
import AppointmentsSingle from '@/pages/appointment/appointmentsSingle'
import AdmissionInfoPage from '@/pages/admission/admission'
import PromotionsPage from '@/pages/promotions/promotions'
import Profile from '@/pages/profile/profile'
import Specializations from '@/pages/appointment/specializations'
import Map from '@/pages/clinicsMap'
import AuthDeleted from '@/pages/auth/authDeleted'

export const routes = [
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: '',
        component: MainPage,
        name: 'Home',
        redirect: '/upcoming',
        meta: { isAuth: true },
        children: [
          {
            path: 'upcoming',
            name: 'UpcomingData',
            component: MedicalInfo,
            meta: {
              title: `${process.env['VUE_APP_DOMAIN_TITLE']} | Предстоящие записи`
            }
          },
          {
            path: 'history',
            name: 'HistoryData',
            component: MedicalInfo,
            meta: {
              title: `${process.env['VUE_APP_DOMAIN_TITLE']} | История посещений`
            }
          },
          {
            path: 'analyses',
            name: 'AnalysisData',
            component: Analyses,
            meta: {
              title: `${process.env['VUE_APP_DOMAIN_TITLE']} | Анализы`
            }
          },
          {
            path: 'diagnostics',
            name: 'Diagnostics',
            component: Diagnostics,
            meta: {
              title: `${process.env['VUE_APP_DOMAIN_TITLE']} | Исследования`
            }
          }
        ]
      },
      {
        path: 'admission',
        name: 'Admission',
        component: AdmissionInfoPage,
        meta: {
          crumbs: 'Информация о приеме'
        }
      },
      {
        path: '/appointment',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          isAuth: true,
          crumbs: 'Запись к врачу'
        },
        children: [
          {
            path: '',
            name: 'Specializations',
            component: Specializations,
            meta: { isSpec: true }
          },
          {
            path: '/appointment/:specId',
            component: {
              render(c) {
                return c('router-view')
              },
              meta: { isSpec: true }
            },
            props: true,
            children: [
              {
                path: '',
                component: Appointments,
                props: true,
                name: 'DoctorsList',
                meta: { isSpec: true }
              },
              {
                path: ':doctorId',
                component: AppointmentsSingle,
                props: true,
                meta: { isSpec: true }
              }
            ]
          }
        ]
      },
      {
        path: '/promotions',
        name: 'PromotionsPage',
        component: PromotionsPage,
        meta: {
          isAuth: true,
          crumbs: 'Акции'
        }
      },
      {
        path: 'profile',
        name: 'ProfilePage',
        component: Profile,
        meta: {
          isAuth: true,
          crumbs: 'Настройки профиля'
        }
      },
      {
        path: '/analysis',
        name: 'AnalysisInfoPage',
        component: AnalysisInfo,
        meta: {
          isAuth: true,
          crumbs: 'Информация об анализе'
        }
      },
      {
        path: '/diagnostic',
        name: 'DiagnosticsInfo',
        component: DiagnosticsInfo,
        meta: {
          isAuth: true,
          crumbs: 'Информация об исследовании'
        }
      },
      {
        path: '/faq',
        name: 'Faq',
        component: FaqPage,
        meta: {
          isAuth: true,
          crumbs: 'Частые вопросы'
        }
      },
      {
        path: '/map',
        name: 'Map',
        component: Map,
        meta: {
          isAuth: true,
          isMap: true,
          crumbs: 'Карта клиник'
        }
      }
    ]
  },
  {
    path: '/auth',
    component: AuthLayout,
    meta: { title: `${process.env['VUE_APP_DOMAIN_TITLE']} | Авторизация` },
    children: [
      { path: '', name: 'Auth', component: AuthMain },
      { path: 'phone', name: 'AuthByPhone', component: AuthByPhone },
      { path: 'login', name: 'AuthByLogin', component: AuthByLogin },
      { path: 'blocked', name: 'AuthBlocked', component: AuthBlocked },
      { path: 'recovery', name: 'Recovery', component: PasswdRecovery },
      { path: 'sms-confirm', name: 'SMSConfirm', component: SmsConfirm },
      { path: 'changed', name: 'PasswordChanged', component: NewPassword },
      { path: 'registration', name: 'Registration', component: Registration },
      { path: 'offer', name: 'Offer', component: AuthOffer },
      { path: 'error-deleted', name: 'AuthDeleted', component: AuthDeleted },
      {
        path: 'auth-date',
        name: 'AuthExtraDate',
        component: AuthExtra,
        meta: { isDate: true }
      },
      {
        path: 'auth-name',
        name: 'AuthExtraName',
        component: AuthExtra
      },
      {
        path: 'recovery-name',
        name: 'AuthRecoveryName',
        component: AuthExtra,
        meta: { isRecovery: true }
      },
      {
        path: 'recovery-date',
        name: 'AuthRecoveryDate',
        component: AuthExtra,
        meta: { isDate: true, isRecovery: true }
      },
      {
        path: 'auth-fail',
        name: 'AuthError',
        component: AuthError
      }
    ]
  }
]
