<template>
  <div class="discount-card">
    <div class="discount-card__image-wrapper">
      <a :href="discountInfo.url" target="_blank">
        <img
          alt="discount"
          :src="`${getBaseUrl}/${discountInfo.image}`"
          v-if="discountInfo.image"
          @click="clickLink" />
        <PromotionsIcon v-else class="promotion-icon"
      /></a>
    </div>
    <div class="discount-card__body">
      <a :href="discountInfo.url" target="_blank"
        ><p class="card-title" @click="clickLink">
          {{ discountInfo.title }}
        </p></a
      >
      <p class="card-content" v-html="discountInfo.subtitle"></p>
      <p class="card-date">{{ periodDate }}</p>
    </div>
  </div>
</template>
<script>
import { parseISO } from 'date-fns'
import { formatWithLocale } from '@/core/utils'
import PromotionsIcon from '@/assets/icons/promotions'

export default {
  name: 'DiscountCard',
  components: { PromotionsIcon },
  props: {
    discountInfo: Object
  },
  computed: {
    periodDate() {
      return `С ${formatWithLocale(
        parseISO(this.discountInfo.from || '2020-11-20T10:36:01.516Z'),
        'd.MM.yyyy'
      )} по ${formatWithLocale(
        parseISO(this.discountInfo.to || '2022-11-20T10:36:01.516Z'),
        'd.MM.yyyy'
      )}`
    },
    getBaseUrl() {
      return process.env.VUE_APP_API_BASE_URL
    }
  },
  methods: {
    clickLink() {
      this.$emit('clickLink', this.discountInfo.id)
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
