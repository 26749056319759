<template>
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.74908 5.94819C1.38639 7.82563 2.00278 9.97786 3.446 11.4211C5.76861 13.7437 9.55434 13.728 11.8963 11.3861C14.2382 9.04414 14.2539 5.25841 11.9313 2.9358C10.4162 1.42069 8.27847 0.900602 6.32508 1.37219"
      stroke="#232F37"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  name: 'PreloaderIcon'
}
</script>
