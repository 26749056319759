<template>
  <form class="auth-form" @submit.prevent="submit">
    <h2 class="auth-form__title">Остался финальный шаг</h2>
    <div class="auth-form__inner">
      <p class="auth-form__description">
        Расскажите нам о себе, чтобы у вас была возможность записываться к врачу
        и пользоваться нашими медицинскими услугами
      </p>
      <div class="registration-inputs">
        <p>Как вас зовут?</p>
        <SmInput
          type="text"
          id="name"
          placeholder="Имя"
          :value="values.name"
          :onChange="(e) => (this.values[e.target.name] = e.target.value)"
          :classes="['auth-input']"
          :invalid="validateState('name')"
          :cyrillic-only="true"
        />
        <SmInput
          type="text"
          id="surname"
          placeholder="Фамилия"
          :value="values.surname"
          :onChange="(e) => (this.values[e.target.name] = e.target.value)"
          :classes="['auth-input']"
          :invalid="validateState('surname')"
          :cyrillic-only="true"
        />
        <SmInput
          type="text"
          id="patronymic"
          placeholder="Отчество (если есть)"
          :value="values.patronymic"
          :onChange="(e) => (this.values[e.target.name] = e.target.value)"
          :classes="['auth-input']"
          :cyrillic-only="true"
        />
        <!--        <SmInput-->
        <!--          type="phone"-->
        <!--          id="phone"-->
        <!--          placeholder="Введите телефон"-->
        <!--          :value="this.values.phone"-->
        <!--          :onChange="(e) => (this.values[e.target.name] = e.target.value)"-->
        <!--          :classes="['auth-input']"-->
        <!--          :onClick="() => (this.values.phone = '+7 ')"-->
        <!--          v-mask="['+7 (###) ###-##-##']"-->
        <!--        />-->
      </div>
      <div class="registration-inputs">
        <p>Дата рождения</p>
        <SmDatepicker
          :invalid="validateState('birthday') || birthdayError"
          :value="this.values.birthday"
          :onChange="(val) => (this.values.birthday = val)"
        />
      </div>
      <div
        class="registration-inputs registration-inputs--mb"
        v-if="citiesOptions.length"
      >
        <p>Ваш город</p>
        <SmSelect
          v-model="cityModel"
          :onChange="onCityChange"
          :options="this.citiesOptions"
          placeholder="Город"
        />
      </div>
      <Button
        :classes="['auth-form__btn']"
        icon="arrow"
        :disabled="loading"
        :loading="loading"
      >
        Продолжить
      </Button>
      <div class="has-error" v-if="errorAuth.message || birthdayError">
        <ErrorToast v-if="errorAuth" :text="errorAuth.message" />
        <ErrorToast v-if="birthdayError" :text="birthdayError" />
      </div>
      <div
        class="has-error"
        v-if="this.$v.values.$anyDirty && this.$v.values.$anyError"
      >
        <ErrorToast :text="'Обязательные поля'" />
      </div>
    </div>
  </form>
</template>
<script>
import SmInput from '@/components/input'
import SmDatepicker from '@/components/datepicker'
import SmSelect from '@/components/select'
import Button from '@/components/button'
import ErrorToast from '@/components/errorToast'
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { TRACKER_SLUGS } from '@/models/tracker'

export default {
  name: 'Registration',
  // eslint-disable-next-line vue/no-unused-components
  components: {
    SmInput,
    SmDatepicker,
    SmSelect,
    Button,
    ErrorToast
  },
  validations: {
    values: {
      birthday: { required },
      surname: { required },
      name: { required }
    }
  },
  data() {
    return {
      cityModel: '',
      values: {
        name: '',
        surname: '',
        patronymic: '',
        birthday: '',
        cityId: ''
      },
      loading: false,
      birthdayError: null
    }
  },
  async mounted() {
    await this.getCities()
    if (this.citiesOptions?.length) {
      this.cityModel =
        this.citiesOptions.find(
          (city) => city.value === 'dd89b529-46c7-11e4-80c5-002590ac1d8d'
        ) || this.citiesOptions[0]
      this.values.cityId = this.cityModel.value
    }
  },
  methods: {
    ...mapActions('cities', ['getCities']),
    ...mapActions('auth', ['registration', 'clearAuthError']),
    async submit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.loading = true
        const dateParts = this.values.birthday.split('.')
        const dateObject = new Date(
          +dateParts[2],
          dateParts[1] - 1,
          +dateParts[0]
        )
        if (dateObject > new Date()) {
          this.birthdayError =
            'Невозможно выставить будущую дату в поле "Дата рождения"'
          this.loading = false
          return
        } else {
          this.birthdayError = null
        }
        this.$tracker(TRACKER_SLUGS.REG_USER_INFO)
        await this.registration({
          ...this.values,
          phone: this.getPhoneSignIn.phone
        })
        this.loading = false
      }
    },
    validateState(name) {
      const { $dirty, $error, $model, $invalid } = this.$v.values[name]
      if ($model === null) return null
      if (!$model.length && !$invalid) return null
      return $dirty ? $error : null
    },
    onCityChange(val) {
      this.values.cityId = val.value
    }
  },
  computed: {
    ...mapGetters('cities', ['citiesData']),
    ...mapGetters('auth', ['getPhoneSignIn', 'errorAuth']),
    citiesOptions() {
      return this.citiesData
        ? this.citiesData.map((instance) => ({
            label: instance.title,
            value: instance.id
          }))
        : []
    }
  },
  beforeRouteLeave(to, from, next) {
    next()
    this.clearAuthError()
  }
}
</script>
<style lang="scss" scoped>
@import '../style.scss';
</style>
