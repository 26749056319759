import { apiV1 } from '@/config/request'

export default {
  async getAnalyses(
    { commit, rootState },
    {
      accountId = rootState.account.account.id,
      patientId = rootState.account.patient.id,
      ...payload
    }
  ) {
    commit('setAnalysesLoading')
    try {
      const { data } = await new apiV1({ auth: true }).get('/bx/analyses', {
        params: { accountId, patientId, ...payload }
      })

      for (let instance of data?.analyses?.content) {
        instance.biomaterialId =
          data?.biomaterials[instance.biomaterialId] ?? {}
      }

      commit('setAnalysesData', data?.analyses ?? {})
      commit('setPaginationData', {
        size: data?.analyses.size,
        totalPages: data?.analyses.totalPages
      })
      commit('setAnalysesLoading')
    } catch (e) {
      commit('setListAnalysesError', `${e}`)
      commit('setAnalysesLoading')
    }
  },
  async getFilteredAnalyses(
    { commit, rootState },
    {
      accountId = rootState.account.account.id,
      patientId = rootState.account.patient.id,
      from,
      to,
      page,
      size,
      sort,
      merge = false,
      ...payload
    }
  ) {
    if (!merge) {
      commit('setAnalysesLoading')
    }

    try {
      const { data } = await new apiV1({ auth: true }).post(
        '/bx/analyses/filter',
        { patientId, from, to, ...payload },
        {
          params: { accountId, page, size, sort }
        }
      )

      for (let instance of data?.analyses?.content) {
        instance.biomaterialId =
          data?.biomaterials[instance.biomaterialId] ?? {}
      }

      if (!merge) {
        commit('setAnalysesData', data?.analyses ?? {})
      } else {
        commit('updateAnalysesData', data?.analyses ?? {})
      }

      commit('setPaginationData', {
        page: payload.page,
        size: data?.analyses.size,
        totalPages: data?.analyses.totalPages
      })
      if (!merge) {
        commit('setAnalysesLoading')
      }
    } catch (e) {
      if (!merge) {
        commit('setAnalysesLoading')
      }
      commit('setListAnalysesError', `${e}`)
    }
  },
  async getAnalysisById(
    { commit, rootState },
    { id, accountId = rootState.account.account.id }
  ) {
    commit('setSingleAnalysisLoading')
    try {
      const { data } = await new apiV1({ auth: true }).get(
        `/bx/analyses/${id}`,
        {
          params: {
            accountId
          }
        }
      )

      const clinic =
        data.analysis.analysisClinic ?? data.analysis.appointmentClinic

      data.analysis.clinicInfo = clinic
      data.analysis.clinicInfo.subway = clinic['subway-stations']
        ? clinic['subway-stations'][0]
        : {}
      data.analysis.doctorInfo = data.analysis.doctor ?? null

      commit('setAnalysisById', data?.analysis ?? {})
      commit('setSingleAnalysisLoading')
    } catch (e) {
      console.log(e)
      commit('setSingleAnalysisError', `${e}`)
      commit('setSingleAnalysisLoading')
    }
  },
  async getAnalysesFilters(
    { commit, rootState },
    {
      accountId = rootState.account.account.id,
      patientId = rootState.account.patient.id
    }
  ) {
    try {
      const { data } = await new apiV1({ auth: true }).get(
        '/filter-values/analyses',
        {
          params: { accountId, patientId }
        }
      )
      // data.biomaterials = data.biomaterials?.map?.((el) => ({
      //   ...el,
      //   direction: el.title
      // }))
      commit('setAnalysesFilters', data)
    } catch (e) {
      console.log(e)
    }
  },
  async getAnalysesFiltersPost(
    { commit, rootState },
    {
      accountId = rootState.account.account.id,
      patientId = rootState.account.patient.id,
      ...payload
    }
  ) {
    try {
      const { data } = await new apiV1({ auth: true }).post(
        '/filter-values/analyses',
        {
          ...payload
        },
        {
          params: { accountId, patientId }
        }
      )
      commit('setAnalysesFilters', data)
    } catch (e) {
      console.log(e)
    }
  },
  clearAnalysesRequestState({ commit }) {
    commit('clearAnalysesRequestState')
  },

  async getClinicsList({ commit }, { analysisId, cityId }) {
    commit('setClinicsListByAnalysisLoading')
    try {
      const { data } = await new apiV1({ auth: true }).get(
        `/bx/clinics/by-analysis`,
        {
          params: {
            analysisId,
            cityId
          }
        }
      )

      commit('setClinicsListByAnalysis', data?.clinics ?? {})
      commit('setClinicsListByAnalysisLoading')
    } catch (e) {
      commit('setClinicsListByAnalysisError', `${e}`)
      commit('setClinicsListByAnalysisLoading')
    }
  }
}
