import { format, isToday, isTomorrow, parseISO, compareAsc } from 'date-fns'
import { ru } from 'date-fns/locale'

const getStatusMessage = (errorCode) => {
  let message
  switch (errorCode) {
    case '00010001':
      message =
        'Ошибка авторизации. Пожалуйста, попробуйте ввести логин и пароль снова'
      break
    case '00010007':
    case '00010014':
      message =
        'Не удалось найти аккаунт с такими данными. Пожалуйста, проверьте введенные данные и попробуйте снова.'
      break
    case '00010009':
    case '00010016':
      message =
        'Не удалось найти аккаунт с такими данными. Пожалуйста, проверьте введенные данные и попробуйте снова.'
      break
    case '00010002':
      message =
        'Превышено количество попыток входа. Повторите вход в приложение через 10 минут.'
      break
    case '00010003':
      message =
        'Превышено количество попыток входа. Для восстановления доступа обратитесь в регистратуру клиники'
      break
    case '00010024':
      message =
        'Не удалось отправить СМС с кодом подтверждения, проверьте правильность введённых данных'
      break
    case '00010004':
      message = 'Неверный код подтверждения'
      break
    case '000100010':
    case '00010017':
      message =
        'К сожалению, возникли проблемы с авторизацией в приложении. Войдите с помощью логина и пароля или обратитесь с паспортом в регистратуру клиники '
      break
    case '00010018':
      message =
        'Ваш аккаунт ограничен. Для разблокировки аккаунта обратитесь в регистратуру клиники холдинга с паспортом.'
      break
    case '00010019':
      message = 'Нет доступа в ЛК, обратитесь в регистратуру.'
      break
    case '00010020':
      message =
        'Ваш телефон находится в черном списке. Для разблокировки номера телефона обратитесь в регистратуру клиники холдинга с паспортом.'
      break
    case '00010023':
      message = 'Учетная запись не найдена, проверьте корректность данных '
      break
    case '00010012':
      message =
        'Учетная запись с таким телефоном не найдена, проверьте корректность данных'
      break
    default:
      message = 'Что то пошло не так. Попробуйте позже.'
      break
  }
  return { errorCode, message }
}
const getDoctorExp = (experience) => {
  let message = ''
  if (experience > 3 && experience < 5) {
    message = 'Врач 2-й категории'
  } else if (experience >= 5 && experience < 7) {
    message = 'Врач 1-й категории'
  } else if (experience >= 7) {
    message = 'Врач высшей категории'
  }
  return message
}

const parseTokenData = (accessToken) => {
  let payload = ''
  let tokenData = {}

  try {
    payload = accessToken.split('.')[1]
    tokenData = JSON.parse(atob(payload))
  } catch (error) {
    throw new Error(error)
  }
  return tokenData
}

const debounce = (inner, ms = 0) => {
  let timer = null
  let resolves = []

  return function () {
    clearTimeout(timer)
    timer = setTimeout(() => {
      const result = inner()
      resolves.forEach((r) => r(result))
      resolves = []
    }, ms)

    return new Promise((resolve) => resolves.push(resolve))
  }
}

function declOfNum(n, text_forms) {
  n = Math.abs(n) % 100
  const n1 = n % 10
  if (n > 10 && n < 20) {
    return text_forms[2]
  }
  if (n1 > 1 && n1 < 5) {
    return text_forms[1]
  }
  if (n1 === 1) {
    return text_forms[0]
  }
  return text_forms[2]
}

function formatWithLocale(date, formatStr = 'PP') {
  return format(date, formatStr, {
    locale: ru
  })
}

function getWeekday(date) {
  const _date = parseISO(date)
  if (isToday(_date)) return 'Сегодня'
  if (isTomorrow(_date)) return 'Завтра'
  return formatWithLocale(_date, 'eeee')
}

function groupDayOptions(data) {
  return Object.values(
    data.reduce((prev, next) => {
      const key = formatWithLocale(
        parseISO(next.dateTime, 'yyyy-dd-MM'),
        'yyyy-dd-MM'
      )
      if (!prev[key])
        prev[key] = {
          label: formatWithLocale(
            parseISO(next.dateTime, 'yyyy-dd-MM'),
            `dd MMMM, ${getWeekday(next.dateTime)}`
          ),
          value: next.dateTime
        }
      return prev
    }, {})
  ).sort((a, b) => compareAsc(parseISO(a.value), parseISO(b.value)))
}

function getFullDate(date, fromFirstDay = false) {
  if (fromFirstDay) {
    const result = formatWithLocale(parseISO(date), 'iiii, dd MMMM, p')
    return result.charAt(0).toUpperCase() + result.slice(1)
  }

  return formatWithLocale(parseISO(date), 'dd MMMM в p, iiii')
}

function getFormatNumber(phone) {
  let val = phone.replace(/\D[^/.]/g, '')
  if (!val.length) return ''
  if (val.length === 10) val = 7 + val
  return `+${val.slice(0, 1)} (${val.slice(1, 4)})
      ${val.slice(4, 7)}-${val.slice(7, 9)}-${val.slice(9, 11)}`
}
//TODO: Убрать если не будет использоваться
// function verticalSort(alphabet) {
//   function alphabetIndex(c) {
//     return c.charCodeAt(0) - 'А'.charCodeAt(0)
//   }
//
//   let rowSize = 4
//   let colSize = Math.ceil(alphabet.length / rowSize)
//   function sortKey(c) {
//     return (
//       rowSize * (alphabetIndex(c) % colSize) +
//       Math.floor(alphabetIndex(c) / colSize)
//     )
//   }
//
//   alphabet.sort(function (a, b) {
//     return sortKey(a) - sortKey(b)
//   })
//
//   return alphabet
// }

export {
  getStatusMessage,
  getWeekday,
  parseTokenData,
  debounce,
  declOfNum,
  formatWithLocale,
  groupDayOptions,
  getFullDate,
  getDoctorExp,
  getFormatNumber
}
