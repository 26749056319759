<template>
  <div class="profile">
    <div class="profile__item profile__item--status">
      <div class="title ym-hide-content">
        <span>{{ getFullName(user.name, user.surname) }}</span>
        <div @click.stop="openModal">
          <ExitIcon />
        </div>
      </div>
      <div class="contract" v-if="hasContract">
        <span>Ваша карта</span>
        <span>{{ user.ambulatoryCards[0] }}</span>
      </div>
      <div class="contract" v-if="!hasContract">
        <AlertIcon />
        <span>Оформите договор в клинике</span>
      </div>
      <div class="discount">
        <div class="num">
          <span>{{ user.discount }}</span>
          <span>%</span>
        </div>
        <span class="text">
          Накопительная скидка
          <SmTooltip
            :trigger="windowWidth >= 1024 ? 'mouseenter' : ''"
            name="profile-discount"
            :interactive="true"
          >
            <template v-slot:trigger>
              <InfoIcon />
            </template>
            <template v-slot:tooltip>
              <DiscountTooltip />
            </template>
          </SmTooltip>
        </span>
      </div>
      <router-link
        class="link"
        to="/profile"
        custom
        v-slot="{ navigate }"
        @click.native="close"
      >
        <span @click="navigate" role="link">
          <SettingsIcon /> Управление профилем
        </span>
      </router-link>
    </div>
    <div class="profile__item">
      <h4 class="title">Ваш баланс</h4>
      <div class="payment-container">
        <SmTooltip
          :trigger="windowWidth >= 1024 ? 'mouseenter' : ''"
          name="profile-payment"
          :interactive="true"
        >
          <template v-slot:trigger>
            <div class="payment">
              <p>Остаток</p>
              <p>{{ user.prepaymentSum }}&nbsp;<RubIcon /></p>
            </div>
          </template>
          <template v-slot:tooltip>
            Остаток денежных средств, внесенных на медицинские услуги
          </template>
        </SmTooltip>

        <div class="payment duty">
          <p>Долг</p>
          <p>{{ user.debtSum }}&nbsp;<RubIcon /></p>
        </div>
      </div>
    </div>
    <div class="profile__item">
      <div class="title-wrapper" v-if="patientIds && patientIds.length">
        <EditIcon />
        <h4 class="title">Сменить профиль пациента</h4>
      </div>
      <div class="patient-profiles">
        <div
          class="patient-profile"
          v-for="patient of patientIds"
          :key="patient.id"
          @click="() => openChangePatientModal(patient.id)"
        >
          <div>
            <p class="patient-profile__full-name">
              {{
                getFullName(patient.surname, patient.name, patient.patronymic)
              }}
            </p>
            <PassportInfo
              :passport="patient.barCode"
              :birthday="patient.birthday"
            />
          </div>
          <ArrowDownIcon />
        </div>
      </div>
    </div>
    <div class="profile__item" v-if="!hasContract">
      <h4 class="title">Оформите договор</h4>
      <p class="description">
        Пожалуйста, заключите договор и оформите медицинскую карту - они
        требуются для оказания услуг. Это можно сделать, обратившись лично в
        регистратуру клиники с паспортом. При записи на приём ребёнка
        потребуется его свидетельство о рождении и паспорт сопровождающего
        законного представителя.
      </p>
    </div>
    <Button
      :onClick="() => goTo('/map')"
      :classes="'search-btn'"
      icon="locate"
      v-if="!hasContract"
    >
      Найти клинику на карте
    </Button>
    <SmModal size="md" name="sign-out">
      <template v-slot:body>
        <h3 class="signOut-body">
          Вы уверены, что хотите выйти из личного кабинета
        </h3>
      </template>
      <template v-slot:footer>
        <div class="signOut-buttons">
          <Button :onClick="closeModal" secondary> Отмена </Button>
          <Button :onClick="signOutHandler"> Выйти </Button>
        </div>
      </template>
    </SmModal>
    <ApproveModal
      :action-handler="changePatient"
      action="сменить профиль пациента"
    />
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import Button from '@/components/button'
import PassportInfo from '@/components/passportInfo'
import DiscountTooltip from '@/components/discountTooltip'
import SmModal from '@/components/modal'
import AlertIcon from '@/assets/icons/alert'
import SettingsIcon from '@/assets/icons/settings'
import SmTooltip from '@/components/tooltip'
import InfoIcon from '@/assets/icons/info'
import EditIcon from '@/assets/icons/edit'
import ArrowDownIcon from '@/assets/icons/arrow-down'
import ExitIcon from '@/assets/icons/exit'
import RubIcon from '@/assets/icons/rub'
import ApproveModal from '@/components/modal/profileModals/approveModal'
import { TRACKER_SLUGS } from '@/models/tracker'

export default {
  name: 'SmSlideProfile',
  components: {
    ApproveModal,
    RubIcon,
    SmModal,
    ExitIcon,
    ArrowDownIcon,
    PassportInfo,
    EditIcon,
    InfoIcon,
    SmTooltip,
    Button,
    AlertIcon,
    SettingsIcon,
    DiscountTooltip
  },
  props: {
    close: {
      type: Function,
      default: () => {}
    },
    user: Object,
    account: Object,
    patient: Object,
    hasContract: Boolean
  },
  data() {
    return {
      lastPatientClick: ''
    }
  },
  methods: {
    ...mapActions('account', ['changeAccount']),
    ...mapActions('auth', ['signOut']),
    goTo(url) {
      this.$router.push(url)
      this.close()
    },
    getFullName(name, surname, patronymic) {
      if (patronymic) {
        return `${name} ${surname} ${patronymic}`
      }
      return `${name} ${surname}`
    },
    changePatient() {
      this.changeAccount(this.lastPatientClick)
      window.location.reload()
      this.$modals.hide('approve-modal')
    },
    signOutHandler() {
      this.$tracker(TRACKER_SLUGS.ROUTE_EXIT)
      this.closeModal()
      this.close()
      setTimeout(() => this.signOut(), 500)
    },
    closeModal() {
      this.$modals.hide('sign-out')
    },
    openModal() {
      this.$modals.show('sign-out')
    },
    openChangePatientModal(patient) {
      this.lastPatientClick = patient
      this.$modals.show('approve-modal')
    }
  },
  computed: {
    patientIds() {
      return this.account.patientIds.filter(({ id }) => this.patient.id !== id)
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style';
.profile::v-deep
  .sm-modal
  .v-modal__wrapper
  .v-modal__content
  .v-modal__panel
  .v-modal__heading
  .v-modal__title
  > div {
  background-size: cover;
}
</style>
