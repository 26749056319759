<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.9 15.1V11.9349L11.5621 1.27279L14.7272 4.43789L4.06509 15.1H0.9Z"
      stroke="url(#paint0_linear_3499_83908)"
      stroke-width="1.8"
    />
    <line
      x1="9.64361"
      y1="3.32123"
      x2="12.6812"
      y2="6.35885"
      stroke="url(#paint1_linear_3499_83908)"
      stroke-width="1.5"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3499_83908"
        x1="0.909621"
        y1="24.058"
        x2="22.0045"
        y2="12.5015"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3499_83908"
        x1="8.22275"
        y1="5.08748"
        x2="10.8932"
        y2="4.00971"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'EditIcon'
}
</script>
