<template>
  <div class="chat-wrapper-iframe" style="pointer-events: visible">
    <div class="iframe-wrapper" :class="{ show: this.showChat }">
      <iframe class="iframe-chat" id="webim-chat-iframe" style="border: none" />
      <div class="close-button" @click="onExit">&#x2715;</div>
    </div>
    <div
      :class="{ show: !this.showChat }"
      class="chat-button"
      @click="onClick"
      v-if="getPatientInfo && getPatientInfo.accountId"
    >
      <OpenChatIcon />
    </div>
  </div>
</template>

<script>
import OpenChatIcon from '@/assets/icons/open-chat'
import { mapGetters } from 'vuex'
import { TRACKER_SLUGS } from '@/models/tracker'

export default {
  name: 'Chat',
  components: {
    OpenChatIcon
  },
  data() {
    return {
      showChat: false,
      hash: ''
    }
  },
  watch: {
    getChatState(v) {
      if (v) {
        window.webim.api.chat.start()
      } else {
        window.webim.api.chat.close()
      }
    },
    showChat(show) {
      if (document.body.clientWidth < 1024) {
        if (show) {
          document.body.style.overflow = 'hidden'
          document.documentElement.style.touchAction = 'none'
          document.body.style.pointerEvents = 'none'
          document.querySelector('.layout').style.pointerEvents = 'none'
          // document
          //   .querySelector('.layout')
          //   .addEventListener('click', (evt) => evt.stopPropagation())
        } else {
          document.body.style.overflow = ''
          document.documentElement.style.touchAction = ''
          document.body.style.pointerEvents = ''
          document.querySelector('.layout').style.pointerEvents = ''
          // document
          //   .querySelector('.layout')
          //   .removeEventListener('click', (evt) => evt.stopPropagation())
        }
      }
    }
  },
  methods: {
    onClick() {
      this.$tracker(TRACKER_SLUGS.CONSULTANT_CHAT)
      window.webim.api.chat.start()
    },
    onExit() {
      window.webim.api.chat.close()
    }
  },
  computed: {
    ...mapGetters('account', ['getPatientInfo', 'getChatState'])
  },
  async mounted() {
    window.webimHandlers = {
      onInit: function () {
        // получает на входе объект {online: true/false, onlineStatus: 'online'/'busy_online'/'offline'/'busy_offline'}
        // здесь следует инициализировать внешний вид кнопки старта чата в зависимости от статуса и сделать ее видимой
      },

      onChatShownStateChanged: (event) => (this.showChat = event.shown),

      onChatReadChanged: function () {
        // получает на входе объект {read: }
        // вызывается, когда меняется статус прочитанности чата посетителем
        // здесь следует включать/выключать визуальную сигнализацию о непрочитанности, например "мигание" заголовком страницы
      },

      onTeleport: function () {
        // получает на входе объект {url: 'https://...'}
        // вызывается, когда оператор воcпользовался функцией "Телепорт" - перенаправление посетителя на заданную страницу
        // стандартное решение здесь - выполнить:
        // window.location.href = event.url;
      },

      onProvidedTokenNotFoundError: function () {
        // *не используется с iframe, но может быть использован в других случаях (обычный виджет чата или мобильное приложение)*
        // вызывается, когда auth_token не обнаружен (обрабатывает событие прихода ошибки от сервера Webim 'provided-auth-token-not-found' (см. Webim Realtime API))
        // в случае отсутствия auth_token, обработчик должен инициировать повторную отправку с бэкенда организации POST-запроса в Webim
      }
    }
    window.webim.api.init()
  }
}
</script>

<style scoped lang="scss">
@import 'style';
</style>
