<template>
  <div class="admission-info-wrapper">
    <div class="admission-info">
      <div class="admission-date">
        <p class="admission-info__title">Дата приема</p>
        <h2>
          {{ getFullDate(admissionInfo.dateTime, true) }}
        </h2>
        <h3>
          {{ admissionInfo.clinic.clinicName }}
        </h3>
        <div>
          <LocateIcon />
          <p class="admission-info__grey-text">
            {{ admissionInfo.clinic.address }}
          </p>
        </div>
        <div v-if="admissionInfo.clinic['subway-stations']">
          <SubwayIcon
            :color="
              admissionInfo.clinic['subway-stations'][0].color &&
              admissionInfo.clinic['subway-stations'][0].color.length
                ? `#${admissionInfo.clinic['subway-stations'][0].color[0]}`
                : 'purple'
            "
          />
          <p class="admission-info__grey-text">
            {{ admissionInfo.clinic['subway-stations'][0].title }}
          </p>
        </div>
      </div>
      <div v-if="type === 'analiseCard'" class="analyse-info ym-hide-content">
        <div class="patient-info">
          <p class="admission-info__title">Пациент</p>
          <h3 class="patient-info__name">
            {{
              `${admissionInfo.patient.name} ${admissionInfo.patient.surname} ${admissionInfo.patient.patronymic}`
            }}
          </h3>
          <PassportInfo
            :passport="admissionInfo.patient.ambulatoryCards[0]"
            :birthday="admissionInfo.patient.birthday"
          />
        </div>
        <div class="patient-info">
          <p class="admission-info__title">Назначивший врач</p>
          <h3 class="patient-info__name">
            {{ doctorInfo ? doctorInfo.fullName : '' }}
          </h3>
        </div>
      </div>
      <div class="patient-info ym-hide-content" v-else>
        <p class="admission-info__title">Пациент</p>
        <h3 class="patient-info__name">
          {{
            `${admissionInfo.patient.name} ${admissionInfo.patient.surname} ${admissionInfo.patient.patronymic}`
          }}
        </h3>
        <PassportInfo
          :passport="admissionInfo.patient.ambulatoryCards[0]"
          :birthday="admissionInfo.patient.birthday"
        />
      </div>
    </div>
    <div class="admission-info__details">
      <template v-if="admissionInfo.status === 'UPCOMING'">
        <div class="price-info" v-if="admissionInfo.price">
          <div class="price-title">
            Предварительная стоимость приема
            <SmTooltip
              :trigger="windowWidth >= 1024 ? 'mouseenter' : ''"
              name="admission-discount"
              :interactive="true"
            >
              <template v-slot:trigger>
                <InfoIcon />
              </template>
              <template v-slot:tooltip>
                <DiscountTooltip />
              </template>
            </SmTooltip>
          </div>
          <h2 class="price-text">
            {{ admissionInfo.price }}
          </h2>
        </div>
        <div class="button-wrapper" v-if="admissionInfo.status === 'UPCOMING'">
          <Button
            secondary
            icon="change-time"
            :onClick="() => handleButtonClick('transfer')"
          >
            Перенести
          </Button>
          <Button
            classes="cancel"
            icon="cancel"
            :onClick="() => handleButtonClick('cancel')"
          >
            Отменить
          </Button>
        </div>
      </template>
      <template v-else>
        <div v-if="admissionInfo.status === 'FINISHED'" class="status">
          <IconSuccessRounded />
          <span>Прием завершен</span>
        </div>
        <div v-else class="status">
          <IconCancelRounded />
          <span>Прием отменен</span>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import LocateIcon from '@/assets/icons/locate'
import InfoIcon from '@/assets/icons/info'
import Button from '@/components/button'
import SubwayIcon from '@/assets/icons/subway'
import PassportInfo from '@/components/passportInfo'
import IconSuccessRounded from '@/assets/icons/success-rounded'
import IconCancelRounded from '@/assets/icons/cancel-rounded'
import { AnalysisTypes, PaymentStatusTypes } from '@/core/constants'
import { getFullDate } from '@/core/utils'
import SmTooltip from '@/components/tooltip'
import DiscountTooltip from '@/components/discountTooltip'

export default {
  name: 'AdmissionInfoCard',
  components: {
    DiscountTooltip,
    SmTooltip,
    SubwayIcon,
    IconSuccessRounded,
    IconCancelRounded,
    Button,
    InfoIcon,
    LocateIcon,
    PassportInfo
  },
  data() {
    return {
      getFullDate,
      AnalysisTypes: Object.freeze(AnalysisTypes),
      PaymentStatusTypes: Object.freeze(PaymentStatusTypes)
    }
  },
  props: {
    type: String,
    admissionInfo: {
      dateTime: String,
      status: String,
      paymentStatus: String,
      doctor: String
    },
    patientInfo: {
      name: String,
      surname: String,
      birthday: String,
      barCode: String
    },
    doctorInfo: {
      fullName: String
    },
    clinicInfo: {
      clinicName: String,
      address: String
    },
    userInfo: {
      name: String,
      surname: String,
      birthday: String,
      barCode: String
    },
    handleButtonClick: {
      type: Function,
      default: () => {}
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style';
</style>
