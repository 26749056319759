<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="onClick"
  >
    <circle cx="10" cy="10" r="8.1" stroke="white" stroke-width="1.8" />
    <path
      d="M10 12L12.5 9H7.5L10 12Z"
      fill="white"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  name: 'ArrowDownCircleIcon',
  props: {
    onClick: {
      type: Function,
      default: () => {}
    }
  }
}
</script>
