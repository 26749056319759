<template>
  <section class="appointment specializations">
    <SmCrumbs />
    <h2 class="appointment__title">Новая запись</h2>
    <div class="find-doctors">
      <SearchInput
        :classes="'search-input'"
        placeholder="Специализация"
        :onChange="inputChangeHandler"
      />
      <p class="find-doctors__text" v-if="windowWidth >= 1024">
        Найдите нужную специальность врача
      </p>
      <p class="find-doctors__text" v-else>
        Найдите нужную специальность врача
      </p>
    </div>
    <div class="choose-doctors-container">
      <div class="buttons-wrapper" v-if="prevSpecItems && prevSpecItems.length">
        <p>Последние обращения:</p>
        <div class="buttons-inner">
          <Button
            v-for="item in prevSpecItems"
            :key="item.id"
            secondary
            classes="search-buttons"
            :onClick="
              () => {
                $router.push({
                  path: `/appointment/${item.id}`,
                  query: $route.query.clinicId
                    ? { clinicId: $route.query.clinicId }
                    : ''
                })
              }
            "
          >
            {{ item.title }}
          </Button>
        </div>
      </div>
      <div class="choose-doctors-area">
        <template v-if="specializationsData.loading">
          <div>
            <Loader
              :loading="specializationsData.loading"
              :error="specializationsData.error"
            />
          </div>
        </template>
        <template v-else>
          <h3 class="choose-doctors-title">Выберите специалиста:</h3>
          <div class="choose-doctors-wrapper" v-if="specializations.length">
            <div
              class="specializations-list"
              v-for="(specializations, index) of specializations"
              :key="index"
            >
              <h4>{{ specializations.letter }}</h4>
              <p
                v-for="(
                  specialization, index
                ) of specializations.specializationsByLetters"
                :key="index"
                v-on:click="chooseSpecialization(specialization)"
              >
                {{ specialization.title }}
              </p>
            </div>
          </div>
          <div v-else>Ничего не найдено</div>
        </template>
      </div>
    </div>
  </section>
</template>
<script>
import SearchInput from '@/components/searchInput'
import Button from '@/components/button'
import { mapActions, mapGetters } from 'vuex'
import { alphabet } from '@/core/constants'
import _, { debounce } from 'lodash'
import SmCrumbs from '@/components/breadcrumbs'
import Loader from '@/components/loader'

export default {
  name: 'AppointmentPage',
  components: { SmCrumbs, SearchInput, Button, Loader },
  data() {
    return {
      filteredSpecializations: [],
      searchValue: '',
      lastSpecialization: null
    }
  },
  async mounted() {
    this.getAvailableSpecializations({ clinicId: this.$route.query.clinicId })
    this.lastSpecialization = await this.getLastSpecializations()
  },
  methods: {
    ...mapActions('appointment', [
      'getAvailableSpecializations',
      'updatePrevSpec',
      'getLastSpecializations'
    ]),
    chooseSpecialization({ id, title }) {
      this.updatePrevSpec({ id, title })
      const clinicId = this.$route.query.clinicId
      const query = { clinicId }
      this.$router.push({
        path: `/appointment/${id}`,
        query
      })
    },
    inputChangeHandler: debounce(function (e) {
      this.specSearch(e.target.value)
    }, 500),
    specSearch(val) {
      this.searchValue = val
      if (val.length > 2) {
        this.filteredSpecializations = this.allSpecializations
          .map(({ letter, specializationsByLetters }) => {
            return {
              letter,
              specializationsByLetters: specializationsByLetters.reduce(
                (prev, next) => {
                  const search = next.title
                    .toLowerCase()
                    .includes(val.toLowerCase())
                  return [...prev, ...(search ? [next] : [])]
                },
                []
              )
            }
          })
          .filter(
            ({ specializationsByLetters }) => specializationsByLetters.length
          )
      }
    }
  },
  computed: {
    ...mapGetters('appointment', ['specializationsData', 'prevSpec']),
    specializations() {
      return this.searchValue && this.searchValue.length > 2
        ? this.filteredSpecializations
        : this.allSpecializations
    },
    allSpecializations() {
      const re = /^[а-я]$/i

      return Object.entries(
        _.groupBy(this.specializationsData.data, ({ title }) =>
          re.test(title.trim().charAt(0))
            ? title.trim().charAt(0).toUpperCase()
            : '-'
        )
      )
        .map(([key, value]) => ({
          letter: key,
          specializationsByLetters: value.map((spec) => ({
            ...spec,
            title: spec.title
          }))
        }))
        .sort(
          (a, b) =>
            re.test(b.letter) - re.test(a.letter) ||
            alphabet.indexOf(a.letter) - alphabet.indexOf(b.letter)
        )
    },
    prevSpecItems() {
      const result = this.lastSpecialization ? this.lastSpecialization : []

      return result.length > 3 ? result.slice(-3) : result
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
