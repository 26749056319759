const initialState = () => ({
  loginSignIn: {
    username: '',
    password: '',
    isOfferAccepted: false
  },
  phoneSignIn: {
    phone: '',
    name: '',
    birthday: '',
    isOfferAccepted: false
  },
  token: {
    val: '',
    exp: ''
  },
  auth_error: '',
  redirect_error: '',
  passwordRecovery: {
    phone: '',
    name: '',
    birthday: ''
  },
  registration: {
    name: '',
    surname: '',
    patronymic: '',
    birthday: '',
    cityId: '',
    isOfferAccepted: false
  },
  loading: false
})

export default {
  initialState: initialState(),
  ...initialState()
}
