<template>
  <div class="aside-menu-mobile">
    <div class="container">
      <div class="aside-menu-mobile__inner">
        <Location />
        <div class="button-container">
          <Button
            :onClick="() => this.$router.push('/map')"
            v-tracker="TRACKER_SLUGS.PROCEED_CLINIC_FIND"
            icon="locate"
            secondary
            :classes="[isSpec ? '' : 'hide']"
            v-if="!isMap"
          >
            Найти клинику
          </Button>
          <Button
            icon="plus"
            :onClick="() => this.$router.push('/appointment')"
            v-tracker="TRACKER_SLUGS.CREATE_APPOINTMENT"
            :classes="[isSpec ? 'hide' : '']"
            v-if="!isSpec"
          >
            Записаться
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Button from '@/components/button'
import Location from '@/components/asideMenu/location'
import { TRACKER_SLUGS } from '@/models/tracker'
import pick from 'lodash/pick'
export default {
  name: 'AsideMenuMobile',
  components: { Location, Button },
  computed: {
    isSpec() {
      return this.$route.meta.isSpec
    },
    isMap() {
      return this.$route.meta.isMap
    }
  },
  created() {
    this.TRACKER_SLUGS = pick(TRACKER_SLUGS, [
      'CREATE_APPOINTMENT',
      'PROCEED_CLINIC_FIND'
    ])
  }
}
</script>
<style scoped lang="scss">
@import 'style';
</style>
