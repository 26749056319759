import state from './accountState'
import mutations from './accountMutations'
import getters from './accountGetters'
import actions from './accountActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
