<template>
  <header class="header">
    <div class="container">
      <div v-if="windowWidth > 1366" class="header-lg">
        <router-link class="logo-mobile" to="/" custom v-slot="{ navigate }">
          <img
            @click="navigate"
            role="link"
            alt="main-logo"
            src="@/assets/logo-mobile.svg"
          />
        </router-link>
        <SmSearchInput placeholder="Врач, специализация" style="opacity: 0" />
        <LoggedInUser />
      </div>
      <div v-else class="header-sm">
        <div class="header-inner">
          <div class="logo-wrap">
            <router-link
              class="logo-mobile"
              to="/"
              custom
              v-slot="{ navigate }"
            >
              <img
                @click="navigate"
                role="link"
                alt="main-logo"
                src="@/assets/logo-mobile.svg"
              />
            </router-link>
            <button
              v-if="windowWidth < 1023"
              class="mobile-burger"
              @click="showSettings"
            >
              <span></span>
            </button>
          </div>
          <LoggedInUser />
          <button
            v-if="windowWidth > 1023"
            class="mobile-burger"
            @click="showSettings"
          >
            <span></span>
          </button>
        </div>
        <SmSearchInput placeholder="Врач, специализация" style="opacity: 0" />
      </div>
    </div>
  </header>
</template>
<script>
import SmSearchInput from '@/components/searchInput'
import LoggedInUser from '@/components/loggedInUser'
import SmSlideSettings from '@/components/slideOutComponents/settings/settings'
export default {
  name: 'Header',
  components: { LoggedInUser, SmSearchInput },
  data() {
    return {}
  },
  methods: {
    showSettings() {
      this.$showPanel({
        component: 'SmSlideoutPanel',
        props: {
          title: 'Настройки',
          component: SmSlideSettings
        },
        openOn: 'right',
        width: '400',
        height: '80',
        sync: true,
        keepAlive: false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
