<template>
  <p class="passport-info">
    <span class="passport">{{ passport }}</span>
    <span>{{ convertedDate }}</span>
  </p>
</template>
<script>
import { differenceInYears, parseISO } from 'date-fns'
import { declOfNum, formatWithLocale } from '@/core/utils'

export default {
  name: 'PassportInfo',
  props: {
    passport: String,
    birthday: String
  },
  computed: {
    convertedDate() {
      const age = differenceInYears(new Date(), parseISO(this.birthday))
      return `${formatWithLocale(
        parseISO(this.birthday),
        'd MMMM yyyy'
      )} (${age} ${declOfNum(age, ['год', 'года', 'лет'])})`
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style';
</style>
