export default {
  setBiomaterialsData(state, payload) {
    state.list.data = payload
  },
  setBiomaterialById(state, payload) {
    state.single.data = payload
  },
  setBiomaterialsLoading(state) {
    state.list.loading = !state.list.loading
  },
  setSingleBiomaterialLoading(state) {
    state.single.loading = !state.single.loading
  },
  setListBiomaterialsError(state, payload) {
    state.list.error = payload
  },
  setSingleBiomaterialError(state, payload) {
    state.single.error = payload
  },
  setPaginationData(state, payload) {
    state.list.pagination = payload
  }
}
