var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"navbar",staticClass:"navbar-container"},[(_vm.withNavigate)?_c('ul',{staticClass:"navbar",on:{"mouseleave":_vm.mouseLeave,"mousedown":_vm.mouseDown,"mouseup":_vm.mouseUp,"mousemove":_vm.mouseMove}},_vm._l((_vm.options),function(ref,i){
var name = ref.name;
var to = ref.to;
var counter = ref.counter;
return _c('li',{key:i,ref:_vm.isActive(to) ? 'activetab' : '',refInFor:true,class:['navbar__item', _vm.isActive(to) ? 'active' : ''],on:{"click":function (e) {
          _vm.mouseClick(e, to)
        }}},[_c('p',[_c('span',{attrs:{"role":"link"}},[_vm._v(" "+_vm._s(name)+" "),(counter)?_c('span',{staticClass:"navbar__counter"},[_vm._v(" "+_vm._s(counter)+" ")]):_vm._e()])])])}),0):_c('ul',{staticClass:"navbar",on:{"mouseleave":_vm.mouseLeave,"mousedown":_vm.mouseDown,"mouseup":_vm.mouseUp,"mousemove":_vm.mouseMove}},_vm._l((_vm.options),function(option){return _c('li',{key:option.id,class:['navbar__item', option.selected ? 'active' : ''],on:{"click":function (e) {
          _vm.mouseClick(e)
          _vm.selectRoute(option)
        }}},[_c('span',[_vm._v(" "+_vm._s(option.title)+" "),(option.counter)?_c('span',{staticClass:"navbar__counter"},[_vm._v(" "+_vm._s(option.counter)+" ")]):_vm._e()])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }