<template>
  <section>
    <div class="card">
      <h3 class="appointment-date">
        {{ formatWeekDay(properties.selectedSlot.slot) }},
        {{ formatDate(properties.selectedSlot.slot) }},
        <span>{{ formatSlot(properties.selectedSlot.slot) }}</span>
      </h3>
      <div class="card-header">
        <div class="personal-info-wrapper">
          <div
            v-if="properties.selectedDoctor.photo"
            class="doctors-image"
            :style="{
              backgroundImage: `url(${properties.selectedDoctor.photo})`
            }"
          ></div>
          <AvatarIcon v-else class="doctors-image" />
          <div class="personal-info">
            <h1 class="doctors-specialization">
              {{ properties.selectedDoctor.specializationInfo.title }}
            </h1>
            <h2>
              {{ properties.selectedDoctor.surname }}
              {{ properties.selectedDoctor.name }}
              {{ properties.selectedDoctor.patronymic }}
            </h2>
            <span
              :class="{
                'hide-dot': !properties.selectedDoctor.totalExperience
              }"
              v-if="
                properties.selectedDoctor &&
                properties.selectedDoctor.totalExperience
              "
            >
              {{
                `Стаж ${properties.selectedDoctor.totalExperience} ${declOfNum(
                  properties.selectedDoctor.totalExperience,
                  ['год', 'года', 'лет']
                )}`
              }}
            </span>
            <div class="doctors-rang">
              <div class="doctors-rang-dot"></div>
              {{ getDoctorExp(properties.selectedDoctor.totalExperience) }}
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <p class="dark-text">
          <LocateIcon />
          {{ properties.selectedClinic.clinicName }}
        </p>
        <p class="dark-text" v-if="properties.selectedClinic.subway">
          <SubwayIcon
            class="metro-icon"
            :color="
              properties.selectedClinic.subway.color &&
              properties.selectedClinic.subway.color[0]
                ? `#${properties.selectedClinic.subway.color[0]}`
                : 'purple'
            "
          />
          {{ properties.selectedClinic.subway.title }}
        </p>
        <p class="grey-text">{{ properties.selectedClinic.address }}</p>
      </div>
    </div>
    <div :class="['patient-card', 'card']">
      <p class="grey-label">Пациент</p>
      <p class="dark-text">
        {{ getPatientInfo.surname }} {{ getPatientInfo.name }}
        {{ getPatientInfo.patronymic }}
      </p>
      <div v-if="properties.selectedDoctor.priceInformation.price">
        <p class="grey-label">Предварительная стоимость приема</p>
        <h3 class="patient-card-price">
          {{ properties.selectedDoctor.priceInformation.price }}
          <span class="patient-card-symbol">₽</span>
        </h3>
        <p class="grey-text">
          Конечная стоимость приема определяется врачем на основании оказанных
          услуг.
        </p>
      </div>
      <Input
        placeholder="Оставьте комментарий врачу"
        classes="patient-text-area"
        type="textarea"
        :value="comment"
        :onChange="onCommentChange"
      />
      <Button
        primary
        icon="plus"
        classes="make-an-appointment"
        :onClick="
          () =>
            this.handleSubmitClick({ ...properties, getPatientInfo }, comment)
        "
      >
        Записаться на прием
      </Button>
    </div>
  </section>
</template>
<script>
import LocateIcon from '@/assets/icons/locate'
import Button from '@/components/button'
import Input from '@/components/input'
import SubwayIcon from '@/assets/icons/subway'
import AvatarIcon from '@/assets/icons/avatar'
import { mapActions, mapGetters } from 'vuex'
import { declOfNum, formatWithLocale, getDoctorExp } from '@/core/utils'
import { isToday, isTomorrow, parseISO } from 'date-fns'
import { TRACKER_SLUGS } from '@/models/tracker'
export default {
  name: 'SmSlideMakeAnAppointment',
  components: { SubwayIcon, LocateIcon, Button, Input, AvatarIcon },
  data() {
    return {
      comment: '',
      getDoctorExp,
      declOfNum
    }
  },
  props: {
    properties: Object,
    close: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters('account', ['getPatientInfo']),
    ...mapGetters('cities', ['selectedCity'])
  },
  methods: {
    ...mapActions('appointment', ['getAppointments']),
    onCommentChange(e) {
      this.comment = e.target.value
    },
    async handleSubmitClick(properties) {
      this.$tracker(TRACKER_SLUGS.CONFIRM_APPOINTMENT)
      const result = await this.properties.makeAnAppointmentHandler(
        properties,
        this.comment
      )
      if (result) {
        this.$tracker(TRACKER_SLUGS.DONE_APPOINTMENT)
        this.close(properties)
      }
    },
    formatSlot({ dateTime }) {
      return formatWithLocale(parseISO(dateTime), 'HH:mm')
    },
    formatWeekDay({ dateTime }) {
      const _date = parseISO(dateTime)
      if (isToday(_date)) return 'Сегодня'
      if (isTomorrow(_date)) return 'Завтра'
      const result = formatWithLocale(_date, 'eeee')
      return result[0].toUpperCase() + result.slice(1)
    },
    formatDate({ dateTime }) {
      const _date = parseISO(dateTime)
      return formatWithLocale(_date, 'd MMMM')
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style';
.personal-info {
  line-height: 16px;
  .doctors-rang {
    display: inline;
    .doctors-rang-dot {
      width: 0.25rem;
      height: 0.25rem;
      display: inline;
      position: absolute;
      top: 0;
      transform: translate(-11px, 7px);
      border-radius: 50%;
      background-color: $grey-500;
      @media (max-width: 1023px) {
        transform: translate(-11px, 6px);
      }
    }
  }
  .doctors-rang:before {
    display: none;
  }
}
</style>
