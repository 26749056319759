export default {
  setClinicsListData(state, payload) {
    state.list.data = payload
  },
  setClinicsSingleData(state, payload) {
    state.single.data = payload
  },
  setClinicsListLoading(state) {
    state.list.loading = !state.loading
  },
  setClinicsListError(state, payload) {
    state.list.error = payload
  },
  setClinicsSingleLoading(state) {
    state.single.loading = !state.loading
  },
  setClinicDirectionsData(state, payload) {
    state.directions.data = payload
  },
  setClinicDirectionsLoading(state) {
    state.directions.loading = !state.directions.loading
  },
  setError(state, payload) {
    state.error = payload
  }
}
