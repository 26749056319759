import $store from '../store'
import { vueSlideoutPanelService } from 'vue2-slideout-panel'
import { apiV1 } from '@/config/request'
import { getYear, parseISO } from 'date-fns'
import router from '@/router/index'
import { tracker } from '@/models/tracker'
// import { debounce } from '@/core/utils'
// import { vueSlideoutPanelService } from 'vue2-slideout-panel'
export async function initCurrentUserStateMiddleware(to, from, next) {
  const account = $store.state.account?.account
  const patient = $store.state.account?.patient
  const hasRefreshToken = !!localStorage.getItem('refreshToken')
  if (hasRefreshToken && account?.id && !patient?.name) {
    try {
      // await debounce(() => {
      //   return $store.dispatch('auth/refreshToken')
      // }, 100)
      try {
        $store.dispatch('cities/getCities')
        await $store.dispatch('account/getFullAccountInfo', {
          id: account.id,
          patientId: patient?.id
        })
      } catch (e) {
        await $store.dispatch('auth/signOut')
      }
      const visitor = {}
      const visitorPatient = $store.state.account?.patient

      if (visitorPatient.id) visitor.id = visitorPatient.id
      if (visitorPatient.name) visitor.display_name = visitorPatient.name
      if (visitorPatient.fullPhone) {
        visitor.phone = visitorPatient.fullPhone.replace?.(/^\D/, '')
      }
      if (visitorPatient.email) visitor.email = visitorPatient.email
      let query = ''
      Object.entries(visitor).map(
        (field) => (query += `${field[0]}=${field[1]}&`)
      )

      new apiV1({ auth: true }).get(`/generate-hash?${query}`).then((res) => {
        const hash = res.data
        if (patient) {
          window.webim_visitor = {
            fields: { ...visitor },
            hash: hash
          }
          window.webim.api.onProvidedVisitorChanged()
        }
      })
      next()
    } catch (e) {
      console.log(e)
    }
  } else {
    if (!account?.id && $store.state.auth?.token?.val) {
      await $store.dispatch('auth/signOut')
    }
    next()
  }
}

export function checkAccessMiddleware(to, from, next) {
  const currentUser = $store.state.account?.patient
  const isAuthRoute = to.matched.some((item) => item.meta.isAuth)
  vueSlideoutPanelService.hideAllPanels()
  if (to.fullPath.includes('auth') && currentUser?.id) {
    return next({ name: 'MainPage' })
  }
  if (isAuthRoute && currentUser?.id) return next()
  if (isAuthRoute) return next({ path: '/auth' })
  next()
}

export function checkPatientSignIn(to, from, next) {
  const currentUser = $store.state.account?.patient
  if (currentUser?.id) {
    const signInDate = !$store.state.account.patient?.contractId
      ? null
      : $store.state.account.patient?.contractId.dateOfSigning
    if (
      (to.fullPath.includes('analyses') ||
        to.fullPath.includes('diagnostics')) &&
      getYear(parseISO(signInDate)) < 2018
    ) {
      router.app.$modals.show('info-modal')
      if (!from.name) {
        next('/upcoming')
      } else {
        next(false)
      }
    } else {
      next()
    }
  } else {
    next()
  }
}

export function setPageTitleMiddleware(to, from, next) {
  const pageTitle = to.matched.find((item) => item.meta.title)

  if (pageTitle) window.document.title = pageTitle.meta.title
  next()
}

export function trackerRouteObserverMiddleware(to, from, next) {
  tracker.track(to.fullPath, { route: true, referer: from.fullPath })
  next()
}
