<template>
  <div
    class="sm-input"
    :class="[invalid ? 'invalid' : '', classes]"
    v-if="type !== 'textarea'"
  >
    <label v-if="showLabel || value">{{ placeholder }}</label>
    <input
      :id="id"
      :type="type"
      :name="id"
      :value="this.value"
      :placeholder="!showLabel ? placeholder : ''"
      ref="inputRef"
      @change="onChange"
      @click="onClick"
      @focus="focusHandler"
      @blur="focusOutHandler"
      @keyup="keyupHandler"
      @keypress="onKeypress"
      @paste="onKeypress"
      :class="{ ['hasVal']: value }"
      :autocomplete="type === 'password' ? 'on' : 'off'"
    />
    <IconEye
      v-if="type === 'password' && hidePassword"
      class="icon"
      :onClick="showPassword"
    />
    <IconEyeShow
      v-if="type === 'password' && !hidePassword"
      class="icon"
      :onClick="showPassword"
    />
    <IconError
      v-if="invalid && type !== 'password'"
      class="icon error"
    ></IconError>
  </div>
  <div
    class="sm-input textarea"
    :class="[invalid ? 'invalid' : '', classes]"
    v-else
  >
    <label :class="{ ['inFocus']: !showLabel }" v-if="showLabel || value">
      {{ placeholder }}
    </label>
    <textarea
      :id="id"
      :name="id"
      :value="this.value"
      :placeholder="!showLabel ? placeholder : ''"
      ref="inputRef"
      @change="onChange"
      @click="onClick"
      @focus="focusHandler"
      @blur="focusOutHandler"
      @keyup="keyupHandler"
      :class="{ ['hasVal']: value }"
    />
  </div>
</template>
<script>
import IconEye from '@/assets/icons/eye'
import IconError from '@/assets/icons/error'
import IconEyeShow from '@/assets/icons/IconEyeShow'

export default {
  name: 'SmInput',
  components: { IconEye, IconError, IconEyeShow },
  props: {
    onChange: {
      type: Function,
      default: () => {}
    },
    onClick: {
      type: Function,
      default: () => {}
    },
    keyupHandler: {
      type: Function,
      default: () => {}
    },
    onFocusOut: {
      type: Function,
      default: () => {}
    },
    classes: [String, Array],
    disabled: Boolean,
    type: String,
    placeholder: String,
    id: String,
    value: String,
    cyrillicOnly: Boolean,
    invalid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showLabel: false,
      hidePassword: true
    }
  },
  methods: {
    focusHandler() {
      this.showLabel = !this.showLabel
    },
    focusOutHandler(e) {
      this.showLabel = !this.showLabel
      this.onFocusOut(e)
    },
    onKeypress(evt) {
      if (this.cyrillicOnly) {
        this.onlyCyrillic(evt)
      }
    },
    onlyCyrillic(evt) {
      const regex = /^[а-яА-ЯёЁ\s-]+$/
      const key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode)
      if (!regex.test(key)) {
        evt.preventDefault()
        return false
      }
    },
    showPassword() {
      const refType = this.$refs.inputRef.type
      if (refType === 'password') {
        this.$refs.inputRef.type = 'text'
        this.hidePassword = false
      } else {
        this.$refs.inputRef.type = 'password'
        this.hidePassword = true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
</style>
