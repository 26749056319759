<template>
  <div class="aside-menu">
    <div class="aside-menu__item">
      <Location />
      <Button
        icon="plus"
        :onClick="() => this.$router.push('/appointment')"
        v-tracker="TRACKER_SLUGS.CREATE_APPOINTMENT"
        v-if="!isSpec"
      >
        Записаться
      </Button>
      <Button
        :onClick="() => this.$router.push('/map')"
        v-tracker="TRACKER_SLUGS.PROCEED_CLINIC_FIND"
        icon="locate"
        secondary
      >
        Найти клинику
      </Button>
    </div>
    <router-link to="/promotions" custom v-slot="{ navigate }">
      <div
        @click="navigate"
        role="link"
        class="aside-menu__item aside-menu__item-link icon"
      >
        <div>
          <PercentIcon />
        </div>
        <span class="linkTo">Наши акции</span>
        <span v-if="getCounter" class="count">{{ getCounter }}</span>
      </div>
    </router-link>

    <router-link to="/faq" custom v-slot="{ navigate }">
      <div
        @click="navigate"
        role="link"
        class="aside-menu__item aside-menu__item-link icon"
      >
        <div>
          <QuestionIcon />
        </div>
        <span class="linkTo">Часто задаваемые вопросы</span>
        <!--      <span class="count">6</span>-->
      </div>
    </router-link>
  </div>
</template>
<script>
import Button from '@/components/button'
import PercentIcon from '@/assets/icons/percent'
import QuestionIcon from '@/assets/icons/question'
import Location from '@/components/asideMenu/location'
import { TRACKER_SLUGS } from '@/models/tracker'
import pick from 'lodash/pick'
import { mapGetters } from 'vuex'

export default {
  name: 'AsideMenu',
  components: { Location, QuestionIcon, PercentIcon, Button },
  computed: {
    ...mapGetters('promotions', ['getCounter']),
    isSpec() {
      return this.$route.meta.isSpec
    }
  },
  created() {
    this.TRACKER_SLUGS = pick(TRACKER_SLUGS, [
      'CREATE_APPOINTMENT',
      'PROCEED_CLINIC_FIND'
    ])
  }
}
</script>
<style scoped lang="scss">
@import 'style';
</style>
