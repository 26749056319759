export default {
  setLoadingCities(state) {
    state.list.loading = !state.list.loading
  },
  setCitiesData(state, payload) {
    state.list.data = payload
  },
  setSelectedCity(state, payload) {
    state.selected = payload
  }
}
