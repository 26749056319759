import { apiV1 } from '@/config/request'

export default {
  async getQualifications({ commit }) {
    commit('setLoadingQualifications')
    try {
      const { data } = await new apiV1({ auth: true }).get(
        '/doctors/qualifications'
      )
      commit('setQualificationsData', data?.qualifications ?? [])
      commit('setLoadingQualifications')
    } catch (e) {
      commit('setLoadingQualifications')
    }
  }
}
