<template>
  <section class="analyses" ref="analysesList">
    <div v-if="getListLoading || getListError || lockSlug">
      <Loader :loading="getListLoading" :error="!!getListError" />
    </div>
    <template v-else>
      <Filters
        :options="sortButtonsProperties"
        :selectFilter="selectSortButton"
        :clearFilter="clearSortButton"
        :clear-all="clearAllFilters"
        :filterBlock="filterBlock"
      />
      <EmptyData
        v-if="!getAnalysesData.length"
        :title="'Мы ничего не нашли по заданным параметрам'"
      />
      <div
        v-else
        v-for="analysis of getAnalysesData"
        :key="analysis.id + analysis.services[0].title"
        class="analysis-card"
        @click="() => handleCardClick(analysis.id)"
      >
        <div class="analysis-card__body">
          <div class="date">
            <span class="date__day">
              {{
                getDay(
                  analysis.status === 'SCHEDULED'
                    ? analysis.appointmentDateTime
                    : analysis.analysisDateTime
                )
              }}
            </span>
            <span class="date__year">
              {{
                getYear(
                  analysis.status === 'SCHEDULED'
                    ? analysis.appointmentDateTime
                    : analysis.analysisDateTime
                )
              }}
            </span>
          </div>
          <div class="description">
            <p>{{ getTitle(analysis) }}</p>
          </div>
        </div>
        <div class="analysis-card__status">
          <EllipseIcon
            :class="analysis.status === 'READY' ? 'green' : 'orange'"
          />
          <p v-html="getAnaliseStatus(analysis)" />
        </div>
      </div>
      <Pagination
        :paginationInfo="paginationInfo"
        :getData="getFilteredData"
        :getMoreData="
          (payload) => this.getFilteredData({ merge: true, ...payload })
        "
        :autoLoad="false"
      >
      </Pagination>
    </template>
  </section>
</template>
<script>
import EmptyData from '@/components/emptyData'
import Pagination from '@/components/pagination/pagination'
import Filters from '@/components/filters'
import EllipseIcon from '@/assets/icons/ellipse'
import { mapActions, mapGetters } from 'vuex'
import { format, parse, parseISO } from 'date-fns'
import { ru } from 'date-fns/locale'
import SmSlideDoctorsSorting from '@/components/slideOutComponents/sorting'
import SmSlideDate from '@/components/slideOutComponents/date/date'
import { formatWithLocale } from '@/core/utils'
import { alphabet, AnalysisTypes, PaymentStatusTypes } from '@/core/constants'
import Loader from '@/components/loader'
import _ from 'lodash'
import router from '@/router'
import { TRACKER_SLUGS } from '@/models/tracker'

export default {
  name: 'Analyses',
  components: {
    Pagination,
    Filters,
    EllipseIcon,
    EmptyData,
    Loader
  },
  data() {
    return {
      filterBlock: false,
      lockSlug: true,
      sortButtonsProperties: [
        // {
        //   id: 'default',
        //   label: 'По дате приема',
        //   title: 'Сортировать',
        //   value: '',
        //   items: [
        //     { id: 'DATETIME', direction: 'Дата' },
        //     { id: 'DOCTOR', direction: 'Назначивший врач' },
        //     { id: 'BIOMATERIAL', direction: 'Вид' },
        //     { id: 'CLINIC', direction: 'Клиника' }
        //   ],
        //   preload: true,
        //   isSelected: false
        // },
        {
          id: 'date',
          value: '',
          label: 'Дата сдачи',
          title: 'Выберите дату приема врача',
          items: [],
          preload: true,
          isSelected: false
        },
        {
          id: 'status',
          value: '',
          label: 'Статус',
          title: 'Выберите Статус',
          items: [],
          preload: true,
          isSelected: false
        },
        {
          id: 'specialityId',
          label: 'Специальность',
          value: '',
          title: 'Выберите специальность врача',
          items: [],
          isSelected: false,
          preload: true
        },
        {
          id: 'biomaterialId',
          label: 'Вид',
          value: '',
          title: 'Выберите вид анализа',
          items: [],
          isSelected: false,
          preload: true
        }
      ],
      filters: {},
      statusItems: [
        { direction: 'В работе', id: 'AT_WORK' },
        { direction: 'Готов', id: 'READY' },
        { direction: 'Частично выполнен', id: 'PARTIALLY_COMPLETED' },
        { direction: 'Назначен', id: 'SCHEDULED' }
      ]
    }
  },
  async created() {
    this.lockSlug = true
    if (Object.keys(this.$route.query ?? {}).length) {
      const filters = _({
        from: this.$route.query.from || null,
        to: this.$route.query.to || null,
        status: this.$route.query.status || null,
        speciality: this.$route.query.specialityId || null,
        biomaterial: this.$route.query.biomaterialId || null
      })
        .omitBy(_.isNull)
        .value()
      await this.getAnalysesFiltersPost(filters)
      this.restoreFilters()
      await this.getFilteredData({
        page: (Number(this.$route.query.page) || 1) - 1,
        size: 5
      })
    } else {
      await this.getAnalysesFilters({})
      await this.getAnalyses({
        page: (Number(this.$route.query.page) || 1) - 1,
        size: 5
      })
    }
    this.lockSlug = false
  },
  methods: {
    ...mapActions('analyses', [
      'getAnalyses',
      'getFilteredAnalyses',
      'getAnalysesFilters',
      'getAnalysesFiltersPost'
    ]),
    handleCardClick(id) {
      this.$tracker(TRACKER_SLUGS.PROCEED_ANALYSES_RESULT)
      this.$router.push({
        name: 'AnalysisInfoPage',
        query: { id }
      })
    },
    async getFilteredData({ page, ...payload } = {}) {
      if (!payload.merge) {
        this.$refs.analysesList?.scrollIntoView(true)
      }
      try {
        const filters = _(this.filters).omitBy(_.isNull).value()
        await this.getFilteredAnalyses({
          size: Number(this.$route.query.size) || 5,
          ...payload,
          ...filters,
          page: page ?? 0
        })
      } catch (e) {
        console.log(e)
      }
    },
    getDay(date) {
      if (!date) return ''
      return format(parseISO(date), 'dd MMM', { locale: ru })
    },
    getYear(date) {
      if (!date) return ''
      return format(parseISO(date), 'yyy')
    },
    restoreFilters() {
      const query = this.$route.query
      for (let [key, value] of Object.entries(query)) {
        this.filters[key] = value

        this.sortButtonsProperties = this.sortButtonsProperties.map(
          (buttonProperties) => {
            if (buttonProperties.id === 'default') {
              if (key === 'sort') {
                buttonProperties.value = value
                buttonProperties.isSelected = true
                const sort = buttonProperties.items.find(
                  (obj) => obj.id === value
                )
                if (sort) {
                  buttonProperties.value = sort.direction
                }
              }
            } else if (buttonProperties.id === 'date') {
              if (key === 'to') {
                buttonProperties.value = `${format(
                  parse(query.from, 'yyyy-mm-dd', new Date()),
                  'dd.MM',
                  {
                    locale: ru
                  }
                )} - ${format(
                  parse(query.to, 'yyyy-mm-dd', new Date()),
                  'dd.MM',
                  {
                    locale: ru
                  }
                )}`
                buttonProperties.isSelected = true
              }
            } else if (buttonProperties.id === key) {
              if (key === 'status') {
                const status = this.statusItems.find((obj) => obj.id === value)
                if (status) {
                  buttonProperties.value = status.direction
                }
              } else if (key === 'specialityId') {
                const speciality = this.getFiltersData.specialities.find(
                  (obj) => obj.id === value
                )
                if (speciality) {
                  buttonProperties.value = speciality.direction
                }
              } else if (key === 'biomaterialId') {
                const biomaterial = this.getFiltersData.biomaterials.find(
                  (obj) => obj.id === value
                )
                if (biomaterial) {
                  buttonProperties.value = biomaterial.direction
                }
              } else {
                buttonProperties.value = value
              }
              buttonProperties.isSelected = true
            }

            return buttonProperties
          }
        )
      }
    },
    async selectSortButton(properties) {
      if (this.$route.query.page && this.$route.query.page !== '0') {
        await this.$router.push({ query: { page: '0' } })
      }
      if (properties.isSelected) {
        this.sortButtonsProperties = this.sortButtonsProperties.map(
          (buttonProperties) => {
            if (buttonProperties.id === properties.id) {
              buttonProperties.isSelected = !buttonProperties.isSelected
              buttonProperties.value = ''
              return buttonProperties
            } else return buttonProperties
          }
        )
      } else {
        this.filterBlock = true
        let component = SmSlideDoctorsSorting

        if (properties.id === 'date') {
          this.$tracker(TRACKER_SLUGS.FILTER_ANALYSES_DATE)
          component = SmSlideDate
        }
        if (properties.id === 'status') {
          this.$tracker(TRACKER_SLUGS.FILTER_ANALYSES_STATUS)
          properties.items = this.statusItems
        }
        if (properties.id === 'specialityId') {
          this.$tracker(TRACKER_SLUGS.FILTER_ANALYSES_DOCTOR_SPEC)
          properties.items = this.getFiltersData.specialities
            .map(({ name, id }) => ({
              direction: name,
              id
            }))
            .sort(
              (a, b) =>
                alphabet.indexOf(a.direction[0]) -
                alphabet.indexOf(b.direction[0])
            )
        }
        if (properties.id === 'biomaterialId') {
          this.$tracker(TRACKER_SLUGS.FILTER_ANALYSES_TYPE)
          properties.items = Object.values(
            this.getFiltersData.biomaterials ?? {}
          )
            .map(({ name, id, title }) => ({
              direction: name || title,
              id
            }))
            .sort(
              (a, b) =>
                alphabet.indexOf(a.direction?.[0]) -
                alphabet.indexOf(b.direction?.[0])
            )
        }
        this.filterBlock = false
        this.$showPanel({
          component: 'SmSlideoutPanel',
          props: {
            title: properties.title,
            component,
            properties
          },
          openOn: 'right',
          width: '400',
          height: '80',
          sync: true,
          keepAlive: false
        }).promise.then((result) => {
          if (result?.closedBy) return
          if (result) {
            this.sortButtonsProperties = this.sortButtonsProperties.map(
              (buttonProperties) => {
                if (buttonProperties.id === properties.id) {
                  buttonProperties.isSelected = !buttonProperties.isSelected

                  if (properties.id === 'date') {
                    buttonProperties.value = result.label
                    this.filters['from'] = formatWithLocale(
                      result.start,
                      'yyyy-MM-dd'
                    )
                    this.filters['to'] = formatWithLocale(
                      result.end,
                      'yyyy-MM-dd'
                    )

                    router.replace(
                      {
                        query: Object.assign(
                          { ...this.$route.query },
                          {
                            from: formatWithLocale(result.start, 'yyyy-MM-dd'),
                            to: formatWithLocale(result.end, 'yyyy-MM-dd')
                          }
                        )
                      },
                      () => {}
                    )
                  } else if (properties.id === 'default') {
                    buttonProperties.value = result.direction
                    this.filters['sort'] = result.id
                    router.replace(
                      {
                        query: Object.assign(
                          { ...this.$route.query },
                          {
                            sort: result.id
                          }
                        )
                      },
                      () => {}
                    )
                  } else {
                    buttonProperties.value = result.direction
                    this.filters[buttonProperties.id] = result.id
                    router.replace(
                      {
                        query: Object.assign(
                          { ...this.$route.query },
                          {
                            [buttonProperties.id]: result.id
                          }
                        )
                      },
                      () => {}
                    )
                  }
                  const filters = _({
                    from: this.filters.from,
                    to: this.filters.to,
                    status: this.filters.status,
                    speciality: this.filters.specialityId,
                    biomaterial: this.filters.biomaterialId
                  })
                    .omitBy(_.isNull)
                    .value()
                  this.getAnalysesFiltersPost(filters)
                  this.getFilteredData()

                  return buttonProperties
                } else return buttonProperties
              }
            )
          }
        })
      }
    },
    clearSortButton(properties) {
      if (this.$route.query.page && this.$route.query.page !== '0') {
        this.$router.push({ query: { page: '0' } })
      }
      const query = { ...this.$route.query }
      this.sortButtonsProperties = this.sortButtonsProperties.map(
        (buttonProperties) => {
          if (buttonProperties.id === properties.id) {
            buttonProperties.isSelected = false
            buttonProperties.value = ''

            if (properties.id === 'default') {
              const byDateSort = this.sortButtonsProperties.find(
                ({ id }) => id === properties.id
              )
              byDateSort.isSelected = false
              this.filters['sort'] = null
              delete query.sort
            } else if (properties.id === 'date') {
              this.filters['from'] = null
              this.filters['to'] = null
              delete query.from
              delete query.to
            } else {
              this.filters[buttonProperties.id] = null
              delete query[buttonProperties.id]
            }

            router.replace(
              {
                query
              },
              () => {}
            )

            this.getFilteredData()
            const filters = _({
              from: this.filters.from || null,
              to: this.filters.to || null,
              status: this.filters.status || null,
              speciality: this.filters.specialityId || null,
              biomaterial: this.filters.biomaterialId || null
            })
              .omitBy(_.isNull)
              .value()
            if (Object.values(filters).length) {
              this.getAnalysesFiltersPost(filters)
            } else {
              this.getAnalysesFilters({})
            }
            return buttonProperties
          } else return buttonProperties
        }
      )
    },
    async clearAllFilters() {
      if (this.$route.query.page && this.$route.query.page !== '0') {
        await this.$router.push({ query: { page: '0' } })
      }
      this.sortButtonsProperties = this.sortButtonsProperties.map(
        (buttonProperties) => {
          buttonProperties.isSelected = false
          buttonProperties.value = ''

          if (buttonProperties.id === 'default') {
            const byDateSort = this.sortButtonsProperties.find(
              ({ id }) => id === buttonProperties.id
            )
            byDateSort.isSelected = false
            this.filters['sort'] = null
          } else if (buttonProperties.id === 'date') {
            this.filters['from'] = null
            this.filters['to'] = null
          } else {
            this.filters[buttonProperties.id] = null
          }

          const query = { ...this.$route.query }

          for (let key of Object.keys(query)) {
            if (key !== 'page' || key !== 'size') {
              delete query[key]
            }
          }

          router.replace(
            {
              query
            },
            () => {}
          )

          return buttonProperties
        }
      )
      this.getAnalysesFilters({})
      await this.getAnalyses({
        page: Number(this.$route.query.page) - 1 || 0,
        size: Number(this.$route.query.size) || 5
      })
    },
    getAnaliseStatus(analysis) {
      return `${AnalysisTypes[analysis.status]},<br />${
        PaymentStatusTypes[analysis.paymentStatus]
      }`
    },
    getTitle(analysis) {
      return analysis.services.map(({ title }) => title).join(', ')
    }
  },
  computed: {
    ...mapGetters('specialities', ['specialitiesListData']),
    ...mapGetters('analyses', [
      'getAnalysesData',
      'getListLoading',
      'getListError',
      'paginationInfo',
      'getFiltersData'
    ])
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
