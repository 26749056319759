export default {
  visitsListData(state) {
    return state.list.data
  },
  visitsSingleData(state) {
    return state.single.data
  },
  visitsFilterData(state) {
    return state.filters.data
  },
  paginationInfo(state) {
    return {
      totalPages:
        state.list.pagination.totalPages || state.list.pagination.page,
      size: state.list.pagination.size
    }
  },
  singleVisitLoading(state) {
    return state.single.loading
  },
  singleVisitError(state) {
    return state.single.error
  },
  listVisitLoading(state) {
    return state.list.loading
  },
  listVisitError(state) {
    return state.list.error
  }
}
