<template>
  <div class="settings">
    <Location />
    <div
      @click="() => goTo('/map')"
      v-tracker="TRACKER_SLUGS.PROCEED_CLINIC_FIND"
      class="settings__button"
    >
      <div>
        <LocateIcon />
      </div>
      <p>Найти клинику</p>
    </div>
    <div @click="() => goTo('/promotions')" class="settings__button">
      <div>
        <PercentIcon />
      </div>
      <p>Наши акции</p>
      <span v-if="getCounter" class="count">{{ getCounter }}</span>
    </div>
    <div @click="goTo('/faq')" class="settings__button">
      <div>
        <QuestionIcon />
      </div>
      <p>Часто задаваемые вопросы</p>
    </div>
    <div
      @click="openChat"
      v-tracker="TRACKER_SLUGS.CONSULTANT_CHAT"
      class="settings__button"
    >
      <div>
        <ChatIcon />
      </div>
      <p>Чат</p>
    </div>
  </div>
</template>
<script>
import PercentIcon from '@/assets/icons/percent'
import QuestionIcon from '@/assets/icons/question'
import ChatIcon from '@/assets/icons/chat'
import Location from '@/components/asideMenu/location'
import LocateIcon from '@/assets/icons/locate'
import { TRACKER_SLUGS } from '@/models/tracker'
import pick from 'lodash/pick'
import { mapGetters } from 'vuex'

export default {
  name: 'SmSlideSettings',
  components: { LocateIcon, Location, PercentIcon, QuestionIcon, ChatIcon },
  props: {
    close: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {}
  },
  created() {
    this.TRACKER_SLUGS = pick(TRACKER_SLUGS, [
      'PROCEED_CLINIC_FIND',
      'CONSULTANT_CHAT'
    ])
  },
  computed: {
    ...mapGetters('promotions', ['getCounter'])
  },
  methods: {
    goTo(url) {
      this.close()
      this.$router.push(url)
    },
    openChat() {
      this.close()
      this.$store.dispatch('account/setChatState', true)
      window.webim.api.chat.start()
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style';
</style>
