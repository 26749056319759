<template>
  <vc-date-picker
    v-model="computeRange"
    :value="computeRange"
    :key="datePickerKey"
    :select-attribute="selectDragAttribute"
    :drag-attribute="selectDragAttribute"
    :min-date="minDate"
    @dayclick="onDayClickInner"
    is-range
    @drag="($event) => (dragValue = $event)"
    class="calendar-wrapper"
    contentClass="calendar-content"
    color="green"
  >
    <template v-slot:day-popover="{ format }">
      <div>
        {{ format(dragValue ? dragValue.start : range.start, 'MMM D') }}
        -
        {{ format(dragValue ? dragValue.end : range.end, 'MMM D') }}
      </div>
    </template>
  </vc-date-picker>
</template>
<script>
export default {
  name: 'SmCalendar',
  components: {},
  props: {
    range: {
      start: String,
      end: String
    },
    maxDays: {
      type: Number,
      default: 0
    },
    fixedRange: Boolean,
    onDayClick: {
      type: Function,
      default: () => {}
    },
    minDate: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dragValue: null,
      datePickerKey: 999991
    }
  },
  methods: {
    onDayClickInner({ date }) {
      this.$nextTick(() => {
        if (this.dragValue.end.valueOf() === this.dragValue.start.valueOf()) {
          this.computeRange.start = date
          this.computeRange.end = date
        }
        if (this.fixedRange) {
          this.setMaxDate()
        } else if (this.maxDays) {
          const timeDiff = Math.abs(
            this.dragValue.end.valueOf() - this.dragValue.start.valueOf()
          )
          const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1
          if (diffDays > this.maxDays) this.setMaxDate()
        }
      })
    },
    setMaxDate() {
      const startDate = new Date(this.computeRange.start)
      this.computeRange.end = new Date(
        startDate.setDate(startDate.getDate() + (this.maxDays - 1))
      )
      this.dragValue = this.computeRange
      this.datePickerKey++
    }
  },
  mounted() {
    if (this.fixedRange) this.setMaxDate()
  },
  computed: {
    selectDragAttribute() {
      return {
        highlight: {
          start: {
            style: {
              backgroundColor: '#2DAB7F'
            },
            contentStyle: {
              color: '#ffffff'
            }
          },
          base: {
            style: {
              backgroundColor: '#EAF6F2'
            }
          },
          end: {
            style: {
              backgroundColor: '#2DAB7F'
            },
            contentStyle: {
              color: '#ffffff'
            }
          }
        },
        popover: {
          visibility: 'hover',
          isInteractive: false
        }
      }
    },
    computeRange: {
      get() {
        return this.range
      },
      set(range) {
        this.onDayClick(range)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
