<template>
  <vue-modal
    hideCloseBtn
    :on-close="closeModal"
    :name="name"
    class="bottom-popup"
    transitionName="fade"
  >
    <div slot="header">
      <slot name="body"></slot>
      <div class="bottom-popup__close">
        <CloseIcon :onClose="closeModal" />
      </div>
    </div>
  </vue-modal>
</template>
<script>
/* eslint-disable */
import CloseIcon from '@/assets/icons/close'
export default {
  name: 'BottomPopup',
  components: { CloseIcon },
  props: {
    name: String
  },
  methods: {
    closeModal() {
      this.$modals.hide(name)
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style';
</style>
