<template>
  <div class="auth-form">
    <p class="auth-form__title nowrap offer">Юридическая информация</p>
    <div class="auth-form__inner">
      <p class="auth-form__description">
        <SmCheckbox :value="isOfferAccepted" :onClick="setOfferStatus">
          Я соглашаюсь с
          <span class="linkTo" @click.prevent="showOfferAgreementModal">
            условиями оферты</span
          >
          и даю согласие на
          <span class="linkTo" @click.prevent="showPersonalDataModal">
            обработку персональных данных</span
          >.
        </SmCheckbox>
      </p>
      <Button
        :classes="'auth-form__btn'"
        icon="arrow"
        :onClick="auth"
        :disabled="!isOfferAccepted"
        :loading="loading"
      >
        Продолжить
      </Button>
      <router-link
        class="linkTo cancel"
        to="/auth"
        custom
        v-slot="{ navigate }"
      >
        <span @click="navigate" role="link"> Отменить регистрацию </span>
      </router-link>
    </div>

    <SmModal size="md" name="offer-agreement-modal" title="Договор оферты">
      <template v-slot:body>
        <div class="line"></div>
        <div class="sm-modal__body">
          <div class="sm-modal__body__icon">
            <DocumentIcon />
          </div>
          <div class="sm-modal__body__content">
            <OfferAgreement />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="modal-buttons-wrapper">
          <Button
            :onClick="closeModal"
            icon="close"
            class="sm-modal__close-btn"
          >
            Закрыть окно
          </Button>
        </div>
      </template>
    </SmModal>

    <SmModal
      size="md"
      name="personal-data-modal"
      title="Обработка персональных данных"
    >
      <template v-slot:body>
        <div class="line"></div>
        <div class="sm-modal__body">
          <div class="sm-modal__body__icon">
            <DocumentIcon />
          </div>
          <div class="sm-modal__body__content">
            <PersonalData />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="modal-buttons-wrapper">
          <Button
            :onClick="closeModal"
            icon="close"
            class="sm-modal__close-btn"
          >
            Закрыть окно
          </Button>
        </div>
      </template>
    </SmModal>
  </div>
</template>

<script>
import Button from '@/components/button'
import SmCheckbox from '@/components/checkbox'
import DocumentIcon from '@/assets/icons/document'
import SmModal from '@/components/modal'
import OfferAgreement from '@/components/documents/OfferAgreement.vue'
import PersonalData from '@/components/documents/PersonalData.vue'
import { TRACKER_SLUGS } from '@/models/tracker'
import { mapActions } from 'vuex'

export default {
  name: 'AuthOffer',
  components: {
    SmCheckbox,
    Button,
    SmModal,
    DocumentIcon,
    OfferAgreement,
    PersonalData
  },
  data() {
    return {
      isOfferAccepted: false,
      loading: false
    }
  },
  methods: {
    ...mapActions('auth', ['signIn', 'signInWithPhone', 'registration']),
    setOfferStatus(val) {
      this.isOfferAccepted = val
    },
    async auth() {
      const mode = this.$route.query.mode
      this.loading = true
      if (mode === 'phone') {
        await this.signInWithPhone({
          data: { isOfferAccepted: this.isOfferAccepted }
        })
      } else if (mode === 'register') {
        this.$tracker(TRACKER_SLUGS.REG_LEGALINFO)
        await this.registration({ isOfferAccepted: this.isOfferAccepted })
      } else {
        await this.signIn({ isOfferAccepted: this.isOfferAccepted })
      }
      this.loading = false
    },

    showOfferAgreementModal() {
      this.$modals.show('offer-agreement-modal')
    },
    showPersonalDataModal() {
      this.$modals.show('personal-data-modal')
    },
    closeModal() {
      this.$modals.hide('sm-modal')
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
