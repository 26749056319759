<template>
  <section class="diagnostics" ref="diagnosticList">
    <div v-if="getListLoading || getListError">
      <Loader :loading="getListLoading" :error="!!getListError" />
    </div>
    <template v-else>
      <Filters
        :options="sortButtonsProperties"
        :selectFilter="selectSortButton"
        :clearFilter="clearSortButton"
        :clear-all="clearAllFilters"
        :filterBlock="filterBlock"
      />
      <EmptyData
        v-if="!getDiagnosticsData.length"
        :title="'Мы ничего не нашли по заданным параметрам'"
      />
      <div
        v-else
        v-for="diagnostic of getDiagnosticsData"
        :key="diagnostic.id"
        class="diagnostic-card"
        @click="() => handleCardClick(diagnostic.id)"
      >
        <div class="diagnostic-card__body">
          <div class="date">
            <span class="date__day">
              {{ getDay(diagnostic.analysisDateTime) }}
            </span>
            <span class="date__year">
              {{ getYear(diagnostic.analysisDateTime) }}
            </span>
          </div>
          <div class="description">
            <p>
              {{ getTitle(diagnostic.specialityId) }}
            </p>
          </div>
        </div>
        <div class="diagnostic-card__status">
          <EllipseIcon class="green" />
          <p>Готово</p>
        </div>
      </div>
      <Pagination
        v-if="getDiagnosticsData.length"
        :paginationInfo="paginationInfo"
        :getData="getFilteredData"
        :getMoreData="(payload) => getFilteredData({ merge: true, ...payload })"
        :autoLoad="false"
      >
      </Pagination>
    </template>
  </section>
</template>
<script>
import EmptyData from '@/components/emptyData'
import Filters from '@/components/filters'
import EllipseIcon from '@/assets/icons/ellipse'
import Pagination from '@/components/pagination/pagination'
import { mapActions, mapGetters } from 'vuex'
import { format, parseISO } from 'date-fns'
import { ru } from 'date-fns/locale'
import _ from 'lodash'
import SmSlideDoctorsSorting from '@/components/slideOutComponents/sorting'
import SmSlideDate from '@/components/slideOutComponents/date/date'
import { formatWithLocale } from '@/core/utils'
import Loader from '@/components/loader'
import { alphabet } from '@/core/constants'
import { TRACKER_SLUGS } from '@/models/tracker'

export default {
  name: 'Diagnostics',
  components: {
    Loader,
    Pagination,
    Filters,
    EmptyData,
    EllipseIcon
  },
  data() {
    return {
      filterBlock: false,
      sortButtonsProperties: [
        {
          id: 'default',
          label: 'По дате исследования',
          title: 'Сортировать',
          value: '',
          items: [],
          preload: true,
          isSelected: false
        },
        {
          id: 'date',
          value: '',
          label: 'Дата исследования',
          title: 'Выберите дату исследования',
          items: [],
          preload: true,
          isSelected: false
        },
        {
          id: 'specialityId',
          label: 'Специальность',
          value: '',
          title: 'Выберите специальность врача',
          items: [],
          isSelected: false,
          preload: true
        }
      ],
      filters: {
        sort: 'DATE'
      }
    }
  },
  async created() {
    await this.getDiagnostics({
      page: (Number(this.$route.query.page) || 1) - 1,
      size: Number(this.$route.query.size) || 5
    })
    await this.getDiagnosticsSorting({})
    await this.getDiagnosticsFilters({})
    const defaultSort = this.sortButtonsProperties.find(
      ({ id }) => id === 'default'
    )
    defaultSort.items = Object.entries(this.getDiagnosticsSortingData).map(
      ([key, value]) => ({
        id: key,
        direction: value
      })
    )
  },
  methods: {
    ...mapActions('diagnostics', [
      'getDiagnostics',
      'getFilteredDiagnostics',
      'getDiagnosticsSorting',
      'getDiagnosticsFilters',
      'getDiagnosticsFiltersPost'
    ]),
    async getFilteredData({ page, ...payload } = {}) {
      if (!payload.merge) {
        this.$refs.diagnosticList?.scrollIntoView(true)
      }
      const filters = _(this.filters).omitBy(_.isNull).value()
      await this.getFilteredDiagnostics({
        page: page ?? 0,
        size: Number(this.$route.query.size) || 5,
        ...payload,
        ...filters
      })
    },
    handleCardClick(id) {
      this.$tracker(TRACKER_SLUGS.PROCEED_DIAGNOSTIC_RESULT)
      this.$router.push({
        name: 'DiagnosticsInfo',
        query: { id }
      })
    },
    getDay(date) {
      return format(parseISO(date), 'dd MMM', { locale: ru })
    },
    getYear(date) {
      return format(parseISO(date), 'yyy')
    },
    getTitle(specialityId) {
      const speciality =
        this.getDiagnosticsProxyData?.specialities[specialityId]
      return speciality ? speciality.title : ''
    },
    async selectSortButton(properties) {
      if (this.$route.query.page && this.$route.query.page !== '0') {
        await this.$router.push({ query: { page: '0' } })
      }
      if (properties.isSelected) {
        this.sortButtonsProperties = this.sortButtonsProperties.map(
          (buttonProperties) => {
            if (buttonProperties.id === properties.id) {
              buttonProperties.isSelected = !buttonProperties.isSelected
              buttonProperties.value = ''
              return buttonProperties
            } else return buttonProperties
          }
        )
      } else {
        this.filterBlock = true
        let component = SmSlideDoctorsSorting

        if (properties.id === 'date') {
          this.$tracker(TRACKER_SLUGS.FILTER_DIAGNOSTIC_DATE)
          component = SmSlideDate
        }
        if (properties.id === 'specialityId') {
          this.$tracker(TRACKER_SLUGS.FILTER_DIAGNOSTIC_DOCTOR_SPEC)
          properties.items = this.getFiltersData.specialities
            .map(({ name, id }) => ({
              direction: name,
              id
            }))
            .sort(
              (a, b) =>
                alphabet.indexOf(a.direction[0]) -
                alphabet.indexOf(b.direction[0])
            )
        }
        this.filterBlock = false
        this.$showPanel({
          component: 'SmSlideoutPanel',
          props: {
            title: properties.title,
            component,
            properties
          },
          openOn: 'right',
          width: '400',
          height: '80',
          sync: true,
          keepAlive: false
        }).promise.then((result) => {
          if (result?.closedBy) return
          if (result) {
            this.sortButtonsProperties = this.sortButtonsProperties.map(
              (buttonProperties) => {
                if (buttonProperties.id === properties.id) {
                  buttonProperties.isSelected = !buttonProperties.isSelected

                  if (properties.id === 'date') {
                    buttonProperties.value = result.label
                    this.filters['from'] = formatWithLocale(
                      result.start,
                      'yyyy-MM-dd'
                    )
                    this.filters['to'] = formatWithLocale(
                      result.end,
                      'yyyy-MM-dd'
                    )
                  } else if (properties.id === 'default') {
                    buttonProperties.value = result.direction
                    this.filters['sort'] = result.id
                  } else {
                    buttonProperties.value = result.direction
                    this.filters[buttonProperties.id] = result.id
                  }

                  this.getFilteredData()
                  const filters = _({
                    speciality: this.filters.specialityId,
                    to: this.filters.to,
                    from: this.filters.from
                  })
                    .omitBy(_.isNull)
                    .value()
                  this.getDiagnosticsFiltersPost(filters)

                  return buttonProperties
                } else return buttonProperties
              }
            )
          }
        })
      }
    },
    clearSortButton(properties) {
      if (this.$route.query.page && this.$route.query.page !== '0') {
        this.$router.push({ query: { page: '0' } })
      }
      this.sortButtonsProperties = this.sortButtonsProperties.map(
        (buttonProperties) => {
          if (buttonProperties.id === properties.id) {
            buttonProperties.isSelected = false
            buttonProperties.value = ''

            if (properties.id === 'default') {
              const byDateSort = this.sortButtonsProperties.find(
                ({ id }) => id === properties.id
              )
              byDateSort.isSelected = false
              this.filters['sort'] = null
            } else if (properties.id === 'date') {
              this.filters['from'] = null
              this.filters['to'] = null
            } else {
              this.filters[buttonProperties.id] = null
            }

            this.getFilteredData()
            const filters = _({
              speciality: this.filters.specialityId || null,
              to: this.filters.to || null,
              from: this.filters.from || null
            })
              .omitBy(_.isNull)
              .value()
            this.getDiagnosticsFiltersPost(filters)
            if (Object.values(filters).length) {
              this.getDiagnosticsFiltersPost(filters)
            } else {
              this.getDiagnosticsFilters({})
            }
            return buttonProperties
          } else return buttonProperties
        }
      )
    },
    async clearAllFilters() {
      if (this.$route.query.page && this.$route.query.page !== '0') {
        await this.$router.push({ query: { page: '0' } })
      }
      this.sortButtonsProperties = this.sortButtonsProperties.map(
        (buttonProperties) => {
          buttonProperties.isSelected = false
          buttonProperties.value = ''

          if (buttonProperties.id === 'default') {
            const byDateSort = this.sortButtonsProperties.find(
              ({ id }) => id === buttonProperties.id
            )
            byDateSort.isSelected = false
            this.filters['sort'] = null
          } else if (buttonProperties.id === 'date') {
            this.filters['from'] = null
            this.filters['to'] = null
          } else {
            this.filters[buttonProperties.id] = null
          }

          return buttonProperties
        }
      )
      this.getDiagnosticsFilters({})
      await this.getDiagnostics({
        page: Number(this.$route.query.page) - 1 || 0,
        size: Number(this.$route.query.size) || 5
      })
    }
  },
  computed: {
    ...mapGetters('diagnostics', [
      'getFiltersData',
      'getDiagnosticsData',
      'getDiagnosticsProxyData',
      'getListLoading',
      'getListError',
      'getDiagnosticsSortingData',
      'paginationInfo'
    ])
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
