<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 16C3.584 16 3.8606e-07 12.416 0 8C-3.86058e-07 3.584 3.584 3.86059e-07 8 0C12.416 -3.86059e-07 16 3.584 16 8C16 12.416 12.416 16 8 16ZM8 8.94118C8.46782 8.94118 8.84706 8.56194 8.84706 8.09412V4.61176C8.84706 4.14395 8.46782 3.76471 8 3.76471C7.53218 3.76471 7.15294 4.14395 7.15294 4.61176L7.15294 8.09412C7.15294 8.56194 7.53218 8.94118 8 8.94118ZM8 12.2353C8.5198 12.2353 8.94118 11.8139 8.94118 11.2941C8.94118 10.7743 8.5198 10.3529 8 10.3529C7.4802 10.3529 7.05882 10.7743 7.05882 11.2941C7.05882 11.8139 7.4802 12.2353 8 12.2353Z"
      fill="#CC3D3D"
    />
  </svg>
</template>
<script>
export default {
  name: 'ErrorIcon'
}
</script>
