var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"medical-info-card",on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.showDetail(_vm.info.id); }).apply(null, arguments)}}},[_c('div',{staticClass:"date"},[_c('CalendarIcon',{staticClass:"calendar-icon"}),_c('h2',{staticClass:"date__day"},[_vm._v(_vm._s(_vm.info.day))]),_c('p',{staticClass:"date__month"},[_vm._v(_vm._s(_vm.info.month))]),(_vm.info.year && _vm.history)?_c('p',{staticClass:"date__year"},[_vm._v(_vm._s(_vm.info.year)+" г.")]):_vm._e(),(!_vm.history)?_c('p',{staticClass:"date__time"},[_vm._v(_vm._s(_vm.info.time))]):_vm._e(),(!_vm.history)?_c('p',{staticClass:"date__weekday"},[_vm._v(_vm._s(_vm.info.weekDay))]):_vm._e(),(_vm.info.status === 'FINISHED' && _vm.history)?_c('div',{staticClass:"history-status"},[_c('IconSuccessRounded'),_c('span',[_vm._v("Завершен")])],1):(_vm.history)?_c('div',{staticClass:"history-status"},[_c('IconCancelRounded'),_c('span',[_vm._v("Отменен")])],1):_vm._e()],1),(_vm.info.doctorInfo)?_c('div',{staticClass:"doctor"},[_c('div',{staticClass:"doctor-inner"},[_c('div',{staticClass:"doctor-image"},[(_vm.info.doctorInfo.photo)?_c('img',{attrs:{"alt":"doctor","src":(_vm.getBaseUrl + "/" + (_vm.info.doctorInfo.photo))}}):_c('AvatarIcon',{staticClass:"avatar"})],1),_c('div',{staticClass:"doctor-info"},[(_vm.info.specialityInfo)?_c('p',{staticClass:"doctor-info__specialization"},[_vm._v(" "+_vm._s(_vm.info.specialityInfo.title)+" ")]):_vm._e(),_c('p',{staticClass:"doctor-info__fullName"},[_vm._v(_vm._s(_vm.getDoctorFullName))]),_c('div',{staticClass:"doctor-info__experience"},[(_vm.info.doctorInfo && _vm.info.doctorInfo.totalExperience)?_c('span',{class:{
              'hide-dot': !_vm.info.doctorInfo.totalExperience
            }},[_vm._v(_vm._s(("Стаж " + (_vm.info.doctorInfo.totalExperience) + " " + (_vm.declOfNum( _vm.info.doctorInfo.totalExperience, ['год', 'года', 'лет'] )))))]):_vm._e(),_c('span',{class:{
              'hide-dot': !_vm.info.doctorInfo.totalExperience
            }},[_vm._v(" "+_vm._s(_vm.getDoctorExp(_vm.info.doctorInfo.totalExperience))+" ")])]),_c('div',{staticClass:"doctor-info__location"},[_c('div',[(_vm.info.clinicInfo.clinicName)?_c('p',{staticClass:"clinic"},[_c('LocateIcon',{staticClass:"icon"}),_c('span',[_vm._v(_vm._s(_vm.info.clinicInfo.clinicName))])],1):_vm._e(),(_vm.info.clinicInfo.subways && _vm.info.clinicInfo.subways.length)?_c('p',{staticClass:"subway"},[_c('SubwayIcon',{staticClass:"icon",attrs:{"color":_vm.info.clinicInfo.subways[0].color &&
                  _vm.info.clinicInfo.subways[0].color.length
                    ? '#' + _vm.info.clinicInfo.subways[0].color[0]
                    : '#BAD067'}}),_c('span',[_vm._v(" "+_vm._s(_vm.info.clinicInfo.subways[0].title)+" ")])],1):_vm._e()]),(_vm.info.clinicInfo.address)?_c('p',{staticClass:"address"},[_vm._v(" "+_vm._s(_vm.info.clinicInfo.address)+" ")]):_vm._e()]),_c('div',{staticClass:"doctor-info__location-link link"},[_vm._v(" Подробнее о приёме "),_c('east-black-icon',{staticClass:"doctor-info__location-link--icon"})],1)])])]):_vm._e(),_c('div',{staticClass:"details"},[(_vm.history)?_c('div',{staticClass:"history"},[_c('div',{staticClass:"status"},[(_vm.info.status === 'FINISHED')?[_c('IconSuccessRounded'),_c('span',[_vm._v("Завершен")])]:[_c('IconCancelRounded'),_c('span',[_vm._v("Отменен")])]],2),(false)?_c('Button',{attrs:{"secondary":"","icon":"more","onClick":function () { return _vm.modalHandler('retransfer', _vm.info); }}},[_vm._v(" Записаться повторно ")]):_vm._e()],1):_c('div',{staticClass:"upcoming"},[_c('div',{staticClass:"price-block"},[_c('p',{staticClass:"price"},[_vm._v(_vm._s(_vm.info.price)+" "),_c('IconRub')],1),_c('div',{staticClass:"price-info"},[_c('span',[_vm._v("Стоимость и оплата")]),_c('SmTooltip',{attrs:{"trigger":_vm.windowWidth >= 1024 ? 'mouseenter' : '',"name":"meeting-discount","interactive":true},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('InfoIcon')]},proxy:true},{key:"tooltip",fn:function(){return [_c('div',{staticClass:"discount-tooltip"},[_c('DiscountTooltip')],1)]},proxy:true}])})],1)]),_c('div',{staticClass:"button-wrapper"},[_c('Button',{attrs:{"secondary":"","icon":"change-time","onClick":function () { return _vm.modalHandler('transfer', _vm.info); }}},[_vm._v(" Перенести ")]),_c('Button',{attrs:{"classes":"btn-light","icon":"cancel","onClick":function () { return _vm.modalHandler('cancel', _vm.info); }}},[_vm._v(" Отменить ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }