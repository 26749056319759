import Vue from 'vue'
import OtpInput from '@bachdgvn/vue-otp-input'
import VueWindowSize from 'vue-window-size'
import Vuelidate from 'vuelidate'
import VueSlideoutPanel from 'vue2-slideout-panel'
import SmSlideoutPanel from '@/components/slideoutPanel'
import VueModal from 'vue2-modal'
import VCalendar from 'v-calendar'
import VueTippy, { TippyComponent } from 'vue-tippy'
import { vTrackerPlugin } from '@/models/tracker'

/* Directives */

//Всплывающая подсказка
Vue.use(VueTippy)
Vue.component('tippy', TippyComponent)

/* Plugins */

Vue.use(vTrackerPlugin)

//Слайд меню
Vue.use(VueSlideoutPanel)
//Валидация
Vue.use(Vuelidate)
//Размер вьюпорта
Vue.use(VueWindowSize)

//Модальное окно
Vue.use(VueModal)
// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc'
})
/* Components */

//Инпут для смс
Vue.component('v-otp-input', OtpInput)
//Выезжающая панель
Vue.component('SmSlideoutPanel', SmSlideoutPanel)
