<template>
  <div class="analysis-info">
    <div class="analysis-main">
      <div class="analysis-info">
        <div class="analysis-info__date">
          <p class="analysis-info__title">
            <template v-if="data.status === 'SCHEDULED'"
              >Дата назначенного анализа</template
            ><template v-else>Дата сдачи анализа</template>
          </p>
          <h2>
            {{
              data
                ? getFullDate(
                    data.analysisDateTime
                      ? data.analysisDateTime
                      : data.appointmentDateTime,
                    true
                  )
                : ''
            }}
          </h2>
          <h3>
            {{ data ? data.clinicInfo.clinicName : '' }}
          </h3>
          <div style="line-height: 18px">
            <LocateIcon />
            <p class="analysis-info__grey-text">
              {{ data ? data.clinicInfo.address : '' }}
            </p>
          </div>
          <div
            v-if="data && data.clinicInfo['subway-stations']"
            style="margin-top: 4px"
          >
            <SubwayIcon
              :color="
                data.clinicInfo['subway-stations'][0].color &&
                data.clinicInfo['subway-stations'][0].color.length
                  ? `#${data.clinicInfo['subway-stations'][0].color[0]}`
                  : 'purple'
              "
            />
            <p
              class="analysis-info__grey-text"
              v-if="data.clinicInfo['subway-stations'][0].title"
            >
              {{ data.clinicInfo['subway-stations'][0].title }}
            </p>
          </div>
        </div>
        <div class="analysis-info__other">
          <div class="patient-info">
            <p class="patient-info__title">Пациент</p>
            <h3 class="patient-info__name">
              {{ `${userInfo.name} ${userInfo.surname} ` }}
            </h3>
            <PassportInfo
              :passport="userInfo.ambulatoryCards[0]"
              :birthday="userInfo.birthday"
            />
          </div>
          <div class="patient-info">
            <p class="patient-info__title">Назначивший врач</p>
            <h3 class="patient-info__name">
              {{
                data && data.doctorInfo
                  ? `${data.doctorInfo.name} ${data.doctorInfo.surname}`
                  : ''
              }}
            </h3>
          </div>
        </div>
      </div>
      <div class="analysis-details" v-if="type === 'analiseCard'">
        <h5 class="analysis-details__mobile-title">Статус</h5>
        <p
          v-if="data.status === 'SCHEDULED'"
          :class="`${data.status}-${
            data.paymentStatus === 'PAID' ? 'paid' : 'not-paid'
          }`"
        >
          {{ getAnalysisStatus(data) }}
        </p>

        <p v-else :class="data.status">
          {{ getAnalysisStatus(data) }}
        </p>
        <p
          class="scheduled-not-paid"
          v-show="data.paymentStatus === 'NOT_PAID'"
        >
          не оплачен
        </p>
      </div>
    </div>
    <div class="analysis-examination">
      <p class="analysis-examination__title">Назначенные обследования</p>
      <p class="analysis-examination__text">
        {{
          data.services && data.services[0]
            ? data.services.map(({ title }) => title).join(', ')
            : ''
        }}
      </p>
      <template
        v-if="
          data.conditions && data.conditions.length && data.status !== 'READY'
        "
      >
        <p class="analysis-examination__title">Особенности сдачи</p>
        <p
          v-for="text in data.conditions"
          :key="text"
          class="analysis-examination__text"
        >
          {{ text }}
        </p>
      </template>
      <p class="analysis-examination__title" v-if="data.status !== 'SCHEDULED'">
        Результаты обследования
      </p>
      <div
        v-if="
          data.status === 'READY' ||
          data.status === 'AT_WORK' ||
          (data.results && data.results.length) ||
          isDiagnostic ||
          data.onlyInPerson
        "
        class="analysis-examination__body"
      >
        <div v-if="data.onlyInPerson" class="need-visit">
          <InfoIcon />
          <p>
            <span class="bold-text">Анализ готов.</span>
            Выдача результатов лабораторных исследований в регистратуре
            производится Пациенту, законному представителю или уполномоченному
            лицу.
            <router-link to="/faq?category=analysis" class="green-text">
              Подробнее
            </router-link>
          </p>
        </div>
        <div v-if="data.paymentStatus === 'NOT_PAID'" class="not-paid-info">
          <p>
            <InfoIcon />Результаты анализов недоступны. У вас задолженность по
            услугам клиники, обратитесь в регистратуру или напишите нам в чат
          </p>
        </div>
        <div v-else-if="data.status === 'AT_WORK' && data.dateOfCompletion">
          <p>
            Анализ будет готов
            <strong>{{ formatDay(data.dateOfCompletion) }}</strong>
          </p>
        </div>
        <template v-if="data.onlyInPerson"></template>
        <div v-else-if="isDiagnostic && data.result" class="result-wrapper">
          <div class="result-info">
            <p>Результаты обследования.pdf</p>
            <p @click="downloadFile(data)" class="result-link green-text">
              Посмотреть результаты обследования
            </p>
          </div>
        </div>
        <div
          v-else
          v-for="(result, index) of data.results"
          :key="index"
          class="result-wrapper"
        >
          <span class="index">{{ index + 1 }}</span>
          <div class="result-info">
            <p>
              {{ result.name
              }}<template v-if="result.extension"
                >.{{ result.extension }}</template
              >
            </p>
            <p @click="downloadFile(result)" class="result-link green-text">
              Посмотреть результаты обследования
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="analysis-service"
      v-if="
        status === AnalysisTypes.SCHEDULED && data.paymentStatus === 'NOT_PAID'
      "
    >
      <p class="analysis-service__title">Стоимость</p>
      <div class="services" v-for="service in services" :key="service.id">
        <div class="analysis-type">
          <SmCheckbox
            :onClick="
              (value) => {
                servicesHandler(value, service.id)
              }
            "
            :value="service.isSelected"
          >
            {{ service.title }}
          </SmCheckbox>
        </div>
        <p class="analysis-info-price">
          {{ service.totalAmount }} <RubIcon class="icon-rub" />
        </p>
      </div>
      <div
        class="extra-services"
        v-for="extraService in data.extraServices"
        :key="extraService.id"
      >
        <p class="extra-services__title">+ {{ extraService.title }}</p>
        <p class="extra-services__price">
          {{ extraService.totalAmount }} <RubIcon class="icon-rub" />
        </p>
      </div>
      <div class="total-price">
        <p translate="no">Итого к оплате:</p>
        <p class="bold-text">
          {{ totalAmount % 1 ? totalAmount.toFixed(2) : totalAmount }}
          <RubIcon class="icon-rub" />
        </p>
      </div>
    </div>

    <template v-if="data.status === 'SCHEDULED'">
      <div class="analysis-place">
        <div class="place-main">
          <p class="place-main__title">Вы можете сдать анализ</p>
          <div
            class="place-main__clinic"
            v-for="clinic in clinics.clinics.content"
            :key="clinic.id"
          >
            <div class="clinic-info">
              <p class="clinic-info__title">
                {{ clinic.clinicName }}
              </p>
              <div class="clinic-info__item">
                <LocateIcon />
                <span>{{ clinic.address }}</span>
              </div>
              <div class="clinic-info__subways">
                <div
                  class="subway"
                  v-for="subwayID in clinic.subwayIds"
                  :key="subwayID"
                >
                  <div
                    v-if="
                      clinics.subwayStations[subwayID] &&
                      clinics.subwayStations[subwayID].title
                    "
                  >
                    <div class="clinic-info__item">
                      <SubwayIcon
                        :color="
                          clinics.subwayStations[subwayID].color &&
                          clinics.subwayStations[subwayID].color.length
                            ? `#${clinics.subwayStations[subwayID].color[0]}`
                            : 'purple'
                        "
                      />
                      <span>{{ clinics.subwayStations[subwayID].title }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="clinics.clinics.clinicDivisions">
              <div
                v-for="division of clinics.clinics.clinicDivisions"
                :key="division.id"
                class="clinic-schedule"
              >
                <p class="clinic-schedule__title">
                  {{
                    division.title
                      ? division.title
                      : 'Режим работы процедурного кабинета'
                  }}
                </p>
                <div class="clinic-schedule__items">
                  <p v-if="division.workingHours.workdays">
                    {{ division.workingHours.workdaysTime }}
                    <span>в будни</span>
                  </p>
                  <p v-if="division.workingHours.weekends">
                    {{ division.workingHours.weekendsTime }}
                    <span>суббота</span>
                  </p>
                  <p v-if="division.workingHours.weekends">
                    {{ division.workingHours.weekendsTime }}
                    <span>воскресенье</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="place-sub">
          <Button
            classes="change-clinic"
            secondary
            icon="locate-stroke"
            :onClick="goTo"
          >
            <span>Выбрать другую клинику</span>
          </Button>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import LocateIcon from '@/assets/icons/locate'
import SubwayIcon from '@/assets/icons/subway'
import PassportInfo from '@/components/passportInfo'
import { AnalysisTypes, PaymentStatusTypes } from '@/core/constants'
import { formatWithLocale, getFullDate } from '@/core/utils'
import SmCheckbox from '@/components/checkbox'
import InfoIcon from '@/assets/icons/info'
import Button from '@/components/button'
import RubIcon from '@/assets/icons/rub'
import { mapActions, mapGetters } from 'vuex'
import { baseURL } from '@/config/request'
import { parseISO } from 'date-fns'
import { TRACKER_SLUGS } from '@/models/tracker'

export default {
  name: 'AnalysisInfoCard',
  components: {
    RubIcon,
    Button,
    InfoIcon,
    SmCheckbox,
    SubwayIcon,
    LocateIcon,
    PassportInfo
  },
  data() {
    return {
      getFullDate,
      AnalysisTypes: Object.freeze(AnalysisTypes),
      PaymentStatusTypes: Object.freeze(PaymentStatusTypes)
    }
  },
  props: {
    type: String,
    isDiagnostic: Boolean,
    data: Object,
    clinics: Object,
    userInfo: Object,
    status: String,
    totalAmount: Number,
    services: Array,
    servicesHandler: {
      type: Function,
      default: () => {}
    },
    handleButtonClick: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters('account', ['getAccountInfo'])
  },
  methods: {
    ...mapActions('analyses', ['getResults']),
    async goTo() {
      await this.$router.push('/map')
    },
    formatDay(date) {
      return formatWithLocale(parseISO(date), 'dd MMMM')
    },
    downloadFile(result) {
      const fileId = result.id

      let url

      if (this.isDiagnostic) {
        this.$tracker(TRACKER_SLUGS.SHOW_DIAGNOSTIC_RESULT)
        url = `${baseURL}/diagnostics/results/${fileId}/getFile?accountId=${this.getAccountInfo.id}&patientId=${this.data.patientId}`
      } else {
        this.$tracker(TRACKER_SLUGS.SHOW_ANALYSES_RESULT)
        url = `${baseURL}/analyses/results/${fileId}/getFile?analysisId=${this.data.id}&patientId=${this.data.patientId}&accountId=${this.getAccountInfo.id}`
      }

      const linkEl = document.createElement('a')
      linkEl.style.display = 'none'
      linkEl.href = url
      if (fileId) {
        linkEl.download = fileId
      }
      document.body.appendChild(linkEl)
      linkEl.click()
      document.body.removeChild(linkEl)
    },
    getPaymentStatus(analysis) {
      return PaymentStatusTypes[analysis.paymentStatus]
    },
    getAnalysisStatus(analysis) {
      return AnalysisTypes[analysis.status]
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style';
</style>
