<template>
  <div class="sm-tooltip">
    <div @click.stop="showPopup">
      <tippy
        :placement="placement"
        theme="light-border"
        animation="fade"
        :trigger="trigger"
        :maxWidth="maxWidth"
        :interactive="interactive"
        arrow
      >
        <template v-slot:trigger>
          <div class="trigger">
            <slot name="trigger" />
          </div>
        </template>
        <slot name="tooltip" />
      </tippy>
    </div>
    <vue-modal
      hideCloseBtn
      :on-close="closeModal"
      :name="name"
      class="mobile-tooltip"
      transitionName="fade"
    >
      <div slot="header">
        <slot name="tooltip"></slot>
        <div class="mobile-tooltip__close">
          <CloseIcon :onClose="closeModal" />
        </div>
      </div>
    </vue-modal>
  </div>
</template>
<script>
import CloseIcon from '@/assets/icons/close'
export default {
  name: 'SmTooltip',
  components: {
    CloseIcon
  },
  props: {
    trigger: String,
    interactive: {
      type: Boolean,
      default: () => false
    },
    placement: {
      type: String,
      default: () => 'bottom'
    },
    name: String,
    maxWidth: {
      type: Number,
      default: () => 292
    },
    onClick: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    closeModal() {
      this.$modals.hide(this.name)
    },
    showPopup() {
      if (this.windowWidth < 1024) {
        this.$modals.show(this.name)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style';
</style>
