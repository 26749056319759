<template>
  <div class="navbar-container" ref="navbar">
    <ul
      class="navbar"
      v-if="withNavigate"
      @mouseleave="mouseLeave"
      @mousedown="mouseDown"
      @mouseup="mouseUp"
      @mousemove="mouseMove"
    >
      <li
        v-for="({ name, to, counter }, i) in options"
        :ref="isActive(to) ? 'activetab' : ''"
        :key="i"
        :class="['navbar__item', isActive(to) ? 'active' : '']"
        @click="
          (e) => {
            mouseClick(e, to)
          }
        "
      >
        <p>
          <span role="link">
            {{ name }}
            <span v-if="counter" class="navbar__counter">
              {{ counter }}
            </span>
          </span>
        </p>
      </li>
    </ul>
    <ul
      class="navbar"
      v-else
      @mouseleave="mouseLeave"
      @mousedown="mouseDown"
      @mouseup="mouseUp"
      @mousemove="mouseMove"
    >
      <li
        v-for="option in options"
        :key="option.id"
        :class="['navbar__item', option.selected ? 'active' : '']"
        @click="
          (e) => {
            mouseClick(e)
            selectRoute(option)
          }
        "
      >
        <span>
          {{ option.title }}
          <span v-if="option.counter" class="navbar__counter">
            {{ option.counter }}
          </span>
        </span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'Navbar',
  props: {
    options: Array,
    withNavigate: { type: Boolean, default: () => true },
    selectRoute: { type: Function, default: () => {} }
  },
  data() {
    return {
      startX: 0,
      isDown: false,
      left: 0,
      dragging: false,
      start: 0
    }
  },
  mounted() {
    const content = this.$el
    //Firefox
    if (content.addEventListener) {
      content.addEventListener(
        'DOMMouseScroll',
        function (e) {
          content.scrollBy(-e.wheelDelta, 0)
        },
        false,
        { passive: true }
      )
    }
    //IE
    if (content.attachEvent) {
      content.attachEvent(
        'onmousewheel',
        function (e) {
          content.scrollBy(-e.wheelDelta, 0)
        },
        { passive: true }
      )
    }
    //Chrome
    content.onmousewheel = function (e) {
      content.scrollBy(-e.wheelDelta, 0)
    }
  },
  updated() {
    const widthTab = this.$refs.activetab[0].getBoundingClientRect().width
    const offset =
      this.$refs.activetab[0].getBoundingClientRect().left -
      this.$el.getBoundingClientRect().left

    this.$el.scrollBy({
      left: offset - widthTab / 2 - 10,
      behavior: 'smooth'
    })
  },
  methods: {
    isActive(to) {
      return this.$route.path.includes(to)
    },
    mouseDown(e) {
      e.preventDefault()
      this.isDown = true
      this.startX = e.pageX - this.$refs.navbar.offsetLeft
      this.scrollLeft = this.$refs.navbar.scrollLeft
      return false
    },
    mouseLeave() {
      this.isDown = false
    },
    mouseUp() {
      this.isDown = false
    },
    mouseMove(e) {
      if (!this.isDown) return
      e.preventDefault()
      const x = e.pageX - this.$refs.navbar.offsetLeft
      const walk = (x - this.startX) * 2
      this.$refs.navbar.scrollLeft = this.scrollLeft - walk
    },
    mouseClick(e, to) {
      e.stopPropagation()
      e.preventDefault()
      const content = this.$el
      const offset =
        e.target.getBoundingClientRect().left -
        this.$el.getBoundingClientRect().left

      content.scrollBy({
        left: offset - 100,
        behavior: 'smooth'
      })

      if (this.withNavigate && to) {
        this.$router.push(`/${to}`)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
.navbar__counter {
  width: 20px;
  height: 20px;
  background: #f28b24;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(12.5px);
  border-radius: 10px;
  margin-left: 2px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #ffffff;
}
</style>
