import { apiV1 } from '@/config/request'

export default {
  async getSpecialities({ commit }, params) {
    commit('setSpecialitiesListLoading')
    try {
      const { data } = await new apiV1({ auth: true }).get('/specialities/', {
        params
      })
      commit('setSpecialitiesListData', data?.specialities ?? [])
      commit('setSpecialitiesListLoading')
    } catch (e) {
      commit('setSpecialitiesListLoading')
    }
  },
  async getSpecialityById({ commit }, id) {
    commit('setSpecialitiesSingleLoading')
    commit('setSpecialitiesSingleData', null)
    try {
      const { data } = await new apiV1({ auth: true }).get(
        `/specialities/${id}`
      )
      commit('setSpecialitiesSingleData', data?.speciality ?? [])
      commit('setSpecialitiesSingleLoading')
    } catch (e) {
      commit('setSpecialitiesSingleLoading')
    }
  }
}
