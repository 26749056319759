export default {
  specialitiesListData(state) {
    return state.list.data
  },
  specialitiesSingleData(state) {
    return state.single.data
  },
  specialitiesSingleLoading(state) {
    return state.single.loading
  }
}
