var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"card"},[_c('h3',{staticClass:"appointment-date"},[_vm._v(" "+_vm._s(_vm.formatWeekDay(_vm.properties.selectedSlot.slot))+", "+_vm._s(_vm.formatDate(_vm.properties.selectedSlot.slot))+", "),_c('span',[_vm._v(_vm._s(_vm.formatSlot(_vm.properties.selectedSlot.slot)))])]),_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"personal-info-wrapper"},[(_vm.properties.selectedDoctor.photo)?_c('div',{staticClass:"doctors-image",style:({
            backgroundImage: ("url(" + (_vm.properties.selectedDoctor.photo) + ")")
          })}):_c('AvatarIcon',{staticClass:"doctors-image"}),_c('div',{staticClass:"personal-info"},[_c('h1',{staticClass:"doctors-specialization"},[_vm._v(" "+_vm._s(_vm.properties.selectedDoctor.specializationInfo.title)+" ")]),_c('h2',[_vm._v(" "+_vm._s(_vm.properties.selectedDoctor.surname)+" "+_vm._s(_vm.properties.selectedDoctor.name)+" "+_vm._s(_vm.properties.selectedDoctor.patronymic)+" ")]),(
              _vm.properties.selectedDoctor &&
              _vm.properties.selectedDoctor.totalExperience
            )?_c('span',{class:{
              'hide-dot': !_vm.properties.selectedDoctor.totalExperience
            }},[_vm._v(" "+_vm._s(("Стаж " + (_vm.properties.selectedDoctor.totalExperience) + " " + (_vm.declOfNum( _vm.properties.selectedDoctor.totalExperience, ['год', 'года', 'лет'] ))))+" ")]):_vm._e(),_c('div',{staticClass:"doctors-rang"},[_c('div',{staticClass:"doctors-rang-dot"}),_vm._v(" "+_vm._s(_vm.getDoctorExp(_vm.properties.selectedDoctor.totalExperience))+" ")])])],1)]),_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"dark-text"},[_c('LocateIcon'),_vm._v(" "+_vm._s(_vm.properties.selectedClinic.clinicName)+" ")],1),(_vm.properties.selectedClinic.subway)?_c('p',{staticClass:"dark-text"},[_c('SubwayIcon',{staticClass:"metro-icon",attrs:{"color":_vm.properties.selectedClinic.subway.color &&
            _vm.properties.selectedClinic.subway.color[0]
              ? ("#" + (_vm.properties.selectedClinic.subway.color[0]))
              : 'purple'}}),_vm._v(" "+_vm._s(_vm.properties.selectedClinic.subway.title)+" ")],1):_vm._e(),_c('p',{staticClass:"grey-text"},[_vm._v(_vm._s(_vm.properties.selectedClinic.address))])])]),_c('div',{class:['patient-card', 'card']},[_c('p',{staticClass:"grey-label"},[_vm._v("Пациент")]),_c('p',{staticClass:"dark-text"},[_vm._v(" "+_vm._s(_vm.getPatientInfo.surname)+" "+_vm._s(_vm.getPatientInfo.name)+" "+_vm._s(_vm.getPatientInfo.patronymic)+" ")]),(_vm.properties.selectedDoctor.priceInformation.price)?_c('div',[_c('p',{staticClass:"grey-label"},[_vm._v("Предварительная стоимость приема")]),_c('h3',{staticClass:"patient-card-price"},[_vm._v(" "+_vm._s(_vm.properties.selectedDoctor.priceInformation.price)+" "),_c('span',{staticClass:"patient-card-symbol"},[_vm._v("₽")])]),_c('p',{staticClass:"grey-text"},[_vm._v(" Конечная стоимость приема определяется врачем на основании оказанных услуг. ")])]):_vm._e(),_c('Input',{attrs:{"placeholder":"Оставьте комментарий врачу","classes":"patient-text-area","type":"textarea","value":_vm.comment,"onChange":_vm.onCommentChange}}),_c('Button',{attrs:{"primary":"","icon":"plus","classes":"make-an-appointment","onClick":function () { return this$1.handleSubmitClick(Object.assign({}, _vm.properties, {getPatientInfo: _vm.getPatientInfo}), _vm.comment); }}},[_vm._v(" Записаться на прием ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }