<template>
  <section class="appointment">
    <div v-if="loading || getError">
      <Loader :loading="loading" :error="getError" />
    </div>
    <template v-else>
      <SmCrumbs
        :dynamic-name="{
          '/appointment': 'Запись к врачу',
          '/appointment/:specId': this.specialitiesSingleData.title,
          '/appointment/:specId/:doctorId': getDoctorFullName
        }"
      />
      <h2 class="appointment__title">Новая запись</h2>
      <div class="limit-info" v-if="showLimit">
        <Info class="orange-info-icon" />
        <p>
          Достигнут лимит записей к врачам для пациентов с неоформленным
          договором.
        </p>
        <span class="green-text">Подробнее</span>
      </div>
      <Filters
        :options="sortButtonsProperties"
        :selectFilter="selectSortButton"
        :clearFilter="clearSortButton"
        :filterBlock="filterBlock"
      />
      <div class="cards">
        <DoctorsCard
          v-if="doctorsInfo"
          :doctorsData="doctorsInfo"
          :handleSlotClick="makeAnAppointment"
        />
      </div>
      <ScheduleModal
        v-if="selectedDoctor"
        :patient="getAccountInfo.patientIds[0]"
        :selectedDoctor="selectedDoctor"
      />
      <ScheduleErrorModal v-if="selectedDoctor" />
      <div v-if="showMap" class="clinic-filter">
        <SmMapComponent
          :markers="markers"
          :markerClickHandler="markerClickHandler"
          :currentCity="
            filters && filters.cityIds && filters.cityIds.length
              ? filters.cityIds[0]
              : ''
          "
        />
      </div>
    </template>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'
import { parseISO } from 'date-fns'
import { formatWithLocale, getWeekday } from '@/core/utils'
import DoctorsCard from '@/components/doctorsCard'
import Filters from '@/components/filters'
import Info from '@/assets/icons/info'
import SmSlideDoctorsSorting from '@/components/slideOutComponents/sorting'
import SmSlideMakeAnAppointment from '@/components/slideOutComponents/makeAnAppointment/makeAnAppointment'
import SmSlideDate from '@/components/slideOutComponents/date/date'
import SmMapComponent from '@/components/mapComponents/map'
import { vueSlideoutPanelService } from 'vue2-slideout-panel'
import ClinicSlideOutComponent from '@/components/slideOutComponents/clinics'
import SmCrumbs from '@/components/breadcrumbs'
import Loader from '@/components/loader'
import ScheduleModal from '@/components/modal/appointmentModals/scheduleModal'
import ScheduleErrorModal from '@/components/modal/appointmentModals/scheduleErrorModal'

export default {
  name: 'AppointmentPage',
  components: {
    ScheduleErrorModal,
    ScheduleModal,
    SmCrumbs,
    SmMapComponent,
    DoctorsCard,
    Filters,
    Info,
    Loader
  },
  async created() {
    await this.getSpecialityById(this.$route.params.specId)
    await this.getData()
    if (this.timeslotsData.doctors[0].clinics.length === 1) {
      this.sortButtonsProperties = this.sortButtonsProperties.filter(
        ({ id }) => id !== 'clinicIds'
      )
    }
  },
  data() {
    return {
      filterBlock: false,
      showMap: false,
      // TODO: Добавить логику когда будет готово
      showLimit: false,
      filters: {
        clinicIds: null,
        from: null,
        to: null
      },
      selectedDoctor: null,
      selectedDate: null,
      sortButtonsProperties: [
        {
          id: 'clinicIds',
          label: 'Клиники',
          title: 'Выберите клинику врача',
          value: '',
          items: [],
          isSelected: false,
          preload: true
        },
        {
          id: 'date',
          value: '',
          label: 'Дата приема',
          title: 'Выберите дату приема врача',
          items: [],
          isSelected: false,
          preload: true
        }
      ]
    }
  },
  computed: {
    ...mapGetters('cities', ['citiesData']),
    ...mapGetters('clinics', ['clinicsListData']),
    ...mapGetters('account', ['getAccountInfo', 'hasContract']),
    ...mapGetters('visits', [
      'visitsSingleData',
      'singleVisitLoading',
      'singleVisitError'
    ]),
    ...mapGetters('timeslots', [
      'timeslotsData',
      'timeslotsError',
      'timeslotsLoading',
      'clinicsFilters'
    ]),
    ...mapGetters('appointment', ['errorCode']),
    ...mapGetters('specialities', [
      'specialitiesSingleData',
      'specialitiesSingleLoading'
    ]),
    getError() {
      return !!(this.timeslotsError || this.singleVisitError)
    },
    getDoctorFullName() {
      return (
        `${this.doctorsInfo?.surname} ${this.doctorsInfo?.name.charAt(0)}. ` +
        (this.doctorsInfo?.patronymic
          ? `${this.doctorsInfo.patronymic.charAt(0)}.`
          : '')
      )
    },
    markers() {
      return this.sortButtonsProperties.find((el) => el.id === 'clinicIds')
        .items
    },
    specialityId() {
      return this.$route.params.specId
    },
    visitId() {
      return this.$route.query.visitId
    },
    cityId() {
      return this.$route.query.cityId
    },
    loading() {
      return (
        this.singleVisitLoading ||
        this.timeslotsLoading ||
        this.specialitiesSingleLoading
      )
    },
    doctorsInfo: {
      get: function () {
        if (this.timeslotsData?.doctors && this.timeslotsData?.doctors[0]) {
          return this.timeslotsData?.doctors
            ? this.timeslotsData?.doctors.map((instance) => ({
                ...instance,
                priceInformation: {
                  price:
                    instance.clinics && instance.clinics.length
                      ? instance.clinics[0].dates[0].price ?? 0
                      : 0
                },
                specializationInfo: this.specialitiesSingleData,
                clinics: (instance.clinics || []).map(
                  ({
                    clinicName,
                    address,
                    id,
                    'subway-stations': subway,
                    dates = []
                  }) => ({
                    clinicName,
                    address,
                    subway: subway ? subway[0] : null,
                    id,
                    options: dates.map(({ date, isAvailable }) => ({
                      label: formatWithLocale(
                        parseISO(date, 'yyyy-dd-MM'),
                        `dd MMMM, ${getWeekday(date)}`
                      ),
                      value: date,
                      isAvailable
                    })),
                    slots: dates
                  })
                )
              }))[0]
            : []
        } else {
          return null
        }
      },
      set: function (newValue) {
        return newValue
      }
    }
  },
  methods: {
    ...mapActions('specialities', ['getSpecialityById']),
    ...mapActions('timeslots', [
      'getTimeslots',
      'disableTimeslot',
      'setTimeslots'
    ]),
    ...mapActions('qualifications', ['getQualifications']),
    ...mapActions('cities', ['getCities']),
    ...mapActions('clinics', ['getClinics']),
    ...mapActions('visits', ['getSingleVisit', 'clearVisitRequestState']),
    ...mapActions('appointment', ['updateAppointment', 'createAppointment']),
    async getData() {
      const id = this.$route.params.doctorId
      let filters
      if (this.filters.clinicIds) {
        filters = _({
          ...this.filters,
          clinicIds: [`${this.filters.clinicIds}`]
        })
          .omitBy(_.isNull)
          .value()
      } else {
        filters = _(this.filters).omitBy(_.isNull).value()
      }

      if (this.visitId) {
        await this.getSingleVisit({ id: this.visitId, ...filters })
        await this.getTimeslots({
          ...(this.specialityId ? { specialityIds: [this.specialityId] } : {}),
          doctorIds: [id],
          cityIds: [this.cityId],
          groupByDoctor: true,
          ...filters
        })
      } else {
        await this.getTimeslots({
          ...(this.specialityId ? { specialityIds: [this.specialityId] } : {}),
          doctorIds: [id],
          groupByDoctor: true,
          ...filters
        })
      }
    },
    markerClickHandler(data) {
      if (data) {
        this.sortButtonsProperties = this.sortButtonsProperties.map(
          (buttonProperties) => {
            if (buttonProperties.id === 'clinicIds') {
              buttonProperties.isSelected = !buttonProperties.isSelected

              buttonProperties.value = data.direction
              this.filters[buttonProperties.id] = [data.id]

              this.getList()

              return buttonProperties
            } else return buttonProperties
          }
        )
      }
      vueSlideoutPanelService.hideAllPanels()
      this.showMap = false
    },
    formatDay({ dateTime }) {
      return formatWithLocale(parseISO(dateTime), 'd MMMM')
    },
    formatTime({ dateTime }) {
      return formatWithLocale(parseISO(dateTime), 'HH:mm')
    },
    hideModal() {
      this.$modals.hide('sm-schedule')
    },
    makeAnAppointment(info) {
      if (info.isScheduledAppointment) {
        this.$modals.show('sm-modal')
      } else {
        this.$showPanel({
          component: 'SmSlideoutPanel',
          props: {
            title: this.visitId ? 'Перенос записи на прием' : 'Запись на прием',
            component: SmSlideMakeAnAppointment,
            properties: {
              ...info,
              priceInformation: this.doctorsInfo.priceInformation,
              makeAnAppointmentHandler: async (properties, comment) => {
                this.selectedDoctor = {
                  ...properties.selectedDoctor,
                  slot: properties.selectedSlot.slot,
                  specialityInfo: this.specialitiesSingleData
                }

                const doctorId = properties.selectedDoctor.id
                const clinicIds = properties.selectedClinic?.id
                const slotId = properties.selectedSlot?.slot?.id
                const dateTime = formatWithLocale(
                  parseISO(properties.selectedSlot?.slot.dateTime),
                  'yyyy-MM-dd'
                )

                if (this.visitId) {
                  const payload = {
                    id: this.visitsSingleData.appointmentId,
                    patientId: properties.getPatientInfo.id,
                    doctorId: properties.selectedDoctor.id,
                    specialityId: this.visitsSingleData.specialityId,
                    clinicId: properties.selectedClinic?.id,
                    timeslotId: properties.selectedSlot?.slot?.id,
                    comment
                  }
                  try {
                    await this.updateAppointment(payload)

                    if (!this.errorCode) {
                      this.$modals.show('sm-schedule')
                    } else {
                      this.$modals.show('sm-schedule-error')
                    }

                    this.disableTimeslot({
                      dateTime,
                      doctorId,
                      clinicIds,
                      id: slotId
                    })
                  } catch (e) {
                    this.$modals.show('sm-schedule-error')
                  }
                } else {
                  const payload = {
                    id: properties.getPatientInfo.accountId,
                    patientId: properties.getPatientInfo.id,
                    doctorId: properties.selectedDoctor.id,
                    specialityId: this.specialitiesSingleData.id,
                    clinicId: properties.selectedClinic?.id,
                    timeslotId: properties.selectedSlot?.slot?.id,
                    comment
                  }

                  try {
                    await this.createAppointment(payload)

                    if (!this.errorCode) {
                      this.$modals.show('sm-schedule')
                    } else {
                      this.$modals.show('sm-schedule-error')
                    }

                    this.disableTimeslot({
                      dateTime,
                      doctorId,
                      clinicIds,
                      id: slotId
                    })
                  } catch (e) {
                    console.log(e)
                    this.$modals.show('sm-schedule-error')
                  }
                }

                return true
              }
            }
          },
          openOn: 'right',
          width: '440',
          height: '80',
          sync: true,
          keepAlive: false
        }).promise.then(async (result) => {
          await this.$router.push({
            name: 'UpcomingData',
            params: {
              dateTime: result.selectedSlot.slot.dateTime,
              address: result.selectedClinic.address,
              clinicName: result.selectedClinic.clinicName,
              patientFullName: `${result.getPatientInfo.name} ${result.getPatientInfo.surname}`,
              recommendation:
                result.selectedDoctor.specializationInfo.recommendations
            }
          })
        })
      }
    },
    async selectSortButton(properties) {
      if (properties.isSelected) {
        this.sortButtonsProperties = this.sortButtonsProperties.map(
          (buttonProperties) => {
            if (buttonProperties.id === properties.id) {
              buttonProperties.isSelected = !buttonProperties.isSelected
              buttonProperties.value = ''
              return buttonProperties
            } else return buttonProperties
          }
        )
      } else {
        this.filterBlock = true
        let component = SmSlideDoctorsSorting

        if (properties.id === 'date') {
          component = SmSlideDate
        }

        if (properties.id === 'clinicIds') {
          component = ClinicSlideOutComponent
          properties.items = Object.values(this.timeslotsData.clinics).map(
            ({ clinicName, id, ...rest }) => ({
              direction: clinicName,
              clinicName,
              id,
              ...rest
            })
          )
          if (this.windowWidth >= 1024) this.showMap = true
        }
        this.filterBlock = false
        this.$showPanel({
          component: 'SmSlideoutPanel',
          cssClass: properties.id === 'clinicIds' ? 'map-filter' : '',
          props: {
            title: properties.title,
            component,
            properties: {
              ...properties,
              fixedRange: false,
              maxDays: 0,
              minDate: new Date()
            }
          },
          openOn: 'right',
          width: '400',
          height: '80',
          sync: true,
          keepAlive: false,
          disableBgClick: properties.id !== 'clinicIds',
          hideBg: properties.id === 'clinicIds'
        }).promise.then((result) => {
          this.showMap = false
          if (result?.closedBy) return
          if (result) {
            this.sortButtonsProperties = this.sortButtonsProperties.map(
              (buttonProperties) => {
                if (buttonProperties.id === properties.id) {
                  buttonProperties.isSelected = !buttonProperties.isSelected

                  if (properties.id === 'date') {
                    buttonProperties.value = result.label
                    this.filters['from'] = formatWithLocale(
                      result.start,
                      "yyyy-MM-dd'T'HH:mm:ss"
                    )
                    this.filters['to'] = formatWithLocale(
                      result.end,
                      "yyyy-MM-dd'T'HH:mm:ss"
                    )
                  } else {
                    buttonProperties.value = result.direction
                    this.filters[buttonProperties.id] = result.id
                  }

                  this.getData()

                  return buttonProperties
                } else return buttonProperties
              }
            )
          }
        })
      }
    },
    clearSortButton(properties) {
      this.sortButtonsProperties = this.sortButtonsProperties.map(
        (buttonProperties) => {
          if (buttonProperties.id === properties.id) {
            buttonProperties.isSelected = false
            buttonProperties.value = ''

            if (properties.id === 'date') {
              this.filters['from'] = null
              this.filters['to'] = null
            } else {
              this.filters[buttonProperties.id] = null
            }

            this.getData()

            return buttonProperties
          } else return buttonProperties
        }
      )
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
