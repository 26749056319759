<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.6903 8.7012C16.5724 8.88275 16.5724 9.11724 16.6903 9.29879L17.3463 10.3091C17.9425 11.2277 17.5431 12.4652 16.52 12.8577L15.3969 13.2887C15.1951 13.3662 15.0576 13.5559 15.0464 13.7722L14.9845 14.976C14.9282 16.0703 13.8792 16.8363 12.8214 16.551L11.66 16.2381C11.4515 16.182 11.2288 16.2544 11.0929 16.4228L10.3369 17.3602C9.64954 18.2124 8.35195 18.2142 7.66322 17.3602L6.90715 16.4228C6.7713 16.2543 6.54877 16.182 6.34003 16.2381L5.17868 16.551C4.12291 16.8357 3.07201 16.0725 3.01561 14.976L2.9537 13.7722C2.94258 13.5559 2.80501 13.3662 2.60321 13.2887L1.48006 12.8577C0.45905 12.466 0.0563708 11.2296 0.653866 10.3091L1.30966 9.29879C1.42748 9.11728 1.42748 8.88272 1.30966 8.7012L0.65376 7.69086C0.0574933 6.77234 0.456805 5.53488 1.48002 5.14232L2.60311 4.71131C2.8049 4.63386 2.94244 4.44409 2.95359 4.22781L3.0155 3.02407C3.07177 1.92987 4.12056 1.16382 5.17857 1.449L6.33999 1.76196C6.5487 1.81811 6.77126 1.74572 6.90708 1.57725L7.66319 0.639783C8.35062 -0.212575 9.64824 -0.213946 10.3368 0.639783L11.0929 1.57722C11.2287 1.74565 11.4514 1.81811 11.66 1.76193L12.8213 1.44897C13.8773 1.16421 14.928 1.92773 14.9844 3.02403L15.0463 4.22778C15.0574 4.44409 15.195 4.63383 15.3968 4.71131L16.52 5.14232C17.541 5.53407 17.9436 6.77051 17.3462 7.69082L16.6903 8.7012ZM11.4792 6.00625C11.7086 5.77685 12.0806 5.77685 12.31 6.00625C12.5395 6.23568 12.5395 6.60766 12.31 6.83702L6.82689 12.3202C6.71221 12.4349 6.56182 12.4923 6.41149 12.4923C6.26116 12.4923 6.1108 12.4349 5.99608 12.3202C5.76666 12.0908 5.76666 11.7188 5.99605 11.4894L11.4792 6.00625ZM6.99896 5.83411C6.35107 5.83411 5.82401 6.36121 5.82401 7.00906C5.82401 7.65695 6.35114 8.18401 6.99896 8.18401C7.64685 8.18401 8.17391 7.65692 8.17391 7.00906C8.17394 6.36121 7.64685 5.83411 6.99896 5.83411ZM10.1321 11.3173C10.1321 10.6695 10.6592 10.1424 11.3071 10.1424C11.955 10.1424 12.4821 10.6695 12.482 11.3173C12.482 11.9652 11.9549 12.4923 11.3071 12.4923C10.6592 12.4923 10.1321 11.9652 10.1321 11.3173Z"
      fill="url(#paint0_linear_2219_30885)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2219_30885"
        x1="1.35568"
        y1="27.0652"
        x2="24.5352"
        y2="14.8958"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'PercentIcon'
}
</script>
