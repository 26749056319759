import state from './clinicsState'
import mutations from './clinicsMutations'
import getters from './clinicsGetters'
import actions from './clinicsActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
