<template>
  <section class="faq">
    <div v-if="getListLoading || getListError">
      <Loader :loading="getListLoading" :error="!!getListError" />
    </div>
    <template v-else>
      <SmCrumbs />
      <h2 class="faq__title">Частые вопросы</h2>
      <Navbar
        :options="currentCategories"
        :with-navigate="false"
        :selectRoute="selectRoute"
        class="faq_nav"
      />
      <div class="faq-list">
        <details
          class="faq-list-item"
          v-for="item of faqList"
          :key="item.id"
          @toggle="onDetailsToggle"
        >
          <summary class="faq-list-item__headline">
            <h3 class="title">{{ item.question }}</h3>
            <IconArrow class="icon" />
          </summary>
          <div
            class="faq-list-item__description"
            v-html="getAnswer(item.answer)"
          ></div>
        </details>
      </div>
    </template>
  </section>
</template>
<script>
import { TRACKER_SLUGS } from '@/models/tracker'

import Navbar from '@/components/navbar'
import { mapActions, mapGetters } from 'vuex'
import IconArrow from '@/assets/icons/arrow-up'
import SmCrumbs from '@/components/breadcrumbs'
import Loader from '@/components/loader'

export default {
  name: 'FaqPage',
  components: { SmCrumbs, Navbar, IconArrow, Loader },
  data() {
    return {
      routes: [],
      data: [],
      animation: null
    }
  },
  methods: {
    ...mapActions('faq', ['getFaq']),
    selectRoute(data) {
      this.categories = this.categories.map((el) =>
        data.id === el.id
          ? { ...el, selected: true }
          : { ...el, selected: false }
      )
      this.faqList = this.getFaqList.filter((faq) => {
        return faq.categoryIds.includes(data.id)
      })
    },
    getAnswer(answer) {
      let res = ''
      answer.replace?.(
        /((?:https?:\/\/|ftps?:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,})|(\n+|(?:(?!(?:https?:\/\/|ftp:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,}).)+)/gim,
        (m, link, text) => {
          res += link
            ? ` <a target="_blank" href=${
                (link[0] === 'w' ? '//' : '') + link
              }>${link}</a>`
            : ` ${text}`
        }
      )
      console.log(res)
      return res
    },
    onDetailsToggle(e) {
      if (e.target.open) {
        this.$tracker(TRACKER_SLUGS.GOFAQ)
      }
    }
  },
  async mounted() {
    await this.getFaq()
    if (this.$route.query?.category === 'analysis') {
      const category = this.currentCategories.find(
        (category) => category.id === '3e82de23-4906-4829-b3f6-1ac7064d406c'
      )
      if (category) this.selectRoute(category)
    }
  },
  computed: {
    ...mapGetters('faq', [
      'getCategoriesList',
      'getFaqList',
      'getListLoading',
      'getListError'
    ]),
    categories: {
      get() {
        return this.routes.length
          ? this.routes
          : (this.getCategoriesList || []).map((c, i) => ({
              ...c,
              selected: i === 0
            }))
      },
      set(val) {
        this.routes = val
      }
    },
    currentCategories() {
      return this.categories.filter((category) =>
        this.getFaqList.some((faq) => faq.categoryIds.includes(category.id))
      )
    },
    faqList: {
      get() {
        return this.data.length
          ? this.data
          : (this.getFaqList || []).filter((faq) =>
              faq.categoryIds.includes(this.getCategoriesList[0].id)
            )
      },
      set(val) {
        this.data = val
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
