const initialState = () => ({
  errors: null,
  loading: false,
  account: null,
  patient: null,
  contract: null,
  changeAccountDataLoading: false,
  showChat: false
})

export default {
  initialState: initialState(),
  ...initialState()
}
