<template>
  <section class="sm-slideout-panel">
    <div class="title-container">
      <p class="title">{{ title }}</p>
      <CloseIcon class="icon" :onClose="() => close(null)" />
    </div>
    <div>
      <component
        :is="component"
        :close="close"
        v-bind="{ ...properties }"
        :properties="properties"
      ></component>
    </div>
  </section>
</template>
<script>
import CloseIcon from '@/assets/icons/close'

export default {
  name: 'SmSlideoutPanel',
  components: {
    CloseIcon
  },
  props: {
    title: String,
    component: {
      type: [String, Object],
      default: 'div'
    },
    properties: Object
  },
  methods: {
    close(data) {
      this.$emit('closePanel', data)
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
