import state from './subwayState'
import mutations from './subwayMutations'
import getters from './subwayGetters'
import actions from './subwayActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
