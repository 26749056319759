<template>
  <svg
    @click="onClick"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8 2.65C9.56411 2.65 10.9347 3.30765 12.0802 4.20916C13.2272 5.11197 14.113 6.23269 14.6886 7.08291C15.0689 7.64479 15.0689 8.35521 14.6886 8.91709C14.113 9.76731 13.2272 10.888 12.0802 11.7908C10.9347 12.6923 9.56411 13.35 8 13.35C6.43589 13.35 5.06525 12.6923 3.91984 11.7908C2.77279 10.888 1.88696 9.76731 1.31142 8.91709C0.931057 8.35521 0.931057 7.64479 1.31142 7.08291C1.88696 6.23269 2.77279 5.11197 3.91984 4.20916C5.06525 3.30765 6.43589 2.65 8 2.65Z"
      stroke="#232F37"
      stroke-width="1.3"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <path
      d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
      stroke="#232F37"
      stroke-width="1.3"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconEyeShow',
  props: {
    onClick: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style scoped></style>
