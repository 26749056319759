<template>
  <svg
    width="11"
    height="13"
    viewBox="0 0 11 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 2.5L1 11.5M1 2.5L10 11.5" stroke="#27926D" stroke-width="2" />
  </svg>
</template>
<script>
export default {
  name: 'CrossIcon'
}
</script>
