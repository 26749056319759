<template>
  <SmModal size="md" name="sm-schedule-error" :title="getTitle">
    <template v-slot:body>
      <div class="line"></div>
      <div class="sm-modal__body">
        <div class="sm-modal__body__icon">
          <CalendarIcon />
        </div>
        <div class="sm-modal__body__content">
          <template v-if="errorMsg">
            {{ errorMsg }}
          </template>
          <template v-else>
            К сожалению, данное время больше недоступно, запишитесь на другое
            время или к другому специалисту.
          </template>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <Button :onClick="hideModal" icon="close" class="sm-modal__close-btn">
        Закрыть окно
      </Button>
    </template>
  </SmModal>
</template>

<script>
import SmModal from '@/components/modal'
import Button from '@/components/button'
import CalendarIcon from '@/assets/icons/calendar'
import { mapGetters } from 'vuex'

export default {
  name: 'ScheduleErrorModal',
  components: { CalendarIcon, Button, SmModal },
  props: { selectedDoctor: Object },
  computed: {
    ...mapGetters('appointment', ['errorMsg']),
    getTitle() {
      return this.errorMsg?.includes(
        'Достигнут лимит записей к врачам для пациентов с неоформленным договором'
      ) || this.errorMsg?.includes('Вы уже записаны на два посещения клиники')
        ? 'Достигнут лимит записей'
        : 'Ошибка записи'
    }
  },
  methods: {
    hideModal() {
      this.$modals.hide('sm-schedule-error')
    }
  }
}
</script>
