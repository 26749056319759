<template>
  <ymap-marker
    :properties="{ iconContent: markerLayout }"
    :options="{
      hideIconOnBalloonOpen: false,
      openBalloonOnClick: this.windowWidth === 1024
    }"
    :coords="[item.latitude, item.longitude]"
    :marker-id="item.id"
    :icon="icon"
    :hint-content="hintLayout"
    :balloon-template="balloonTemplate"
    cluster-name="1"
    @click="() => markerClickHandler(item)"
    @mouseenter="hoverHandler"
    @mouseleave="hoverHandler"
    @balloonopen="bindListener"
    @balloonclose="unbindListener"
  />
</template>
<script>
import { ymapMarker } from 'vue-yandex-maps'
export default {
  name: 'SmMarkers',
  data() {
    return {
      icon: {
        layout: 'default#imageWithContent',
        imageHref: '/',
        imageSize: [70, 70]
      }
    }
  },
  props: {
    item: { type: Object },
    map: { type: Object },
    showClinicInfo: {
      type: Function,
      default: () => {}
    },
    balloonCallback: {
      type: Function,
      default: () => {}
    }
  },
  components: { ymapMarker },
  methods: {
    renderSubway() {
      if (!this.item.subwayStations || !this.item.subwayStations.length)
        return ''
      const title = this.item.subwayStations[0]?.title

      return `<p class="map-hint__item">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM5.38088 9.5H4.22888L4.47687 4.164H5.62088L7.01288 6.668L8.37288 4.164H9.52488L9.77287 9.5H8.56487L8.41288 6.012L7.30888 8.092H6.68488L5.51688 6.036L5.38088 9.5Z"
                    fill="#${this.item.subwayStations[0]?.color[0]}"
                  />
                </svg>
                ${title}
              </p>`
    },
    hoverHandler(e) {
      const marker = document.querySelector(`#m${this.item.id}`)
      if (e.originalEvent.type === 'mouseenter') {
        marker.classList.add('hover')
      } else {
        marker.classList.remove('hover')
      }
    },
    markerClickHandler(data) {
      if (this.windowWidth !== 1024) {
        this.showClinicInfo(data)
      }
    },
    balloonClickHandler() {
      this.map.balloon.close()
      this.showClinicInfo(this.item)
      this.balloonCallback(this.item)
    },
    bindListener() {
      const balloonButton = document.getElementById('select-clinic')
      if (balloonButton) {
        balloonButton.addEventListener('click', this.balloonClickHandler)
      }
    },
    unbindListener() {
      const balloonButton = document.getElementById('select-clinic')
      if (balloonButton) {
        balloonButton.removeEventListener('click', this.balloonClickHandler)
      }
    }
  },
  computed: {
    markerLayout() {
      return `<div class="map-marker" id="m${this.item.id}"></div>`
    },

    hintLayout() {
      const hint = `
          <p class="map-hint__item">
             <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1 7.025C1 7.017 1 7.008 1 7C1 3.137 4.137 0 8 0C11.863 0 15 3.137 15 7C15 7.008 15 7.017 15 7.025C14.984 12.256 9.95 15.378 8.359 15.934C8.253 15.971 8.126 16 8 16C7.874 16 7.753 15.976 7.641 15.934C6.05 15.378 1.016 12.256 1 7.025V7.025V7.025ZM8 3.27847C10.0543 3.27847 11.7215 4.94572 11.7215 7C11.7215 9.05428 10.0543 10.7215 8 10.7215C5.94572 10.7215 4.27847 9.05428 4.27847 7C4.27847 4.94572 5.94572 3.27847 8 3.27847Z" fill="url(#paint0_linear_2213_8055)"/>
                <defs>
                <linearGradient id="paint0_linear_2213_8055" x1="1.79592" y1="24.058" x2="21.3097" y2="14.7039" gradientUnits="userSpaceOnUse">
                <stop stop-color="#27926D"/>
                <stop offset="1" stop-color="#2DAB7F"/>
                </linearGradient>
                </defs>
              </svg>
              ${this.item.direction || this.item.clinicName}
          </p>
          ${this.renderSubway()}
           <p class="map-hint__address">
             ${this.item.address || ''}
          </p>
      `

      return `<div class="map-hint">${hint}</div>`
    },
    balloonTemplate() {
      return `
      <div class="map-balloon">
        <p class="map-balloon__item">
             <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1 7.025C1 7.017 1 7.008 1 7C1 3.137 4.137 0 8 0C11.863 0 15 3.137 15 7C15 7.008 15 7.017 15 7.025C14.984 12.256 9.95 15.378 8.359 15.934C8.253 15.971 8.126 16 8 16C7.874 16 7.753 15.976 7.641 15.934C6.05 15.378 1.016 12.256 1 7.025V7.025V7.025ZM8 3.27847C10.0543 3.27847 11.7215 4.94572 11.7215 7C11.7215 9.05428 10.0543 10.7215 8 10.7215C5.94572 10.7215 4.27847 9.05428 4.27847 7C4.27847 4.94572 5.94572 3.27847 8 3.27847Z" fill="url(#paint0_linear_2213_8055)"/>
                <defs>
                <linearGradient id="paint0_linear_2213_8055" x1="1.79592" y1="24.058" x2="21.3097" y2="14.7039" gradientUnits="userSpaceOnUse">
                <stop stop-color="#27926D"/>
                <stop offset="1" stop-color="#2DAB7F"/>
                </linearGradient>
                </defs>
              </svg>
              ${this.item.clinicName}
          </p>
           ${this.renderSubway()}
          <p class="map-balloon__address">
             ${this.item.address}
          </p>
          <span class="map-balloon__select-clinic" id="select-clinic">Выбрать клинику</span>
          </div>
      `
    }
  }
}
</script>
