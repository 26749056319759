<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.9375 14.4331V15.7925C7.9375 17.1732 9.05679 18.2925 10.4375 18.2925H17.6875L20.0259 20.7478C20.6484 21.4014 21.75 20.9608 21.75 20.0581V10.4331C21.75 9.05239 20.6307 7.93311 19.25 7.93311H17.4844V11.3862C17.4844 13.069 16.1202 14.4331 14.4375 14.4331H7.9375Z"
      fill="url(#paint0_linear_2539_58207)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.45246 3.04639C3.23607 3.04639 2.25 4.03246 2.25 5.24884V15.2517C2.25 16.2143 3.47586 16.6222 4.05263 15.8516L5.8136 13.4985C5.92701 13.347 6.10523 13.2578 6.29451 13.2578H14.4636C15.4588 13.2578 16.2656 12.451 16.2656 11.4558V5.24884C16.2656 4.03246 15.2796 3.04639 14.0632 3.04639H4.45246ZM4.85294 6.85061C4.85294 6.40829 5.21151 6.04972 5.65383 6.04972H11.2601C11.7024 6.04972 12.061 6.40829 12.061 6.85061C12.061 7.29293 11.7024 7.65151 11.2601 7.65151H5.65383C5.21151 7.65151 4.85294 7.29293 4.85294 6.85061ZM5.65383 8.85286C5.21151 8.85286 4.85294 9.21143 4.85294 9.65375C4.85294 10.0961 5.21151 10.4546 5.65383 10.4546H8.76161C9.20393 10.4546 9.5625 10.0961 9.5625 9.65375C9.5625 9.21143 9.20393 8.85286 8.76161 8.85286H5.65383Z"
      fill="url(#paint1_linear_2539_58207)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2539_58207"
        x1="8.72276"
        y1="27.6712"
        x2="26.4939"
        y2="17.4271"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2539_58207"
        x1="3.04681"
        y1="22.9068"
        x2="21.0032"
        y2="12.4684"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ChatIcon'
}
</script>
