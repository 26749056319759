import state from './promotionState'
import mutations from './promotionMutations'
import getters from './promotionGetters'
import actions from './promotionActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
