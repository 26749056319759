<template>
  <article class="doctor-card" :class="{ 'open-slots': openSlots }">
    <div class="card-header" @click.stop="() => handleDoctorClick(doctorsData)">
      <div class="doctor-image">
        <div
          v-if="doctorsData.photo"
          class="doctor-photo"
          :style="{
            backgroundImage: `url(${getBaseUrl}/${doctorsData.photo})`
          }"
        ></div>
        <AvatarIcon v-else class="avatar" />
      </div>
      <div class="doctor-info">
        <div class="personal-info">
          <p class="personal-info__title">
            {{ doctorsData.surname }}
            {{ doctorsData.name }}
            <template v-if="doctorsData.patronymic"
              >{{ doctorsData.patronymic }}
            </template>
          </p>
          <span
            class="personal-info__qualification"
            v-if="doctorsData.totalExperience"
            :class="{
              'hide-dot':
                !doctorsData.qualifications ||
                !doctorsData.qualifications.length ||
                !doctorsData.qualifications[0].title
            }"
            >{{
              `Стаж ${doctorsData.totalExperience} ${declOfNum(
                doctorsData.totalExperience,
                ['год', 'года', 'лет']
              )}`
            }}</span
          >
          <span
            class="personal-info__qualification"
            :class="{
              'hide-dot': !doctorsData.totalExperience
            }"
            v-if="
              doctorsData.qualifications &&
              doctorsData.qualifications.length &&
              doctorsData.qualifications[0].title
            "
          >
            <template v-if="doctorsData.qualifications[0].title">
              {{ doctorsData.qualifications[0].title }}
            </template>
          </span>
        </div>
        <div
          class="price"
          v-if="
            doctorsData.priceInformation && doctorsData.priceInformation.price
          "
        >
          <div class="price__inner">
            <span>Предварительная стоимость приема</span>
            <SmTooltip
              :trigger="windowWidth >= 1024 ? 'mouseenter' : ''"
              name="price-tooltip"
              placement="top"
              :max-width="330"
            >
              <template v-slot:trigger>
                <InfoIcon class="info-icon" />
              </template>
              <template v-slot:tooltip>
                <div class="price-tooltip">
                  <h3 class="price-tooltip__title">Стоимость приема</h3>
                  <p class="price-tooltip__text">
                    Стоимость указана за стандартный прием. Перед выполнением
                    дополнительных манипуляций, обследований, анализов просим
                    уточнять стоимость.
                  </p>
                </div>
              </template>
            </SmTooltip>
          </div>
          <p>
            {{ doctorsData.priceInformation.price }}
            <RubIcon />
          </p>
        </div>
      </div>
    </div>
    <div
      class="card-body"
      v-for="(clinic, index) of doctorsData.clinics"
      :key="index"
    >
      <div class="left-part">
        <p class="left-part__item">
          <LocateIcon class="icon" />
          <span>{{ clinic.clinicName }}</span>
        </p>
        <p class="left-part__item" v-if="clinic.subway">
          <SubwayIcon
            class="icon"
            :color="
              clinic.subway.color && clinic.subway.color.length
                ? `#${clinic.subway.color[0]}`
                : 'purple'
            "
          />
          <span>{{ clinic.subway.title }}</span>
        </p>
        <p class="left-part__item grey-text">{{ clinic.address }}</p>
      </div>
      <div class="right-part">
        <p>Выберите дату</p>
        <Select
          v-model="selectedDate[clinic.id]"
          class="select-date"
          label="label"
          :options="clinic.options"
          :onChange="onDateChange"
          :clearable="false"
          v-tracker="TRACKER_SLUGS.APPOINTMENT_DATE"
        />
        <p>Выберите время приема</p>
        <div class="slots">
          <div
            :class="[
              doctorsData.isNeedCall ||
              !slot.isAvailable ||
              !selectedDate[clinic.id].isAvailable
                ? 'disable-slot'
                : null,
              'slot'
            ]"
            v-for="(slot, index) of getSlot(clinic)"
            v-show="selectedDate[clinic.id].isAvailable || slot.isAvailable"
            :key="index + slot.id"
            @click="() => slotClick(slot, clinic)"
            v-tracker="TRACKER_SLUGS.APPOINTMENT_TIME"
          >
            {{ formatSlot(slot) }}
          </div>
        </div>
        <!-- //TODO: порефакторить говнокод ниже на слоты -->
        <div
          class="need-call-wrapper"
          v-if="doctorsData.isNeedCall || !selectedDate[clinic.id].isAvailable"
        >
          <InfoIcon class="orange-info-icon" />
          <p>
            <span v-if="!hasSpecialStatus">
              Запись к данному специалисту возможна только через контакт-центр
              <span class="green-text" v-if="contactsPhone">{{
                contactsPhone
              }}</span>
              <span class="green-text" v-else>+7 (495) 154-10-82</span>
            </span>
            <span v-if="hasSpecialStatus">
              <a :href="siteUrl" class="green-link"
                >Запишитесь онлайн на сайте</a
              >
            </span>
            <span>
              или
              <span class="green-link" @click="openWebim"
                >обратитесь в чат</span
              ></span
            >
          </p>
        </div>
      </div>
    </div>
    <div
      class="open-card"
      v-if="
        getSlot(doctorsData.clinics[doctorsData.clinics.length - 1]).length > 5
      "
      @click="openSlotsHandler"
    ></div>
  </article>
</template>

<script>
import LocateIcon from '@/assets/icons/locate'
import InfoIcon from '@/assets/icons/info'
import Select from '@/components/select'
import SubwayIcon from '@/assets/icons/subway'
import RubIcon from '@/assets/icons/rub'
import SmTooltip from '@/components/tooltip'
import { formatWithLocale } from '@/core/utils'
import { parseISO } from 'date-fns'
import AvatarIcon from '@/assets/icons/avatar'
import { declOfNum } from '@/core/utils'
import { mapGetters } from 'vuex'
import pick from 'lodash/pick'
import { TRACKER_SLUGS } from '@/models/tracker'

export default {
  name: 'DoctorsCard',
  components: {
    SubwayIcon,
    InfoIcon,
    LocateIcon,
    RubIcon,
    Select,
    SmTooltip,
    AvatarIcon
  },
  props: {
    doctorsData: {
      name: String,
      totalExperience: Number,
      price: Number,
      photo: String,
      clinicName: String,
      address: String,
      slots: [String],
      isNeedCall: Boolean,
      phoneNumber: String,
      id: String
    },
    speciality: {
      id: String,
      recommendations: String,
      specialStatus: String | null,
      title: String
    },
    handleSlotClick: {
      type: Function,
      default: () => {}
    },
    handleDoctorClick: {
      type: Function,
      default: () => {}
    },
    onDateChange: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    console.debug(this.speciality?.specialStatus)
    return {
      openSlots: false,
      selectedDate: this.doctorsData.clinics.reduce((prev, { id, options }) => {
        prev[id] = options ? options[0] : null
        return prev
      }, {}),
      declOfNum
    }
  },
  created() {
    this.TRACKER_SLUGS = pick(TRACKER_SLUGS, [
      'APPOINTMENT_DATE',
      'APPOINTMENT_TIME'
    ])
  },
  methods: {
    openWebim() {
      window.webim.api.chat.start()
    },
    slotClick(slot, clinic) {
      if (!(this.doctorsData.isNeedCall || !slot.isAvailable)) {
        this.handleSlotClick({
          selectedDoctor: this.doctorsData,
          selectedClinic: clinic,
          selectedSlot: { slot },
          type: 'create'
        })
      }
    },
    openSlotsHandler() {
      this.openSlots = !this.openSlots
    },
    formatSlot({ dateTime }) {
      return formatWithLocale(parseISO(dateTime), 'HH:mm')
    },
    getSlot(clinic) {
      try {
        return [
          ...clinic.slots.find(
            ({ date }) => this.selectedDate[clinic.id]?.value === date
          ).timeslots
        ].sort(
          (current, next) =>
            new Date(current.dateTime) - new Date(next.dateTime)
        )
      } catch (e) {
        return []
      }
    }
  },
  computed: {
    ...mapGetters('cities', ['selectedCityData']),
    getBaseUrl() {
      return process.env.VUE_APP_API_BASE_URL
    },
    contactsPhone() {
      return this.selectedCityData?.contactCenterPhone
    },
    siteUrl() {
      return (
        this.selectedCityData?.siteUrl || 'https://www.smclinic.ru/appointment/'
      )
    },
    hasSpecialStatus() {
      const { specialStatus } = this.speciality || {}
      return specialStatus && specialStatus === 'WEB_AVAILABLE'
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style';

.hide-dot:after {
  display: none !important;
}
</style>
