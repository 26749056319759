export default {
  single: {
    data: null,
    error: null,
    loading: false
  },
  filters: {
    data: null
  },
  list: {
    data: null,
    error: null,
    loading: false,
    pagination: {
      totalPages: 1,
      size: 5
    }
  },
  specializations: {
    data: null,
    error: null,
    loading: false
  }
}
