<template>
  <svg
    @click="onClick"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.2066 2.85295L10.2393 3.82021C11.256 4.29592 12.1561 5.04997 12.9156 5.87844C13.5394 6.55898 14.0259 7.24491 14.3565 7.76241C14.4491 7.90736 14.4491 8.09264 14.3565 8.23759C14.0259 8.75509 13.5394 9.44102 12.9156 10.1216C11.6371 11.5162 9.96009 12.7 7.99885 12.7C6.24568 12.7 4.71963 11.7541 3.50236 10.5572L2.58317 11.4763C3.92951 12.8018 5.76449 14 7.99885 14C11.5085 14 14.0327 11.0438 15.2257 9.28146C15.755 8.49951 15.755 7.50049 15.2257 6.71854C14.436 5.55188 13.0628 3.86202 11.2066 2.85295ZM1.57476 10.3634C1.26249 9.97924 0.994165 9.60965 0.772001 9.28146C0.242666 8.49951 0.242666 7.50049 0.772001 6.71854C1.96499 4.9562 4.48922 2 7.99885 2C8.59568 2 9.16401 2.08549 9.70214 2.23606L8.60093 3.33726C8.4034 3.31278 8.20266 3.3 7.99885 3.3C6.03761 3.3 4.36061 4.48376 3.08215 5.87844C2.45833 6.55898 1.97186 7.24491 1.64123 7.76241C1.54862 7.90736 1.54862 8.09264 1.64123 8.23759C1.86052 8.58082 2.14836 8.99814 2.49936 9.43884L1.57476 10.3634ZM5.42524 8.63427C5.70948 9.79153 6.75392 10.65 7.99885 10.65C9.46241 10.65 10.6489 9.46355 10.6489 8C10.6489 6.75506 9.79038 5.71063 8.63313 5.42639L5.42524 8.63427ZM1.16063 8.58333C1.16048 8.58363 1.16051 8.58357 1.16072 8.58316L1.16063 8.58333ZM1.16072 7.41684C1.16051 7.41643 1.16048 7.41637 1.16063 7.41667L1.16072 7.41684ZM7.99885 6.65C7.25327 6.65 6.64885 7.25442 6.64885 8C6.64885 8.74558 7.25327 9.35 7.99885 9.35C8.74444 9.35 9.34885 8.74558 9.34885 8C9.34885 7.25442 8.74444 6.65 7.99885 6.65Z"
      fill="#232F37"
    />
    <path
      d="M1 13.5L13.5 1"
      stroke="#232F37"
      stroke-width="1.3"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  name: 'EyeIcon',
  props: {
    onClick: {
      type: Function,
      default: () => {}
    }
  }
}
</script>
