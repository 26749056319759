import state from './diagnosticsState'
import mutations from './diagnosticsMutations'
import getters from './diagnosticsGetters'
import actions from './diagnosticsActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
