import { apiV1 } from '@/config/request'

export default {
  async getCities({ commit }) {
    commit('setLoadingCities')
    try {
      const { data } = await new apiV1({ auth: true }).get('/cities')
      commit('setCitiesData', data?.cities ?? [])
      commit('setLoadingCities')
      return data?.cities
    } catch (e) {
      commit('setLoadingCities')
    }
  },
  selectCity({ commit }, setSelectedCity) {
    commit('setSelectedCity', setSelectedCity)
  }
}
