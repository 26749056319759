<template>
  <div class="auth-form sms">
    <p class="auth-form__title">Введите код из SMS</p>
    <div class="auth-form__inner">
      <p class="auth-form__description">
        Код отправлен на
        <span class="phone">
          {{ getFormatNumber(getRecoveryPhone) }}
        </span>
      </p>
      <a class="change-phone" @click="changePhone">Изменить телефон</a>
      <OtpInput
        ref="otpInput"
        :onComplete="onCodeEntered"
        :invalid="!!errorAuth.message"
        :text="errorAuth.message"
      />
      <div class="code-resend" v-if="showCountdown || isNeedWait">
        <span>Отправить код повторно</span>
        <Countdown
          type="reset"
          :name="counterName"
          :ms="counterMs"
          :reset="resetCountdown"
        />
      </div>
      <div class="code-resend active" v-else>
        <span @click="resendCode">Отправить код повторно</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import OtpInput from '@/components/otpInput'
import Countdown from '@/components/countdown'
import { getFormatNumber } from '@/core/utils'

export default {
  name: 'SmsConfirm',
  components: { OtpInput, Countdown },
  watch: {
    errorAuth(v) {
      if (v.errorCode) {
        this.clearInput()
        this.$v?.$reset?.()
      }
    }
  },
  data() {
    return {
      showCountdown: true,
      getFormatNumber
    }
  },
  methods: {
    ...mapActions('auth', [
      'setPhoneSignInData',
      'clearRecoveryPhone',
      'confirmSmsCode',
      'confirmSmsRecovery',
      'signInWithPhone',
      'resendWithPhone',
      'clearAuthError'
    ]),
    resetCountdown() {
      this.showCountdown = !this.showCountdown
    },
    changePhone() {
      // !this.isRecovery && this.clearRecoveryPhone()
      this.$router.push({
        name: this.isRecovery ? 'Recovery' : 'AuthByPhone',
        query: { back: true }
      })
    },
    onCodeEntered(value) {
      this.isRecovery
        ? this.confirmSmsRecovery(value)
        : this.confirmSmsCode({ payload: value, firstStep: true })
    },
    async resendCode() {
      await this.resendWithPhone({
        isRecovery: this.isRecovery
      })
      this.showCountdown = true
      this.clearInput()
    },
    clearInput() {
      this.$refs.otpInput.$refs.input.clearInput()
    }
  },
  computed: {
    ...mapGetters('auth', ['getRecoveryPhone', 'getPhoneSignIn', 'errorAuth']),
    isRecovery() {
      return this.$route.query.mode === 'recovery'
    },
    counterMs() {
      return this.isNeedWait ? this.errorAuth.timer * 1000 : 60000
    },
    counterName() {
      return (this.isRecovery ? 'timer20' : 'timer2') + this.counterMs
    },
    isNeedWait() {
      return this.errorAuth?.errorCode === 'needWait'
    }
  },
  mounted() {
    if (!(this.getPhoneSignIn.phone || this.getRecoveryPhone)) {
      //TODO: Вернуть редирект
      // this.$router.push('/auth')
    }
  },
  beforeRouteLeave(to, from, next) {
    next()
    this.clearAuthError()
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setPhoneSignInData({
        code: ''
      })
      vm.clearAuthError()
    })
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
