<template>
  <div>
    <form class="change-password" @submit.prevent="showModal">
      <template v-if="this.type === 'password'">
        <SmInput
          type="password"
          id="pass"
          placeholder="Новый пароль"
          :value="values['pass']"
          :onChange="(e) => setValue(e)"
          :classes="['auth-input', 'm-b']"
          :invalid="validateState('pass')"
        />
        <SmInput
          type="password"
          id="repeatPass"
          placeholder="Повторите новый пароль"
          :value="values['repeatPass']"
          :onChange="(e) => setValue(e)"
          :classes="['auth-input', 'm-b']"
          :invalid="validateState('repeatPass')"
        />
      </template>
      <template v-else>
        <SmInput
          type="text"
          id="val"
          placeholder="Новый логин"
          :value="values['val']"
          :onChange="(e) => setValue(e)"
          :classes="['auth-input', 'm-b']"
          :invalid="validateState('val')"
        />
        <SmInput
          type="text"
          id="repeatVal"
          placeholder="Повторите новый логин"
          :value="values['repeatVal']"
          :onChange="(e) => setValue(e)"
          :classes="['auth-input', 'm-b']"
          :invalid="validateState('repeatVal')"
        />
      </template>
      <Button
        type="submit"
        class="change-button"
        icon="arrow"
        :loading="getChangeAccountDataLoading"
      >
        Изменить
      </Button>
      <div class="has-error" v-if="$v.values.$anyDirty && $v.values.$anyError">
        <ErrorToast :text="errorMsg" />
      </div>
      <div class="has-error">
        <ErrorToast v-if="this.getErrors" :text="this.getErrors.description" />
      </div>
    </form>
    <ApproveModal
      :action-handler="submitForm"
      :action="this.type !== 'password' ? 'сменить логин' : 'сменить пароль'"
    />
  </div>
</template>
<script>
import SmInput from '@/components/input'
import Button from '@/components/button'
import { requiredIf, sameAs } from 'vuelidate/lib/validators'
import ErrorToast from '@/components/errorToast'
import { mapGetters } from 'vuex'
import ApproveModal from '@/components/modal/profileModals/approveModal'
export default {
  name: 'ChangeProfileDataForm',
  components: { ApproveModal, ErrorToast, Button, SmInput },
  data() {
    return {
      values: {
        val: '',
        repeatVal: '',
        pass: '',
        repeatPass: ''
      }
    }
  },
  props: {
    type: String,
    close: {
      type: Function,
      default: () => {}
    },
    onChange: {
      type: Function,
      default: () => {}
    }
  },
  validations: {
    values: {
      val: {
        required: requiredIf(function () {
          return this.type !== 'password'
        })
      },
      pass: {
        required: requiredIf(function () {
          return this.type === 'password'
        }),
        customValidator: (value) => {
          if (value) {
            return (
              !(value === value.toLowerCase()) &&
              /[0-9]/.test(value) &&
              value.length >= 6
            )
          } else {
            return true
          }
        }
      },
      repeatPass: {
        required: requiredIf(function () {
          return this.type === 'password'
        }),
        sameAs: sameAs(function () {
          return this.values.pass
        })
      },
      repeatVal: {
        required: requiredIf(function () {
          return this.type !== 'password'
        }),
        sameAs: sameAs(function () {
          return this.values.val
        })
      }
    }
  },
  methods: {
    setValue(e) {
      this.values[e.target.name] = e.target.value
    },
    showModal() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$modals.show('approve-modal')
      }
    },
    submitForm() {
      this.type === 'password'
        ? this.onChange(this.values.pass, this.close)
        : this.onChange(this.values.val, this.close)
      this.$modals.hide('approve-modal')
    },
    validateState(name) {
      const { $dirty, $error, $model, $invalid } = this.$v.values[name]
      if ($model === null) return null
      if (!$model.length && !$invalid) return null
      return $dirty ? $error : null
    }
  },
  computed: {
    ...mapGetters('account', ['getChangeAccountDataLoading', 'getErrors']),
    errorMsg() {
      let msg = ''
      if (this.type === 'password') {
        if (
          !this.$v.values.pass.required ||
          !this.$v.values.repeatPass.required
        ) {
          msg = 'Обязательные поля'
          return msg
        }
        if (!this.$v.values.pass.customValidator) {
          msg =
            'Пароль не соответствует требованиям (не менее 6 символов, цифра, строчная и заглавная буква латиницы)'
          return msg
        }
        if (!this.$v.values.repeatPass.sameAs) {
          msg =
            this.type === 'password'
              ? 'Пароль и подтверждение не совпадают'
              : 'Логин и подтверждение не совпадают'
          return msg
        }
      } else {
        if (
          !this.$v.values.val.required ||
          !this.$v.values.repeatVal.required
        ) {
          msg = 'Обязательные поля'
          return msg
        }

        if (!this.$v.values.repeatVal.sameAs) {
          msg =
            this.type === 'password'
              ? 'Пароль и подтверждение не совпадают'
              : 'Логин и подтверждение не совпадают'
          return msg
        }
      }
      return msg
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
