<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.76824 14.3696C9.16547 14.7668 9.80713 14.7668 10.2044 14.3696L13.8608 10.7131C13.9553 10.6189 14.0302 10.507 14.0813 10.3838C14.1324 10.2605 14.1587 10.1285 14.1587 9.99507C14.1587 9.86168 14.1324 9.72959 14.0813 9.60638C14.0302 9.48316 13.9553 9.37124 13.8608 9.27701L10.2044 5.62053C10.0139 5.43009 9.75562 5.3231 9.4863 5.3231C9.21698 5.3231 8.95868 5.43009 8.76824 5.62053C8.5778 5.81097 8.47081 6.06926 8.47081 6.33859C8.47081 6.60791 8.5778 6.8662 8.76824 7.05664L10.6831 8.98164H1.8525C1.29232 8.98164 0.833984 9.43998 0.833984 10.0002C0.833984 10.5603 1.29232 11.0187 1.8525 11.0187H10.6831L8.76824 12.9335C8.37102 13.3307 8.38121 13.9826 8.76824 14.3696ZM17.1303 0.833496H2.87102C2.33077 0.833496 1.81264 1.04811 1.43062 1.43013C1.0486 1.81215 0.833984 2.33028 0.833984 2.87053V5.92609C0.833984 6.48627 1.29232 6.94461 1.8525 6.94461C2.41269 6.94461 2.87102 6.48627 2.87102 5.92609V3.88905C2.87102 3.32887 3.32935 2.87053 3.88954 2.87053H16.1118C16.6719 2.87053 17.1303 3.32887 17.1303 3.88905V16.1113C17.1303 16.6715 16.6719 17.1298 16.1118 17.1298H3.88954C3.32935 17.1298 2.87102 16.6715 2.87102 16.1113V14.0742C2.87102 13.5141 2.41269 13.0557 1.8525 13.0557C1.29232 13.0557 0.833984 13.5141 0.833984 14.0742V17.1298C0.833984 18.2502 1.75065 19.1668 2.87102 19.1668H17.1303C18.2507 19.1668 19.1673 18.2502 19.1673 17.1298V2.87053C19.1673 1.75016 18.2507 0.833496 17.1303 0.833496Z"
      fill="#CC3D3D"
    />
  </svg>
</template>
<script>
export default {
  name: 'ExitIcon'
}
</script>
