import _ from 'lodash'

export default {
  setSubwaySingleData(state, payload) {
    state.single.data = payload
  },
  setSubwayListData(state, payload) {
    state.list.data = _.unionBy(state.list.data, payload, 'id')
  },
  setSingleLoading(state) {
    state.single.loading = !state.single.loading
  },
  setListLoading(state) {
    state.list.loading = !state.list.loading
  },
  setSingleError(state, payload) {
    state.single.error = payload
  },
  setListError(state, payload) {
    state.list.error = payload
  }
}
