<template>
  <vue-modal
    hideCloseBtn
    :on-close="closeModal"
    :name="name"
    class="sm-modal"
    :class="size"
    transitionName="fade"
  >
    <div slot="header" v-if="windowWidth >= 1024">
      <div class="sm-modal__logo">
        <ModalLogoIcon />
      </div>
      <CloseIcon class="sm-modal__close" :onClose="closeModal" />
      <h3>{{ title }}</h3>
    </div>
    <div slot="header" v-else>
      <div class="container">
        <h3>{{ title }}</h3>
        <CloseIcon class="sm-modal__close" :onClose="closeModal" />
      </div>
    </div>
    <div :class="{ container: windowWidth <= 1023 }">
      <slot name="body" />
    </div>
    <div class="sm-modal__footer" :class="{ container: windowWidth <= 1023 }">
      <slot name="footer" />
    </div>
  </vue-modal>
</template>
<script>
import CloseIcon from '@/assets/icons/close'
import ModalLogoIcon from '@/assets/icons/modal-logo'

export default {
  name: 'SmModal',
  components: { ModalLogoIcon, CloseIcon },
  props: {
    title: String,
    name: String,
    size: String
  },
  methods: {
    closeModal() {
      this.$modals.hide('sm-modal')
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
