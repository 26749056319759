<template>
  <form class="auth-form" @submit.prevent="auth">
    <h2
      v-if="showTimer || !!this.errorAuth.errorCode"
      class="auth-form__title title-center"
    >
      <template v-if="showTimer"> Временная блокировка </template>
      <template v-else> Ошибка авторизации </template>
    </h2>
    <h2 v-else class="auth-form__title">Войдите в личный кабинет</h2>
    <div class="auth-form__inner">
      <SmInput
        type="text"
        id="username"
        placeholder="Логин"
        :value="values['username']"
        :onChange="(e) => setValue(e)"
        :classes="'auth-input'"
        :invalid="!!this.errorAuth.errorCode || validateState('username')"
      />
      <!--      <SmInput-->
      <!--        type="text"-->
      <!--        id="username"-->
      <!--        placeholder="Логин"-->
      <!--        :value="values['username']"-->
      <!--        :onChange="(e) => setValue(e)"-->
      <!--        :classes="'auth-input'"-->
      <!--        :invalid="!!this.errorAuth.errorCode || validateState('username')"-->
      <!--        :onClick="() => (this.values['username'] = 'SM- ')"-->
      <!--        v-mask="['SM-##########']"-->
      <!--      />-->
      <SmInput
        type="password"
        id="password"
        placeholder="Пароль"
        :value="values['password']"
        :onChange="(e) => setValue(e)"
        :classes="'auth-input'"
        :invalid="!!this.errorAuth.errorCode || validateState('password')"
      />
      <router-link
        class="lost-password"
        to="recovery"
        custom
        v-slot="{ navigate }"
      >
        <span @click="navigate" role="link"> Забыли пароль? </span>
      </router-link>
      <Button
        :classes="'auth-form__btn'"
        icon="arrow"
        :disabled="showTimer"
        :loading="this.getLoading"
      >
        Продолжить
      </Button>
    </div>
    <div class="has-error" v-if="this.errorAuth.errorCode">
      <!--      <router-link class="linkTo" to="phone" custom v-slot="{ navigate }">-->
      <!--        <span @click="navigate" role="link"> Войти по номеру телефона </span>-->
      <!--      </router-link>-->
      <ErrorToast
        v-if="this.errorAuth"
        :text="
          showTimer
            ? `${this.errorAuth.message}. Чтобы записаться к врачу, позвоните по телефону +7 495 154 10 82`
            : this.errorAuth.message
        "
      />
    </div>
    <div class="has-error" v-if="this.$v.$invalid && this.$v.$anyError">
      <ErrorToast v-if="this.$v.$invalid" :text="'Обязательные поля'" />
    </div>
    <Countdown :ms="600000" name="timer1" v-if="showTimer" />
  </form>
</template>
<script>
import Button from '@/components/button'
import SmInput from '@/components/input'
import { mapActions, mapGetters } from 'vuex'
import ErrorToast from '@/components/errorToast'
import Countdown from '@/components/countdown'
import { required, minLength } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import { TRACKER_SLUGS } from '@/models/tracker'

export default {
  name: 'AuthByLogin',
  components: { Countdown, ErrorToast, SmInput, Button },
  directives: { mask },
  data() {
    return {
      values: {
        username: '',
        password: '',
        isOfferAccepted: false
      },
      isBlocked: false
    }
  },
  validations: {
    values: {
      username: { required, minLength: minLength(3) },
      password: { required }
    }
  },
  watch: {
    errorAuth(v) {
      if (v.errorCode) {
        this.values = {
          username: '',
          password: '',
          isOfferAccepted: false
        }
        this.$v.$reset()
      }
    }
  },
  methods: {
    ...mapActions('auth', ['signIn', 'clearAuthError']),
    async auth() {
      this.clearAuthError()
      this.$v.$touch()
      this.$tracker(TRACKER_SLUGS.AUTH_LOGIN)
      if (!this.$v.$invalid) {
        await this.signIn({
          ...this.values,
          username: this.values.username
        })
        if (!this.errorAuth.errorCode && this.getPatientInfo?.id) {
          await this.$router.push('/')
        }
      }
    },
    setValue(e) {
      const name = e.target.name
      const value = e.target.value
      this.values[name] = value
    },
    validateState(name) {
      const { $dirty, $error, $model, $invalid } = this.$v.values[name]
      if ($model === null) return null
      if (!$model.length && !$invalid) return null
      return $dirty ? $error : null
    }
  },
  computed: {
    ...mapGetters('auth', ['errorAuth', 'getLoading', 'getLoginData']),
    ...mapGetters('account', ['getPatientInfo']),
    showTimer() {
      return this.errorAuth.errorCode === '00010002'
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.clearAuthError()
    })
  },
  beforeRouteLeave(to, from, next) {
    next()
    this.clearAuthError()
  }
}
</script>
<style lang="scss" scoped>
@import '../style.scss';
.auth-form__captcha {
  margin-bottom: 1rem;
}
</style>
