<template>
  <SmModal
    size="md"
    name="sm-change-profile-message"
    class="sm-modal change-profile-message-modal"
    :title="messageType === 'password' ? 'Смена пароля' : 'Смена логина'"
  >
    <template v-slot:body>
      <div class="line"></div>
      <div class="sm-modal__body">
        <div class="sm-modal__body__content">
          <p>
            <template v-if="messageType === 'password'">Пароль</template
            ><template v-else>Логин</template> был успешно изменен
          </p>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <Button :onClick="hideModal" icon="close" class="sm-modal__close-btn">
        ОК
      </Button>
    </template>
  </SmModal>
</template>

<script>
import SmModal from '@/components/modal'
import Button from '@/components/button'

export default {
  name: 'ChangeProfileMessageModal',
  components: { Button, SmModal },
  props: {
    messageType: String
  },
  methods: {
    hideModal() {
      this.$modals.hide('sm-change-profile-message')
    }
  }
}
</script>
