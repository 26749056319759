<template>
  <section class="layout">
    <Header />
    <main class="main">
      <AsideMenuMobile v-if="windowWidth <= 1366" />
      <div class="container">
        <div class="inner" :class="{ smMap: isMap }">
          <router-view></router-view>
          <AsideMenu v-if="windowWidth > 1366 && !isMap" />
        </div>
      </div>
    </main>
    <Footer />
  </section>
</template>
<script>
import Footer from '@/components/footer'
import Header from '@/components/header'
import AsideMenuMobile from '@/components/asideMenu/mobile'
import AsideMenu from '@/components/asideMenu/desktop'
export default {
  components: { AsideMenuMobile, AsideMenu, Header, Footer },
  computed: {
    isMap() {
      return this.$route.name === 'Map'
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
