<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 12L20 12"
      stroke="url(#paint0_linear_3227_29185)"
      stroke-width="3"
      stroke-linecap="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3227_29185"
        x1="28.058"
        y1="11.9431"
        x2="27.9993"
        y2="10.231"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'MinusIcon'
}
</script>
