import state from './faqState'
import mutations from './faqMutations'
import getters from './faqGetters'
import actions from './faqActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
