<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.00078 15.2C11.9772 15.2 15.2008 11.9765 15.2008 8.00005C15.2008 4.0236 11.9772 0.800049 8.00078 0.800049C4.02433 0.800049 0.800781 4.0236 0.800781 8.00005C0.800781 11.9765 4.02433 15.2 8.00078 15.2ZM6.13111 5.06977C5.83821 4.77687 5.36334 4.77687 5.07045 5.06977C4.77755 5.36266 4.77755 5.83753 5.07045 6.13043L6.94012 8.0001L5.07045 9.86977C4.77755 10.1627 4.77755 10.6375 5.07045 10.9304C5.36334 11.2233 5.83821 11.2233 6.13111 10.9304L8.00078 9.06076L9.87045 10.9304C10.1633 11.2233 10.6382 11.2233 10.9311 10.9304C11.224 10.6375 11.224 10.1627 10.9311 9.86977L9.06144 8.0001L10.9311 6.13043C11.224 5.83753 11.224 5.36266 10.9311 5.06977C10.6382 4.77687 10.1633 4.77687 9.87045 5.06977L8.00078 6.93944L6.13111 5.06977Z"
      fill="#CC3D3D"
    />
  </svg>
</template>
<script>
export default {
  name: 'CancelRoundedIcon'
}
</script>
