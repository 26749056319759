<template>
  <div class="sm-slider">
    <div class="sm-slider__header">
      <p>{{ title }}</p>
      <div class="sm-slider__buttons">
        <div class="prev">
          <ArrowDown />
        </div>
        <div class="next">
          <ArrowDown />
        </div>
      </div>
    </div>
    <div class="sm-slider__container">
      <swiper
        :slides-per-view="slidesPerView"
        :space-between="spaceBetween"
        :loop="false"
        :pagination="pagination"
        :navigation="{
          nextEl: '.next',
          prevEl: '.prev'
        }"
        :breakpoints="breakpoints"
        class="sm-slider"
      >
        <slot />
      </swiper>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination } from 'swiper'

import { SwiperCore, Swiper } from 'swiper-vue2'

import 'swiper/swiper-bundle.css'
import ArrowDown from '@/assets/icons/arrow-down'

SwiperCore.use([Navigation, Pagination])

export default {
  name: 'SmSlider',
  components: {
    ArrowDown,
    Swiper
  },
  props: {
    slidesPerView: [Number, String],
    spaceBetween: Number,
    pagination: Boolean,
    title: String,
    breakpoints: Object
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
