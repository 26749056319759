export default {
  setLoadingListDoctors(state) {
    state.list.loading = !state.list.loading
  },
  setErrorListDoctors(state, payload) {
    state.list.error = payload
  },
  setLoadingSingleDoctors(state) {
    state.single.loading = !state.single.loading
  },
  setListDoctorsData(state, payload) {
    state.list.data = payload
  },
  updateListDoctorsData(state, payload) {
    state.list.data.content = [...state.list.data.content, ...payload.content]
  },
  setSingleDoctorsError(state, payload) {
    state.single.error = payload
  },
  setSingleDoctorsData(state, payload) {
    state.single.data = payload
  },
  setPaginationData(state, payload) {
    state.list.pagination = payload
  },
  clearDoctorRequestState(state) {
    state.single.loading = false
    state.single.error = null
  }
}
