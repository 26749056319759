import { apiV1 } from '@/config/request'

export default {
  async getVisits(
    { commit, rootState },
    {
      accountId = rootState.account.account.id,
      patientId = rootState.account.patient.id,
      sort,
      page,
      size,
      merge = false,
      ...payload
    }
  ) {
    if (!merge) {
      commit('setLoadingListVisits')
    }
    try {
      const { data } = await new apiV1({ auth: true }).post(
        '/bx/visits/filter',
        { ...payload, patientId },
        {
          params: { accountId, sort, size, page }
        }
      )

      data.visits.content = data.visits.content.map((instance) => ({
        ...instance,
        doctorInfo: data.doctors[instance.doctorId],
        clinicInfo: {
          subways:
            data.clinics[instance.clinicId] &&
            data.clinics[instance.clinicId]['subway-stations']
              ? data.clinics[instance.clinicId]['subway-stations']
              : {},
          ...data.clinics[instance.clinicId]
        },
        specialityInfo: data.specialities[instance.specialityId]
      }))
      if (!merge) {
        commit('setListVisitsData', data ?? {})
      } else {
        commit('updateListVisitsData', data?.visits ?? {})
      }

      commit('setPaginationData', {
        page: data?.visits?.totalPages,
        size: data?.visits?.size
      })
      if (!merge) {
        commit('setLoadingListVisits')
      }
    } catch (e) {
      commit('setListVisitsError', e)
      if (!merge) {
        commit('setLoadingListVisits')
      }
      console.log(e)
    }
  },
  openVisit(
    { rootState },
    {
      id,
      accountId = rootState.account.account.id,
      patientId = rootState.account.patient.id
    }
  ) {
    return new apiV1({ auth: true }).post(
      `/visits/open`,
      {
        appointmentId: id
      },
      {
        params: {
          accountId,
          patientId
        }
      }
    )
  },
  async getSingleVisit(
    { commit, rootState, dispatch },
    {
      id,
      accountId = rootState.account.account.id,
      patientId = rootState.account.patient.id,
      withClinic = true,
      withDoctor = true
    }
  ) {
    try {
      commit('setLoadingSingleVisits')
      const { data } = await new apiV1({ auth: true }).get(`/visits/${id}`, {
        params: {
          accountId,
          patientId
        }
      })

      const actions = []

      const setClinic = async (instance) => {
        await dispatch(
          'clinics/getClinicById',
          { id: instance.clinicId },
          {
            root: true
          }
        )
        const data = rootState.clinics.single.data

        const subwayId = data?.clinic?.subwayIds[0] ?? null
        if (subwayId) {
          await dispatch('subways/getSubwayById', subwayId, { root: true })
          data.subway = rootState.subways?.single.data
        }
        instance.clinic = data
      }

      const setDoctor = async (instance) => {
        const id = instance.doctorId
        await dispatch(
          'doctors/getDoctorById',
          { id },
          {
            root: true
          }
        )
        instance.doctor = rootState.doctors.single.data
      }

      if (withClinic) actions.push(setClinic)
      if (withDoctor) actions.push(setDoctor)

      if (actions.length) {
        await Promise.all(
          actions.map((callback) => callback(data?.visit))
        ).catch((e) => console.log(e))
      }
      // // костыль
      // if (!data.visit?.price) {
      //   await new apiV1({ auth: true })
      //     .get(`/appointments/${id}`, {
      //       params: {
      //         accountId,
      //         patientId
      //       }
      //     })
      //     .then((res) => {
      //       data.visit.price = res.data.appointment.price
      //     })
      // }
      commit('setSingleVisitsData', {
        ...data.visit,
        patient: rootState.account.patient
      })
      commit('setLoadingSingleVisits')
    } catch (e) {
      console.log(e)
      commit('setLoadingSingleError', e)
      commit('setLoadingSingleVisits')
    }
  },
  async getVisitsFilters(
    { commit, rootState },
    {
      accountId = rootState.account.account.id,
      patientId = rootState.account.patient.id,
      ...payload
    }
  ) {
    try {
      const { data } = await new apiV1({ auth: true }).post(
        'bx/filter-values/visits',
        { ...payload },
        {
          params: { accountId, patientId }
        }
      )
      commit('setFilters', data)
    } catch (e) {
      console.log(e)
    }
  },
  clearVisits({ commit }) {
    commit('setListVisitsData', null)
    commit('setListVisitsError', null)
  },
  clearVisitRequestState({ commit }) {
    commit('clearVisitRequestState')
  }
}
