export default {
  setError(state, payload) {
    state.errors = payload
  },
  setLoading(state) {
    state.loading = !state.loading
  },
  setChangeAccountDataLoading(state) {
    state.changeAccountDataLoading = !state.changeAccountDataLoading
  },
  setAccountInfo(state, payload) {
    state.account = payload
  },
  setLogin(state, payload) {
    state.account.login = payload
  },
  setChatState(state, payload) {
    state.showChat = payload
  },
  setPatientInfo(state, payload) {
    state.patient = payload
  },
  setPatientId(state, payload) {
    state.patient.id = payload
  },
  setContract(state, payload) {
    state.contract = payload
  },
  clearAccountState(state) {
    for (const key of Object.keys(state)) {
      if (key !== 'initialState') {
        state[key] = state.initialState[key]
      }
    }
  }
}
