<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0.65C12.056 0.65 15.35 3.94399 15.35 8C15.35 12.056 12.056 15.35 8 15.35C3.94399 15.35 0.65 12.056 0.65 8C0.65 3.94399 3.94399 0.65 8 0.65Z"
      stroke="#27926D"
      stroke-width="1.3"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.82422 11.3059C6.82422 11.8689 7.28222 12.3269 7.84522 12.3269C8.40822 12.3269 8.86722 11.8689 8.86722 11.3059C8.86722 10.7429 8.40822 10.2839 7.84522 10.2839C7.28222 10.2839 6.82422 10.7429 6.82422 11.3059Z"
      fill="#27926D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.84047 3.52388C5.72447 3.62288 5.10547 5.25888 5.10547 5.96588C5.10547 6.27988 5.10547 6.88188 5.80047 6.95888C6.45547 7.03188 6.70447 6.59088 6.83447 6.21288C6.92547 5.94988 7.21847 5.22488 8.09547 5.22488C8.72347 5.22488 9.09547 5.59888 9.09547 6.14888C9.09547 7.24888 6.95747 7.25088 6.95747 8.87488C6.95747 9.42488 7.45347 9.56088 7.75647 9.56088C7.96747 9.56088 8.31247 9.55388 8.50447 9.16988C8.91347 8.34988 10.8935 8.02488 10.8935 5.99988C10.8935 4.69688 9.72547 3.43588 7.84047 3.52388Z"
      fill="#27926D"
    />
  </svg>
</template>
<script>
export default {
  name: 'HelpIcon'
}
</script>
