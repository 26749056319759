import state from './qualificationsState'
import mutations from './qualificationsMutations'
import getters from './qualificationGetters'
import actions from './qualificationsActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
