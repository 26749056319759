import { apiV1 } from '@/config/request'

export default {
  async getDiagnosticsSorting(
    { commit, rootState },
    {
      accountId = rootState.account.account.id,
      patientId = rootState.account.patient.id,
      ...payload
    }
  ) {
    try {
      const { data } = await new apiV1({ auth: true }).get(
        '/diagnostics/sorting-values',
        {
          params: { accountId, patientId, ...payload }
        }
      )
      commit('setSortData', data)
    } catch (e) {
      console.log(e)
    }
  },
  async getDiagnosticsFilters(
    { commit, rootState },
    {
      accountId = rootState.account.account.id,
      patientId = rootState.account.patient.id
    }
  ) {
    try {
      const { data } = await new apiV1({ auth: true }).get(
        '/filter-values/diagnostics',
        {
          params: { accountId, patientId }
        }
      )
      commit('setFiltersData', data)
    } catch (e) {
      console.log(e)
    }
  },
  async getDiagnosticsFiltersPost(
    { commit, rootState },
    {
      accountId = rootState.account.account.id,
      patientId = rootState.account.patient.id,
      ...payload
    }
  ) {
    try {
      const { data } = await new apiV1({ auth: true }).post(
        '/filter-values/diagnostics',
        { ...payload },
        {
          params: { accountId, patientId }
        }
      )
      commit('setFiltersData', data)
    } catch (e) {
      console.log(e)
    }
  },
  async getDiagnostics(
    { commit, rootState },
    {
      accountId = rootState.account.account.id,
      patientId = rootState.account.patient.id,
      ...payload
    }
  ) {
    commit('setListLoading')
    try {
      const { data } = await new apiV1({ auth: true }).get('/bx/diagnostics', {
        params: { accountId, patientId, ...payload }
      })
      commit('setDiagnosticsData', data ?? {})
      commit('setPaginationData', {
        page: payload.page,
        size: data?.diagnostics.size,
        totalPages: data?.diagnostics.totalPages
      })
      commit('setListLoading')
    } catch (e) {
      commit('setListLoading')
      commit('setListError', `${e}`)
    }
  },
  async getFilteredDiagnostics(
    { commit, rootState },
    {
      accountId = rootState.account.account.id,
      patientId = rootState.account.patient.id,
      page,
      size,
      sort,
      merge = false,
      ...payload
    }
  ) {
    if (!merge) {
      commit('setListLoading')
    }

    try {
      const { data } = await new apiV1({ auth: true }).post(
        '/bx/diagnostics/filter',
        { patientId, ...payload },
        {
          params: { accountId, page, size, sort }
        }
      )
      if (!merge) {
        commit('setDiagnosticsData', data ?? {})
      } else {
        commit('updateDiagnosticsData', data ?? {})
      }

      if (!merge) {
        commit('setListLoading')
      }
    } catch (e) {
      if (!merge) {
        commit('setListLoading')
      }
      commit('setListError', `${e}`)
    }
  },
  async getDiagnosticById(
    { commit, dispatch, rootState },
    {
      id,
      accountId = rootState.account.account.id,
      patientId = rootState.account.patient.id,
      withDoctor,
      withClinic,
      withSubway
    }
  ) {
    commit('setSingleLoading')
    try {
      const { data } = await new apiV1({ auth: true }).get(
        `/diagnostics/${id}`,
        {
          params: {
            accountId,
            patientId
          }
        }
      )
      const setDoctor = async (instance) => {
        const id = instance.doctorId
        await dispatch(
          'doctors/getDoctorById',
          { id },
          {
            root: true
          }
        )
        instance.doctorInfo = rootState.doctors.single.data
      }

      const setClinic = async (instance) => {
        await dispatch(
          'clinics/getClinicById',
          { id: instance.analysisClinicId },
          {
            root: true
          }
        )
        const data = rootState.clinics.single.data
        if (withSubway) {
          const subwayId = data?.clinic?.subwayIds[0] ?? null
          if (subwayId) {
            await dispatch('subways/getSubwayById', subwayId, { root: true })
            data.subway = rootState.subways?.single.data
          }
        }
        instance.clinicInfo = data
      }
      const actions = []

      if (withDoctor) actions.push(setDoctor)
      if (withClinic) actions.push(setClinic)
      if (actions.length) {
        await Promise.all(
          actions.map((callback) => callback(data?.diagnostic))
        ).catch((e) => console.error(e))

        commit('setDiagnosticById', data?.diagnostic ?? {})
        commit('setSingleLoading')
      } else {
        commit('setDiagnosticById', data?.diagnostic ?? {})
        commit('setSingleLoading')
      }
    } catch (e) {
      commit('setSingleLoading')
      commit('setSingleError', `${e}`)
    }
  },
  clearDiagnosticsRequestState({ commit }) {
    commit('clearDiagnosticsRequestState')
  }
}
