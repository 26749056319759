<template>
  <section ref="historyList">
    <div v-if="listVisitLoading || listVisitError">
      <Loader
        :loading="listVisitLoading"
        :error="!!listVisitError"
        :key="isHistory ? 'is-history' : 'default'"
      />
    </div>
    <template v-else>
      <div v-if="isHistory" class="medical-info__filters">
        <Filters
          :options="sortButtonsProperties"
          :selectFilter="selectSortButton"
          :clearFilter="clearSortButton"
          :clear-all="clearAllFilters"
          :filterBlock="filterBlock"
        />
      </div>
      <EmptyData
        v-if="!upcomingMeetings.length"
        :title="
          isHistory
            ? 'У вас еще нет истории посещений'
            : 'У вас еще нет предстоящих записей'
        "
      />
      <div class="medical-info" v-else>
        <MedicalInfoCard
          v-for="(info, index) of upcomingMeetings"
          :info="info"
          :key="index"
          :history="isHistory"
          :modalHandler="modalHandler"
          :showDetail="handleAppointmentClick"
        />
        <Pagination
          :key="isHistory ? 'is-history' : 'default'"
          :paginationInfo="this.paginationInfo"
          :getData="this.getData"
          :getMoreData="(payload) => this.getData({ merge: true, ...payload })"
          :autoLoad="false"
        >
        </Pagination>
        <CancelAdmission
          v-if="appointmentInfo"
          :afterCloseHandler="
            () =>
              this.getData({
                page: Number(this.$route.query.page) - 1 || 0,
                size: Number(this.$route.query.size) || 5
              })
          "
          :rescheduleHandler="rescheduleHandler"
          :id="appointmentInfo.id"
        />
        <SmModal
          size="md"
          name="upcomingMeeting-modal"
          title="Вы уже записаны на прием"
        >
          <template v-slot:body>
            <div class="line"></div>
            <div class="sm-modal__body">
              <div class="sm-modal__body__icon"><CalendarIcon /></div>
              <div class="sm-modal__body__content">
                4 июня в 15:00 вы записаны на прием к терапевту Жадину П.М. по
                адресу: ул. Лесная, д. 57, стр. 1
              </div>
            </div>
          </template>
          <template v-slot:footer>
            <div class="modal-buttons-wrapper">
              <Button :onClick="closeModal" class="reschedule-btn" secondary>
                Перенести прием
              </Button>
              <Button :onClick="closeModal" class="sign-btn">
                Записаться на другое время
              </Button>
            </div>
          </template>
        </SmModal>
        <SmModal
          size="md"
          name="appointmentCreated-modal"
          :title="`${modalData.patientFullName}, вы записаны на прием в &quot;СМ-Клиника&quot;`"
          v-if="modalData"
          class="new-created-appointment"
        >
          <template v-slot:body>
            <div class="line"></div>
            <div class="sm-modal__body">
              <div class="sm-modal__body__icon"><CalendarIcon /></div>
              <div class="sm-modal__body__content">
                <div class="new-created-appointment__block">
                  <p class="date">
                    {{ getFullDate(modalData.dateTime, true) }}
                  </p>
                  <p class="address">
                    {{ modalData.address }}
                  </p>
                </div>
                <div class="new-created-appointment__block">
                  <p class="label">Пациент</p>
                  <p class="patient-name">
                    {{ modalData.patientFullName }}
                  </p>
                </div>
                <div
                  class="new-created-appointment__block new-created-appointment__block--recommendation"
                  v-if="modalData.recommendation"
                >
                  <p class="recommendation">
                    {{ modalData.recommendation }}
                  </p>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:footer>
            <div class="modal-buttons-wrapper">
              <Button
                :onClick="newAppointment"
                :class="['reschedule-btn', 'created-app-button']"
                secondary
                icon="plus"
              >
                Записаться еще
              </Button>
              <Button
                :onClick="closeModal"
                :class="['sign-btn', 'created-app-button']"
                icon="close"
              >
                Готово
              </Button>
            </div>
          </template>
        </SmModal>
        <div v-if="showMap" class="clinic-filter">
          <SmMapComponent
            :coords="coords"
            :markers="markers"
            :markerClickHandler="markerClickHandler"
          />
        </div>
      </div>
    </template>
  </section>
</template>
<script>
import EmptyData from '@/components/emptyData'
import MedicalInfoCard from '@/components/medicalInfoCard'
import Button from '@/components/button/index'
import Pagination from '@/components/pagination/pagination'
import CancelAdmission from '@/components/modal/admissionModals/cancelModal'
import Filters from '@/components/filters'
import { mapActions, mapGetters } from 'vuex'
import { parseISO } from 'date-fns'
import { getFullDate, formatWithLocale, getWeekday } from '@/core/utils'
import SmSlideDoctorsSorting from '@/components/slideOutComponents/sorting'
import SmSlideDate from '@/components/slideOutComponents/date/date'
import _ from 'lodash'
import SmModal from '@/components/modal'
import CalendarIcon from '@/assets/icons/calendar'
import SmMapComponent from '@/components/mapComponents/map'
import ClinicSlideOutComponent from '@/components/slideOutComponents/clinics'
import { vueSlideoutPanelService } from 'vue2-slideout-panel'
import Loader from '@/components/loader'
import { alphabet } from '@/core/constants'
import { TRACKER_SLUGS } from '@/models/tracker'
export default {
  name: 'MedicalInfo',
  components: {
    Loader,
    SmMapComponent,
    CancelAdmission,
    MedicalInfoCard,
    EmptyData,
    Button,
    Pagination,
    Filters,
    SmModal,
    CalendarIcon
  },
  async created() {
    if (this.isHistory) {
      this.filters.sort = null
    }
    await this.getData({
      page: (Number(this.$route.query.page) || 1) - 1,
      status: this.isHistory ? 'FINISHED' : 'UPCOMING',
      size: Number(this.$route.query.size) || 5
    })
    if (this.isHistory) {
      this.getVisitsFilters({ status: 'FINISHED' })
    }
  },
  mounted() {
    if (this.$route.params.address) {
      this.modalData = this.$route.params
      this.showModal()
    }
  },
  data() {
    return {
      showMap: false,
      modalData: null,
      filterBlock: false,
      appointmentInfo: null,
      coords: [55.755864, 37.617698],
      filters: {
        sort: 'DATETIME'
      },
      getFullDate,
      sortButtonsProperties: [
        {
          id: 'default',
          label: 'По дате приема',
          title: 'Сортировать',
          value: '',
          items: [
            { id: 'DATETIME', direction: 'Дата' },
            { id: 'FIO', direction: 'Назначивший врач' },
            { id: 'SPECIALITY', direction: 'Специальность' },
            { id: 'CLINIC', direction: 'Клиника' }
          ],
          preload: true,
          isSelected: false
        },
        {
          id: 'clinicId',
          label: 'Клиники',
          title: 'Выберите клинику врача',
          value: '',
          items: [],
          preload: true,
          isSelected: false
        },
        {
          id: 'doctorId',
          label: 'Врачи',
          value: '',
          title: 'Врачи',
          items: [],
          preload: true,
          isSelected: false
        },
        {
          id: 'date',
          value: '',
          label: 'Дата приема',
          title: 'Выберите дату приема врача',
          items: [],
          preload: true,
          isSelected: false
        },
        {
          id: 'specialityId',
          label: 'Специальность',
          value: '',
          title: 'Выберите специальность врача',
          items: [],
          isSelected: false,
          preload: true
        }
        // {
        //   id: 'qualificationId',
        //   label: 'Квалификация',
        //   value: '',
        //   title: 'Выберите квалификацию врача',
        //   items: [],
        //   isSelected: false,
        //   preload: true
        // }
      ]
    }
  },
  watch: {
    '$route.name': function (currentVal) {
      if (this.isHistory) {
        this.filters.sort = null
      } else if (!this.filters.sort) {
        this.filters.sort = 'DATETIME'
      }
      this.clearVisits()
      if (this.isHistory) {
        this.getVisitsFilters({ status: 'FINISHED' })
      }
      this.getData({
        page: Number(this.$route.query.page) - 1 || 0,
        size: Number(this.$route.query.size) || 5,
        status: currentVal === 'HistoryData' ? 'FINISHED' : 'UPCOMING'
      })
    }
  },
  computed: {
    ...mapGetters('visits', [
      'visitsListData',
      'paginationInfo',
      'listVisitLoading',
      'listVisitError',
      'visitsFilterData'
    ]),
    ...mapGetters('appointment', ['newCreatedAppointment']),
    ...mapGetters('doctors', ['doctorsListData']),
    ...mapGetters('qualifications', ['qualificationsData']),
    ...mapGetters('cities', ['citiesData']),
    ...mapGetters('clinics', ['clinicsListData']),
    ...mapGetters('specialities', ['specialitiesListData']),
    upcomingMeetings() {
      return this.visitsListData?.visits?.content
        ? [
            ...this.visitsListData?.visits?.content?.map(
              ({
                id,
                dateTime,
                doctorInfo,
                clinicInfo,
                specialityInfo,
                services = [],
                status,
                ...rest
              }) => ({
                id,
                day: this.getDay(dateTime),
                month: this.getMonth(dateTime),
                time: this.getTime(dateTime),
                weekDay: getWeekday(dateTime),
                year: this.getYear(dateTime),
                totalPrice: (services || []).reduce(
                  (prev, next) => prev + (next.price ?? 0),
                  0
                ),
                visitId: id,
                doctorInfo,
                clinicInfo,
                specialityInfo,
                status,
                ...rest
              })
            )
          ]
        : []
    },
    isHistory() {
      return this.$route.name === 'HistoryData'
    },
    markers() {
      return this.sortButtonsProperties.find((el) => el.id === 'clinicId')
        ?.items
    }
  },
  methods: {
    ...mapActions('visits', ['getVisits', 'clearVisits', 'getVisitsFilters']),
    ...mapActions('qualifications', ['getQualifications']),
    ...mapActions('clinics', ['getClinics']),
    async getData({ page, ...payload } = {}) {
      if (!payload.merge) {
        this.$refs.historyList?.scrollIntoView(true)
      }
      const filters = _(this.filters).omitBy(_.isNull).value()
      await this.getVisits({
        status: this.isHistory ? 'FINISHED' : 'UPCOMING',
        page: page ?? 0,
        size: Number(this.$route.query.size) || 5,
        ...payload,
        ...filters
      })
    },
    handleAppointmentClick(id) {
      this.$tracker(TRACKER_SLUGS.ENTRY_SCHEDULE_APPOINTMENT)
      this.$router.push({
        name: 'Admission',
        query: { id }
      })
    },
    modalHandler(type, data) {
      const { specialityId, doctorInfo, visitId, cityId } = data
      this.appointmentInfo = data
      const doctorId = doctorInfo.id
      if (type === 'cancel') {
        this.$tracker(TRACKER_SLUGS.CANCEL_SCHEDULE_APPOINTMENT)
        this.$modals.show('cancel-modal')
      } else if (type === 'transfer') {
        this.$tracker(TRACKER_SLUGS.RESCHEDULE_APPOINTMENT)
        this.$router.push(
          `/appointment/${specialityId}/${doctorId}?visitId=${visitId}&cityId=${cityId}`
        )
      } else {
        this.$router.push(`/appointment/${specialityId}/${doctorId}`)
      }
    },
    rescheduleHandler() {
      this.closeModal()
      this.modalHandler('transfer', this.appointmentInfo)
    },
    newAppointment() {
      this.closeModal()
      this.$router.push('/appointment')
    },
    showModal() {
      this.$modals.show('appointmentCreated-modal')
    },
    closeModal() {
      this.$modals.hide('appointmentCreated-modal')
    },
    getYear(date) {
      return formatWithLocale(parseISO(date), 'yyyy')
    },
    getDay(date) {
      return formatWithLocale(parseISO(date), 'dd')
    },
    getMonth(date) {
      return formatWithLocale(parseISO(date), 'MMM')
    },
    getTime(date) {
      return formatWithLocale(parseISO(date), 'HH:mm')
    },
    async selectSortButton(properties) {
      if (this.$route.query.page && this.$route.query.page !== '0') {
        await this.$router.push({ query: { page: '0' } })
      }
      if (properties.isSelected) {
        this.sortButtonsProperties = this.sortButtonsProperties.map(
          (buttonProperties) => {
            if (buttonProperties.id === properties.id) {
              buttonProperties.isSelected = !buttonProperties.isSelected
              buttonProperties.value = ''
              return buttonProperties
            } else return buttonProperties
          }
        )
      } else {
        this.filterBlock = true
        let component = SmSlideDoctorsSorting

        if (properties.id === 'date') {
          this.$tracker(TRACKER_SLUGS.FILTER_VISITS_HISTORY_DATE)
          component = SmSlideDate
        }

        if (properties.id === 'clinicId') {
          this.$tracker(TRACKER_SLUGS.FILTER_VISITS_HISTORY_CLINICS)
          component = ClinicSlideOutComponent
          properties.items = this.visitsFilterData?.clinics.map(
            ({ name, id, ...rest }) => ({
              direction: name,
              id,
              ...rest
            })
          )
          if (this.windowWidth >= 1024) this.showMap = true
        }

        if (properties.id === 'qualificationId') {
          await this.getQualifications()
          properties.items = this.qualificationsData.map(({ title, id }) => ({
            direction: title,
            id
          }))
        }

        if (properties.id === 'doctorId') {
          this.$tracker(TRACKER_SLUGS.FILTER_VISITS_HISTORY_DOCTOR_NAME)
          properties.items = this.visitsFilterData?.doctors.map(
            ({ name, id }) => ({
              direction: name,
              id
            })
          )
        }

        if (properties.id === 'specialityId') {
          this.$tracker(TRACKER_SLUGS.FILTER_VISITS_HISTORY_DOCTOR_SPEC)
          properties.items = this.visitsFilterData.specialities
            .map(({ name, id }) => ({
              direction: name,
              id
            }))
            .sort(
              (a, b) =>
                alphabet.indexOf(a.direction[0]) -
                alphabet.indexOf(b.direction[0])
            )
        }

        this.$showPanel({
          component: 'SmSlideoutPanel',
          cssClass: properties.id === 'clinicId' ? 'map-filter' : '',
          props: {
            title: properties.title,
            component,
            properties
          },
          openOn: 'right',
          width: '400',
          height: '80',
          sync: true,
          keepAlive: false
        }).promise.then((result) => {
          this.filterBlock = false
          this.showMap = false
          if (result?.closedBy) return

          if (result) {
            this.sortButtonsProperties = this.sortButtonsProperties.map(
              (buttonProperties) => {
                if (buttonProperties.id === properties.id) {
                  buttonProperties.isSelected = !buttonProperties.isSelected

                  if (properties.id === 'date') {
                    buttonProperties.value = result.label
                    this.filters['from'] = formatWithLocale(
                      result.start,
                      'yyyy-MM-dd'
                    )
                    this.filters['to'] = formatWithLocale(
                      result.end,
                      'yyyy-MM-dd'
                    )
                  } else if (properties.id === 'default') {
                    buttonProperties.value = result.direction
                    this.filters['sort'] = result.id
                  } else {
                    buttonProperties.value = result.direction
                    this.filters[buttonProperties.id] = result.id
                  }

                  this.getData()
                  if (this.isHistory) {
                    const filters = _({
                      clinic: this.filters.clinicId,
                      doctor: this.filters.doctorId,
                      speciality: this.filters.specialityId,
                      to: this.filters.to,
                      from: this.filters.from
                    })
                      .omitBy(_.isNull)
                      .value()
                    this.getVisitsFilters(filters)
                  }
                  return buttonProperties
                } else return buttonProperties
              }
            )
          }
        })
      }
    },
    clearSortButton(properties) {
      if (this.$route.query.page && this.$route.query.page !== '0') {
        this.$router.push({ query: { page: '0' } })
      }
      this.sortButtonsProperties = this.sortButtonsProperties.map(
        (buttonProperties) => {
          if (buttonProperties.id === properties.id) {
            buttonProperties.isSelected = false
            buttonProperties.value = ''

            if (properties.id === 'default') {
              const byDateSort = this.sortButtonsProperties.find(
                ({ id }) => id === properties.id
              )
              byDateSort.isSelected = false
              this.filters['sort'] = null
            } else if (properties.id === 'date') {
              this.filters['from'] = null
              this.filters['to'] = null
            } else {
              this.filters[buttonProperties.id] = null
            }

            this.getData()
            if (this.isHistory) {
              const filters = _({
                clinic: this.filters.clinicId,
                doctor: this.filters.doctorId,
                speciality: this.filters.specialityId,
                to: this.filters.to,
                from: this.filters.from
              })
                .omitBy(_.isNull)
                .value()
              this.getVisitsFilters(filters)
            }
            return buttonProperties
          } else return buttonProperties
        }
      )
    },
    async clearAllFilters() {
      if (this.$route.query.page && this.$route.query.page !== '0') {
        this.$router.push({ query: { page: '0' } })
      }
      this.sortButtonsProperties = this.sortButtonsProperties.map(
        (buttonProperties) => {
          buttonProperties.isSelected = false
          buttonProperties.value = ''

          if (buttonProperties.id === 'default') {
            const byDateSort = this.sortButtonsProperties.find(
              ({ id }) => id === buttonProperties.id
            )
            byDateSort.isSelected = false
            this.filters['sort'] = null
          } else if (buttonProperties.id === 'date') {
            this.filters['from'] = null
            this.filters['to'] = null
          } else {
            this.filters[buttonProperties.id] = null
          }

          return buttonProperties
        }
      )
      if (this.isHistory) {
        const filters = _({
          clinic: this.filters.clinicId,
          doctor: this.filters.doctorId,
          speciality: this.filters.specialityId,
          to: this.filters.to,
          from: this.filters.from
        })
          .omitBy(_.isNull)
          .value()
        this.getVisitsFilters(filters)
      }
      await this.getData()
    },
    markerClickHandler(data) {
      if (data) {
        this.sortButtonsProperties = this.sortButtonsProperties.map(
          (buttonProperties) => {
            if (buttonProperties.id === 'clinicId') {
              buttonProperties.isSelected = !buttonProperties.isSelected

              buttonProperties.value = data.direction
              this.filters[buttonProperties.id] = data.id

              this.getData()

              return buttonProperties
            } else return buttonProperties
          }
        )
      }
      vueSlideoutPanelService.hideAllPanels()
      this.showMap = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import './style';
</style>
