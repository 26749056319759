import state from './specialitiesState'
import mutations from './specialitiesMutations'
import getters from './specialitiesGetters'
import actions from './specialitiesActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
