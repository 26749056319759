import { apiV1 } from '@/config/request'
import { parseISO } from 'date-fns'
import { formatWithLocale } from '@/core/utils'

export default {
  changeAccount({ commit }, patientId) {
    commit('setPatientId', patientId)
  },
  async getFullAccountInfo({ dispatch, commit, rootState }, { id, patientId }) {
    try {
      //Запрос аккаунта
      const { account } = await dispatch('getAccountData', id)

      //Запрос всех пациентов по id
      const patientIds = await Promise.all(
        account.patientIds.map((id) =>
          dispatch('getPatientInfo', {
            id,
            accountId: account.id,
            currentPatient: patientId,
            withContract: true
          })
        )
      )

      if (patientIds.includes(undefined)) {
        return
      }

      const accountComputedData = { ...account, patientIds }
      commit('setAccountInfo', { ...accountComputedData })

      let defaultPatient = accountComputedData.patientIds[0]
      if (
        rootState.auth.phoneSignIn.birthday ||
        rootState.auth.phoneSignIn.name
      ) {
        const patientBirthday = rootState.auth.phoneSignIn.birthday
          ? formatWithLocale(
              parseISO(rootState.auth.phoneSignIn.birthday, 'yyyy-dd-MM'),
              'yyyy-dd-MM'
            )
          : null
        const patientName = rootState.auth.phoneSignIn.name
          ?.toLowerCase()
          ?.trim()

        const patient = accountComputedData.patientIds.find(
          ({ name: defaultName, birthday }) => {
            const name = defaultName?.trim()?.toLowerCase()
            if (patientName && patientBirthday) {
              return name === patientName && birthday === patientBirthday
            } else {
              return name === patientName || birthday === patientBirthday
            }
          }
        )

        if (patient) {
          defaultPatient = patient
        }
      }
      let isChangeCity = new URLSearchParams(window.location.search).get(
        'changeCity'
      )

      if (!isChangeCity) {
        await dispatch(
          'cities/selectCity',
          patientId
            ? accountComputedData.patientIds.find(
                (patient) => patient.id === patientId
              ).cityId || rootState.cities.defaultCity
            : defaultPatient.cityId || rootState.cities.defaultCity,
          { root: true }
        )
      }
      dispatch('promotions/getStories', '', { root: true })
      dispatch('promotions/getPromotionsCounters', '', { root: true })

      // Если в сторе нет записи о текущем пациенте, записываем первого из аккаунта
      if (!patientId) {
        commit('setPatientInfo', defaultPatient)
      } else {
        // Если есть, то ищем нужного пациента из аккаунта и записываем в стор
        commit(
          'setPatientInfo',
          accountComputedData.patientIds.find(
            (patient) => patient.id === patientId
          )
        )
      }
    } catch (e) {
      console.log(e)
      throw e
    }
  },
  setChatState({ commit }, payload) {
    commit('setChatState', payload)
  },
  async getPatientInfo(
    { commit, dispatch, rootState },
    { id, accountId, withContract, currentPatient }
  ) {
    try {
      const currentUrl = id === currentPatient ? 'profile' : 'personal-info'

      const { data } = await new apiV1({ auth: true }).get(
        `patients/${id}/${currentUrl}`,
        {
          params: { accountId }
        }
      )
      const patient = data.patient || data.personalInfo
      patient.birthday = patient.birthday || patient.dateOfBirth

      const { errorCode, description } = data
      const actions = []

      const setContract = async (instance) => {
        const contractId = instance?.contractId
        if (contractId) {
          await dispatch('getContractInfo', {
            contractId,
            accountId: instance.accountId,
            patientId: instance.id
          })
          instance.contractId = rootState.account.contract
        }
      }

      if (withContract) {
        actions.push(setContract)
      }

      await Promise.all(actions.map((callback) => callback(patient))).catch(
        (e) => {
          commit('visits/setLoadingSingleVisits', null, { root: true })
          console.error(e)
        }
      )

      if (errorCode) {
        commit('setError', { errorCode, message: description })
      }
      if (patient) {
        commit('setPatientInfo', patient)
        return patient
      }
    } catch (e) {
      console.log(e)
    }
  },
  async getAccountData(_, id) {
    try {
      const { data } = await new apiV1({ auth: true }).get(`accounts/${id}`)
      return data
    } catch (e) {
      console.log(e)
    }
  },
  async getContractInfo({ commit }, { contractId, accountId, patientId }) {
    try {
      const {
        data: { contract }
      } = await new apiV1({ auth: true }).get(`contracts/${contractId}`, {
        params: {
          accountId,
          patientId
        }
      })
      commit('setContract', contract)
      return contract
    } catch (e) {
      console.log(e)
    }
  },
  // eslint-disable-next-line no-unused-vars
  async getCounters({ commit }, { accountId, patientId }) {
    try {
      const { data } = await new apiV1({ auth: true }).get(
        `/counters/get?accountId=${accountId}&patientId=${patientId}`
      )
      return data?.unread
    } catch (e) {
      console.log(e)
    }
  },
  async changeLogin({ commit }, { accountId, patientId, newLogin }) {
    commit('setError', null)
    commit('setChangeAccountDataLoading')
    try {
      const { data } = await new apiV1({ auth: true }).patch(
        `accounts/${accountId}/login`,
        {},
        { params: { patientId, newLogin } }
      )
      commit('setChangeAccountDataLoading')
      if (data.errorCode) {
        commit('setError', data)
      } else {
        commit('setLogin', newLogin)
      }
      return data
    } catch (e) {
      console.log(e)
      commit('setError', e)
      commit('setChangeAccountDataLoading')
    }
  },
  async changePassword({ commit }, { accountId, patientId, newPassword }) {
    commit('setError', null)
    commit('setChangeAccountDataLoading')
    try {
      const { data } = await new apiV1({ auth: true }).patch(
        `accounts/${accountId}/password`,
        {},
        { params: { patientId, newPassword } }
      )
      commit('setChangeAccountDataLoading')
      if (data.errorCode) {
        commit('setError', data)
      }
      return data
    } catch (e) {
      console.log(e)
      commit('setChangeAccountDataLoading')
    }
  },
  clearAccountData({ commit }) {
    commit('clearAccountState')
  },
  setError({ commit }, payload) {
    commit('setError', payload)
  }
}
