<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.35 8.3189C15.35 12.4583 12.0496 15.7964 8 15.7964C3.95036 15.7964 0.65 12.4583 0.65 8.3189C0.65 4.17947 3.95036 0.841406 8 0.841406C12.0496 0.841406 15.35 4.17947 15.35 8.3189Z"
      stroke="url(#paint0_linear_1583_238519)"
      stroke-width="1.3"
    />
    <path
      d="M12 13.399C12 11.8669 10.66 10.6227 9.008 10.619V10.1272C9.60133 9.80536 10 9.20858 10 8.52656V7.12482C10 6.10178 9.104 5.27148 8 5.27148C6.896 5.27148 6 6.10178 6 7.12482V8.52656C6 9.20858 6.39867 9.80536 6.992 10.1272V10.619C5.34 10.6227 4 11.8669 4 13.399"
      stroke="url(#paint1_linear_1583_238519)"
      stroke-width="1.3"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1583_238519"
        x1="0.909621"
        y1="24.6328"
        x2="22.1571"
        y2="13.1752"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1583_238519"
        x1="4.45481"
        y1="17.4922"
        x2="15.0786"
        y2="11.7634"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'UserIcon'
}
</script>
