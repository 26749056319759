<template>
  <section class="appointment" ref="appointmentList">
    <div v-if="timeslotsLoading || timeslotsError">
      <Loader :loading="timeslotsLoading" :error="!!timeslotsError" />
    </div>
    <template v-else>
      <SmCrumbs
        v-if="specialitiesSingleData"
        :dynamic-name="{
          '/appointment': 'Запись к врачу',
          '/appointment/:specId': specialitiesSingleData.title
        }"
      />
      <h2 class="appointment__title">Новая запись</h2>
      <div class="limit-info" v-if="showLimit">
        <Info class="orange-info-icon" />
        <p>Чтобы записаться к врачу, пожалуйста, оформите договор.</p>
        <span class="green-text" @click="modalHandler">Подробнее</span>
      </div>
      <SearchInput v-if="false" :classes="'search-input'" />
      <Filters
        :options="sortButtonsProperties"
        :selectFilter="selectSortButton"
        :clearFilter="clearSortButton"
        :clear-all="clearAllFilters"
        :filterBlock="filterBlock"
      />
      <div
        v-if="doctorsInfo && doctorsInfo.length"
        class="doctor-cards-container"
      >
        <DoctorsCard
          v-for="record of doctorsInfo"
          :key="record.id + record.name"
          :doctorsData="record"
          :speciality="speciality"
          :handleSlotClick="makeAnAppointment"
          :handleDoctorClick="openSingleDoctor"
        />
      </div>

      <div class="doctor-cards-empty" v-else>По Вашему запросу нет врачей</div>

      <Pagination
        :paginationInfo="this.paginationInfo"
        :getData="this.getList"
        :getMoreData="(payload) => this.getList({ ...payload, merge: true })"
        :autoLoad="false"
      >
      </Pagination>
      <div v-if="showMap" class="clinic-filter">
        <SmMapComponent
          :markers="markers"
          :markerClickHandler="markerClickHandler"
          :currentCity="
            filters && filters.cityIds && filters.cityIds.length
              ? filters.cityIds[0]
              : ''
          "
        />
      </div>
      <ScheduleModal
        v-if="selectedDoctor"
        :patient="getAccountInfo.patientIds[0]"
        :selectedDoctor="selectedDoctor"
      />
      <ScheduleErrorModal v-if="selectedDoctor" />
      <RescheduleModal />
      <LimitModal />
    </template>
  </section>
</template>
<script>
import SearchInput from '@/components/searchInput'
import DoctorsCard from '@/components/doctorsCard'
import Filters from '@/components/filters'
import Info from '@/assets/icons/info'
import SmSlideDoctorsSorting from '@/components/slideOutComponents/sorting'
import SmSlideMakeAnAppointment from '@/components/slideOutComponents/makeAnAppointment/makeAnAppointment'
import SmSlideDate from '@/components/slideOutComponents/date/date'
import Pagination from '@/components/pagination/pagination'
import { mapActions, mapGetters } from 'vuex'
import { parseISO } from 'date-fns'
import _ from 'lodash'
import SmMapComponent from '@/components/mapComponents/map'
import { vueSlideoutPanelService } from 'vue2-slideout-panel'
import ClinicSlideOutComponent from '@/components/slideOutComponents/clinics'
import { formatWithLocale, getWeekday } from '@/core/utils'
import RescheduleModal from '@/components/modal/appointmentModals/rescheduleModal'
import ScheduleErrorModal from '@/components/modal/appointmentModals/scheduleErrorModal'
import ScheduleModal from '@/components/modal/appointmentModals/scheduleModal'
import SmCrumbs from '@/components/breadcrumbs'
import LimitModal from '@/components/modal/admissionModals/limitModal'
import Loader from '@/components/loader'
import { alphabet } from '@/core/constants'
import { TRACKER_SLUGS } from '@/models/tracker'

export default {
  name: 'AppointmentPage',
  components: {
    ScheduleModal,
    ScheduleErrorModal,
    LimitModal,
    SmCrumbs,
    RescheduleModal,
    SmMapComponent,
    Pagination,
    DoctorsCard,
    SearchInput,
    Filters,
    Info,
    Loader
  },
  async created() {
    await this.getList({
      page: (Number(this.$route.query.page) || 1) - 1,
      size: Number(this.$route.query.size) || 5
    })
    this.doctorsLoading = true
    await this.getDoctorsFilter({
      ...(this.$route.params.specId
        ? { specialityId: this.$route.params.specId }
        : {})
    })
    this.doctorsLoading = false
    if (this.slideOutInstance && this.openedPanel === 'doctorIds') {
      this.slideOutInstance.hide()
      this.selectSortButton({
        id: 'doctorIds',
        label: 'Врачи',
        value: '',
        title: 'Врачи',
        items: [],
        preload: true,
        multiple: true,
        isSelected: false
      })
    }
    if (this.slideOutInstance && this.openedPanel === 'clinicIds') {
      this.lockmap = true
      this.slideOutInstance.hide()
      this.selectSortButton({
        id: 'clinicIds',
        label: 'Клиники',
        title: 'Выберите клинику',
        value: '',
        items: [],
        preload: true,
        multiple: true,
        isSelected: false
      })
      this.lockmap = false
    }
    if (this.$route.query.clinicId) {
      const clinicButton = this.sortButtonsProperties.find(
        ({ id }) => id === 'clinicIds'
      )
      const clinic = this.clinicsFilters?.find(
        (clinic) => clinic.id === this.$route.query.clinicId
      )
      if (clinic?.name) {
        clinicButton.value = clinic.name
        clinicButton.isSelected = true
      }
    }
  },
  data() {
    return {
      // TODO: Добавить логику когда будет готово
      showLimit: false,
      filterBlock: false,
      showMap: false,
      slideOutInstance: null,
      showModal: false,
      selectedDoctor: null,
      doctorsLoading: false,
      openedPanel: null,
      lockmap: false,
      filters: {
        clinicIds: this.$route.query.clinicId
          ? [this.$route.query.clinicId]
          : null,
        specialityId: null,
        doctorId: null,
        from: null,
        to: null,
        doctorSex: null,
        cityId: null,
        qualificationId: null,
        sort: 'APPOINTMENT_DATE'
      },
      sortButtonsProperties: [
        {
          id: 'default',
          label: 'Сортировать',
          title: 'Сортировать',
          value: 'По дате приема',
          items: [
            // { id: 'NEAR', direction: 'Рядом' },
            // { id: 'RECOMMENDED', direction: 'Рекомендованные' },
            { id: 'APPOINTMENT_DATE', direction: 'По дате приема' },
            // { id: 'RATING', direction: 'По рейтингу' },
            { id: 'EXPERIENCE', direction: 'По стажу' }
          ],
          preload: true,
          isSelected: true
        },
        {
          id: 'clinicIds',
          label: 'Клиники',
          title: 'Выберите клинику',
          value: '',
          items: [],
          preload: true,
          multiple: true,
          isSelected: false
        },
        {
          id: 'doctorIds',
          label: 'Врачи',
          value: '',
          title: 'Врачи',
          items: [],
          preload: true,
          multiple: true,
          isSelected: false
        },
        {
          id: 'date',
          value: '',
          label: 'Дата приема',
          title: 'Выберите дату приема врача',
          items: [],
          preload: true,
          isSelected: false
        },
        {
          id: 'doctorSex',
          value: '',
          label: 'Пол врача',
          title: 'Выберите пол врача',
          items: [
            { id: 'MALE', direction: 'Мужской' },
            { id: 'FEMALE', direction: 'Женский' }
          ],
          isSelected: false
        },
        {
          id: 'cityIds',
          label: 'Город',
          value: '',
          title: 'Выберите город',
          items: [],
          preload: true,
          multiple: true,
          isSelected: false
        }
        // {
        //   id: 'qualificationId',
        //   label: 'Квалификация',
        //   value: '',
        //   title: 'Выберите квалификацию врача',
        //   items: [],
        //   isSelected: false,
        //   preload: true
        // }
      ]
    }
  },
  async mounted() {
    this.getSpecializationsById(this.$route.params.specId)
  },
  computed: {
    ...mapGetters('qualifications', ['qualificationsData']),
    ...mapGetters('cities', ['citiesData', 'selectedCity']),
    ...mapGetters('account', ['getAccountInfo', 'hasContract']),
    ...mapGetters('appointment', ['errorCode', 'specializationsData']),
    ...mapGetters('timeslots', [
      'timeslotsData',
      'timeslotsLoading',
      'timeslotsError',
      'paginationInfo',
      'doctorsFilters',
      'clinicsFilters'
    ]),
    doctorsInfo: {
      get: function () {
        return this.timeslotsData?.doctors
          ? this.timeslotsData?.doctors.map((instance) => ({
              ...instance,
              priceInformation: {
                price:
                  instance.clinics && instance.clinics.length
                    ? instance.clinics[0].dates[0].price ?? 0
                    : 0
              },
              specializationInfo: this.specialitiesSingleData,
              clinics: (instance.clinics || []).map(
                ({
                  clinicName,
                  address,
                  id,
                  'subway-stations': subway,
                  dates = []
                }) => ({
                  clinicName,
                  address,
                  subway: subway ? subway[0] : null,
                  id,
                  options: [
                    ...dates.map(({ date, isAvailable }) => ({
                      label: formatWithLocale(
                        parseISO(date, 'yyyy-dd-MM'),
                        `dd MMMM, ${getWeekday(date)}`
                      ),
                      value: date,
                      isAvailable
                    }))
                  ].sort(
                    (current, next) =>
                      new Date(current.value) - new Date(next.value)
                  ),
                  slots: dates
                })
              )
            }))
          : []
      },
      set: function (newValue) {
        return newValue
      }
    },
    markers() {
      return this.doctorsLoading
        ? []
        : this.sortButtonsProperties.find((el) => el.id === 'clinicIds').items
    },
    specialitiesSingleData() {
      return this.timeslotsData.specialities[this.$route.params.specId]
    },
    speciality() {
      return this.specializationsData.data
    }
  },
  methods: {
    ...mapActions('appointment', [
      'createAppointment',
      'getSpecializationsById'
    ]),
    ...mapActions('qualifications', ['getQualifications']),
    ...mapActions('cities', ['getCities']),
    ...mapActions('timeslots', [
      'getTimeslots',
      'disableTimeslot',
      'getDoctorsFilter'
    ]),
    modalHandler() {
      this.$modals.show('limit-modal')
    },
    async openSingleDoctor({ id }) {
      await this.$router.push({
        path: `${this.$route.path}/${id}`
      })
    },
    getList({
      page = Number(this.$route.query.page) || 0,
      size = Number(this.$route.query.size) || 5,
      ...payload
    } = {}) {
      if (!payload.merge) {
        window.scrollTo({
          top: 0
        })
      }
      const filters = _(this.filters).omitBy(_.isNull).value()
      this.getTimeslots({
        ...(this.$route.params.specId
          ? { specialityIds: [this.$route.params.specId] }
          : {}),
        page: filters.doctorIds?.length ? 0 : page,
        size,
        groupByDoctor: true,
        ...filters,
        ...payload
      })
    },
    makeAnAppointment(info) {
      if (info.isScheduledAppointment) {
        this.$modals.show('reschedule-modal', {
          info
        })
      } else {
        this.$showPanel({
          component: 'SmSlideoutPanel',
          props: {
            title: 'Запись на прием',
            component: SmSlideMakeAnAppointment,
            properties: {
              ...info,
              makeAnAppointmentHandler: async (properties, comment) => {
                this.selectedDoctor = {
                  ...properties.selectedDoctor,
                  slot: properties.selectedSlot.slot,
                  specialityInfo: this.specialitiesSingleData
                }

                const payload = {
                  id: properties.getPatientInfo.accountId,
                  patientId: properties.getPatientInfo.id,
                  doctorId: properties.selectedDoctor.id,
                  specialityId: this.specialitiesSingleData.id,
                  clinicId: properties.selectedClinic?.id,
                  timeslotId: properties.selectedSlot?.slot?.id,
                  comment
                }

                const doctorId = properties.selectedDoctor.id
                const clinicId = properties.selectedClinic?.id
                const slotId = properties.selectedSlot?.slot?.id
                const dateTime = formatWithLocale(
                  parseISO(properties.selectedSlot?.slot.dateTime),
                  'yyyy-MM-dd'
                )

                try {
                  await this.createAppointment(payload)

                  if (this.errorCode) {
                    vueSlideoutPanelService.hideAllPanels()
                    this.disableTimeslot({
                      dateTime,
                      doctorId: properties.selectedDoctor.id,
                      clinicId: properties.selectedClinic?.id,
                      id: slotId
                    })
                    this.$modals.show('sm-schedule-error')
                    return false
                  }

                  this.disableTimeslot({
                    dateTime,
                    doctorId,
                    clinicId,
                    id: slotId
                  })
                } catch (e) {
                  vueSlideoutPanelService.hideAllPanels()
                  this.disableTimeslot({
                    dateTime,
                    doctorId: properties.selectedDoctor.id,
                    clinicId: properties.selectedClinic?.id,
                    id: slotId
                  })
                  this.$modals.show('sm-schedule-error')
                  return false
                }

                return true
              }
            }
          },
          openOn: 'right',
          width: '440',
          height: '80',
          sync: true,
          keepAlive: false
        }).promise.then(async (result) => {
          if (result?.closedBy) return
          if (result?.type === 'create') {
            await this.$router.push({
              name: 'UpcomingData',
              params: {
                dateTime: result.selectedSlot.slot.dateTime,
                address: result.selectedClinic.address,
                clinicName: result.selectedClinic.clinicName,
                patientFullName: `${result.getPatientInfo.name} ${result.getPatientInfo.surname}`,
                patientName: result.getPatientInfo.name,
                recommendation:
                  result.selectedDoctor.specializationInfo.recommendations
              }
            })
          }
          this.doctorsInfo = this.doctorsInfo.map((doctor) => {
            if (doctor.id === result) {
              doctor.isScheduledAppointment = true
              return doctor
            } else return doctor
          })
        })
      }
    },
    async selectSortButton(properties) {
      if (this.$route.query.page && this.$route.query.page !== '0') {
        await this.$router.push({ query: { page: '0' } })
      }
      if (properties.isSelected && properties.id !== 'date') {
        this.sortButtonsProperties = this.sortButtonsProperties.map(
          (buttonProperties) => {
            if (buttonProperties.id === properties.id) {
              buttonProperties.isSelected = !buttonProperties.isSelected
              buttonProperties.value = ''
              return buttonProperties
            } else return buttonProperties
          }
        )
      } else {
        this.filterBlock = true
        let component = SmSlideDoctorsSorting

        if (properties.id === 'doctorIds') {
          this.$tracker(TRACKER_SLUGS.FILTER_APPOINTMENT_DOCTOR_NAME)
          properties.loading = this.doctorsLoading
          properties.items = this.doctorsFilters
            .map(({ name, id, photo }) => ({
              direction: name,
              id,
              photo
            }))
            .sort(
              (a, b) =>
                alphabet.indexOf(a.direction[0]) -
                alphabet.indexOf(b.direction[0])
            )
        }

        if (properties.id === 'date') {
          this.$tracker(TRACKER_SLUGS.FILTER_APPOINTMENT_DATE)
          component = SmSlideDate
        }

        if (properties.id === 'qualificationId') {
          await this.getQualifications()
          properties.items = this.qualificationsData.map(({ title, id }) => ({
            direction: title,
            id
          }))
        }

        if (properties.id === 'clinicIds') {
          this.$tracker(TRACKER_SLUGS.FILTER_APPOINTMENT_CLINICS)
          component = ClinicSlideOutComponent
          properties.loading = this.doctorsLoading
          properties.items = this.clinicsFilters?.map(
            ({ name, id, ...rest }) => ({
              direction: name,
              name,
              id,
              ...rest
            })
          )
          if (this.windowWidth >= 1024) this.showMap = true
        }

        if (properties.id === 'cityIds') {
          this.$tracker(TRACKER_SLUGS.FILTER_APPOINTMENT_CITY)
          await this.getCities()
          properties.currentLocationId = this.selectedCity
          properties.items = this.citiesData.map(({ id, title }) => ({
            direction: title,
            id
          }))
        }
        this.filterBlock = false
        this.openedPanel = properties.id
        this.slideOutInstance = this.$showPanel({
          component: 'SmSlideoutPanel',
          cssClass: properties.id === 'clinicIds' ? 'map-filter' : '',
          props: {
            title: properties.title,
            component,
            properties: {
              ...properties,
              fixedRange: false,
              maxDays: 0,
              minDate: new Date()
            }
          },
          openOn: 'right',
          width: '400',
          height: '80',
          sync: true,
          keepAlive: false,
          disableBgClick: properties.id !== 'clinicIds',
          hideBg: properties.id === 'clinicIds'
        })

        this.slideOutInstance.promise.then((result) => {
          this.openedPanel = null
          console.log(!result?.props?.properties?.loading)
          if (!result?.props?.properties?.loading) {
            this.showMap = false
          }
          if (result?.closedBy) return
          if (result && !result.props?.properties?.loading) {
            this.sortButtonsProperties = this.sortButtonsProperties.map(
              (buttonProperties) => {
                if (buttonProperties.id === properties.id) {
                  buttonProperties.isSelected = true

                  if (properties.id === 'date') {
                    buttonProperties.value = result.label
                    this.filters['from'] = formatWithLocale(
                      result.start,
                      "yyyy-MM-dd'T'HH:mm:ss"
                    )
                    this.filters['to'] = formatWithLocale(
                      result.end,
                      "yyyy-MM-dd'T'23:59:59"
                    )
                  } else if (properties.id === 'default') {
                    buttonProperties.value = result.direction
                    this.filters['sort'] = result.id
                  } else {
                    buttonProperties.value = result.direction
                    this.filters[buttonProperties.id] =
                      buttonProperties.multiple ? [result.id] : result.id
                  }

                  this.getList()

                  const filters = _({
                    clinicId: this.filters.clinicIds?.[0],
                    cityId: this.filters.cityId,
                    doctorId: this.filters.doctorIds?.[0],
                    from: this.filters.from,
                    to: this.filters.to,
                    doctorSex: this.filters.doctorSex
                  })
                    .omitBy(_.isNull)
                    .value()
                  this.doctorsLoading = true
                  this.getDoctorsFilter({
                    ...(this.$route.params.specId
                      ? { specialityId: this.$route.params.specId }
                      : {}),
                    ...filters
                  })
                  this.doctorsLoading = false
                  if (
                    this.slideOutInstance &&
                    this.openedPanel === 'doctorIds'
                  ) {
                    this.slideOutInstance.hide()
                    this.selectSortButton({
                      id: 'doctorIds',
                      label: 'Врачи',
                      value: '',
                      title: 'Врачи',
                      items: [],
                      preload: true,
                      multiple: true,
                      isSelected: false
                    })
                  }
                  if (
                    this.slideOutInstance &&
                    this.openedPanel === 'clinicIds'
                  ) {
                    this.lockmap = true
                    this.slideOutInstance.hide()
                    this.selectSortButton({
                      id: 'clinicIds',
                      label: 'Клиники',
                      title: 'Выберите клинику',
                      value: '',
                      items: [],
                      preload: true,
                      multiple: true,
                      isSelected: false
                    })
                    this.lockmap = false
                  }
                  return buttonProperties
                } else return buttonProperties
              }
            )
          }
        })
      }
    },
    clearSortButton(properties) {
      if (this.$route.query.page && this.$route.query.page !== '0') {
        this.$router.push({ query: { page: '0' } })
      }
      if (properties.id === 'date') {
        this.selectSortButton(properties)
        return
      }
      this.sortButtonsProperties = this.sortButtonsProperties.map(
        (buttonProperties) => {
          if (buttonProperties.id === properties.id) {
            buttonProperties.isSelected = false
            buttonProperties.value = ''

            if (properties.id === 'default') {
              this.filters['sort'] = 'RECOMMENDED'
            } else if (properties.id === 'date') {
              this.filters['from'] = null
              this.filters['to'] = null
            } else {
              this.filters[buttonProperties.id] = null
            }

            this.getList()
            const filters = _({
              clinicId: this.filters.clinicIds?.[0],
              cityId: this.filters.cityId,
              doctorId: this.filters.doctorIds?.[0],
              from: this.filters.from,
              to: this.filters.to,
              doctorSex: this.filters.doctorSex
            })
              .omitBy(_.isNull)
              .value()
            this.doctorsLoading = true
            this.getDoctorsFilter({
              ...(this.$route.params.specId
                ? { specialityId: this.$route.params.specId }
                : {}),
              ...filters
            })
            this.doctorsLoading = false
            if (this.slideOutInstance && this.openedPanel === 'doctorIds') {
              this.slideOutInstance.hide()
              this.selectSortButton({
                id: 'doctorIds',
                label: 'Врачи',
                value: '',
                title: 'Врачи',
                items: [],
                preload: true,
                multiple: true,
                isSelected: false
              })
            }
            if (this.slideOutInstance && this.openedPanel === 'clinicIds') {
              this.lockmap = true
              this.slideOutInstance.hide()
              this.selectSortButton({
                id: 'clinicIds',
                label: 'Клиники',
                title: 'Выберите клинику',
                value: '',
                items: [],
                preload: true,
                multiple: true,
                isSelected: false
              })
              this.lockmap = false
            }
            return buttonProperties
          } else return buttonProperties
        }
      )
    },
    clearAllFilters(properties) {
      if (this.$route.query.page && this.$route.query.page !== '0') {
        this.$router.push({ query: { page: '0' } })
      }
      this.sortButtonsProperties = this.sortButtonsProperties.map(
        (buttonProperties) => {
          buttonProperties.isSelected = false
          buttonProperties.value = ''

          if (properties.id === 'default') {
            this.filters['sort'] = 'RECOMMENDED'
          } else if (properties.id === 'date') {
            this.filters['from'] = null
            this.filters['to'] = null
          } else {
            this.filters[buttonProperties.id] = null
          }

          return buttonProperties
        }
      )
      this.getList()
      const filters = _({
        clinicId: this.filters.clinicIds?.[0],
        cityId: this.filters.cityId,
        doctorId: this.filters.doctorIds?.[0],
        from: this.filters.from,
        to: this.filters.to,
        doctorSex: this.filters.doctorSex
      })
        .omitBy(_.isNull)
        .value()
      this.doctorsLoading = true
      this.getDoctorsFilter({
        ...(this.$route.params.specId
          ? { specialityId: this.$route.params.specId }
          : {}),
        ...filters
      })
      this.doctorsLoading = false
      if (this.slideOutInstance && this.openedPanel === 'doctorIds') {
        this.slideOutInstance.hide()
        this.selectSortButton({
          id: 'doctorIds',
          label: 'Врачи',
          value: '',
          title: 'Врачи',
          items: [],
          preload: true,
          multiple: true,
          isSelected: false
        })
      }
      if (this.slideOutInstance && this.openedPanel === 'clinicIds') {
        this.lockmap = true
        this.slideOutInstance.hide()
        this.selectSortButton({
          id: 'clinicIds',
          label: 'Клиники',
          title: 'Выберите клинику',
          value: '',
          items: [],
          preload: true,
          multiple: true,
          isSelected: false
        })
        this.lockmap = false
      }
    },
    markerClickHandler(data) {
      if (data) {
        this.sortButtonsProperties = this.sortButtonsProperties.map(
          (buttonProperties) => {
            if (buttonProperties.id === 'clinicIds') {
              buttonProperties.isSelected = !buttonProperties.isSelected

              buttonProperties.value = data.direction
              this.filters[buttonProperties.id] = [data.id]

              this.getList()

              return buttonProperties
            } else return buttonProperties
          }
        )
      }
      vueSlideoutPanelService.hideAllPanels()
      this.showMap = false
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
