<template>
  <section class="admission">
    <div v-if="singleVisitLoading || singleVisitError">
      <Loader :loading="singleVisitLoading" :error="singleVisitError" />
    </div>
    <template v-else-if="getSingleVisitData">
      <SmCrumbs
        :customCrumbs="[
          {
            name: 'Главная страница',
            path: '/'
          },
          {
            name: 'История посещений',
            path: '/history'
          },
          {
            name: 'Информация о приеме',
            path: '/admission'
          }
        ]"
      />
      <div class="admission__headline">
        <h2>Информация о приеме</h2>
        <p @click="handleBackClick" class="go-back">
          <span><ArrowBackIcon /></span>
          К списку приемов
        </p>
      </div>
      <AdmissionInfoCard
        :patientInfo="getPatientInfo"
        :admissionInfo="getSingleVisitData"
        :handleButtonClick="modalHandler"
      />
      <div
        class="admission-sub-info"
        v-if="getSingleVisitData.status === 'UPCOMING'"
      >
        <p class="admission-sub-info__title">Данные о приеме</p>
        <div class="info-banner" v-if="!hasContract">
          <InfoIcon class="icon" />
          <p>
            Вы не можете открыть визит удаленно. Необходимо заключить договор с
            клиникой. Перед визитом пройдите в регистратуру клиники.
            <span class="link" @click="limitModalHandler">Подробнее</span>
          </p>
        </div>
        <div class="visit-info" v-else>
          <div class="visit-info__description">
            <p v-if="!showVisit">
              Вы можете открыть визит и получить данные о кабинете для визита
            </p>
            <div v-if="showVisit">
              <p>
                Врач примет вас в кабинете
                <span>{{ getCabinet }}</span>
              </p>
              <p>
                Начало приёма в
                <span>{{ getTime(getSingleVisitData.dateTime) }}</span>
              </p>
              <p>При задержке приёма, пожалуйста, обратитесь в регистратуру</p>
            </div>
          </div>
          <Button
            v-if="!showVisit"
            :classes="'visit-info__open-btn'"
            :onClick="showVisitHandler"
          >
            Открыть визит
          </Button>
          <div class="info-banner">
            <InfoIcon class="icon" />
            <p v-if="!visitError">
              Если вы опаздываете более чем на 15 мин., просим вас
              <span class="link" @click.stop="modalHandler">
                перезаписаться
              </span>
            </p>
            <p v-else>
              {{ visitError }}
            </p>
          </div>
        </div>
      </div>
      <div class="doctor-info-wrapper">
        <div
          class="doctor-info"
          :class="{
            'doctor-info--border':
              getSingleVisitData.services && getSingleVisitData.services.length
          }"
        >
          <div class="doctors-image">
            <img
              v-if="getSingleVisitData.doctor.photo"
              alt="doctor"
              :src="`${getBaseUrl}/${getSingleVisitData.doctor.photo}`"
            />
            <AvatarIcon v-else class="avatar" />
          </div>
          <div class="personal-info">
            <p class="doctors-specialization" v-if="specialitiesSingleData">
              {{ specialitiesSingleData.title }}
            </p>
            <h2>
              {{
                getSingleVisitData.doctor
                  ? getDoctorFullName(getSingleVisitData.doctor)
                  : ''
              }}
            </h2>
            <span
              class="personal-info__years"
              v-if="
                getSingleVisitData.doctor &&
                getSingleVisitData.doctor.totalExperience
              "
              >{{
                `Стаж ${
                  getSingleVisitData.doctor
                    ? getSingleVisitData.doctor.totalExperience
                    : ''
                } года`
              }}</span
            >
            <span class="experience-description">
              {{ getDoctorExp(getSingleVisitData.doctor.totalExperience) }}
            </span>
          </div>
        </div>
        <div
          class="doctor-extra"
          v-if="
            getSingleVisitData.services && getSingleVisitData.services.length
          "
        >
          <p class="doctor-extra-header">
            <template v-if="getSingleVisitData.status === 'FINISHED'"
              >Выполненные услуги</template
            ><template v-else>Назначенные обследования</template>
          </p>
          <div
            v-for="service in getSingleVisitData.services"
            v-bind:key="service.id"
          >
            <p class="doctor-extra-text">- {{ service.title }}</p>
          </div>
        </div>
        <div class="doctor-extra" v-if="getSingleVisitData.recommendations">
          <p class="doctor-extra-header">Рекомендации врача</p>
          <div>
            <p class="doctor-extra-text doctor-extra-text--list">
              {{ getSingleVisitData.recommendations }}
            </p>
          </div>
        </div>
      </div>
      <SmSlider
        title="Обратите внимание"
        :space-between="20"
        :slides-per-view="'auto'"
        :pagination="true"
        v-if="getPromotionsData"
        :breakpoints="{
          1025: {
            spaceBetween: 16
          },
          320: {
            spaceBetween: 20
          }
        }"
      >
        <swiper-slide
          v-for="promotion of getPromotionsData"
          :key="promotion.id"
          class="slide"
        >
          <DiscountCard :discountInfo="promotion" :key="promotion.id" />
        </swiper-slide>
      </SmSlider>
      <CancelAdmission
        :afterCloseHandler="singleVisitRequest"
        :rescheduleHandler="() => this.modalHandler('transfer')"
        :id="getSingleVisitData.id"
      />
      <LimitModal />
      <p @click="handleBackClick" class="go-back-mobile">К списку приемов</p>
    </template>
  </section>
</template>
<script>
import AdmissionInfoCard from '@/components/admissionInfoCard/admissioInfoCard'
import SmSlider from '@/components/slider'
import CancelAdmission from '@/components/modal/admissionModals/cancelModal'
import { mapActions, mapGetters } from 'vuex'
import Button from '@/components/button'
import { formatWithLocale, getDoctorExp } from '@/core/utils'
import { parseISO } from 'date-fns'
import InfoIcon from '@/assets/icons/info'
import SmCrumbs from '@/components/breadcrumbs'
import ArrowBackIcon from '@/assets/icons/arrow-back'
import { SwiperSlide } from 'swiper-vue2'
import DiscountCard from '@/components/discountCard/discountCard'
import LimitModal from '@/components/modal/admissionModals/limitModal'
import Loader from '@/components/loader'
import AvatarIcon from '@/assets/icons/avatar'
import { TRACKER_SLUGS } from '@/models/tracker'

export default {
  name: 'SingleAdmission',
  components: {
    LimitModal,
    DiscountCard,
    ArrowBackIcon,
    SmCrumbs,
    InfoIcon,
    Button,
    AvatarIcon,
    CancelAdmission,
    AdmissionInfoCard,
    SmSlider,
    SwiperSlide,
    Loader
  },
  data() {
    return {
      showVisit: false,
      visitError: '',
      visitCabinet: null,
      getDoctorExp
    }
  },
  async mounted() {
    await this.singleVisitRequest()
    await this.getSpecialityById(this.getSingleVisitData.specialityId)
    await this.getPromotions({
      page: 0,
      size: 5
    })
  },
  methods: {
    ...mapActions('visits', ['getSingleVisit', 'openVisit']),
    ...mapActions('promotions', ['getPromotions']),
    ...mapActions('specialities', ['getSpecialityById']),
    limitModalHandler() {
      this.$modals.show('limit-modal')
    },
    modalHandler(type) {
      const { specialityId, id, doctorId, patient } = this.getSingleVisitData
      if (type === 'cancel') {
        this.$tracker(TRACKER_SLUGS.CANCEL_VISIT)
        this.$modals.show('cancel-modal')
      } else if (type === 'transfer') {
        this.$tracker(TRACKER_SLUGS.RESCHEDULE_VISIT)
        this.$router.push(
          `/appointment/${specialityId}/${doctorId}?visitId=${id}&cityId=${patient.cityId}`
        )
      } else {
        this.$tracker(TRACKER_SLUGS.PROCEED_VISIT)
        this.$router.push(`/appointment/${specialityId}/${doctorId}`)
      }
    },
    getDoctorFullName(doctor) {
      return `${doctor.surname} ${doctor.name} ${doctor.patronymic}`
    },
    showVisitHandler() {
      this.$tracker(TRACKER_SLUGS.PROCEED_VISIT)
      this.openVisit({ id: this.$route.query.id })
        .then((res) => {
          if (res.data?.errorCode) {
            this.visitError = res.data.description
          } else {
            this.visitCabinet = res.data?.visit.cabinet || res.data?.cabinet
            this.showVisit = true
          }
        })
        .catch(() => {
          this.visitError = 'Неизвестная ошибка. Попробуйте позже'
        })
    },
    singleVisitRequest() {
      const id = this.$route.query.id
      return this.getSingleVisit({ id })
    },
    getTime(dateTime) {
      return formatWithLocale(parseISO(dateTime), 'p')
    },
    handleBackClick() {
      this.$router.go(-1)
    }
  },
  computed: {
    ...mapGetters('promotions', ['getPromotionsData']),
    ...mapGetters('account', ['getPatientInfo', 'hasContract']),
    ...mapGetters('doctors', ['doctorsSingleData']),
    ...mapGetters('clinics', ['clinicsSingleData']),
    ...mapGetters('specialities', ['specialitiesSingleData']),
    ...mapGetters('specialities', ['specialitiesSingleData']),
    ...mapGetters('visits', [
      'visitsSingleData',
      'singleVisitLoading',
      'singleVisitError'
    ]),
    ...mapGetters('appointment', ['singleAppointment']),
    getSingleVisitData() {
      return this.visitsSingleData
    },
    getBaseUrl() {
      return process.env.VUE_APP_API_BASE_URL
    },
    getCabinet() {
      return this.visitCabinet
        ? this.visitCabinet
        : this.getSingleVisitData.cabinet
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
