<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <defs>
      <linearGradient
        id="a"
        x1="18"
        x2="18"
        y2="36"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#FB6361" />
        <stop offset="1" stop-color="#ED3E45" />
      </linearGradient>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <path
        fill="url(#a)"
        d="M10.101 0C2.705 0 0 2.705 0 10.099v15.802C0 33.295 2.705 36 10.101 36h15.793C33.29 36 36 33.295 36 25.901V10.099C36 2.705 33.295 0 25.899 0z"
      />
      <path
        fill="#fff"
        d="M15.703 20.879h1.445l-.725-1.686zm-.356.85-.429.981h-.976L16.018 18h.844l2.067 4.71h-1.002l-.423-.981zm15.71.977H32V18h-.944zM27.3 20.685h1.739v-.859H27.3v-.964h2.526v-.859h-3.469v4.707h3.56v-.859H27.3zm-3.75.556L22.483 18h-.78l-1.07 3.24-1.041-3.238h-1.018l1.643 4.71h.79l1.072-3.093 1.072 3.094h.798l1.639-4.71h-.992zm-11.05-.543c0 .766-.38 1.175-1.07 1.175-.695 0-1.078-.421-1.078-1.208v-2.662h-.956v2.695c0 1.325.736 2.085 2.02 2.085 1.297 0 2.04-.774 2.04-2.125V18h-.955zM7.116 18h.956v4.713h-.956v-1.914H4.956v1.914H4V18h.956v1.901h2.16zM18 12c-3.309 0-6-2.692-6-6h.848c0 2.84 2.311 5.152 5.152 5.152S23.153 8.841 23.153 6H24c0 3.308-2.692 6-6 6"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'AppGallery'
}
</script>

<style scoped></style>
