<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 12.6004C0 6.65257 -2.32986e-06 3.68334 1.84167 1.84167C3.68334 -2.32986e-06 6.66197 0 12.6004 0H27.3996C33.3474 0 36.3167 -2.32986e-06 38.1583 1.84167C40 3.68334 40 6.66197 40 12.6004V27.3996C40 33.3474 40 36.3167 38.1583 38.1583C36.3167 40 33.338 40 27.3996 40H12.6004C6.65257 40 3.68334 40 1.84167 38.1583C-2.32986e-06 36.3167 0 33.338 0 27.3996V12.6004Z"
      fill="url(#paint0_linear_1557_237133)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.217 8.95483C28.217 9.23672 27.7848 9.24611 26.9015 9.20853C26.2344 9.18974 25.821 8.99241 25.9243 8.65415C26.0277 8.31588 26.6102 8.27829 27.2398 8.42863C27.5499 8.49441 28.217 8.67294 28.217 8.95483ZM20.3335 32.4737C11.8675 32.4737 4.47258 26.9205 4.63232 21.0009C4.4256 16.6692 6.84045 12.0932 12.2903 9.65955C14.3293 8.74811 17.7965 7.98701 21.1604 7.94942C21.9027 7.93063 22.3819 7.83667 23.0396 7.70512C23.4625 7.62056 23.9511 7.52659 24.6182 7.42323C25.727 7.2541 27.2492 7.09436 28.5741 7.62995C30.1245 8.40984 31.6373 9.56558 31.6373 10.1388C31.6373 10.7119 30.6225 11.7079 29.9647 11.7925C28.8278 11.9335 27.3713 11.7079 25.586 11.4261C24.2048 11.2099 22.6168 10.9562 20.8127 10.8247C13.1923 10.7119 7.59215 15.8611 7.77068 20.8411C7.93981 27.2024 15.9079 29.9649 20.8127 29.9649C34.4937 30.0401 34.0521 17.8531 30.848 17.2705C29.7956 17.0826 28.9687 18.8961 28.0479 20.8975C26.9861 23.2184 25.8116 25.7836 24.0451 25.7648C22.3913 25.746 21.0852 22.9929 20.0047 20.7002C19.2436 19.0934 18.5952 17.7122 18.0032 17.6464C16.9509 17.5336 16.1428 20.0237 15.438 22.2036C14.9588 23.6694 14.536 25.0037 14.1038 25.3044C13.1172 26.291 10.5332 24.1486 10.8996 23.3311C11.2567 22.5231 11.5856 22.8331 11.952 23.162C12.2527 23.4439 12.5816 23.7352 12.9574 23.4157C13.0984 23.2936 13.2957 22.7016 13.5776 21.8841C14.3669 19.5632 15.7669 15.4289 18.1442 15.2409C19.8543 15.1094 21.2074 18.182 22.2879 20.6344C22.9927 22.2224 23.5752 23.5473 24.0638 23.5473C24.6182 23.5379 25.182 22.307 25.8585 20.8129C26.9861 18.3135 28.4613 15.0624 30.8668 15.4571C36.9744 16.4719 37.463 32.4831 20.3335 32.4737Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1557_237133"
        x1="15.0694"
        y1="-64.1304"
        x2="131.389"
        y2="-5.484"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#008D62" />
        <stop offset="1" stop-color="#00CA8B" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ModalLogoIcon'
}
</script>
