<template>
  <SmModal
    size="md"
    name="limit-modal"
    title="Достигнут лимит записей"
    class="limit-modal"
  >
    <template v-slot:body>
      <div class="line"></div>
      <div class="sm-modal__body">
        <div class="sm-modal__body__icon"><TestIcon /></div>
        <div class="sm-modal__body__content">
          <div class="limit-modal__block">
            <p class="limit-modal__info">
              Вы уже записаны на два посещения клиники. Для последующих записей
              через личный кабинет, заключите договор в регистратуре клиники. Вы
              можете записаться к другим специалистам, написав нам в чат.
            </p>
            <p class="limit-modal__details">
              Вы можете оставить заявку на запись по телефону<span
                class="green-text"
              >
                +7 (495) 154-10-82
              </span>
            </p>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="limit-modal__button-wrapper">
        <Button :onClick="closeModal" class="close-button" icon="close">
          Закрыть окно
        </Button>
      </div>
    </template>
  </SmModal>
</template>
<script>
import SmModal from '@/components/modal'
import TestIcon from '@/assets/icons/tests'
import Button from '@/components/button'
export default {
  name: 'LimitModal',
  components: { Button, TestIcon, SmModal },
  methods: {
    closeModal() {
      this.$modals.hide('limit-modal')
    }
  }
}
</script>
