<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="onClose"
  >
    <path
      d="M7 25L25 7M7 7L25 25"
      stroke="#228462"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2539_57543"
        x1="8.02332"
        y1="34.0652"
        x2="31.755"
        y2="21.0641"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'CloseIcon',
  props: {
    onClose: {
      type: Function,
      default: () => {}
    }
  }
}
</script>
