var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"authorization",class:( _obj = {}, _obj['hide'] = this.windowWidth < 1440 && this.$route.name !== 'Auth', _obj )},[_c('aside',{staticClass:"left-side"},[(this.windowWidth >= 1440)?_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var navigate = ref.navigate;
return [_c('img',{staticClass:"logo",attrs:{"role":"link","alt":"main-logo","src":require("@/assets/logo.svg")},on:{"click":navigate}})]}}],null,false,4004304098)}):_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var navigate = ref.navigate;
return [_c('img',{staticClass:"logo-mobile",attrs:{"role":"link","alt":"main-logo","src":require("@/assets/logo-mobile.svg")},on:{"click":navigate}})]}}])}),_c('div',{staticClass:"text"},[_vm._v(" С помощью обновленного личного кабинета записаться стало гораздо проще! ")]),_c('img',{staticClass:"sub-logo",attrs:{"alt":"sub-logo","src":require("@/assets/subLogo.png")}})],1),_c('main',{staticClass:"content",class:( _obj$1 = {
      registration: _vm.isRegistration,
      initial: _vm.isInitialPage
    }, _obj$1['by-phone'] = _vm.isPhoneAuth, _obj$1.base = _vm.isLoginAuth, _obj$1 )},[_c('div',{staticClass:"container"},[(
          this.$route.name !== 'Auth' && this.$route.name !== 'Registration'
        )?_c('div',{staticClass:"go-back"},[_c('div',{staticClass:"go-back__inner"},[_c('a',{staticClass:"link",on:{"click":_vm.goBack}},[_c('IconBack'),_vm._v("Вернуться назад ")],1),_c('router-link',{staticClass:"link",attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var navigate = ref.navigate;
return [(_vm.errorAuth.errorCode)?_c('span',{attrs:{"role":"link"},on:{"click":navigate}},[_c('SmTooltip',{attrs:{"trigger":_vm.windowWidth >= 1024 ? 'mouseenter' : '',"name":"help","interactive":true},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('HelpIcon'),_vm._v(" Помощь ")]},proxy:true},{key:"tooltip",fn:function(){return [_c('ErrorAuthTooltip')]},proxy:true}],null,true)})],1):_vm._e()]}}],null,false,483402912)})],1)]):_vm._e(),_c('router-view')],1)]),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }