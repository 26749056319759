var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{ref:"appointmentList",staticClass:"appointment"},[(_vm.timeslotsLoading || _vm.timeslotsError)?_c('div',[_c('Loader',{attrs:{"loading":_vm.timeslotsLoading,"error":!!_vm.timeslotsError}})],1):[(_vm.specialitiesSingleData)?_c('SmCrumbs',{attrs:{"dynamic-name":{
        '/appointment': 'Запись к врачу',
        '/appointment/:specId': _vm.specialitiesSingleData.title
      }}}):_vm._e(),_c('h2',{staticClass:"appointment__title"},[_vm._v("Новая запись")]),(_vm.showLimit)?_c('div',{staticClass:"limit-info"},[_c('Info',{staticClass:"orange-info-icon"}),_c('p',[_vm._v("Чтобы записаться к врачу, пожалуйста, оформите договор.")]),_c('span',{staticClass:"green-text",on:{"click":_vm.modalHandler}},[_vm._v("Подробнее")])],1):_vm._e(),(false)?_c('SearchInput',{attrs:{"classes":'search-input'}}):_vm._e(),_c('Filters',{attrs:{"options":_vm.sortButtonsProperties,"selectFilter":_vm.selectSortButton,"clearFilter":_vm.clearSortButton,"clear-all":_vm.clearAllFilters,"filterBlock":_vm.filterBlock}}),(_vm.doctorsInfo && _vm.doctorsInfo.length)?_c('div',{staticClass:"doctor-cards-container"},_vm._l((_vm.doctorsInfo),function(record){return _c('DoctorsCard',{key:record.id + record.name,attrs:{"doctorsData":record,"speciality":_vm.speciality,"handleSlotClick":_vm.makeAnAppointment,"handleDoctorClick":_vm.openSingleDoctor}})}),1):_c('div',{staticClass:"doctor-cards-empty"},[_vm._v("По Вашему запросу нет врачей")]),_c('Pagination',{attrs:{"paginationInfo":this.paginationInfo,"getData":this.getList,"getMoreData":function (payload) { return this$1.getList(Object.assign({}, payload, {merge: true})); },"autoLoad":false}}),(_vm.showMap)?_c('div',{staticClass:"clinic-filter"},[_c('SmMapComponent',{attrs:{"markers":_vm.markers,"markerClickHandler":_vm.markerClickHandler,"currentCity":_vm.filters && _vm.filters.cityIds && _vm.filters.cityIds.length
            ? _vm.filters.cityIds[0]
            : ''}})],1):_vm._e(),(_vm.selectedDoctor)?_c('ScheduleModal',{attrs:{"patient":_vm.getAccountInfo.patientIds[0],"selectedDoctor":_vm.selectedDoctor}}):_vm._e(),(_vm.selectedDoctor)?_c('ScheduleErrorModal'):_vm._e(),_c('RescheduleModal'),_c('LimitModal')]],2)}
var staticRenderFns = []

export { render, staticRenderFns }