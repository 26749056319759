<template>
  <div class="document-content">
    <p>
      Настоящим, действуя свободно, своей волей и в своем интересе, а также
      подтверждая свою дееспособность, я (далее - Пользователь) даю свое
      согласие на обработку моих персональных данных и (или) персональных данных
      лица, законным представителем которого являюсь, (в т.ч. фамилия, имя,
      отчество, дата рождения, номер телефона, адрес электронной почты, сведения
      о состоянии здоровья и об обращениях к специалистам клиник, сведения об
      оплатах и финансовых обязательствах, номера медицинских карт), а именно -
      на совершение следующих действий: сбора, записи, систематизации,
      накопления, хранения, уточнения (обновления, изменения), извлечения,
      использования, предоставления доступа, обезличивания, блокирования,
      удаления и уничтожения, как с использованием средств автоматизации, так и
      без использования таких средств, в целях оказания медицинских услуг и
      информационных услуг, в том числе установления с Пользователем обратной
      связи, включая направление уведомлений, запросов, касающихся использования
      личного кабинета, оказания услуг, обработку запросов и заявок от
      Пользователя, ООО «Группа компаний СМ-Клиника», адрес местонахождения:
      125130, г. Москва, Старопетровский проезд, д.7А, стр.30, эт.2, пом.III,
      ком.8, а также клиникам:
    </p>
    <p>
      - ООО «СМ-Клиника», адрес местонахождения: 125130, г. Москва, ул. Клары
      Цеткин, д. 33, корпус 28, пом.VII, комн. 7;
    </p>
    <p>
      - ООО «СМ-Доктор», адрес местонахождения: 125130, г.Москва,
      Старопетровский проезд, д.7А, стр.19, этаж 1, пом. II, комн. 20;
    </p>
    <p>
      - ООО «СМ-МЕД», адрес местонахождения: 121351 г. Москва, ул. Ярцевская,
      д.8;
    </p>
    <p>
      - ООО «Клиника ТРИ ПОКОЛЕНИЯ», адрес местонахождения: 129164, г. Москва,
      ул. Ярославская, д.4, корп.2; - ООО «Дэрайс», адрес местонахождения:
      127299, г. Москва, ул.Космонавта Волкова, 9/2;
    </p>
    <p>
      - ООО «ДЕТЗДРАВ», адрес местонахождения: 129164, г.Москва, пер. Расковой,
      д.14-22; - ООО «СМ-Медика», адрес местонахождения: 103055, г.Москва,
      ул.Лесная, д.57, стр.1;
    </p>
    <p>
      - ООО «СМ-Регионмед», адрес местонахождения: 125130, г. Москва,
      Старопетровский проезд, д. 7А, строение 30, этаж 3, комн. 10;
    </p>
    <p>
      - ООО «Доктор 03», адрес местонахождения: 125130, г. Москва, ул. Клары
      Цеткин, д.33, корп.24;
    </p>
    <p>
      - ООО «ЛДЦ», адрес местонахождения: 125130, г. Москва, Старопетровский
      проезд, 7А, стр.19, э. 3, пом. IV, ком. 11;
    </p>
    <p>
      - ООО «СМ-Косметология», адрес местонахождения: 127299, г. Москва, ул.
      Космонавта Волкова, д.9/2;
    </p>
    <p>
      - ООО «Меди Проф», адрес местонахождения: 192288, г. Санкт-Петербург,
      Дунайский пр., д.47, литер А, помещение № 35;
    </p>
    <p>
      - ООО «Меди ком», адрес местонахождения: 195279, г. Санкт-Петербург, пр.
      Ударников, д. 19, корп. 1;
    </p>
    <p>
      - ООО «Меди Лен», адрес местонахождения: 198332, г. Санкт-Петербург, ул.
      Маршала Захарова, д.20, литер Б, часть пом. 2Н, пом. № 39.
    </p>
    <p>
      Я также даю свое согласие на передачу в целях осуществления действий,
      предусмотренных настоящим согласием, моих персональных данных и
      персональных данных лиц, представителем которых я являюсь, третьим лицам в
      объеме, необходимом для реализации вышеуказанных целей и для обеспечения
      информационной поддержки клиник.
    </p>
    <p>
      Настоящее согласие действует с момента его предоставления и в течение
      всего периода использования мной личного кабинета.
    </p>
    <p>
      Я оставляю за собой право отозвать свое согласие посредством составления
      соответствующего письменного документа, который может быть направлен мной
      в адрес ООО «Группа компаний СМ-Клиника». В случае отзыва мною согласия на
      обработку персональных данных оператор вправе продолжить обработку
      персональных данных без моего согласия при наличии оснований, указанных в
      пунктах 2 – 11 части 1 статьи 6, части 2 статьи 10 и части 2 статьи 11
      Федерального закона №152-ФЗ «О персональных данных» от 26.06.2006 г.
    </p>
  </div>
</template>
<script>
export default {
  name: 'PersonalData'
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
