<template>
  <div>
    <div class="sm-filters" :class="filterBlock ? 'disabled' : ''">
      <div
        v-for="properties of currentOptions"
        :key="properties.label"
        :class="[properties.isSelected ? 'btn-primary' : 'btn-secondary']"
        @click="
          () =>
            properties.isSelected
              ? clearFilter(properties)
              : selectFilter(properties)
        "
      >
        <span>
          {{ properties.value ? properties.value : properties.label }}
        </span>
        <CloseRoundIcon
          v-if="properties.isSelected && clearable"
          class="icon-close"
          :onClose="() => clearFilter(properties)"
        />
        <SortIcon
          v-if="
            (properties.items.length || properties.preload) &&
            !properties.isSelected
          "
          class="icon-sort"
        />
      </div>
      <span
        v-if="clearAll && shouFilterButton.length"
        class="clear-all"
        @click="clearAll"
      >
        Очистить фильтры
      </span>
    </div>
    <span
      v-if="clearAll && shouFilterButton.length"
      class="clear-all clear-all--mobile"
      @click="clearAll"
    >
      <CrossIcon /> Очистить фильтры
    </span>
  </div>
</template>
<script>
import SortIcon from '@/assets/icons/sort'
import CloseRoundIcon from '@/assets/icons/close-round'
import CrossIcon from '@/assets/icons/cross'
export default {
  name: 'Filters',
  components: { CrossIcon, CloseRoundIcon, SortIcon },
  props: {
    clearAll: Function,
    filterBlock: Boolean,
    selectFilter: {
      type: Function,
      default: () => {}
    },
    clearFilter: {
      type: Function,
      default: () => {}
    },
    options: Array,
    refLink: Object,
    clearable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    currentOptions() {
      return this.options.filter((filter) => !filter.isHide)
    },
    shouFilterButton() {
      return this.options.filter((filter) => filter.value)
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
