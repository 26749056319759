<template>
  <section class="promotions">
    <div v-if="getLoading || getPromotionError">
      <Loader :loading="getLoading" :error="getPromotionError" />
    </div>
    <template v-else>
      <SmCrumbs />
      <h2 class="promotions-title">
        Акции
        <SmTooltip
          :trigger="windowWidth >= 1024 ? 'mouseenter' : ''"
          placement="top"
          :max-width="220"
          name="promotion-tooltip"
        >
          <template v-slot:trigger>
            <InfoIcon class="info-icon" />
          </template>
          <template v-slot:tooltip>
            <div class="promotions-tooltip">
              <h3 class="promotions-tooltip__title">Акции в «СМ-Клиника»</h3>
              <p class="promotions-tooltip__text">
                Вы просматриваете все акции в
                {{ cityName }}
              </p>
            </div>
          </template>
        </SmTooltip>
      </h2>
      <Filters
        :options="filterOptions"
        :selectFilter="selectFilter"
        :clearFilter="filterClear"
        :filterBlock="filterBlock"
      />
      <div class="promotions-wrapper" ref="promotionsList">
        <DiscountCard
          v-for="(promotion, index) of promotions"
          :discountInfo="promotion"
          :key="index"
          @clickLink="sendCounters"
        />
      </div>
      <Pagination
        :paginationInfo="this.paginationInfo"
        :getData="this.getFilteredData"
        :getMoreData="
          (payload) => this.getFilteredData({ merge: true, ...payload })
        "
        :autoLoad="false"
      >
      </Pagination>
    </template>
  </section>
</template>
<script>
import DiscountCard from '@/components/discountCard/discountCard'
import SmSlideSorting from '@/components/slideOutComponents/sorting'
import Filters from '@/components/filters'
import InfoIcon from '@/assets/icons/info'
import Pagination from '@/components/pagination/pagination'
import { mapActions, mapGetters } from 'vuex'
import SmTooltip from '@/components/tooltip'
import SmCrumbs from '@/components/breadcrumbs'
import _ from 'lodash'
import Loader from '@/components/loader'

export default {
  name: 'PromotionsPage',
  components: {
    Loader,
    SmCrumbs,
    SmTooltip,
    Pagination,
    InfoIcon,
    DiscountCard,
    Filters
  },
  data() {
    return {
      filterBlock: false,
      initialOptions: [
        {
          id: 1,
          label: 'Все акции',
          title: 'Выберите направление',
          value: '',
          items: [],
          isSelected: false
        }
      ],
      filters: {
        directionIds: []
      }
    }
  },
  async created() {
    await this.getPromotions({
      page: (Number(this.$route.query.page) || 1) - 1,
      size: Number(this.$route.query.size) || 12
    })
    await this.getFilters()
    const childFilter = this.getPromotionFilters.find(
      (filter) => filter.id === '1aa1d145-604e-4a0d-88f2-99efd65d3b44'
    )
    if (childFilter && this.getAge < 18) {
      this.filterOptions = this.initialOptions.map((option) => ({
        ...option,
        isSelected: true,
        value: childFilter.direction,
        items: [childFilter]
      }))
      this.filters.directionIds[0] = childFilter.id
      this.getFilteredData()
    }
  },
  methods: {
    ...mapActions('promotions', [
      'getPromotions',
      'getFilters',
      'getFilteredPromotions',
      'updatePromotionsCounters',
      'getPromotionsCounters'
    ]),
    getFilteredData({ page, ...payload } = {}) {
      if (!payload.merge) {
        this.$refs.promotionsList?.scrollIntoView(true)
      }
      const filters = _(this.filters).omitBy(_.isNull).value()
      this.getFilteredPromotions({
        page: page ?? 0,
        size: Number(this.$route.query.size) || 12,
        ...payload,
        ...filters
      })
    },
    selectFilter(properties) {
      if (this.$route.query.page && this.$route.query.page !== '0') {
        this.$router.push({ query: { page: '0' } })
      }
      this.$showPanel({
        component: 'SmSlideoutPanel',
        props: {
          title: 'Выберите направление',
          component: SmSlideSorting,
          properties
        },
        openOn: 'right',
        width: '400',
        height: '80',
        sync: true,
        keepAlive: false
      }).promise.then((result) => {
        if (result?.closedBy) return
        this.filterOptions = this.initialOptions.map((option) => ({
          ...option,
          isSelected: true,
          value: result.direction,
          items: [result]
        }))
        this.filters.directionIds[0] = result.id
        this.getFilteredData()
      })
    },
    filterClear(properties) {
      if (this.$route.query.page && this.$route.query.page !== '0') {
        this.$router.push({ query: { page: '0' } })
      }
      if (properties.isSelected) {
        this.filterOptions = this.initialOptions.map((option) => ({
          ...option,
          isSelected: false,
          value: ''
        }))
      }
      this.getPromotions({
        page: Number(this.$route.query.page) - 1 || 0,
        size: Number(this.$route.query.size) || 12
      })
    },
    async sendCounters(id) {
      console.log('sendCounters', id)
      const status = await this.updatePromotionsCounters({
        patientId: this.getPatientInfo.id,
        id
      })

      console.log('statusstatusstatus', status)

      if (status === 'success') {
        this.getPromotionsCounters({
          patientId: this.getPatientInfo.id,
          accountId: this.getAccountInfo.id
        })
      }
    }
  },
  computed: {
    ...mapGetters('promotions', [
      'getPromotionsData',
      'getPromotionFilters',
      'paginationInfo',
      'getLoading',
      'getPromotionError',
      'getUpdateCountersStatus'
    ]),
    ...mapGetters('cities', ['selectedCityData']),
    ...mapGetters('account', ['getAge', 'getPatientInfo', 'getAccountInfo']),
    promotions() {
      return [...this.getPromotionsData].sort(
        (a, b) => (a.priority ?? Infinity) - (b.priority ?? Infinity)
      )
    },
    filterOptions: {
      get() {
        return this.initialOptions.map((el) => ({
          ...el,
          items: this.getPromotionFilters
        }))
      },
      set(val) {
        this.initialOptions = val
      }
    },
    cityName() {
      return this.selectedCityData?.title
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style.scss';
</style>
