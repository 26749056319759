<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <mask :id="idForMask" fill="white">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM8 7.11854C8.43222 7.11854 8.78261 7.46893 8.78261 7.90115V11.2174C8.78261 11.6496 8.43222 12 8 12C7.56778 12 7.21739 11.6496 7.21739 11.2174V7.90115C7.21739 7.46893 7.56778 7.11854 8 7.11854ZM7 4.94377C7 4.38298 7.43478 4 8 4C8.56522 4 9 4.38298 9 4.94377C9 5.49088 8.56522 5.88754 8 5.88754C7.43478 5.88754 7 5.49088 7 4.94377Z"
        />
      </mask>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM8 7.11854C8.43222 7.11854 8.78261 7.46893 8.78261 7.90115V11.2174C8.78261 11.6496 8.43222 12 8 12C7.56778 12 7.21739 11.6496 7.21739 11.2174V7.90115C7.21739 7.46893 7.56778 7.11854 8 7.11854ZM7 4.94377C7 4.38298 7.43478 4 8 4C8.56522 4 9 4.38298 9 4.94377C9 5.49088 8.56522 5.88754 8 5.88754C7.43478 5.88754 7 5.49088 7 4.94377Z"
        fill="#92979A"
      />
      <path
        d="M1.5 8C1.5 4.41015 4.41015 1.5 8 1.5V-1.5C2.75329 -1.5 -1.5 2.75329 -1.5 8H1.5ZM8 14.5C4.41015 14.5 1.5 11.5899 1.5 8H-1.5C-1.5 13.2467 2.75329 17.5 8 17.5V14.5ZM14.5 8C14.5 11.5899 11.5899 14.5 8 14.5V17.5C13.2467 17.5 17.5 13.2467 17.5 8H14.5ZM8 1.5C11.5899 1.5 14.5 4.41015 14.5 8H17.5C17.5 2.75329 13.2467 -1.5 8 -1.5V1.5ZM10.2826 7.90115C10.2826 6.6405 9.26065 5.61854 8 5.61854V8.61854C7.6038 8.61854 7.28261 8.29736 7.28261 7.90115H10.2826ZM10.2826 11.2174V7.90115H7.28261V11.2174H10.2826ZM8 13.5C9.26065 13.5 10.2826 12.478 10.2826 11.2174H7.28261C7.28261 10.8212 7.6038 10.5 8 10.5V13.5ZM5.71739 11.2174C5.71739 12.478 6.73935 13.5 8 13.5V10.5C8.39621 10.5 8.71739 10.8212 8.71739 11.2174H5.71739ZM5.71739 7.90115V11.2174H8.71739V7.90115H5.71739ZM8 5.61854C6.73935 5.61854 5.71739 6.6405 5.71739 7.90115H8.71739C8.71739 8.29735 8.3962 8.61854 8 8.61854V5.61854ZM8 2.5C6.71908 2.5 5.5 3.44615 5.5 4.94377H8.5C8.5 5.07199 8.44108 5.23839 8.30643 5.3629C8.18164 5.4783 8.05655 5.5 8 5.5V2.5ZM10.5 4.94377C10.5 3.44615 9.28091 2.5 8 2.5V5.5C7.94345 5.5 7.81836 5.4783 7.69357 5.3629C7.55892 5.23839 7.5 5.07199 7.5 4.94377H10.5ZM8 7.38754C9.2959 7.38754 10.5 6.41379 10.5 4.94377H7.5C7.5 4.80129 7.56404 4.64049 7.68795 4.52473C7.80536 4.41504 7.92917 4.38754 8 4.38754V7.38754ZM5.5 4.94377C5.5 6.41379 6.7041 7.38754 8 7.38754V4.38754C8.07083 4.38754 8.19463 4.41504 8.31205 4.52473C8.43596 4.64049 8.5 4.80129 8.5 4.94377H5.5Z"
        fill="#92979A"
        :mask="`url(#${idForMask})`"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'InfoIcon',
  props: {
    keyForMask: String
  },
  computed: {
    idForMask() {
      return 'path-1-inside-1_1664_229153' + Math.floor(Math.random() * 10000)
    }
  }
}
</script>
