export default {
  specializationsData(state) {
    return state.specializations
  },
  prevSpec(state) {
    return state.prevSpec
  },
  newCreatedAppointment(state) {
    return state.newCreatedAppointment
  },
  singleAppointment(state) {
    return state.single.data
  },
  errorCode(state) {
    return state.errorCode
  },
  errorMsg(state) {
    return state.errorMsg
  }
}
