<template>
  <div class="discount-tooltip">
    <h3 class="discount-tooltip__headline">Накопительная скидка</h3>
    <p class="discount-tooltip__text">
      Для постоянных пациентов мы предлагаем систему накопительных скидок.
    </p>
    <p class="discount-tooltip__text">
      Размер вашей скидки зависит от суммы оплаченных медицинских услуг. Чем
      больше сумма оплаченных услуг, тем выше скидка.
    </p>
    <p class="discount-tooltip__text" @click="hideModal">
      Ознакомиться с возможным размером скидок можно в разделе
      <router-link class="discount-tooltip__link" to="/faq"
        >Частые вопросы</router-link
      >.
    </p>
    <p class="discount-tooltip__sub-text">
      Скидка распространяется на все услуги, кроме: хирургического лечения в
      стационаре, анестезии, лекарственных средств.
    </p>
  </div>
</template>

<script>
export default {
  name: 'DiscountTooltip',
  methods: {
    hideModal() {
      this.$modals.hide('sm-modal')
    }
  }
}
</script>
