var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"main-page"},[(_vm.getPromotionStories)?_c('SmSlider',{attrs:{"space-between":24,"slides-per-view":"auto","pagination":true,"title":"Специально для вас","breakpoints":{
      1367: {
        spaceBetween: 24
      },
      1024: {
        spaceBetween: 19
      },
      320: {
        spaceBetween: 6
      }
    }}},_vm._l((_vm.currentPromotionStories),function(story){return _c('swiper-slide',{key:story.id,staticClass:"slide"},[_c('a',{attrs:{"href":story.url,"target":"_blank"}},[_c('img',{attrs:{"alt":"alt","src":(_vm.getBaseUrl + "/" + (story.image))}})])])}),1):_vm._e(),_c('Navbar',{staticClass:"main-navbar",attrs:{"options":_vm.routes}}),_c('div',{staticClass:"content"},[_c('router-view')],1),(_vm.show)?_c('SmModal',{attrs:{"size":"md","name":"sm-modal"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"line"}),_c('div',{staticClass:"sm-modal__body"},[_c('div',{staticClass:"sm-modal__body__icon"},[_c('DocumentIcon')],1),_c('div',{staticClass:"sm-modal__body__content"},[_vm._v(" К сожалению, вы давно не пользовались нашими услугами и вам необходимо обновить договор, доступ к приложению, рекомендациям, истории посещений, пожалуйста, обновите договор на оказание медицинских услуг. Для этого обратитесь в "),_c('router-link',{staticClass:"linkTo",attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var navigate = ref.navigate;
return [_c('span',{attrs:{"role":"link"},on:{"click":navigate}},[_vm._v(" регистратуру клиники")])]}}],null,false,1797515659)}),_vm._v(" . "),_c('br'),_c('br'),_vm._v(" Вы можете записаться на приём, и во время визита мы обновим договор. ")],1)])]},proxy:true},{key:"footer",fn:function(){return [_c('Button',{staticClass:"sm-modal__close-btn",attrs:{"onClick":_vm.closeModal,"icon":"close"}},[_vm._v(" Закрыть окно ")])]},proxy:true}],null,false,513171783)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }