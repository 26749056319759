<template>
  <div class="error-auth-tooltip">
    <h3 class="error-auth-tooltip__headline">Забыли пароль?</h3>
    <p class="error-auth-tooltip__text">
      Чтобы сменить забытый пароль перейдите на страницу восстановления пароля.
      Восстановление пароля происходит по номеру телефона, который привязан к
      вашей учетной записи.
    </p>
    <router-link
      @click="hideModal"
      class="error-auth-tooltip__link"
      to="/auth/recovery"
      >Восстановить пароль</router-link
    >
    <h3 class="error-auth-tooltip__headline">Изменился телефон?</h3>
    <p class="error-auth-tooltip__text">
      Если у вас изменился номер телефона, для восстановления доступа,
      обратитесь в регистратуру клиники холдинга с паспортом. До восстановления
      доступа вы можете авторизоваться в приложении по номеру телефона и
      получить доступ к просмотру расписания врачей и записи на прием.
    </p>
  </div>
</template>

<script>
export default {
  name: 'ErrorAuthTooltip',
  methods: {
    hideModal() {
      this.$modals.hide('sm-modal')
    }
  }
}
</script>
