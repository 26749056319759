import { apiV1 } from '@/config/request'

export default {
  async getClinics(
    { commit, rootState },
    { cityId = rootState.cities.selected, size = 100 }
  ) {
    commit('setClinicsListLoading')
    try {
      const { data } = await new apiV1({ auth: true }).get('/bx/clinics', {
        params: { cityId, size }
      })

      commit('setClinicsListData', data)
      commit('setClinicsListLoading')
    } catch (e) {
      commit('setClinicsListLoading')
      commit('setClinicsListError', `${e}`)
    }
  },
  async getClinicById({ commit }, { id }) {
    commit('setClinicsSingleLoading')

    try {
      const { data } = await new apiV1({ auth: true }).get(`/bx/clinics/${id}`)
      commit('setClinicsSingleData', data?.clinic ?? [])
      commit('setClinicsSingleLoading')
    } catch (e) {
      commit('setClinicsSingleLoading')
    }
  },
  async getFilteredClinic(
    { commit, rootState },
    {
      subwayId,
      clinicDirectionIds,
      specialityId,
      cityId = rootState.cities.selected
    }
  ) {
    commit('setClinicsListLoading')
    try {
      const { data } = await new apiV1({ auth: true }).post(
        '/bx/clinics/filter?size=50',
        {
          cityId,
          subwayId,
          clinicDirectionIds,
          specialityId
        }
      )
      commit('setClinicsListData', data)
      commit('setClinicsListLoading')
    } catch (e) {
      commit('setClinicsListLoading')
      commit('setError', `${e}`)
    }
  },
  async getDirections({ commit, rootState }) {
    commit('setClinicDirectionsLoading')
    try {
      const {
        data: { clinicDirections }
      } = await new apiV1({ auth: true }).get(
        `/clinics/directions?cityId=${rootState.cities.selected}`
      )
      commit('setClinicDirectionsData', clinicDirections)
    } catch (e) {
      commit('setClinicDirectionsLoading')
      commit('setError', `${e}`)
    }
  }
}
