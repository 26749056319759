import { formatDistanceToNowStrict } from 'date-fns'
import locale from 'date-fns/locale/ru'

export default {
  getAccountInfo(state) {
    return state.account
  },
  getPatientInfo(state) {
    return state.patient
  },
  getChatState(state) {
    return state.showChat
  },
  getSignInDate(state) {
    return !state.patient?.contractId
      ? null
      : state.patient?.contractId.dateOfSigning
  },
  getAge(state) {
    const ageSting = state.patient.birthday
      ? formatDistanceToNowStrict(new Date(state.patient.birthday), {
          locale,
          roundingMethod: 'floor'
        })
      : ''
    return Number(ageSting.replace(/\D/g, ''))
  },
  hasContract(state) {
    return !!state.patient?.contractId
  },
  getLoading(state) {
    return state.loading
  },
  getChangeAccountDataLoading(state) {
    return state.changeAccountDataLoading
  },
  getErrors(state) {
    return state.errors
  }
}
