<template>
  <div class="loggedIn">
    <div class="loggedIn__user">
      <div class="ym-hide-content" @click="showProfile">
        <p v-if="hasContract || windowWidth > 1439 || windowWidth < 1024">
          {{ getUserName }}
        </p>
        <p v-else>{{ getUserName }}</p>
        <ArrowDownCircleIcon />
      </div>
    </div>
    <div class="loggedIn__no-contract" v-if="!hasContract">
      <AlertIcon />
      <span class="text">Оформите договор в клинике</span>
    </div>
    <div class="loggedIn__has-contract" v-else>
      <div class="card ym-hide-content">
        <p>Ваша карта</p>
        <p>{{ getPatientInfo.ambulatoryCards[0] }}</p>
      </div>
      <div class="discount">
        <p>{{ getPatientInfo.discount }} <span>%</span></p>
        <span
          >Накопительная <br />
          скидка</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import ArrowDownCircleIcon from '@/assets/icons/arrow-down-circle'
import AlertIcon from '@/assets/icons/alert'
import SmSlideProfile from '@/components/slideOutComponents/profile/profile'
import { mapGetters } from 'vuex'
import { TRACKER_SLUGS } from '@/models/tracker'

export default {
  name: 'loggedInUser',
  components: { AlertIcon, ArrowDownCircleIcon },
  methods: {
    showProfile() {
      this.$tracker(TRACKER_SLUGS.ROUTE_PROFILE)
      this.$showPanel({
        component: 'SmSlideoutPanel',
        props: {
          title: 'Профиль',
          component: SmSlideProfile,
          properties: {
            user: this.getPatientInfo,
            account: this.getAccountInfo,
            patient: this.getPatientInfo,
            hasContract: this.hasContract
          }
        },
        openOn: 'right',
        sync: true,
        keepAlive: false
      })
    }
  },

  computed: {
    ...mapGetters('account', [
      'getPatientInfo',
      'getAccountInfo',
      'hasContract'
    ]),
    getUserName() {
      return `${this.getPatientInfo?.name ?? ''} ${
        this.getPatientInfo?.surname ?? ''
      }`
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
