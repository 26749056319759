<template>
  <div
    class="pagination-wrapper"
    :class="[
      paginationInfo.totalPages <= page ? 'pagination-wrapper--hide-more' : ''
    ]"
    v-if="paginationInfo.totalPages > 1"
  >
    <Button
      icon="more"
      classes="btn-show-more"
      :onClick="showMoreHandler"
      v-if="paginationInfo.totalPages > page"
    >
      Показать еще
    </Button>
    <Paginate
      v-model="page"
      :page-count="pageCount"
      :page-range="3"
      :margin-pages="2"
      :click-handler="pageChangeHandler"
      :prev-text="''"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'page-item'"
    >
    </Paginate>
  </div>
</template>
<script>
import Paginate from 'vuejs-paginate'
import Button from '@/components/button'
export default {
  name: 'Pagination',
  components: { Button, Paginate },
  data() {
    return {
      page: Number(this.$route.query.page) || 0
    }
  },
  props: {
    autoLoad: {
      type: Boolean,
      default: true
    },
    paginationInfo: {
      size: Number,
      totalPages: Number
    },
    getData: {
      type: Function,
      default: () => {}
    },
    getMoreData: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    pageSize() {
      return this.paginationInfo.size
    },
    pageCount() {
      return this.paginationInfo.totalPages
    }
  },
  methods: {
    async pageChangeHandler(page) {
      await this.$router.push({ query: { page } })
      await this.getData({ page: page - 1, size: this.pageSize })
    },
    async showMoreHandler() {
      const currentPage = this.page || 1
      this.page = currentPage + 1
      await this.$router.push({
        query: { page: currentPage === 0 ? 2 : this.page }
      })
      await this.getMoreData({
        page: this.page - 1 === 0 ? 1 : this.page - 1,
        size: this.pageSize
      })
    }
  },
  async mounted() {
    if (this.autoLoad) await this.getData({ page: this.page, size: this.size })
  }
}
</script>
