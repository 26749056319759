export default {
  setToken(state, { token, exp }) {
    state.token = {
      val: token,
      exp
    }
  },
  auth_error(state, payload) {
    state.auth_error = payload
  },
  redirect_error(state, payload) {
    state.redirect_error = payload
  },
  setAuthData(state, payload) {
    state.authData = {
      ...payload
    }
  },
  setLoading(state) {
    state.loading = !state.loading
  },
  setPasswordRecovery(state, payload) {
    for (const key of Object.keys(payload)) {
      if (key !== 'recaptchaToken') {
        state.passwordRecovery[key] = payload[key]
      }
    }
  },
  setLoginSignInData(state, payload) {
    for (const key of Object.keys(payload)) {
      state.loginSignIn[key] = payload[key]
    }
  },
  setPhoneSignInData(state, payload) {
    for (const key of Object.keys(payload)) {
      if (key !== 'recaptchaToken') {
        state.phoneSignIn[key] = payload[key]
      }
    }
  },
  setRegistrationData(state, payload) {
    for (const key of Object.keys(payload)) {
      state.registration[key] = payload[key]
    }
  },
  clearPhoneSignInData(state) {
    state.phoneSignIn = { ...state.initialState.phoneSignIn }
  },
  clearRecoveryData(state) {
    state.passwordRecovery = { ...state.initialState.passwordRecovery }
  },
  clearAuthState(state) {
    for (const key of Object.keys(state)) {
      if (key !== 'initialState') {
        state[key] = state.initialState[key]
      }
    }
  }
}
