export default {
  setSpecialitiesListData(state, payload) {
    state.list.data = payload
  },
  setSpecialitiesSingleData(state, payload) {
    state.single.data = payload
  },
  setSpecialitiesListLoading(state) {
    state.list.loading = !state.loading
  },
  setSpecialitiesSingleLoading(state) {
    state.single.loading = !state.single.loading
  },
  setError(state, payload) {
    state.error = payload
  }
}
