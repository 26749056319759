<template>
  <label class="cm-check option">
    <input
      class="cm-check__input"
      type="checkbox"
      :checked="value"
      :value="value"
      @click="() => onClick(!value)"
    />
    <span class="cm-check__box"></span>
    {{ label }}
    <slot></slot>
  </label>
</template>
<script>
export default {
  name: 'SmCheckbox',
  props: {
    label: String,
    value: Boolean,
    onClick: {
      type: Function,
      default: () => {}
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
