import { apiV1 } from '@/config/request'
import $store from '@/store'

export default {
  async getDoctorsFilter(
    { commit, rootState },
    { cityId = rootState.cities.selected, ...payload }
  ) {
    const account = $store.state.account?.account
    const patient = $store.state.account?.patient
    const { data } = await new apiV1({ auth: true }).post(
      `/bx/filter-values/appointments?accountId=${account.id}&patientId=${patient?.id}`,
      {
        ...payload,
        cityId
      }
    )
    commit('setDoctorsFilter', data ?? [])
  },
  async getTimeslots(
    { commit, rootState },
    {
      cityIds = [rootState.cities.selected],
      clinicIds,
      groupByDoctor,
      page,
      size,
      sort,
      ...payload
    }
  ) {
    if (!payload.merge) {
      commit('setTimeslotsLoading')
    }
    if ('from' in payload || 'to' in payload) {
      if (payload.from === payload.to) {
        const timeArr = payload.to.split('T')
        timeArr[1] = '23:59:59'
        payload.to = timeArr.join('T')
      }
    }
    try {
      const { data } = await new apiV1({ auth: true }).post(
        '/bx/timeslots/containers-web/filter',
        {
          ...payload,
          patientBirthday: rootState.account.patient.birthday,
          includePrices: true,
          onlyWithAvailableTimeslots: true,
          cityIds,
          clinicIds
        },
        {
          params: {
            ...(groupByDoctor ? { groupByDoctor: 'Y' } : {}),
            page,
            sort,
            size
          }
        }
      )
      data.doctors = Object.values(data.doctors).map((doc) => {
        const { containers } = data.content.find(
          (el) => el.doctorId === doc.id
        ) || { containers: [] }
        return {
          ...doc,
          clinics: Object.values(data.clinics)
            ?.filter?.((clinic) =>
              containers.map((el) => el.clinicId).includes(clinic.id)
            )
            .map((clinic) => ({
              ...clinic,
              dates: containers.filter(
                (container) => container.clinicId === clinic.id
              )
            }))
        }
      })

      if (!payload.merge) {
        commit('setTimeslotsData', data ?? [])
      } else {
        commit('updateTimeslotsData', data ?? [])
      }
      if (!payload.merge) {
        commit('setTimeslotsLoading')
      }
    } catch (e) {
      if (!payload.merge) {
        commit('setTimeslotsLoading')
      }
    }
  },
  setTimeslots({ commit }, payload) {
    commit('setTimeslotsData', payload)
  },
  disableTimeslot({ commit }, payload) {
    commit('disableTimeslot', payload)
  }
}
