export default {
  setFaqData(state, payload) {
    state.list.data = payload
  },
  setCategories(state, payload) {
    if (Array.isArray(payload)) {
      const index = payload.findIndex(
        ({ title }) => title.toLowerCase() === 'общие вопросы'
      )

      if (index) {
        const category = payload[index]
        payload.splice(index, 1)
        payload.unshift(category)
      }
    }

    state.categories.data = payload
  },
  setListLoading(state) {
    state.list.loading = !state.list.loading
  },
  setCategoriesLoading(state) {
    state.categories.loading = !state.categories.loading
  },
  setListError(state, payload) {
    state.list.error = payload
  },
  setCategoriesError(state, payload) {
    state.categories.error = payload
  }
}
