var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"doctor-card",class:{ 'open-slots': _vm.openSlots }},[_c('div',{staticClass:"card-header",on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.handleDoctorClick(_vm.doctorsData); }).apply(null, arguments)}}},[_c('div',{staticClass:"doctor-image"},[(_vm.doctorsData.photo)?_c('div',{staticClass:"doctor-photo",style:({
          backgroundImage: ("url(" + _vm.getBaseUrl + "/" + (_vm.doctorsData.photo) + ")")
        })}):_c('AvatarIcon',{staticClass:"avatar"})],1),_c('div',{staticClass:"doctor-info"},[_c('div',{staticClass:"personal-info"},[_c('p',{staticClass:"personal-info__title"},[_vm._v(" "+_vm._s(_vm.doctorsData.surname)+" "+_vm._s(_vm.doctorsData.name)+" "),(_vm.doctorsData.patronymic)?[_vm._v(_vm._s(_vm.doctorsData.patronymic)+" ")]:_vm._e()],2),(_vm.doctorsData.totalExperience)?_c('span',{staticClass:"personal-info__qualification",class:{
            'hide-dot':
              !_vm.doctorsData.qualifications ||
              !_vm.doctorsData.qualifications.length ||
              !_vm.doctorsData.qualifications[0].title
          }},[_vm._v(_vm._s(("Стаж " + (_vm.doctorsData.totalExperience) + " " + (_vm.declOfNum( _vm.doctorsData.totalExperience, ['год', 'года', 'лет'] )))))]):_vm._e(),(
            _vm.doctorsData.qualifications &&
            _vm.doctorsData.qualifications.length &&
            _vm.doctorsData.qualifications[0].title
          )?_c('span',{staticClass:"personal-info__qualification",class:{
            'hide-dot': !_vm.doctorsData.totalExperience
          }},[(_vm.doctorsData.qualifications[0].title)?[_vm._v(" "+_vm._s(_vm.doctorsData.qualifications[0].title)+" ")]:_vm._e()],2):_vm._e()]),(
          _vm.doctorsData.priceInformation && _vm.doctorsData.priceInformation.price
        )?_c('div',{staticClass:"price"},[_c('div',{staticClass:"price__inner"},[_c('span',[_vm._v("Предварительная стоимость приема")]),_c('SmTooltip',{attrs:{"trigger":_vm.windowWidth >= 1024 ? 'mouseenter' : '',"name":"price-tooltip","placement":"top","max-width":330},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('InfoIcon',{staticClass:"info-icon"})]},proxy:true},{key:"tooltip",fn:function(){return [_c('div',{staticClass:"price-tooltip"},[_c('h3',{staticClass:"price-tooltip__title"},[_vm._v("Стоимость приема")]),_c('p',{staticClass:"price-tooltip__text"},[_vm._v(" Стоимость указана за стандартный прием. Перед выполнением дополнительных манипуляций, обследований, анализов просим уточнять стоимость. ")])])]},proxy:true}],null,false,1309061617)})],1),_c('p',[_vm._v(" "+_vm._s(_vm.doctorsData.priceInformation.price)+" "),_c('RubIcon')],1)]):_vm._e()])]),_vm._l((_vm.doctorsData.clinics),function(clinic,index){return _c('div',{key:index,staticClass:"card-body"},[_c('div',{staticClass:"left-part"},[_c('p',{staticClass:"left-part__item"},[_c('LocateIcon',{staticClass:"icon"}),_c('span',[_vm._v(_vm._s(clinic.clinicName))])],1),(clinic.subway)?_c('p',{staticClass:"left-part__item"},[_c('SubwayIcon',{staticClass:"icon",attrs:{"color":clinic.subway.color && clinic.subway.color.length
              ? ("#" + (clinic.subway.color[0]))
              : 'purple'}}),_c('span',[_vm._v(_vm._s(clinic.subway.title))])],1):_vm._e(),_c('p',{staticClass:"left-part__item grey-text"},[_vm._v(_vm._s(clinic.address))])]),_c('div',{staticClass:"right-part"},[_c('p',[_vm._v("Выберите дату")]),_c('Select',{directives:[{name:"tracker",rawName:"v-tracker",value:(_vm.TRACKER_SLUGS.APPOINTMENT_DATE),expression:"TRACKER_SLUGS.APPOINTMENT_DATE"}],staticClass:"select-date",attrs:{"label":"label","options":clinic.options,"onChange":_vm.onDateChange,"clearable":false},model:{value:(_vm.selectedDate[clinic.id]),callback:function ($$v) {_vm.$set(_vm.selectedDate, clinic.id, $$v)},expression:"selectedDate[clinic.id]"}}),_c('p',[_vm._v("Выберите время приема")]),_c('div',{staticClass:"slots"},_vm._l((_vm.getSlot(clinic)),function(slot,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedDate[clinic.id].isAvailable || slot.isAvailable),expression:"selectedDate[clinic.id].isAvailable || slot.isAvailable"},{name:"tracker",rawName:"v-tracker",value:(_vm.TRACKER_SLUGS.APPOINTMENT_TIME),expression:"TRACKER_SLUGS.APPOINTMENT_TIME"}],key:index + slot.id,class:[
            _vm.doctorsData.isNeedCall ||
            !slot.isAvailable ||
            !_vm.selectedDate[clinic.id].isAvailable
              ? 'disable-slot'
              : null,
            'slot'
          ],on:{"click":function () { return _vm.slotClick(slot, clinic); }}},[_vm._v(" "+_vm._s(_vm.formatSlot(slot))+" ")])}),0),(_vm.doctorsData.isNeedCall || !_vm.selectedDate[clinic.id].isAvailable)?_c('div',{staticClass:"need-call-wrapper"},[_c('InfoIcon',{staticClass:"orange-info-icon"}),_c('p',[(!_vm.hasSpecialStatus)?_c('span',[_vm._v(" Запись к данному специалисту возможна только через контакт-центр "),(_vm.contactsPhone)?_c('span',{staticClass:"green-text"},[_vm._v(_vm._s(_vm.contactsPhone))]):_c('span',{staticClass:"green-text"},[_vm._v("+7 (495) 154-10-82")])]):_vm._e(),(_vm.hasSpecialStatus)?_c('span',[_c('a',{staticClass:"green-link",attrs:{"href":_vm.siteUrl}},[_vm._v("Запишитесь онлайн на сайте")])]):_vm._e(),_c('span',[_vm._v(" или "),_c('span',{staticClass:"green-link",on:{"click":_vm.openWebim}},[_vm._v("обратитесь в чат")])])])],1):_vm._e()],1)])}),(
      _vm.getSlot(_vm.doctorsData.clinics[_vm.doctorsData.clinics.length - 1]).length > 5
    )?_c('div',{staticClass:"open-card",on:{"click":_vm.openSlotsHandler}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }