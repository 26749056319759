export default {
  getListLoading(state) {
    return state.list.loading
  },
  getListError(state) {
    return state.list.error
  },
  getCategoriesLoading(state) {
    return state.categories.loading
  },
  getFaqList(state) {
    return state.list.data || []
  },
  getCategoriesList(state) {
    return state.categories.data
  }
}
