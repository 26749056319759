<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 11.9414C5 11.5272 5.33579 11.1914 5.75 11.1914H10.25C10.6642 11.1914 11 11.5272 11 11.9414C11 12.3556 10.6642 12.6914 10.25 12.6914H5.75C5.33579 12.6914 5 12.3556 5 11.9414Z"
      fill="url(#paint0_linear_1962_151527)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 8.94141C5 8.52719 5.33579 8.19141 5.75 8.19141H10.25C10.6642 8.19141 11 8.52719 11 8.94141C11 9.35562 10.6642 9.69141 10.25 9.69141H5.75C5.33579 9.69141 5 9.35562 5 8.94141Z"
      fill="url(#paint1_linear_1962_151527)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 5.94141C5 5.52719 5.33579 5.19141 5.75 5.19141H6.5C6.91421 5.19141 7.25 5.52719 7.25 5.94141C7.25 6.35562 6.91421 6.69141 6.5 6.69141H5.75C5.33579 6.69141 5 6.35562 5 5.94141Z"
      fill="url(#paint2_linear_1962_151527)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 2.19141C4.17157 2.19141 3.5 2.86298 3.5 3.69141V12.6914C3.5 13.5198 4.17157 14.1914 5 14.1914H11C11.8284 14.1914 12.5 13.5198 12.5 12.6914V5.81273C12.5 5.61381 12.421 5.42305 12.2803 5.2824L9.40901 2.41108C9.26836 2.27042 9.07759 2.19141 8.87868 2.19141H5ZM2 3.69141C2 2.03455 3.34315 0.691406 5 0.691406H8.87868C9.47542 0.691406 10.0477 0.928459 10.4697 1.35042L13.341 4.22174C13.7629 4.64369 14 5.21599 14 5.81273V12.6914C14 14.3483 12.6569 15.6914 11 15.6914H5C3.34315 15.6914 2 14.3483 2 12.6914V3.69141Z"
      fill="url(#paint3_linear_1962_151527)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.5 1.44141V2.94141C9.5 4.18405 10.5074 5.19141 11.75 5.19141H13.25V6.69141H11.75C9.67893 6.69141 8 5.01247 8 2.94141V1.44141H9.5Z"
      fill="url(#paint4_linear_1962_151527)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1962_151527"
        x1="5.34111"
        y1="13.4468"
        x2="7.11373"
        y2="9.56242"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1962_151527"
        x1="5.34111"
        y1="10.4468"
        x2="7.11373"
        y2="6.56242"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1962_151527"
        x1="5.12792"
        y1="7.44684"
        x2="7.43008"
        y2="5.55503"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1962_151527"
        x1="2.68222"
        y1="23.2458"
        x2="19.9373"
        y2="15.6834"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1962_151527"
        x1="8.29847"
        y1="9.33543"
        x2="15.2202"
        y2="5.54345"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'TestIcon'
}
</script>
