export default {
  setPromotionsData(state, payload) {
    state.data = payload
  },
  updatePromotionsData(state, payload) {
    state.data.content = [...state.data.content, ...payload.content]
  },
  setPromotionsFilters(state, payload) {
    state.filters = payload
  },
  setLoading(state) {
    state.loading = !state.loading
  },
  setError(state, payload) {
    state.error = payload
  },
  setStoriesData(state, payload) {
    state.stories = payload
  },
  setCounter(state, payload) {
    state.counter = payload
  },
  setUpdateCountersStatus(state, payload) {
    state.updateCountersStatus = payload
  }
}
