<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 0C13.9669 0 18 4.03312 18 9C18 10.8381 17.4476 12.5484 16.5 13.9736C16.0748 14.6131 17 18 17 18C17 18 13.728 16.7008 13 17.063C11.7947 17.6627 10.4364 18 9 18C4.03312 18 0 13.9669 0 9C0 4.03312 4.03312 0 9 0ZM7.83398 12.9128C7.83398 13.5462 8.34923 14.0614 8.98261 14.0614C9.61598 14.0614 10.1312 13.5462 10.1312 12.9128C10.1312 12.2794 9.61598 11.7642 8.98261 11.7642C8.34923 11.7642 7.83398 12.2794 7.83398 12.9128V12.9128V12.9128ZM9.13042 3.96112C7.18642 3.96112 6.06592 5.59575 6.06592 6.39112C6.06592 6.74437 6.25829 7.30462 6.89167 7.30462C7.37767 7.30462 7.70167 6.95137 7.71629 6.642C7.76017 6.33262 8.08417 5.625 9.07192 5.625C9.77842 5.625 10.4264 6.12562 10.4264 6.74437C10.4264 7.98187 8.01104 8.08087 8.01104 9.90787C8.01104 10.5266 8.43854 10.9687 8.96842 10.9687C9.52754 10.9687 9.95504 10.5266 9.95504 9.96637C9.95504 8.97637 12.2827 8.82112 12.2827 6.7005C12.2827 5.15362 10.8539 3.96112 9.13042 3.96112V3.96112V3.96112Z"
      fill="url(#paint0_linear_2219_30906)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2219_30906"
        x1="1.02332"
        y1="27.0652"
        x2="24.755"
        y2="14.0641"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'QuestionIcon'
}
</script>
