export default {
  setLoadingListVisits(state) {
    state.list.loading = !state.list.loading
  },
  setListVisitsError(state, payload) {
    state.list.error = payload
  },
  setLoadingSingleVisits(state) {
    state.single.loading = !state.single.loading
  },
  setLoadingSingleError(state, payload) {
    state.single.error = payload
  },
  setListVisitsData(state, payload) {
    state.list.data = payload
  },
  updateListVisitsData(state, payload) {
    let content = state.list.data.visits.content || []
    state.list.data.visits.content = [...content, ...payload.content]
  },
  setSingleVisitsData(state, payload) {
    state.single.data = payload
  },
  setFilters(state, payload) {
    state.filters.data = payload
  },
  setPaginationData(state, payload) {
    state.list.pagination = payload
  },
  clearVisitRequestState(state) {
    state.single.loading = false
    state.single.error = null
  }
}
