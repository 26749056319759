<template>
  <aside class="aside-block" :class="{ 'with-scroll': hasScroll }">
    <slot></slot>
  </aside>
</template>
<script>
export default {
  name: 'AsideBlock',
  props: { hasScroll: Boolean }
}
</script>
<style lang="scss" scoped>
@import 'style';
</style>
