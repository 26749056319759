<template>
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.29 10.26V8.434H6.468C9.196 8.434 11.242 6.982 11.242 4.342C11.242 2.406 10.164 0.139999 6.38 0.139999H2.288V6.806H0.484V8.434H2.288V10.26H0.484V11.888H2.288V14H4.29V11.888H8.844V10.26H4.29ZM4.29 1.944H6.38C8.052 1.944 9.064 2.89 9.064 4.408C9.064 5.904 8.206 6.806 6.38 6.806H4.29V1.944Z"
      fill="#565C5A"
    />
  </svg>
</template>
<script>
export default {
  name: 'RubIcon'
}
</script>
