<template>
  <div>
    <router-view></router-view>
    <slideout-panel class="sm-slideout-panel"></slideout-panel>
    <Chat />
    <SmModal size="md" name="info-modal">
      <template v-slot:body>
        <div class="line"></div>
        <div class="sm-modal__body">
          <div class="sm-modal__body__icon"><DocumentIcon /></div>
          <div class="sm-modal__body__content">
            К сожалению, вы давно не пользовались нашими услугами и вам
            необходимо обновить договор, доступ к приложению, рекомендациям,
            истории посещений, пожалуйста, обновите договор на оказание
            медицинских услуг. Для этого обратитесь в регистратуру клиники .
            <br /><br />
            Вы можете записаться на приём, и во время визита мы обновим договор.
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <Button :onClick="closeModal" icon="close" class="sm-modal__close-btn">
          Закрыть окно
        </Button>
      </template>
    </SmModal>
  </div>
</template>

<script>
import Chat from '@/components/chat'
import SmModal from '@/components/modal'
import DocumentIcon from '@/assets/icons/document'
import Button from '@/components/button'

export default {
  name: 'App',
  components: {
    Button,
    DocumentIcon,
    SmModal,
    Chat
  },
  methods: {
    closeModal() {
      this.$modals.hide('info-modal')
    }
  },
  mounted() {
    let viewport = document.querySelector('meta[name="viewport"]')

    if (document.body.clientWidth < 1367 && document.body.clientWidth > 799) {
      viewport.setAttribute('content', 'width=1024px')
    } else {
      viewport.setAttribute(
        'content',
        'width=device-width,initial-scale=1,maximum-scale=1,user-scalable=0'
      )
    }
  }
}
</script>
