<template>
  <SmModal size="md" name="approve-modal" class="approve-modal">
    <!--    <template v-slot:footer>-->
    <!--      <div class="modal-buttons-wrapper">-->
    <!--        <Button :classes="['admission-btn', '']" :onClick="actionHandler">-->
    <!--          Да-->
    <!--        </Button>-->
    <!--        <Button :classes="'admission-btn'" :onClick="closeModal" secondary>-->
    <!--          Нет-->
    <!--        </Button>-->
    <!--      </div>-->
    <!--    </template>-->
    <template v-slot:body>
      <h3 v-html="getTitle" class="signOut-body" />
    </template>
    <template v-slot:footer>
      <div class="signOut-buttons">
        <Button :onClick="actionHandler"> Да </Button>
        <Button :onClick="closeModal" secondary> Нет </Button>
      </div>
    </template>
  </SmModal>
</template>
<script>
import SmModal from '@/components/modal'
import Button from '@/components/button'

export default {
  name: 'ApproveModal',
  components: { Button, SmModal },
  props: {
    action: String,
    actionHandler: { type: Function, default: () => {} }
  },
  computed: {
    getTitle() {
      return `Вы действительно хотите ${this.action}?`
    }
  },
  methods: {
    closeModal() {
      this.$modals.hide('approve-modal')
    }
  }
}
</script>

<style lang="scss" scoped>
.approve-modal {
  ::v-deep {
    //.modal-buttons-wrapper {
    //  margin-top: 0px !important;
    //}
    ////.v-modal__content {
    ////  width: 100%;
    ////  max-width: 400px;
    ////}
    //
    //.sm-modal__close {
    //  right: 12px;
    //  top: 12px;
    //  width: 28px;
    //  height: 28px;
    //}
    .v-modal__heading {
      margin-bottom: -1px;
    }
    .signOut-buttons {
      display: flex;
      margin-top: 2.8125rem;
      > .btn {
        width: 100%;
        margin-bottom: 0;
        &:first-of-type {
          margin-right: 0.625rem;
        }
      }
    }
    .signOut-body {
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      @include ff_main_medium;
    }
    @include media-breakpoint-down(sm) {
      .signOut-body {
        br {
          display: none;
        }
      }
    }
    .v-modal__wrapper
      .v-modal__content
      .v-modal__panel
      .v-modal__heading
      .v-modal__title
      > div {
      background-size: cover;
    }
  }
}
</style>
