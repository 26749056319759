<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click.stop="onClose"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.50005 15.2C12.4765 15.2 15.7 11.9765 15.7 8.00005C15.7 4.0236 12.4765 0.800049 8.50005 0.800049C4.5236 0.800049 1.30005 4.0236 1.30005 8.00005C1.30005 11.9765 4.5236 15.2 8.50005 15.2ZM6.63037 5.06977C6.33748 4.77687 5.86261 4.77687 5.56971 5.06977C5.27682 5.36266 5.27682 5.83753 5.56971 6.13043L7.43938 8.0001L5.56971 9.86977C5.27682 10.1627 5.27682 10.6375 5.56971 10.9304C5.86261 11.2233 6.33748 11.2233 6.63037 10.9304L8.50004 9.06076L10.3697 10.9304C10.6626 11.2233 11.1375 11.2233 11.4304 10.9304C11.7233 10.6375 11.7233 10.1627 11.4304 9.86977L9.5607 8.0001L11.4304 6.13043C11.7233 5.83753 11.7233 5.36266 11.4304 5.06977C11.1375 4.77687 10.6626 4.77687 10.3697 5.06977L8.50004 6.93944L6.63037 5.06977Z"
      fill="#F2F7F6"
    />
  </svg>
</template>
<script>
export default {
  name: 'CloseRoundIcon',
  props: {
    onClose: {
      type: Function,
      default: () => {}
    }
  }
}
</script>
