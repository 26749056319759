<template>
  <section
    class="authorization"
    :class="{
      ['hide']: this.windowWidth < 1440 && this.$route.name !== 'Auth'
    }"
  >
    <aside class="left-side">
      <router-link
        to="/"
        v-if="this.windowWidth >= 1440"
        custom
        v-slot="{ navigate }"
      >
        <img
          @click="navigate"
          role="link"
          alt="main-logo"
          src="@/assets/logo.svg"
          class="logo"
        />
      </router-link>
      <router-link v-else to="/" custom v-slot="{ navigate }">
        <img
          @click="navigate"
          role="link"
          alt="main-logo"
          src="@/assets/logo-mobile.svg"
          class="logo-mobile"
        />
      </router-link>
      <div class="text">
        С помощью обновленного личного кабинета записаться стало гораздо проще!
      </div>
      <img alt="sub-logo" src="@/assets/subLogo.png" class="sub-logo" />
    </aside>
    <main
      class="content"
      :class="{
        registration: isRegistration,
        initial: isInitialPage,
        ['by-phone']: isPhoneAuth,
        base: isLoginAuth
      }"
    >
      <div class="container">
        <div
          v-if="
            this.$route.name !== 'Auth' && this.$route.name !== 'Registration'
          "
          class="go-back"
        >
          <div class="go-back__inner">
            <a class="link" @click="goBack"> <IconBack />Вернуться назад </a>
            <router-link to="/" custom v-slot="{ navigate }" class="link">
              <span @click="navigate" role="link" v-if="errorAuth.errorCode">
                <SmTooltip
                  :trigger="windowWidth >= 1024 ? 'mouseenter' : ''"
                  name="help"
                  :interactive="true"
                >
                  <template v-slot:trigger>
                    <HelpIcon />
                    Помощь
                  </template>
                  <template v-slot:tooltip>
                    <ErrorAuthTooltip />
                  </template>
                </SmTooltip>
              </span>
            </router-link>
          </div>
        </div>
        <router-view></router-view>
      </div>
    </main>
    <Footer />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import Footer from '@/components/footer'
import ErrorAuthTooltip from '@/components/errorAuthTooltip'
import SmTooltip from '@/components/tooltip'
import HelpIcon from '@/assets/icons/help'
import IconBack from '@/assets/icons/arrow-back'

export default {
  name: 'AuthLayout',
  components: {
    SmTooltip,
    HelpIcon,
    Footer,
    IconBack,
    ErrorAuthTooltip
  },
  methods: {
    goBack() {
      if (this.isPasswordChanged) {
        //TODO: Вернуть редирект
        // this.$router.push('/auth')
      } else {
        this.$router.go(-1)
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['errorAuth']),
    isRegistration() {
      return this.$route.name === 'Registration'
    },
    isPasswordChanged() {
      return this.$route.name === 'PasswordChanged'
    },
    isInitialPage() {
      return this.$route.name === 'Auth'
    },
    isPhoneAuth() {
      return this.$route.name === 'AuthByPhone'
    },
    isLoginAuth() {
      return this.$route.name === 'AuthByLogin'
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
