import Vue from 'vue'
import Router from 'vue-router'
import { routes } from './routes'
import {
  checkAccessMiddleware,
  checkPatientSignIn,
  initCurrentUserStateMiddleware,
  setPageTitleMiddleware,
  trackerRouteObserverMiddleware
} from '@/router/middlewares'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes
})

router.beforeEach(initCurrentUserStateMiddleware)
router.beforeEach(checkAccessMiddleware)
router.beforeEach(setPageTitleMiddleware)
router.beforeEach(checkPatientSignIn)
router.beforeEach(trackerRouteObserverMiddleware)

export default router
