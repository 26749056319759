export default {
  subwayList(state) {
    return state.list.data
  },
  subwaySingle(state) {
    return state.single.data
  },
  getListLoading(state) {
    return state.list.loading
  },
  getSingleLoading(state) {
    return state.single.loading
  }
}
