/*eslint-disable*/
<template>
  <section class="smMap">
    <div v-if="!this.clinicsListData || clinicsListError">
      <Loader :loading="getClinicsListLoading" :error="clinicsListError" />
    </div>
    <template v-else>
      <SmCrumbs />
      <div class="smMap__headline">
        <h2>Карта клиник</h2>
        <div class="buttons-container">
          <Location />
          <Button
            :classes="'smMap-btn'"
            icon="plus"
            :onClick="() => this.$router.push('/appointment')"
          >
            Записаться
          </Button>
        </div>
      </div>
      <Filters
        :options="filterOptions"
        :selectFilter="selectFilter"
        :clearFilter="clearFilter"
        :filterBlock="filterBlock"
        :clearable="false"
      />
      <div class="smMap__container" v-if="currCoords">
        <SmMapComponent
          :initMap="initMap"
          :markers="markers"
          :location="location"
          :markerClickHandler="showClinicInfo"
          :coords="currCoords"
        />
        <MapControls :zoom="updateZoom" :geolocation="toUserLocation" />
      </div>
      <AsideBlock
        :has-scroll="true"
        v-if="selectedClinic || subwayList.length"
        ref="AsideBlock"
      >
        <SelectedClinic
          ref="SelectedClinic"
          v-if="selectedClinic"
          :clinic="selectedClinic"
          :hasTitle="true"
          :close="clearSelectedClinic"
        />
        <Subways
          v-show="
            !selectedClinic && (!getClinicsListLoading || subwayList.length)
          "
          ref="subways"
          :items="subwayList"
          :changeHandler="subwayClickHandler"
        />
      </AsideBlock>
      <BottomPopup name="clinic-info" v-if="selectedClinic">
        <template v-slot:title>
          <div class="popup-header">
            <LocateIcon />
            {{ selectedClinic.clinicName }}
          </div>
        </template>
        <template v-slot:body>
          <div class="popup-body">
            <p class="popup-body__clinic-name">
              <LocateIcon class="icon" />
              <span>{{ selectedClinic.clinicName }}</span>
            </p>
            <template v-if="selectedClinic['subway-stations'].length">
              <p
                class="popup-body__subway"
                v-for="subway in selectedClinic['subway-stations']"
                :key="subway.id"
              >
                <SubwayIcon
                  v-if="subway.id"
                  :color="subway.color[0] ? `#${subway.color[0]}` : 'purple'"
                />
                {{ subway.title }}
              </p>
            </template>

            <p class="popup-body__address">{{ selectedClinic.address }}</p>
            <p
              class="popup-body__more"
              @click="() => showSlideoutPanel(selectedClinic)"
            >
              Подробнее
            </p>
          </div>
        </template>
      </BottomPopup>
    </template>
  </section>
</template>
<script>
/*eslint-disable*/
import Location from '@/components/asideMenu/location'
import Button from '@/components/button'
import Filters from '@/components/filters'
import { mapActions, mapGetters } from 'vuex'
import SmSlideSubways from '@/components/slideOutComponents/subways'
import AsideBlock from '@/components/mapComponents/asideBlock'
import Subways from '@/components/mapComponents/subways'
import SelectedClinic from '@/components/mapComponents/selectedClinic'
import BottomPopup from '@/components/bottomPopup'
import LocateIcon from '@/assets/icons/locate'
import SubwayIcon from '@/assets/icons/subway'
import SmMapComponent from '@/components/mapComponents/map'
import MapControls from '@/components/mapComponents/mapControls'
import _ from 'lodash'
import SmCrumbs from '@/components/breadcrumbs'
import Loader from '@/components/loader'
import { TRACKER_SLUGS } from '@/models/tracker'

export default {
  name: 'SmMapPage',
  components: {
    SmCrumbs,
    MapControls,
    SmMapComponent,
    SubwayIcon,
    LocateIcon,
    BottomPopup,
    SelectedClinic,
    Subways,
    AsideBlock,
    Filters,
    Location,
    Button,
    Loader
  },
  watch: {
    windowWidth: {
      handler(val) {
        this.subwayFilterHandler(val)
      }
    },
    markers(v) {
      if (v && v.length) this.map?.setCenter([v[0].latitude, v[0].longitude])
    },
    selectedCityData: {
      handler(newVal, oldVal) {
        if (
          (oldVal === undefined && newVal) ||
          (newVal && this.currCoords === null)
        ) {
          const { latitude, longitude } = newVal
          if (latitude && longitude) {
            this.currCoords = [latitude, longitude]
          }
        }
      }
    }
  },
  data() {
    return {
      currCoords: null,
      filterBlock: false,
      map: null,
      selectedClinic: null,
      clearSubwayFilter: null,
      location: null,
      filters: {
        subwayId: null,
        clinicDirectionIds: [],
        specialityId: null
      },
      initialOptions: [
        {
          id: 'all',
          items: [],
          label: 'Все клиники',
          isSelected: true
        }
      ]
    }
  },
  async created() {
    if (this.selectedCityData && !this.currCoords) {
      const { latitude, longitude } = this.selectedCityData
      if (latitude && longitude) {
        this.currCoords = [latitude, longitude]
      }
    }
    await this.getClinics({ size: 100 })
    await this.getDirections()
    this.subwayFilterHandler(this.windowWidth)
  },

  updated() {
    if (this.$refs.SelectedClinic) {
      this.$refs.AsideBlock.$el.scrollTo({
        top: this.$refs.SelectedClinic.$el.offsetTop - 100,
        behavior: 'smooth'
      })
    }
  },

  methods: {
    ...mapActions('clinics', [
      'getClinics',
      'getDirections',
      'getClinicById',
      'getFilteredClinic'
    ]),
    clearSelectedClinic() {
      this.selectedClinic = null
    },
    getFilteredData(payload) {
      const filters = _(this.filters).omitBy(_.isNull).value()
      if (!filters.clinicDirectionIds?.length) {
        delete filters.clinicDirectionIds
      }
      this.getFilteredClinic({
        ...filters,
        ...payload
      })
    },
    async subwayClickHandler(payload, cb) {
      this.clearSubwayFilter = cb
      if (payload.subwayId) {
        this.filters.subwayId = payload.subwayId
        this.getFilteredData(payload)
      } else {
        this.filters.subwayId = null
        this.getFilteredData()
      }
    },
    initMap(map) {
      this.map = map
    },
    updateZoom(zoomIn) {
      const zoom = this.map.getZoom()
      if (zoomIn) {
        this.map.setZoom(zoom + 1)
      } else {
        this.map.setZoom(zoom - 1)
      }
    },
    toUserLocation() {
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          this.location = this.currCoords = [coords.latitude, coords.longitude]
        },
        (error) => {
          console.log(error)
        }
      )
    },
    selectFilter(properties) {
      console.log('selectFilter', properties)
      this.filterBlock = true
      this.clearSelectedClinic()
      this.$refs.subways?.clearData?.()
      this.filters.subwayId = null
      if (properties.id !== 'subway') {
        this.filterOptions = this.filterOptions.map((filter) => {
          if (filter.id === 'subway') {
            return { ...filter, isSelected: false, value: 'Метро' }
          } else {
            return filter
          }
        })
      }
      if (properties.id === 'all' && !properties.isSelected) {
        this.filters = {
          subwayId: null,
          clinicDirectionIds: [],
          specialityId: null
        }
        this.getClinics({})
      } else if (properties.id !== 'subway') {
        this.filters.clinicDirectionIds = [properties.id]
        this.getFilteredData()
      }
      this.filterBlock = false
      if (properties.items.length) {
        this.$showPanel({
          component: 'SmSlideoutPanel',
          props: {
            title: properties.title,
            properties,
            component: SmSlideSubways
          },
          openOn: 'right',
          width: '400',
          height: '80',
          sync: true,
          keepAlive: false
        }).promise.then((result) => {
          if (result?.closedBy) return
          if (result) {
            this.subwayClickHandler(
              { subwayId: result.id },
              this.$refs.subways.clearData
            )
            this.initialOptions = this.initialOptions.map((filter) =>
              filter.id === properties.id
                ? { ...filter, isSelected: true, value: result.title }
                : filter
            )
          }
        })
      } else {
        this.filterOptions = this.filterOptions.map((filter) => {
          if (filter.id !== 'subway') {
            return filter.id === properties.id
              ? { ...filter, isSelected: true, value: properties.value }
              : { ...filter, isSelected: false }
          } else {
            return filter
          }
        })
      }
    },
    clearFilter(properties) {
      if (properties.id === 'subway') {
        this.$showPanel({
          component: 'SmSlideoutPanel',
          props: {
            title: properties.title,
            properties,
            component: SmSlideSubways
          },
          openOn: 'right',
          width: '400',
          height: '80',
          sync: true,
          keepAlive: false
        }).promise.then((result) => {
          if (result?.closedBy) return
          if (result) {
            this.subwayClickHandler(
              { subwayId: result.id },
              this.$refs.subways.clearData
            )
            this.initialOptions = this.initialOptions.map((filter) =>
              filter.id === properties.id
                ? { ...filter, isSelected: true, value: result.title }
                : filter
            )
          }
        })
      }
    },
    showPopup() {
      this.$modals.show('clinic-info')
    },
    showSlideoutPanel(item) {
      this.$modals.hide('clinic-info')
      this.$showPanel({
        component: 'SmSlideoutPanel',
        props: {
          title: item.clinicName,
          component: SelectedClinic,
          properties: { clinic: item, hasTitle: false }
        },
        openOn: 'right',
        sync: true,
        keepAlive: false
      })
    },
    async showClinicInfo(item) {
      await this.getClinicById({ id: item.id, withSubway: true })
      this.coords = [item.longitude, item.latitude]
      this.selectedClinic = this.clinicsSingleData
      this.$tracker(TRACKER_SLUGS.CLINIC_FIND)
      if (this.windowWidth < 1440 && this.windowWidth >= 1024) {
        this.showSlideoutPanel(this.selectedClinic)
      }

      if (this.windowWidth < 1024) {
        this.showPopup()
      }
    },
    subwayFilterHandler(width) {
      if (this.subwayList.length) {
        if (width >= 1366) {
          this.initialOptions = this.initialOptions.filter(
            (el) => el.id !== 'subway'
          )
        } else {
          if (!this.initialOptions.find((el) => el.id === 'subway')) {
            this.initialOptions.unshift({
              id: 'subway',
              items: this.subwayList,
              label: 'Метро',
              title: 'Выберите станцию метро',
              isSelected: false
            })
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters('clinics', [
      'clinicsListData',
      'getClinicsListLoading',
      'getClinicDirections',
      'clinicsSingleData',
      'clinicsListError'
    ]),
    ...mapGetters('cities', ['selectedCity', 'selectedCityData']),
    markers() {
      return this.clinicsListData?.clinics?.content
    },
    subwayList() {
      return this.clinicsListData?.subwayStations
        ? Object.values(this.clinicsListData?.subwayStations)
        : []
    },
    filterOptions: {
      get() {
        return this.getClinicDirections
          ? _.unionBy(
              this.initialOptions,
              this.getClinicDirections.map((direction) => ({
                ...direction,
                label: direction.title,
                isSelected: false,
                items: []
              })),
              'id'
            )
          : []
      },
      set(val) {
        this.initialOptions = val
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import 'style';
</style>
