import axios, { Axios } from 'axios'
import qs from 'qs'
import $store from '@/store'

const apiVersion = 'v020'
export const baseURL =
  process.env.NODE_ENV === 'development'
    ? `/api/${apiVersion}`
    : `${process.env.VUE_APP_API_BASE_URL}/api/${apiVersion}`
export class apiV1 extends Axios {
  constructor(status) {
    super()
    this.isAuth = status && status.auth ? status.auth : false
    this.instance = axios.create({
      baseURL
    })
    return this.init()
  }

  init() {
    if (this.isAuth) {
      this.instance.interceptors.request.use(
        (request) => {
          const accessToken = $store.state.auth.token.val
          // const hasRefreshToken = !!localStorage.getItem('refreshToken')

          request.headers.authorization = `Bearer ${accessToken}`
          request.paramsSerializer = (params) =>
            qs.stringify(params, { allowDots: true, skipNulls: true })

          // if ($store.getters['auth/isAccessTokenExpired'] && hasRefreshToken) {
          //   return $store
          //     .dispatch('auth/refreshToken')
          //     .then((response) => {
          //       request.headers.authorization = `Bearer ${response.token}`
          //       return request
          //     })
          //     .catch((error) => Promise.reject(error))
          // } else {
          //   return request
          // }
          return request
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    }

    return this.instance
  }
}
