<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.375 2.25C13.3118 2.25 16.5 5.43822 16.5 9.375C16.5 13.3118 13.3118 16.5 9.375 16.5C5.43822 16.5 2.25 13.3118 2.25 9.375C2.25 5.43822 5.43822 2.25 9.375 2.25Z"
      stroke="white"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15 15L18.125 18.125"
      stroke="white"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  name: 'SearchIcon'
}
</script>
