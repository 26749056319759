<template>
  <div>
    <DP
      :popup-style="{ top: '64px', left: 0 }"
      :append-to-body="false"
      class="cm-datepicker"
      v-model="propModel"
      format="DD.MM.YYYY"
      valueType="format"
      @change="changeHandler"
      :open.sync="open"
    >
      <div slot="input">
        <SmInput
          :invalid="invalid"
          placeholder="дд.мм.гггг"
          :value="propModel"
          :onChange="(e) => changeHandler(e.target.value)"
          v-mask="['##.##.####']"
          :keyupHandler="(e) => keyupHandler(e)"
        />
      </div>
      <i slot="icon-calendar">
        <CalendarIcon v-if="!invalid" />
      </i>
    </DP>
  </div>
</template>
<script>
import DP from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import ru from 'vue2-datepicker/locale/ru'
import CalendarIcon from '@/assets/icons/calendar'
import SmInput from '@/components/input'
import { mask } from 'vue-the-mask'

const defaultLang = {
  yearFormat: 'YYYY',
  monthFormat: 'MMMM'
}

Object.assign(ru, defaultLang)
DP.locale('ru', ru)

export default {
  name: 'SmDatepicker',
  components: { SmInput, CalendarIcon, DP },
  directives: { mask },
  props: {
    onChange: {
      type: Function,
      default: () => {}
    },
    value: String,
    invalid: Boolean
  },
  methods: {
    changeHandler(e) {
      this.onChange(e)
    },
    keyupHandler(e) {
      this.changeHandler(e.target.value)
      if (e.key === 'Enter') {
        this.open = false
      }
    }
  },
  data() {
    return {
      open: false
    }
  },
  computed: {
    propModel: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('update:prop', value)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
