var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"appointment specializations"},[_c('SmCrumbs'),_c('h2',{staticClass:"appointment__title"},[_vm._v("Новая запись")]),_c('div',{staticClass:"find-doctors"},[_c('SearchInput',{attrs:{"classes":'search-input',"placeholder":"Специализация","onChange":_vm.inputChangeHandler}}),(_vm.windowWidth >= 1024)?_c('p',{staticClass:"find-doctors__text"},[_vm._v(" Найдите нужную специальность врача ")]):_c('p',{staticClass:"find-doctors__text"},[_vm._v(" Найдите нужную специальность врача ")])],1),_c('div',{staticClass:"choose-doctors-container"},[(_vm.prevSpecItems && _vm.prevSpecItems.length)?_c('div',{staticClass:"buttons-wrapper"},[_c('p',[_vm._v("Последние обращения:")]),_c('div',{staticClass:"buttons-inner"},_vm._l((_vm.prevSpecItems),function(item){return _c('Button',{key:item.id,attrs:{"secondary":"","classes":"search-buttons","onClick":function () {
              _vm.$router.push({
                path: ("/appointment/" + (item.id)),
                query: _vm.$route.query.clinicId
                  ? { clinicId: _vm.$route.query.clinicId }
                  : ''
              })
            }}},[_vm._v(" "+_vm._s(item.title)+" ")])}),1)]):_vm._e(),_c('div',{staticClass:"choose-doctors-area"},[(_vm.specializationsData.loading)?[_c('div',[_c('Loader',{attrs:{"loading":_vm.specializationsData.loading,"error":_vm.specializationsData.error}})],1)]:[_c('h3',{staticClass:"choose-doctors-title"},[_vm._v("Выберите специалиста:")]),(_vm.specializations.length)?_c('div',{staticClass:"choose-doctors-wrapper"},_vm._l((_vm.specializations),function(specializations,index){return _c('div',{key:index,staticClass:"specializations-list"},[_c('h4',[_vm._v(_vm._s(specializations.letter))]),_vm._l((specializations.specializationsByLetters),function(specialization,index){return _c('p',{key:index,on:{"click":function($event){return _vm.chooseSpecialization(specialization)}}},[_vm._v(" "+_vm._s(specialization.title)+" ")])})],2)}),0):_c('div',[_vm._v("Ничего не найдено")])]],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }