export default {
  single: {
    data: null,
    error: null,
    loading: false
  },
  list: {
    data: null,
    error: null,
    loading: false,
    pagination: {
      totalPages: 1,
      size: 5
    }
  }
}
