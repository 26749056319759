export default {
  timeslotsData(state) {
    return state.data
  },
  doctorsFilters(state) {
    return state.doctorsFilter?.doctors || []
  },
  clinicsFilters(state) {
    return state.doctorsFilter?.clinics || []
  },
  timeslotsLoading(state) {
    return state.loading
  },
  timeslotsError(state) {
    return state.error
  },
  filterClinics(state) {
    return Object.values(state.data?.clinics)
  },
  paginationInfo(state) {
    return {
      totalPages: state.data?.totalPages || 0,
      size: state.data?.size || 0
    }
  }
}
