<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 1.25V2H13C14.6569 2 16 3.34315 16 5V14C16 15.6569 14.6569 17 13 17H3C1.34315 17 0 15.6569 0 14V5C0 3.34315 1.34315 2 3 2H4.04545V1.25C4.04545 0.973875 4.5 0.75 5 0.75C5.5 0.75 6 0.973875 6 1.25V2H10V1.25C10 0.973875 10.5 0.75 11 0.75C11.5 0.75 12 0.973875 12 1.25ZM1.95455 6.81818C1.6784 6.81818 1.45455 7.04204 1.45455 7.31818V13.5455C1.45455 14.65 2.34998 15.5455 3.45455 15.5455H12.5455C13.65 15.5455 14.5455 14.65 14.5455 13.5455V7.31818C14.5455 7.04204 14.3216 6.81818 14.0455 6.81818H1.95455Z"
      fill="url(#paint0_linear_2213_4803)"
    />
    <path
      d="M8.72798 9H7.27344V10.4545H8.72798V9Z"
      fill="url(#paint1_linear_2213_4803)"
    />
    <path
      d="M5.09126 9H3.63672V10.4545H5.09126V9Z"
      fill="url(#paint2_linear_2213_4803)"
    />
    <path
      d="M5.09126 11.9092H3.63672V13.3637H5.09126V11.9092Z"
      fill="url(#paint3_linear_2213_4803)"
    />
    <path
      d="M8.72798 11.9092H7.27344V13.3637H8.72798V11.9092Z"
      fill="url(#paint4_linear_2213_4803)"
    />
    <path
      d="M12.3647 11.9092H10.9102V13.3637H12.3647V11.9092Z"
      fill="url(#paint5_linear_2213_4803)"
    />
    <path
      d="M12.3647 9H10.9102V10.4545H12.3647V9Z"
      fill="url(#paint6_linear_2213_4803)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2213_4803"
        x1="0.909621"
        y1="25.1839"
        x2="22.1542"
        y2="13.7244"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2213_4803"
        x1="7.35613"
        y1="11.1871"
        x2="9.27384"
        y2="10.1365"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2213_4803"
        x1="3.71941"
        y1="11.1871"
        x2="5.63712"
        y2="10.1365"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2213_4803"
        x1="3.71941"
        y1="14.0963"
        x2="5.63712"
        y2="13.0457"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2213_4803"
        x1="7.35613"
        y1="14.0963"
        x2="9.27384"
        y2="13.0457"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_2213_4803"
        x1="10.9928"
        y1="14.0963"
        x2="12.9106"
        y2="13.0457"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_2213_4803"
        x1="10.9928"
        y1="11.1871"
        x2="12.9106"
        y2="10.1365"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'CalendarIcon'
}
</script>
