import { apiV1 } from '@/config/request'
import _ from 'lodash'
export default {
  async getSpecializations({ commit }) {
    commit('setLoadingSpecialization')
    try {
      const { data } = await new apiV1({ auth: true }).get('/specialities/')
      commit('setSpecializationData', data?.specialities ?? [])
      commit('setLoadingSpecialization')
    } catch (e) {
      commit('setLoadingSpecialization')
    }
  },
  async getSpecializationsById({ commit }, specId) {
    commit('setLoadingSpecialization')
    try {
      const { data } = await new apiV1({ auth: true }).get(
        `/specialities/${specId}`
      )
      commit('setSpecializationData', data?.speciality ?? [])
      commit('setLoadingSpecialization')
    } catch (e) {
      commit('setLoadingSpecialization')
    }
  },
  async getAvailableSpecializations(
    { commit, rootState },
    {
      cityId = rootState.cities.selected,
      birthday = rootState.account.patient.birthday,
      clinicId
    }
  ) {
    commit('setLoadingSpecialization')
    try {
      const { data } = await new apiV1({ auth: true }).get(
        `/specialities/${clinicId ? 'by-clinic' : 'available'}`,
        {
          params: clinicId ? { clinicId } : { cityId, birthday }
        }
      )
      commit('setSpecializationData', data?.specialities ?? [])
      commit('setLoadingSpecialization')
    } catch (e) {
      commit('setLoadingSpecialization')
    }
  },
  async getLastSpecializations({ rootState }) {
    const { data } = await new apiV1({ auth: true }).get(
      `/bx/specialities/getLast`,
      {
        params: {
          cityId: rootState.cities.selected,
          birthday: rootState.account.patient.birthday,
          patientId: rootState.account.patient.id,
          accountId: rootState.account.account.id,
          isArray: true
        }
      }
    )
    return data
  },
  async getUpcomingAppointments({ commit }) {
    commit('setLoadingAppointments')
    try {
      const { data } = await new apiV1({ auth: true }).get(
        '/appointments/upcoming'
      )
      commit('setAppointmentsData', data?.appointments ?? [])
      commit('setLoadingAppointments')
    } catch (e) {
      commit('setLoadingAppointments')
    }
  },
  async getAppointments({ commit }, { accountId, cityId, ...payload }) {
    commit('setLoadingAppointments')
    try {
      const { data } = await new apiV1({ auth: true }).post(
        '/appointments/',
        payload,
        {
          params: {
            accountId,
            cityId
          }
        }
      )
      commit('setNewCreatedAppointment', data)
      commit('setLoadingAppointments')
    } catch (e) {
      commit('setLoadingAppointments')
    }
  },
  async getAppointmentById({ commit }, { id, accountId }) {
    commit('setLoadingSingleAppointment')
    try {
      const {
        data: { appointment }
      } = await new apiV1({ auth: true }).get(`/appointments/${id}`, {
        params: {
          accountId
        }
      })
      commit('setSingleAppointmentData', appointment)
      commit('setLoadingSingleAppointment')
    } catch (e) {
      commit('setLoadingSingleAppointment')
    }
  },
  async getPrice(_, { clinicId, doctorId, specialityId }) {
    try {
      const { data } = await new apiV1({ auth: true }).get(`/prices/find`, {
        params: { clinicId, doctorId, specialityId }
      })
      return data
    } catch (e) {
      console.log(e)
    }
  },
  async createAppointment(
    { commit, rootState },
    {
      accountId = rootState.account.account.id,
      cityId = rootState.cities.selected,
      ...payload
    }
  ) {
    commit('setCreateAppointmentLoading')
    try {
      const {
        data: { errorCode, description }
      } = await new apiV1({ auth: true }).post(`appointments/`, payload, {
        params: {
          accountId,
          cityId
        }
      })
      if (errorCode) {
        commit('setError', errorCode)
        commit('setErrorMsg', description)
      }
      commit('setCreateAppointmentLoading')
    } catch (e) {
      commit('setCreateAppointmentLoading')
    }
  },
  async cancelAppointment({ commit, rootState }, id) {
    commit('setCancelAppointmentLoading')
    try {
      const data = await new apiV1({ auth: true }).delete(
        `appointments/${id}`,
        {
          params: { accountId: rootState.account.account.id }
        }
      )
      commit('setCancelAppointmentLoading')
      return data
    } catch (e) {
      commit('setCancelAppointmentLoading')
      console.log(e)
    }
  },
  async updateAppointment({ commit, rootState }, payload) {
    commit('setCreateAppointmentLoading')
    try {
      const {
        data: { errorCode }
      } = await new apiV1({ auth: true }).patch(
        `appointments/${payload.id}`,
        payload,
        {
          params: {
            accountId: rootState.account.account.id,
            cityId: rootState.cities.selected
          }
        }
      )
      if (errorCode) {
        commit('setError', errorCode)
      }
      commit('setCreateAppointmentLoading')
    } catch (e) {
      commit('setCreateAppointmentLoading')
    }
  },
  updatePrevSpec({ commit, state }, payload) {
    let result = [...state.prevSpec]
    if (result.length >= 3) {
      result = _.unionBy(result, [payload], 'id').slice(-3)
    } else {
      result = _.unionBy(result, [payload], 'id')
    }

    commit('setPrevSpec', result)
  }
}
