const AnalysisTypes = {
  READY: 'Готов',
  AT_WORK: 'В работе',
  SCHEDULED: 'Назначен',
  PARTIALLY_COMPLETED: 'Частично выполнен'
}
const PaymentStatusTypes = {
  PAID: 'Оплачен',
  NOT_PAID: 'Не оплачен'
}

const alphabet = [
  'А',
  'Б',
  'В',
  'Г',
  'Д',
  'Е',
  'Ж',
  'З',
  'И',
  'К',
  'Л',
  'М',
  'Н',
  'О',
  'П',
  'Р',
  'С',
  'Т',
  'У',
  'Ф',
  'Х',
  'Ц',
  'Ч',
  'Ш',
  'Щ',
  'Э',
  'Ю',
  'Я'
]

// Days
export const APPOINTMENT_DEFAULT_TO = 3

export { AnalysisTypes, PaymentStatusTypes, alphabet }
