import { apiV1 } from '@/config/request'

export default {
  async getBiomateriaslByPatient(
    { commit, rootSate },
    {
      page,
      size,
      accountId = rootSate.account.account.id,
      patientId = rootSate.account.patient.id
    }
  ) {
    commit('setBiomaterialsLoading')
    try {
      const { data } = await new apiV1({ auth: true }).get(
        `/biomaterials/by-patient`,
        { params: { page, size, accountId, patientId } }
      )
      commit('setBiomaterialsData', data)
      commit('setBiomaterialsLoading')
    } catch (e) {
      commit('setListBiomaterialsError', `${e}`)
      commit('setBiomaterialsLoading')
    }
  },
  async getBiomaterialById({ commit }, id) {
    commit('setSingleBiomaterialLoading')
    try {
      const {
        data: { biomaterial }
      } = await new apiV1({ auth: true }).get(`/biomaterials/${id}`)
      commit('setBiomaterialById', biomaterial)
      commit('setSingleBiomaterialLoading')
    } catch (e) {
      commit('setSingleBiomaterialError', `${e}`)
      commit('setSingleBiomaterialLoading')
    }
  }
}
