<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5 15.75L12 8.25L19.5 15.75"
      stroke="url(#paint0_linear_2443_85332)"
      stroke-width="3"
      stroke-linecap="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2443_85332"
        x1="27.0543"
        y1="15.3236"
        x2="23.7787"
        y2="3.36499"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ArrowUpIcon'
}
</script>
