<template>
  <article class="empty-data">
    <h3 class="empty-data__title">{{ title }}</h3>
    <div class="empty-data__content">
      <p>Запишитесь на приём к высококвалифированным специалистам клиники</p>
      <img alt="hand" src="@/assets/images/hand-up.png" />
    </div>
  </article>
</template>
<script>
export default {
  name: 'EmptyData',
  props: {
    title: String
  },
  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
