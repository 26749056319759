import state from './citiesState'
import mutations from './citiesMutations'
import getters from './citiesGetters'
import actions from './citiesActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
