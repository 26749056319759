<template>
  <div class="auth-form">
    <h2 class="auth-form__title">Восстановление доступа</h2>
    <form class="auth-form__inner" @submit.prevent="passwdRecovery">
      <p class="auth-form__description">
        Для восстановления пароля, укажите ваш телефон. Вам придет код
        подтверждения. После успешного ввода, вам придет смс с логином и
        паролем.
      </p>
      <SmInput
        type="phone"
        id="phone"
        placeholder="Введите ваш номер телефона"
        :value="phone"
        :onChange="(e) => (this.phone = e.target.value)"
        :classes="'auth-input'"
        :onClick="() => (this.phone = '+7 ')"
        v-mask="['+7 (###) ###-##-##']"
        :invalid="validateState('phone')"
      />
      <div class="auth-form__captcha" v-show="showCaptcha">
        <vue-recaptcha ref="recaptcha" :sitekey="sitekey" @verify="register" />
      </div>
      <Button
        :loading="getLoading"
        :classes="'auth-form__btn'"
        icon="arrow"
        :disabled="getLoading || (showCaptcha && !successCaptcha)"
      >
        Продолжить
      </Button>
      <div class="has-error" v-if="errorAuth.message && !showCaptcha">
        <ErrorToast v-if="errorAuth" :text="errorAuth.message" />
      </div>
      <div class="has-error" v-if="this.$v.$invalid && this.$v.$anyError">
        <ErrorToast v-if="this.$v.$invalid" :text="'Обязательное поле'" />
      </div>
    </form>
  </div>
</template>
<script>
import Button from '@/components/button'
import SmInput from '@/components/input'
import { mask } from 'vue-the-mask'
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import ErrorToast from '@/components/errorToast'
import { VueRecaptcha } from 'vue-recaptcha'

export default {
  name: 'PasswdRecovery',
  components: { SmInput, Button, ErrorToast, VueRecaptcha },
  directives: { mask },
  data() {
    return {
      phone: '',
      sitekey: '6Lfm8kckAAAAAIF3HC1MEiJQbRWSW2IhBW_b-g0c',
      successCaptcha: false
    }
  },
  validations: {
    phone: { required }
  },
  methods: {
    ...mapActions('auth', ['passwordRecovery', 'clearAuthError']),
    register(recaptchaToken) {
      this.successCaptcha = true
      const isBack = !!this.$route.query.back
      this.passwordRecovery({
        isBack,
        data: { phone: this.phone, birthday: undefined, recaptchaToken }
      })
    },
    passwdRecovery() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const isBack = !!this.$route.query.back
        this.passwordRecovery({
          isBack,
          data: { phone: this.phone, birthday: undefined }
        })
      }
    },
    validateState(name) {
      const { $dirty, $error, $model, $invalid } = this.$v[name]
      if ($model === null) return null
      if (!$model.length && !$invalid) return null
      return $dirty ? $error : null
    }
  },
  computed: {
    ...mapGetters('auth', ['getLoading', 'errorAuth']),
    showCaptcha() {
      return (
        this.errorAuth?.errorCode && this.errorAuth?.errorCode === 'needCaptcha'
      )
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.clearAuthError()
    })
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
.auth-form__captcha {
  margin-bottom: 1rem;
}
</style>
