<template>
  <div class="auth-form error">
    <p class="auth-form__title">Вы-пациент «СМ-Клиника»</p>
    <div class="auth-form__inner">
      <p class="auth-form__description">
        Мы не можем вас идентифицировать только по номеру телефона. Для
        <template v-if="isRecovery">продолжения</template
        ><template v-else>авторизации</template> укажите
        <template v-if="isDate">дату рождения</template
        ><template v-else>дополнительные данные</template>.
      </p>
      <div v-if="isDate" class="registration-inputs">
        <p>Дата рождения</p>
        <SmDatepicker
          :invalid="validateState('birthday')"
          :onChange="onDateChange"
          :value="birthday"
        />
      </div>
      <div v-else class="registration-inputs">
        <p>Ваше имя</p>
        <SmInput
          type="text"
          id="firstname"
          placeholder="Например, Александр"
          :value="this.name"
          :onChange="(e) => (this.name = e.target.value)"
          :classes="'auth-input'"
          :invalid="validateState('name')"
        />
      </div>
      <Button
        :classes="'auth-form__btn'"
        icon="arrow"
        :loading="getLoading"
        :disabled="getLoading"
        :onClick="auth"
      >
        <template v-if="isRecovery">Восстановить </template>
        <template v-else>Войти</template>
      </Button>
      <div class="has-error" v-if="errorAuth.message">
        <ErrorToast v-if="errorAuth" :text="errorAuth.message" />
      </div>
      <div class="date-error" v-if="this.$v.$invalid && this.$v.$anyError">
        <ErrorToast
          v-if="
            this.$v[isDate ? 'birthday' : 'name'].$dirty &&
            this.$v[isDate ? 'birthday' : 'name'].$invalid
          "
          :text="'Обязательное поле'"
        />
      </div>
      <template v-if="!isRecovery">
        <p class="sub-description">или вы можете войти с логином и паролем</p>
        <Button
          :classes="['auth-form__btn']"
          icon="key"
          secondary
          :loading="getLoading"
          :disabled="getLoading"
          :onClick="goToLoginAuth"
        >
          Войти с логином и паролем
        </Button>
      </template>
    </div>
  </div>
</template>
<script>
import SmDatepicker from '@/components/datepicker'
import Button from '@/components/button'
import SmInput from '@/components/input'
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import ErrorToast from '@/components/errorToast'
import { formatRFC3339 } from 'date-fns'
import { parse } from 'date-fns'

export default {
  name: 'AuthExtra',
  components: { SmInput, Button, SmDatepicker, ErrorToast },
  mounted() {
    if (!(this.getPhoneSignIn.phone || this.getRecoveryPhone)) {
      //TODO: Вернуть редирект
      // this.$router.push('/auth')
    }
  },
  data() {
    return {
      name: '',
      birthday: ''
    }
  },
  watch: {
    errorAuth(v) {
      if (v.errorCode) {
        this.name = ''
        this.birthday = ''
        this.$v.$reset()
      }
    }
  },
  validations() {
    if (this.isDate) {
      return { birthday: { required } }
    } else {
      return { name: { required } }
    }
  },
  computed: {
    ...mapGetters('auth', [
      'getLoading',
      'errorAuth',
      'getPhoneSignIn',
      'getRecoveryPhone'
    ]),
    isDate() {
      return this.$route.meta.isDate
    },
    isRecovery() {
      return this.$route.meta.isRecovery
    }
  },
  methods: {
    ...mapActions('auth', [
      'confirmSmsCode',
      'clearAuthError',
      'setPhoneSignInData',
      'passwordRecovery'
    ]),
    goToLoginAuth() {
      this.$router.push('/auth/login')
    },
    onDateChange(val) {
      if (val === null) {
        this.$v.birthday.$reset()
      }
      this.birthday = val
    },

    auth() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const date = this.birthday.split('.')
        const data = {
          ...(this.isDate
            ? {
                birthday: formatRFC3339(
                  parse(
                    `${date[1]}.${date[0]}.${date[2]}`,
                    'MM.dd.yyyy',
                    new Date()
                  )
                )
              }
            : { name: this.name })
        }
        this.isRecovery
          ? this.passwordRecovery({ data })
          : this.confirmSmsCode({ payload: data })
      }
    },
    validateState(name) {
      const { $dirty, $error, $model, $invalid } = this.$v[name]
      if ($model === null) return null
      if (!$model.length && !$invalid) return null
      return $dirty ? $error : null
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.clearAuthError()
      vm.setPhoneSignInData({
        [vm.isDate ? 'birthday' : 'name']: ''
      })
    })
  },
  beforeRouteLeave(to, from, next) {
    next()
    this.clearAuthError()
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
.auth-form__title {
  white-space: nowrap;
}
</style>
