export default {
  setLoadingSpecialization(state) {
    state.specializations.loading = !state.specializations.loading
  },
  setLoadingAppointments(state) {
    state.list.loading = !state.list.loading
  },
  setLoadingSingleAppointment(state) {
    state.single.loading = !state.single.loading
  },
  setAppointmentsData(state, payload) {
    state.list.data = payload.list.data
  },
  setSingleAppointmentData(state, payload) {
    state.single.data = payload
  },
  setCancelAppointmentLoading(state) {
    state.cancelLoading = !state.cancelLoading
  },
  setCreateAppointmentLoading(state) {
    state.createLoading = !state.createLoading
  },
  setError(state, payload) {
    state.errorCode = payload
  },
  setErrorMsg(state, payload) {
    state.errorMsg = payload
  },
  setSpecializationData(state, payload) {
    state.specializations.data = payload
  },
  setPrevSpec(state, payload) {
    state.prevSpec = payload
  }
}
