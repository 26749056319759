export default {
  list: {
    data: null,
    loading: false,
    error: null,
    pagination: {
      totalPages: 0,
      size: 5
    }
  },
  single: {
    data: null,
    loading: false,
    error: null
  }
}
