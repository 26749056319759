export default {
  list: {
    data: null,
    error: null,
    loading: false,
    pagination: {
      totalPages: 1,
      size: 5
    }
  },
  filters: {
    data: null
  },
  single: {
    data: null,
    error: null,
    loading: false
  },
  sortingValues: {}
}
