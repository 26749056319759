<template>
  <button
    :class="[
      'btn',
      secondary ? 'btn-secondary' : 'btn-primary',
      { [icon]: icon && !loading },
      classes
    ]"
    @click.stop="onClick"
    :disabled="disabled || loading"
  >
    <slot />
    <Preloader
      :class="['preloader', secondary ? 'light' : 'dark']"
      v-if="loading"
    />
  </button>
</template>
<script>
import Preloader from '@/assets/icons/preloader'

export default {
  name: 'Button',
  props: {
    onClick: {
      type: Function,
      default: () => {}
    },
    classes: [String, Array],
    disabled: Boolean,
    secondary: {
      type: Boolean,
      default: false
    },
    icon: String,
    loading: { type: Boolean, default: false }
  },
  components: { Preloader }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
