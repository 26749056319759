<template>
  <div class="auth-form">
    <p class="auth-form__title">Аккаунт удален</p>
    <div class="auth-form__inner">
      <p class="auth-form__description">
        Нет доступа в ЛК, обратитесь в регистратуру.
      </p>
      <p class="auth-form__description">
        Чтобы записаться к врачу, позвоните по телефону
        <span class="phone">+7 495 154 10 82</span>
        <span>
          или
          <span class="phone cursor-pointer" @click="openWebim"
            >обратитесь в чат</span
          ></span
        >
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AuthDeleted',
  methods: {
    openWebim() {
      window.webim.api.chat.start()
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
