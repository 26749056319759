export default {
  single: {
    data: null,
    error: null,
    loading: false
  },
  list: {
    data: null,
    error: null,
    loading: false
  },
  selected: null,
  defaultCity: 'dd89b529-46c7-11e4-80c5-002590ac1d8d'
}
