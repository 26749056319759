<template>
  <div class="subways">
    <div
      class="subways__item"
      :class="{ active }"
      v-for="{ title, id, active, color } of subways"
      :key="id"
      @click="() => clickHandler(id)"
    >
      <SubwayIcon
        :color="active ? '#fff' : color.length ? `#${color[0]}` : 'purple'"
      />
      <p class="subways__title">{{ title }}</p>
    </div>
  </div>
</template>
<script>
import SubwayIcon from '@/assets/icons/subway'

export default {
  name: 'Subways',
  data() {
    return {
      activeSubway: ''
    }
  },
  props: {
    items: Array,
    changeHandler: {
      type: Function,
      default: () => {}
    }
  },
  components: {
    SubwayIcon
  },
  computed: {
    subways() {
      return this.items.map((el) => ({
        ...el,
        active: el.id === this.activeSubway
      }))
    }
  },
  mounted() {
    this.clearData()
  },
  methods: {
    clickHandler(id) {
      if (this.activeSubway === id) {
        this.activeSubway = ''
      } else {
        this.activeSubway = id
      }
      this.changeHandler(
        { subwayId: this.activeSubway ? id : null },
        this.clearData
      )
    },
    clearData() {
      this.activeSubway = ''
    }
  }
}
</script>
<style scoped lang="scss">
@import 'style';
</style>
