export default {
  doctorsListData(state) {
    return state.list.data
  },
  doctorsSingleData(state) {
    return state.single.data
  },
  doctorSingleError(state) {
    return state.single.error
  },
  doctorSingleLoading(state) {
    return state.single.loading
  },
  doctorListLoading(state) {
    return state.list.loading
  },
  doctorListError(state) {
    return state.list.error
  },
  paginationInfo(state) {
    return {
      totalPages: state.list.pagination.totalPages,
      size: state.list.pagination.size
    }
  }
}
