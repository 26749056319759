<template>
  <div class="slideout-block">
    <div v-if="properties.loading">
      <Loader :loading="properties.loading" />
    </div>
    <template v-else>
      <div
        v-for="item of sortingitems"
        class="slideout-block__button"
        @click="() => clickHandler(item)"
        :key="item.id"
      >
        <div class="slideout-block__inner">
          <div class="doctors-image" v-if="item.photo">
            <img alt="doctor" :src="item.photo" />
          </div>
          <LocateIcon
            v-if="
              properties.currentLocationId &&
              item.id === properties.currentLocationId
            "
          />
          <p>
            {{ item.direction }}
            <span
              v-if="
                properties.currentLocationId &&
                item.id === properties.currentLocationId
              "
              >(Вы здесь)</span
            >
          </p>
        </div>
        <ArrowDownIcon class="arrow" />
      </div>
    </template>
  </div>
</template>
<script>
import ArrowDownIcon from '@/assets/icons/arrow-down'
import LocateIcon from '@/assets/icons/locate'
import Loader from '@/components/loader'
export default {
  name: 'SmSlideSorting',
  components: { ArrowDownIcon, LocateIcon, Loader },
  props: {
    properties: Object,
    close: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    clickHandler(val) {
      this.close(val)
    }
  },
  computed: {
    sortingitems() {
      if (this.properties.currentLocationId) {
        return [...this.properties.items].sort((item) => {
          return item.id === this.properties.currentLocationId ? -1 : 1
        })
      } else {
        return this.properties.items
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../style';
</style>
