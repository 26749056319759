<template>
  <div class="sm-otp-input" :class="[this.invalid ? 'invalid' : '']">
    <v-otp-input
      ref="input"
      input-classes="otp-input"
      input-type="number"
      separator=" "
      :num-inputs="4"
      :should-auto-focus="true"
      :is-input-num="true"
      @on-complete="handleOnComplete"
      class="otp-input"
    />
    <ErrorToast v-if="invalid" :text="text" />
  </div>
</template>

<script>
import ErrorToast from '@/components/errorToast'

export default {
  name: 'OtpInput',
  components: { ErrorToast },
  props: {
    onComplete: {
      type: Function,
      default: () => {}
    },
    invalid: Boolean,
    text: {
      type: String,
      default: 'Неверный код подтверждения'
    }
  },
  data() {
    return {}
  },
  methods: {
    handleOnComplete(value) {
      this.onComplete(value)
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
