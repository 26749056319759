<template>
  <div class="countdown">
    <vue-countdown
      :time="+msLocal"
      @progress="progressHandler"
      :transform="transformSlotProps"
      :key="keyTimer"
      ref="countdown"
      v-slot="{ minutes, seconds }"
    >
      {{ minutes }}:{{ seconds }}
    </vue-countdown>
  </div>
</template>
<script>
import VueCountdown from '@chenfengyuan/vue-countdown'
import { mapActions } from 'vuex'
export default {
  name: 'Countdown',
  components: { VueCountdown },
  props: {
    ms: Number,
    name: String,
    type: String,
    reset: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      msLocal: 0,
      keyTimer: 1,
      interval: null
    }
  },
  created() {
    this.interval = setInterval(() => {
      if (document.visibilityState !== 'visible') {
        try {
          document.visibilityState = 'visible'
        } catch (e) {
          console.log(e)
        }
      } else {
        if (!this.$refs.countdown.totalMilliseconds) {
          this.msLocal = 0
          sessionStorage.removeItem(this.name)
          sessionStorage.removeItem('timestamp')
          this.clearAuthError()
          this.reset()
        }
      }
    }, 100)
    window.addEventListener('unload', this.setTimeStamp)

    const timestamp = sessionStorage.getItem('timestamp')
    const countdown = sessionStorage.getItem(this.name)
    const dateNow = Date.now()
    if (countdown) {
      this.msLocal =
        +countdown - (dateNow - +timestamp) > 0
          ? +countdown - (dateNow - +timestamp)
          : 0
      if (!this.msLocal) {
        this.reset()
        sessionStorage.removeItem('timestamp')
        sessionStorage.removeItem(this.name)
      }
    } else {
      this.msLocal = this.ms
    }
  },
  destroyed() {
    window.removeEventListener('unload', this.setTimeStamp)
    clearInterval(this.interval)
    this.setTimeStamp()
  },
  methods: {
    ...mapActions('auth', ['clearAuthError']),
    progressHandler({ minutes, seconds }) {
      sessionStorage.setItem(
        this.name,
        JSON.stringify((minutes * 60 + seconds) * 1000)
      )
      if (
        (minutes <= 1 && seconds <= 1) ||
        (seconds <= 1 && this.ms === 60000)
      ) {
        this.msLocal = 0
        sessionStorage.removeItem(this.name)
        sessionStorage.removeItem('timestamp')
        this.clearAuthError()
        this.reset()
      }
    },
    setTimeStamp() {
      if (this.msLocal !== 0) {
        sessionStorage.setItem('timestamp', JSON.stringify(Date.now()))
      }
    },
    transformSlotProps(props) {
      const formattedProps = {}

      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value)
      })

      return formattedProps
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
