export default {
  list: {
    data: null,
    loading: false,
    error: null,
    pagination: {
      totalPages: 1,
      size: 5
    }
  },
  filters: {
    data: null
  },
  single: {
    data: null,
    loading: false,
    error: null
  },
  clinics: {
    data: null,
    loading: false,
    error: null
  }
}
