import { apiV1 } from '@/config/request'
import router from '@/router'
import { getStatusMessage, parseTokenData } from '@/core/utils'
import { tracker, TRACKER_SLUGS } from '@/models/tracker'

const setRefreshToken = (status) => {
  if (!['', 'true'].includes(status)) {
    throw new Error(
      `setRefreshToken: invalid value ${status}; Expect one of ['', 'true']`
    )
  }

  localStorage.setItem('refreshToken', status)
}

export default {
  setAuthData({ commit }, { account, token, exp } = {}) {
    setRefreshToken('true')
    commit('account/setAccountInfo', account, { root: true })
    commit('setToken', { token, exp })
  },
  resetAuthData({ commit }) {
    // commit('setUser', {})
    commit('setToken', { token: null, exp: null })
    setRefreshToken('')
  },
  setPhoneSignInData({ commit }, data) {
    commit('setPhoneSignInData', data)
  },
  async refreshToken({ dispatch }, user) {
    try {
      const { data } = await new apiV1().post('/auth/refresh-token', user)

      dispatch('setAuthData', {
        token: data.token,
        exp: parseTokenData(data.token).exp
      })
      return data.token
    } catch (error) {
      dispatch('resetAuthData')
      await router.push({ name: 'login' }).catch(() => {})
    }
  },
  async signIn({ state, commit, dispatch, rootState }, authData) {
    commit('setLoading')
    commit('setLoginSignInData', authData)
    try {
      const {
        data: { token, errorCode, accountId, success, description, patientIds }
      } = await new apiV1().post('/auth/login', state.loginSignIn)
      if (errorCode) {
        switch (errorCode) {
          case '00010018':
            commit('setLoading')
            await router.push('/auth/blocked')
            break
          case '00010021':
            commit('setLoading')
            await router.push('/auth/offer')
            break
          default:
            commit(
              'auth_error',
              description
                ? { errorCode, message: description }
                : getStatusMessage(errorCode)
            )
            commit('setLoading')
            break
        }
        return
      }
      await dispatch('setAuthData', {
        token,
        exp: parseTokenData(token).exp
      })

      await dispatch(
        'account/getFullAccountInfo',
        { id: accountId, patientId: patientIds[0] },
        {
          root: true
        }
      )

      tracker.track(TRACKER_SLUGS.AUTH_DONE)

      if (rootState.account.errors.errorCode) {
        commit('auth_error', rootState.account.errors)
        dispatch('account/clearAccountData', {}, { root: true })
        commit('setLoading')
        return
      }
      commit('setLoading')
      return success
    } catch (e) {
      commit('setLoading')
      commit('auth_error', `${e}`)
    }
  },
  async resendWithPhone({ commit, state }, { isRecovery }) {
    commit('setLoading')
    commit('auth_error', '')
    try {
      const instance = isRecovery
        ? {
            method: 'patch',
            data: state.passwordRecovery,
            url: '/auth/reset-password'
          }
        : {
            method: 'post',
            data: state.phoneSignIn,
            url: '/auth/login-with-phone'
          }

      const { data, url, method } = instance

      const request = {
        ...data,
        phone: data.phone.replace(/\D/g, '')
      }

      const { data: response } = await new apiV1()[method](url, request)
      commit('setLoading')

      if (response.errorCode) {
        const authError = response.description
          ? { errorCode: response.errorCode, message: response.description }
          : getStatusMessage(response.errorCode)
        if (response.timer) {
          authError.timer = response.timer
        }
        if (response.errorCode === 'blockUser') {
          await router.push({
            name: 'AuthBlocked',
            query: { mode: 'recovery' }
          })
        }
        commit('auth_error', authError)
      }
    } catch (e) {
      commit('setLoading')
      commit('auth_error', getStatusMessage())
    }
  },
  async signInWithPhone({ commit, state }, { isBack, data }) {
    commit('setLoading')
    commit('auth_error', '')
    try {
      const phone = data.phone ?? state.phoneSignIn.phone
      const sendData = {
        ...state.phoneSignIn,
        ...data,
        isOfferAccepted: false,
        phone: phone.replace(/\D/g, '')
      }
      commit('setPasswordRecovery', {
        phone: data.phone
      })
      const { data: response } = await new apiV1().post(
        '/auth/login-with-phone',
        sendData
      )
      commit('setLoading')

      if (response.errorCode === '00010021') {
        commit('setPhoneSignInData', data)
        await router.push({ name: 'Offer', query: { mode: 'phone' } })
        return
      }

      if (response.errorCode === '000100010') {
        commit(
          'auth_error',
          response.description
            ? { errorCode: response.errorCode, message: response.description }
            : getStatusMessage(response.errorCode)
        )
        await router.push('/auth/auth-fail')
        return
      }

      if (
        response.errorCode === '00010006' ||
        response.errorCode === '00010008'
      ) {
        if (isBack) {
          commit('clearPhoneSignInData')
          commit('setPhoneSignInData', {
            phone
          })
        } else {
          commit('setPhoneSignInData', data)
        }
        if (response.errorCode === '00010006') {
          await router.push('/auth/auth-date')
        }
        if (response.errorCode === '00010008') {
          await router.push('/auth/auth-name')
        }
        return
      }

      if (response.errorCode) {
        if (response.description) {
          commit('auth_error', {
            errorCode: response.errorCode,
            message: response.description
          })
        } else {
          const authError = response.description
            ? { errorCode: response.errorCode, message: response.description }
            : getStatusMessage(response.errorCode)
          if (response.timer) {
            authError.timer = response.timer
          }
          if (response.errorCode === 'blockUser') {
            await router.push({ name: 'AuthBlocked' })
          }
          commit('auth_error', authError)
        }
      } else {
        commit('setPhoneSignInData', data)
        commit('auth_error', '')
        await router.push('/auth/sms-confirm')
      }
    } catch (e) {
      commit('setLoading')
      commit('auth_error', getStatusMessage())
    }
  },
  async confirmSmsCode({ commit, state, dispatch }, { payload, firstStep }) {
    commit('setLoading')
    commit('auth_error', '')
    try {
      const reqData = {
        ...state.phoneSignIn,
        phone: state.phoneSignIn.phone.replace(/\D/g, ''),
        ...(firstStep ? { code: payload } : { ...payload })
      }

      const { data: response } = await new apiV1().post(
        '/auth/login-with-phone/confirm',
        {
          ...reqData
        }
      )

      commit('setPhoneSignInData', {
        ...(firstStep ? { code: payload } : { ...payload })
      })

      if (response.errorCode) {
        commit(
          'auth_error',
          response.description
            ? { errorCode: response.errorCode, message: response.description }
            : getStatusMessage(response.errorCode)
        )
        if (response.errorCode === '00010018') {
          await router.push('/auth/blocked')
        }
        if (response.errorCode === '00010019') {
          await router.push('/auth/error-deleted')
        }
        if (response.errorCode === '00010006') {
          await router.push('/auth/auth-date')
        }
        if (response.errorCode === '00010008') {
          await router.push('/auth/auth-name')
        }
        if (response.errorCode === '00010022' && firstStep) {
          await router.push('/auth/registration')
        }
        commit('setLoading')
        return
      }

      dispatch('setAuthData', {
        account: response.account,
        patient: { id: response.account.patientIds },
        token: response.token,
        exp: parseTokenData(response.token).exp
      })

      await dispatch(
        'account/getFullAccountInfo',
        {
          id: response.account.id,
          patientId: response.account.patientIds[0]
        },
        {
          root: true
        }
      )

      tracker.track(TRACKER_SLUGS.AUTH_DONE)
      await router.push('/')
      commit('setLoading')
    } catch (e) {
      commit('setLoading')
      commit('auth_error', `${e}`)
    }
  },
  async confirmSmsRecovery({ commit, state }, code) {
    commit('auth_error', '')
    commit('setLoading')
    try {
      const { data: response } = await new apiV1().post(
        '/auth/reset-password/confirm',
        {
          ...state.passwordRecovery,
          phone: state.passwordRecovery.phone.replace(/\D/g, ''),
          code
        }
      )
      commit('setLoading')

      if (response.errorCode) {
        commit(
          'auth_error',
          response.description
            ? { errorCode: response.errorCode, message: response.description }
            : getStatusMessage(response.errorCode)
        )
        if (response.errorCode === '00010005') {
          await router.push({
            name: 'AuthBlocked',
            query: { mode: 'recovery' }
          })
        }
        return
      }

      await router.push('/auth/changed')
    } catch (e) {
      commit('setLoading')
      commit('auth_error', `${e}`)
    }
  },
  async passwordRecovery({ commit, state }, { isBack, data }) {
    commit('setLoading')
    commit('auth_error', '')
    try {
      const phone = (data.phone ?? state.passwordRecovery.phone).replace(
        /\D/g,
        ''
      )
      const { data: response } = await new apiV1().patch(
        '/auth/reset-password',
        {
          ...state.passwordRecovery,
          ...data,
          phone: phone
        }
      )
      const payload = {
        ...data,
        phone
      }

      if (response.errorCode === '00010017') {
        commit(
          'auth_error',
          response.description
            ? { errorCode: response.errorCode, message: response.description }
            : getStatusMessage(response.errorCode)
        )
        await router.push('/auth/auth-fail')
        commit('setLoading')
        return
      }

      if (
        response.errorCode === '00010013' ||
        response.errorCode === '00010015'
      ) {
        if (isBack) {
          commit('clearRecoveryData')
          commit('setPasswordRecovery', {
            phone
          })
        } else {
          commit('setPasswordRecovery', payload)
        }
        await router.push(
          response.errorCode === '00010013'
            ? '/auth/recovery-date'
            : '/auth/recovery-name'
        )
        commit('setLoading')
        return
      }

      if (response.errorCode) {
        commit(
          'auth_error',
          response.description
            ? { errorCode: response.errorCode, message: response.description }
            : getStatusMessage(response.errorCode)
        )
        commit('setLoading')
      } else {
        commit('setPasswordRecovery', payload)
        await router.push({ name: 'SMSConfirm', query: { mode: 'recovery' } })
        commit('setLoading')
      }
    } catch (e) {
      commit('setLoading')
    }
  },
  clearRecoveryPhone({ commit }) {
    commit('setPasswordRecovery', { phone: '' })
  },
  clearRecoveryData({ commit }) {
    commit('clearRecoveryData')
  },
  clearAuthError({ commit }) {
    commit('auth_error', '')
  },
  async signOut({ commit, dispatch }) {
    commit('clearAuthState')
    dispatch('account/clearAccountData', {}, { root: true })
    await router.push('/auth')
  },
  async registration({ commit, state, dispatch }, data) {
    commit('auth_error', '')
    try {
      const { patronymic, ...payload } = {
        ...state.registration,
        ...data
      }

      const { data: response } = await new apiV1().post('/auth/register', {
        ...payload,
        ...(patronymic ? { patronymic } : {})
      })

      if (response.errorCode) {
        commit(
          'auth_error',
          response.description
            ? { errorCode: response.errorCode, message: response.description }
            : getStatusMessage(response.errorCode)
        )
        if (response.errorCode === '00010026') {
          commit('setRegistrationData', data)
          await router.push({ name: 'Offer', query: { mode: 'register' } })
        }
      } else {
        dispatch('setAuthData', {
          account: {
            id: response.accountId
          },
          patient: { id: response.patientIds },
          token: response.token,
          exp: parseTokenData(response.token).exp
        })
        tracker.track(TRACKER_SLUGS.REG_DONE)
        await router.push('/')
      }
    } catch (e) {
      console.log(e)
    }
  }
}
