<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.73964 5.7253C3.42012 6.02571 3.42012 6.51276 3.73964 6.81316L7.42146 10.2747C7.74098 10.5751 8.25902 10.5751 8.57854 10.2747L12.2604 6.81316C12.5799 6.51276 12.5799 6.02571 12.2604 5.7253C11.9408 5.4249 11.4228 5.4249 11.1033 5.7253L8 8.64291L4.89672 5.7253C4.5772 5.4249 4.05916 5.4249 3.73964 5.7253Z"
      fill="url(#paint0_linear_2213_5295)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2213_5295"
        x1="-1.03261"
        y1="10.2157"
        x2="1.35474"
        y2="2.37174"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ArrowDownIcon'
}
</script>
