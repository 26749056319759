import {
  TRACKER_SLUGS,
  YM_USER_ID,
  EVENT_TYPES,
  EVENT_SLUGS
} from './tracker.constants'

class MetricsProvider {
  static #logsBus = []
  static #instance

  constructor() {
    if (MetricsProvider.#instance) {
      return MetricsProvider.#instance
    }

    MetricsProvider.#instance = this
  }

  static notifyServices(type, options) {
    if (
      !Object.values(TRACKER_SLUGS)?.includes(options.target) &&
      type === EVENT_TYPES.GOAL
    ) {
      console.warn('UNEXPECTED Goal id')
      return false
    }

    this.#logsBus?.push(options.target)
    const _slug = this.#logsBus?.at(-1)

    MetricsProvider.YM(type, _slug, options)
    MetricsProvider.GA(type, _slug, options)
  }

  static YM(type, slug, options) {
    window.ym(
      YM_USER_ID,
      EVENT_SLUGS[type].YM,
      slug,
      options?.referer ? { referer: options.referer } : {}
    )
  }

  static GA(type, slug) {
    if (type === EVENT_TYPES.ROUTE_CHANGING) {
      return
    }

    window.gtag(EVENT_SLUGS[type].GA, slug)
  }

  static clearUserLogs() {
    this.#logsBus.length = 0
  }
}

export { MetricsProvider }
