<template>
  <ul class="sm-crumbs" v-if="crumbs.length">
    <li
      v-for="(crumb, i) in crumbs"
      :key="i"
      class="sm-crumbs-item"
      :style="{
        flexGrow: windowWidth >= 1024 ? 0 : grow(crumbs, i),
        width:
          windowWidth >= 1024
            ? 'fit-content'
            : crumbs.length > 2
            ? 0
            : 'fit-content'
      }"
    >
      <router-link v-if="i < crumbs.length - 1" :to="crumb.path" class="link">
        {{ crumb.name }}
      </router-link>
      <span
        v-else
        class="last link"
        :style="{
          whiteSpace: 'nowrap'
        }"
      >
        {{ crumb.name }}
      </span>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'SmCrumbs',
  props: { root: String, dynamicName: Object, customCrumbs: Array },
  methods: {
    grow(crumbs, i) {
      if (crumbs.length > 2) {
        if (i + 1 === crumbs.length) {
          return 4
        }
        return 3
      }
    }
  },
  computed: {
    crumbs() {
      if (this.customCrumbs?.length) return this.customCrumbs
      let path = ''
      let title = this.root || 'Главная страница'

      const cs = [{ name: title, path: '/' }]
      if (!this.$route) return []

      const r = this.$route.path.split('/')
      const m = this.$route.matched.map((r) => r.meta.crumbs)
      for (let i = 1; i < r.length; i++) {
        const name = this.dynamicName
          ? this.dynamicName[this.$route.matched[i].path]
          : m[i] || r[i]

        if (r[i] === '') continue

        title += ' : ' + name
        path += '/' + r[i]
        cs.push({ name: name, path: path })
      }

      window.document.title = title

      return cs
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style';
</style>
