<template>
  <div class="locations">
    <SmCalendar
      :onDayClick="onDayClick"
      :range.sync="range"
      :maxDays="maxDays"
      :fixedRange="fixedRange"
      :minDate="minDate"
    />
    <Button icon="ok" classes="calendar-button" :onClick="handleApply">
      Выбрать
    </Button>
  </div>
</template>
<script>
import SmCalendar from '@/components/calendar/calendar'
import Button from '@/components/button'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'

export default {
  name: 'SmSlideDate',
  components: { Button, SmCalendar },
  props: {
    properties: {
      items: []
    },
    maxDays: {
      type: Number,
      default: 0
    },
    fixedRange: Boolean,
    close: {
      type: Function,
      default: () => {}
    },
    minDate: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      range: {
        start: new Date(),
        end: new Date()
      }
    }
  },
  methods: {
    onDayClick(range) {
      this.range = range
    },
    handleApply() {
      this.close({
        label: `${format(this.range.start, 'dd.MM', {
          locale: ru
        })} - ${format(this.range.end, 'dd.MM', {
          locale: ru
        })}`,
        start: this.range.start,
        end: this.range.end
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style';
</style>
