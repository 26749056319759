<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__inner">
        <p class="copyright">
          <span> © 2002-{{ new Date().getFullYear() }} ООО «СМ-Клиника».</span>
          Лицензия № ЛО-77-01-018779 от 19 сентября 2019 г.
        </p>
        <div class="wrap">
          <div class="markets">
            <a
              v-tracker="TRACKER_SLUGS.PROCEED_MOBILE_STORE_APPSTORE"
              target="_blank"
              href="https://apps.apple.com/ru/app/%D1%81%D0%BC-%D0%BA%D0%BB%D0%B8%D0%BD%D0%B8%D0%BA%D0%B0-%D0%BB%D0%B8%D1%87%D0%BD%D1%8B%D0%B9-%D0%BA%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82/id6443942006"
            >
              <AppStore />
            </a>
            <a
              v-tracker="TRACKER_SLUGS.PROCEED_MOBILE_STORE_GOOGLEPLAY"
              target="_blank"
              href="https://play.google.com/store/apps/details?id=ru.smclinic.app.lk&hl=ru&gl=US"
            >
              <GooglePlay />
            </a>
            <a
              v-tracker="TRACKER_SLUGS.PROCEED_MOBILE_STORE_APPGALLERY"
              class="markets__custom"
              target="_blank"
              href="https://appgallery.huawei.com/#/app/C107241661"
            >
              <AppGallery />
              <span class="markets__custom-text">
                Загрузите в <br />
                <b>AppGallery</b>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import AppStore from '@/assets/icons/appStore'
import GooglePlay from '@/assets/icons/googlePlay'
import AppGallery from '@/assets/icons/AppGallery'
import { TRACKER_SLUGS } from '@/models/tracker'
import pick from 'lodash/pick'

export default {
  name: 'Footer',
  components: { AppGallery, GooglePlay, AppStore },
  data() {
    return {}
  },
  created() {
    this.TRACKER_SLUGS = pick(TRACKER_SLUGS, [
      'PROCEED_MOBILE_STORE_APPSTORE',
      'PROCEED_MOBILE_STORE_GOOGLEPLAY',
      'PROCEED_MOBILE_STORE_APPGALLERY'
    ])
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
