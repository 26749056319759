<template>
  <section class="analysis">
    <div v-if="getSingleLoading || getSingleError">
      <Loader :loading="getSingleLoading" :error="!!getSingleError" />
    </div>
    <template v-else>
      <SmCrumbs />
      <div class="analysis__headline">
        <h2>Информация об анализе</h2>
        <p @click="handleBackClick" class="go-back">
          <span><ArrowBackIcon /></span>
          К списку анализов
        </p>
      </div>
      <AnalysisInfoCard
        v-if="getSingleAnalysisData"
        :data="getSingleAnalysisData"
        :userInfo="getPatientInfo"
        :status="analysisStatus"
        :totalAmount="totalAmount"
        :services="services"
        :servicesHandler="setServiceStatus"
        :clinics="clinicsListData"
        type="analiseCard"
      />
      <p @click="handleBackClick" class="go-back-mobile">К списку анализов</p>
    </template>
  </section>
</template>
<script>
import AnalysisInfoCard from '@/components/analysisInfoCard/analysisInfoCard'
import ArrowBackIcon from '@/assets/icons/arrow-back'
import { AnalysisTypes, PaymentStatusTypes } from '@/core/constants'
import { mapActions, mapGetters } from 'vuex'
import SmCrumbs from '@/components/breadcrumbs'
import Loader from '@/components/loader'

export default {
  name: 'AnalysisSingle',
  components: {
    SmCrumbs,
    ArrowBackIcon,
    AnalysisInfoCard,
    Loader
  },
  data() {
    return {
      AnalysisTypes: Object.freeze(AnalysisTypes),
      PaymentStatusTypes: Object.freeze(PaymentStatusTypes),
      analysisServices: []
    }
  },
  async mounted() {
    const id = this.$route.query.id
    await this.getAnalysisById({
      id
    })
    await this.getClinicsList({
      analysisId: id,
      cityId: this.selectedCity
    })
    await this.getClinics({ cityId: this.selectedCity, size: 100 })
  },
  destroyed() {
    this.clearAnalysesRequestState()
  },
  methods: {
    ...mapActions('analyses', [
      'getAnalysisById',
      'clearAnalysesRequestState',
      'getClinicsList'
    ]),
    ...mapActions('clinics', ['getClinicById', 'getClinics']),
    handleBackClick() {
      this.$router.go(-1)
    },
    setServiceStatus(value, id) {
      this.services = this.services.map((service) => {
        if (service.id === id) {
          service.isSelected = value
          return service
        }
        return service
      })
    }
  },
  computed: {
    ...mapGetters('analyses', [
      'getSingleAnalysisData',
      'getSingleLoading',
      'getSingleError',
      'getClinicsListByAnalysis'
    ]),
    ...mapGetters('account', ['getPatientInfo']),
    ...mapGetters('cities', ['selectedCity']),
    ...mapGetters('clinics', ['clinicsListData']),
    analysisStatus() {
      return this.getSingleAnalysisData
        ? AnalysisTypes[this.getSingleAnalysisData.status]
        : null
    },
    services: {
      get() {
        return this.analysisServices.length
          ? this.analysisServices
          : (this.getSingleAnalysisData?.services || []).map((service) => ({
              ...service,
              isSelected: true
            }))
      },
      set(newValue) {
        this.analysisServices = newValue
      }
    },
    totalAmount() {
      const servicesTotalAmount = this.services.reduce((total, service) => {
        if (service.isSelected) {
          return total + service.totalAmount
        } else return total
      }, 0)

      const extraServicesTotalAmount = (
        this.getSingleAnalysisData?.extraServices || []
      ).reduce((total, extraService) => {
        return total + extraService.totalAmount
      }, 0)
      return servicesTotalAmount + extraServicesTotalAmount
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style';
</style>
