<template>
  <div class="error-toast">
    <div><ErrorIcon /></div>
    <p>{{ text }}</p>
  </div>
</template>
<script>
import ErrorIcon from '@/assets/icons/error'
export default {
  name: 'ErrorToast',
  components: { ErrorIcon },
  props: {
    text: String
  },
  data() {
    return {}
  }
}
</script>
<style scoped lang="scss">
@import 'style.scss';
</style>
