<template>
  <div>
    <Select
      :value="defaultModel"
      :filterable="filterable"
      :searchable="searchable"
      :options="options"
      :label="label"
      :clearable="clearable"
      :disabled="disabled"
      :filterBy="filterBy"
      :placeholder="placeholder"
      @input="onDefaultChange"
    >
      <template #no-options> Введите название... </template>
      <template #open-indicator="{ attributes }">
        <span v-bind="attributes"><ArrowDown /></span>
      </template>
    </Select>
  </div>
</template>

<script>
import ArrowDown from '@/assets/icons/arrow-down'
import vSelect from 'vue-select'

export default {
  components: {
    ArrowDown,
    Select: vSelect
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: 'label'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: false
    },
    filterBy: {
      type: Function,
      default: null
    },
    clearable: {
      type: Boolean,
      default: false
    },
    onChange: {
      type: Function,
      default: () => {}
    },
    placeholder: String
  },
  mounted() {},
  data: function () {
    return {
      model: null
    }
  },
  computed: {
    defaultModel() {
      return this?.value
    }
  },
  methods: {
    onDefaultChange(value) {
      this.$emit('input', value)
      this.onChange(value ? value : undefined)
    }
  }
}
</script>
<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';
@import './style.scss';
</style>
