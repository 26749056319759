<template>
  <div class="auth-form error">
    <p class="auth-form__title">Ошибка авторизации</p>
    <div class="auth-form__inner">
      <p class="auth-form__description">
        {{ errorAuth.message }}
      </p>
      <Button
        :onClick="() => this.$router.push('map')"
        :classes="'auth-form__btn'"
        icon="locate"
        secondary
      >
        Найти клинику
      </Button>
      <p class="sub-description">или вы можете войти с логином и паролем</p>
      <Button :classes="['auth-form__btn']" icon="key" :onClick="goToLoginAuth">
        Войти с логином и паролем
      </Button>
    </div>
  </div>
</template>
<script>
import Button from '@/components/button'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AuthError',
  components: { Button },
  methods: {
    ...mapActions('auth', ['signInWithPhone', 'clearAuthError']),
    goToLoginAuth() {
      this.$router.push('/auth/login')
    }
  },
  computed: {
    ...mapGetters('auth', ['errorAuth'])
  },
  beforeRouteLeave(to, from, next) {
    next()
    this.clearAuthError()
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
