<template>
  <div :class="classes ? classes : 'sm-search-input'">
    <div class="icon-prepend">
      <Search />
    </div>
    <input :placeholder="placeholder ? placeholder : ''" @input="onChange" />
  </div>
</template>
<script>
import Search from '@/assets/icons/search'
export default {
  name: 'SmSearchInput',
  components: { Search },
  props: {
    classes: [String, Array],
    placeholder: String,
    onChange: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
