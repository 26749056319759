<template>
  <div class="clinic-info">
    <div class="clinic-info__headline" v-if="hasTitle">
      <h3>{{ clinic.clinicName }}</h3>
      <CloseIcon :onClose="close" />
    </div>
    <div class="clinic-info__img">
      <img
        alt="clinic"
        :src="`${getBaseUrl}/${
          windowWidth >= 1024 ? clinic.lkWebPhoto : clinic.lkMobilePhoto
        }`"
      />
    </div>
    <div class="clinic-info__location">
      <p><LocateIcon /> {{ clinic.address }}</p>
      <p v-if="subway">
        <SubwayIcon
          :color="
            subway.color && subway.color.length
              ? `#${subway.color[0]}`
              : 'purple'
          "
        />
        {{ subway.title }}
      </p>
    </div>
    <div class="clinic-info__main">
      <div class="clinic-info__phone">
        <p class="clinic-info__category-title">Телефон</p>
        <p class="phone-number">{{ clinic.phone }}</p>
      </div>

      <div class="clinic-info__service">
        <p class="clinic-info__category-title" v-if="clinic.clinicDivisions">
          Режим работы
        </p>
        <div v-for="division of clinic.clinicDivisions" :key="division.id">
          <p class="clinic-info__category-sub-title">{{ division.title }}</p>
          <div class="clinic-info__schedule">
            <div class="schedule-item" v-if="division.workingHours.workdays">
              <span>Будни</span>
              <span>{{
                division.workingHours.aroundTheClock
                  ? 'Круглосуточно'
                  : division.workingHours.workdaysTime
              }}</span>
            </div>
            <div class="schedule-item" v-if="division.workingHours.weekends">
              <span>Суббота</span>
              <span>{{
                division.workingHours.aroundTheClock
                  ? 'Круглосуточно'
                  : parseDay(division.workingHours.weekendsTime, 'SATURDAY')
              }}</span>
            </div>
            <div class="schedule-item" v-if="division.workingHours.weekends">
              <span>Воскресенье</span>
              <span>{{
                division.workingHours.aroundTheClock
                  ? 'Круглосуточно'
                  : parseDay(division.workingHours.weekendsTime, 'SUNDAY')
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="clinic-info__common">
        {{ clinic.description }}
      </div>
      <a :href="clinic.aboutUrl" target="_blank" class="clinic-info__more">
        Подробнее о клинике <ArrowBackIcon />
      </a>
    </div>
    <Button
      icon="plus"
      :classes="'register-btn'"
      :onClick="() => this.$router.push(`/appointment?clinicId=${clinic.id}`)"
      v-tracker="TRACKER_SLUGS.CLINIC_NEW_APPOINTMENT"
    >
      Записаться
    </Button>
  </div>
</template>
<script>
import LocateIcon from '@/assets/icons/locate'
import SubwayIcon from '@/assets/icons/subway'
import Button from '@/components/button'
import ArrowBackIcon from '@/assets/icons/arrow-back'
import CloseIcon from '@/assets/icons/close'
import { TRACKER_SLUGS } from '@/models/tracker'
import pick from 'lodash/pick'
export default {
  name: 'SelectedClinic',
  components: { CloseIcon, ArrowBackIcon, Button, SubwayIcon, LocateIcon },
  props: {
    clinic: Object,
    hasTitle: Boolean,
    close: { type: Function, default: () => {} }
  },
  created() {
    this.TRACKER_SLUGS = pick(TRACKER_SLUGS, ['CLINIC_NEW_APPOINTMENT'])
  },
  methods: {
    parseDay(value, type) {
      if (value.indexOf('сб') !== -1) {
        let val = value
          .split(',')
          .find(
            (date) => date.indexOf(type === 'SATURDAY' ? 'сб' : 'вс') !== -1
          )

        if (val) {
          val = val.replace(/сб:|вс:/g, '').trim()
          return val
        }
      }

      return value
    }
  },
  computed: {
    getBaseUrl() {
      return process.env.VUE_APP_API_BASE_URL
    },
    subway() {
      if (!this.clinic['subway-stations']) return null
      return this.clinic['subway-stations'][0]
    }
  }
}
</script>
<style scoped lang="scss">
@import 'style';
</style>
