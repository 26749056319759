<template>
  <svg
    width="108"
    height="36"
    viewBox="0 0 108 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.8457 18.0359C22.8187 15.4619 24.9517 14.2109 25.0507 14.1479C23.8447 12.3839 21.9727 12.1499 21.3157 12.1319C19.7407 11.9699 18.2197 13.0679 17.4187 13.0679C16.5997 13.0679 15.3667 12.1409 14.0437 12.1679C12.3337 12.1949 10.7317 13.1849 9.85871 14.7239C8.04971 17.8559 9.39971 22.4639 11.1367 24.9929C12.0007 26.2349 13.0177 27.6209 14.3407 27.5669C15.6367 27.5129 16.1227 26.7389 17.6887 26.7389C19.2367 26.7389 19.6957 27.5669 21.0457 27.5399C22.4407 27.5129 23.3137 26.2979 24.1507 25.0379C25.1497 23.6159 25.5547 22.2119 25.5727 22.1399C25.5457 22.1399 22.8727 21.1229 22.8457 18.0359Z"
      fill="#565C5A"
    />
    <path
      d="M20.2899 10.467C20.9919 9.59395 21.4689 8.40595 21.3339 7.19995C20.3259 7.24495 19.0569 7.90195 18.3279 8.75695C17.6799 9.51295 17.113 10.746 17.257 11.907C18.391 11.979 19.5609 11.322 20.2899 10.467Z"
      fill="#565C5A"
    />
    <path
      d="M38.415 24.561H34.158L33.132 27.585H31.332L35.364 16.407H37.236L41.268 27.585H39.432L38.415 24.561ZM34.599 23.166H37.974L36.309 18.261H36.264L34.599 23.166Z"
      fill="#565C5A"
    />
    <path
      d="M49.9895 23.508C49.9895 26.037 48.6305 27.666 46.5875 27.666C45.4265 27.666 44.5085 27.144 44.0225 26.244H43.9865V30.276H42.3125V19.431H43.9325V20.79H43.9595C44.4275 19.917 45.4175 19.35 46.5515 19.35C48.6215 19.35 49.9895 20.979 49.9895 23.508ZM48.2705 23.508C48.2705 21.861 47.4155 20.772 46.1195 20.772C44.8415 20.772 43.9775 21.879 43.9775 23.508C43.9775 25.146 44.8415 26.253 46.1195 26.253C47.4155 26.253 48.2705 25.173 48.2705 23.508Z"
      fill="#565C5A"
    />
    <path
      d="M58.9532 23.508C58.9532 26.037 57.5942 27.666 55.5512 27.666C54.3902 27.666 53.4722 27.144 52.9862 26.244H52.9502V30.276H51.2852V19.431H52.9052V20.79H52.9322C53.4002 19.917 54.3902 19.35 55.5242 19.35C57.5942 19.35 58.9532 20.979 58.9532 23.508ZM57.2342 23.508C57.2342 21.861 56.3792 20.772 55.0832 20.772C53.8052 20.772 52.9412 21.879 52.9412 23.508C52.9412 25.146 53.8052 26.253 55.0832 26.253C56.3882 26.253 57.2342 25.173 57.2342 23.508Z"
      fill="#565C5A"
    />
    <path
      d="M64.8838 24.471C65.0098 25.578 66.0808 26.307 67.5568 26.307C68.9698 26.307 69.9778 25.578 69.9778 24.579C69.9778 23.715 69.3658 23.193 67.9168 22.833L66.4678 22.482C64.4158 21.987 63.4618 21.024 63.4618 19.467C63.4618 17.541 65.1448 16.218 67.5298 16.218C69.8878 16.218 71.5078 17.541 71.5618 19.467H69.8698C69.7708 18.351 68.8438 17.676 67.5028 17.676C66.1618 17.676 65.2348 18.36 65.2348 19.35C65.2348 20.142 65.8198 20.601 67.2598 20.961L68.4928 21.267C70.7878 21.807 71.7418 22.734 71.7418 24.363C71.7418 26.451 70.0768 27.765 67.4308 27.765C64.9558 27.765 63.2818 26.487 63.1738 24.462H64.8838V24.471Z"
      fill="#565C5A"
    />
    <path
      d="M75.3593 17.505V19.431H76.9073V20.754H75.3593V25.245C75.3593 25.947 75.6653 26.271 76.3493 26.271C76.5203 26.271 76.7903 26.244 76.8983 26.235V27.549C76.7093 27.594 76.3403 27.63 75.9713 27.63C74.3243 27.63 73.6763 27.009 73.6763 25.434V20.763H72.4883V19.44H73.6763V17.505H75.3593Z"
      fill="#565C5A"
    />
    <path
      d="M77.7988 23.508C77.7988 20.943 79.3108 19.332 81.6598 19.332C84.0178 19.332 85.5208 20.943 85.5208 23.508C85.5208 26.082 84.0268 27.684 81.6598 27.684C79.3018 27.684 77.7988 26.082 77.7988 23.508ZM83.8288 23.508C83.8288 21.753 83.0188 20.709 81.6688 20.709C80.3188 20.709 79.5088 21.753 79.5088 23.508C79.5088 25.272 80.3188 26.307 81.6688 26.307C83.0188 26.307 83.8288 25.272 83.8288 23.508Z"
      fill="#565C5A"
    />
    <path
      d="M86.916 19.4311H88.509V20.8171H88.545C88.797 19.8991 89.544 19.3411 90.507 19.3411C90.75 19.3411 90.948 19.3771 91.083 19.4041V20.9701C90.948 20.9161 90.66 20.8711 90.336 20.8711C89.256 20.8711 88.59 21.6001 88.59 22.7431V27.5761H86.916V19.4311Z"
      fill="#565C5A"
    />
    <path
      d="M98.7866 25.191C98.5616 26.667 97.1216 27.684 95.2766 27.684C92.9096 27.684 91.4336 26.1 91.4336 23.544C91.4336 20.988 92.9096 19.332 95.2046 19.332C97.4546 19.332 98.8766 20.88 98.8766 23.355V23.931H93.1256V24.03C93.1256 25.425 93.9986 26.334 95.3216 26.334C96.2486 26.334 96.9776 25.893 97.2026 25.191H98.7866ZM93.1346 22.752H97.2116C97.1756 21.501 96.3746 20.682 95.2136 20.682C94.0526 20.691 93.2246 21.528 93.1346 22.752Z"
      fill="#565C5A"
    />
    <path
      d="M33.4112 10.26H34.1312C34.7252 10.26 35.1392 9.90902 35.1392 9.40502C35.1392 8.91002 34.7702 8.58602 34.1312 8.58602C33.5012 8.58602 33.0962 8.90102 33.0512 9.42302H32.2592C32.3132 8.45102 33.0332 7.84802 34.1582 7.84802C35.2112 7.84802 35.9582 8.44202 35.9582 9.28802C35.9582 9.88202 35.5622 10.386 34.9862 10.512V10.575C35.6792 10.647 36.1832 11.124 36.1832 11.871C36.1832 12.789 35.3102 13.464 34.1762 13.464C33.0062 13.464 32.2052 12.834 32.1602 11.871H32.9612C33.0062 12.384 33.4832 12.717 34.1762 12.717C34.8692 12.717 35.3282 12.348 35.3282 11.817C35.3282 11.286 34.8962 10.953 34.1492 10.953H33.4112V10.26Z"
      fill="#565C5A"
    />
    <path
      d="M37.0918 12.213C37.0918 11.484 37.6318 11.061 38.5948 11.007L39.6928 10.944V10.593C39.6928 10.161 39.4138 9.92701 38.8648 9.92701C38.4148 9.92701 38.1088 10.089 38.0188 10.377H37.2448C37.3258 9.68401 37.9828 9.23401 38.9008 9.23401C39.9178 9.23401 40.4938 9.73801 40.4938 10.593V13.365H39.7198V12.798H39.6568C39.4138 13.203 38.9728 13.437 38.4418 13.437C37.6588 13.428 37.0918 12.96 37.0918 12.213ZM39.6928 11.871V11.529L38.7028 11.592C38.1448 11.628 37.8928 11.817 37.8928 12.177C37.8928 12.546 38.2078 12.753 38.6398 12.753C39.2518 12.753 39.6928 12.375 39.6928 11.871Z"
      fill="#565C5A"
    />
    <path
      d="M42.6174 9.98103V13.356H41.8164V9.30603H44.4804V9.98103H42.6174Z"
      fill="#565C5A"
    />
    <path
      d="M49.332 11.331C49.332 12.618 48.684 13.428 47.658 13.428C47.082 13.428 46.623 13.176 46.407 12.744H46.344V14.706H45.543V9.30601H46.317V9.97201H46.38C46.623 9.51301 47.1 9.23401 47.649 9.23401C48.684 9.24301 49.332 10.044 49.332 11.331ZM48.513 11.331C48.513 10.476 48.108 9.95401 47.433 9.95401C46.758 9.95401 46.335 10.485 46.335 11.331C46.335 12.177 46.758 12.708 47.424 12.708C48.108 12.708 48.513 12.195 48.513 11.331Z"
      fill="#565C5A"
    />
    <path
      d="M50.3945 14.814V14.121C50.4575 14.13 50.5835 14.139 50.6555 14.139C51.0335 14.139 51.2495 13.986 51.3845 13.572L51.4475 13.374L49.9805 9.30603H50.8535L51.8705 12.528H51.9335L52.9415 9.30603H53.7875L52.2755 13.527C51.9335 14.517 51.5465 14.832 50.7185 14.832C50.6645 14.832 50.4755 14.823 50.3945 14.814Z"
      fill="#565C5A"
    />
    <path
      d="M54.418 12.213H55.21C55.291 12.573 55.624 12.753 56.092 12.753C56.623 12.753 56.956 12.519 56.956 12.15C56.956 11.763 56.659 11.574 56.092 11.574H55.489V10.953H56.083C56.569 10.953 56.812 10.764 56.812 10.431C56.812 10.134 56.578 9.90901 56.083 9.90901C55.633 9.90901 55.309 10.098 55.255 10.422H54.481C54.562 9.70201 55.165 9.23401 56.074 9.23401C57.037 9.23401 57.586 9.66601 57.586 10.35C57.586 10.755 57.325 11.088 56.947 11.178V11.241C57.469 11.367 57.739 11.7 57.739 12.15C57.739 12.924 57.082 13.428 56.083 13.428C55.21 13.437 54.49 13.032 54.418 12.213Z"
      fill="#565C5A"
    />
    <path
      d="M58.9082 13.3651V9.31506H59.7002V12.1051H59.7632L61.6352 9.31506H62.4272V13.3651H61.6352V10.5661H61.5722L59.7002 13.3651H58.9082Z"
      fill="#565C5A"
    />
    <path
      d="M66.7926 9.98103H65.5146V13.356H64.7135V9.98103H63.4355V9.30603H66.7926V9.98103Z"
      fill="#565C5A"
    />
    <path
      d="M71.0938 12.267C70.9138 12.996 70.2658 13.437 69.3388 13.437C68.1778 13.437 67.4668 12.645 67.4668 11.349C67.4668 10.053 68.1958 9.23401 69.3388 9.23401C70.4638 9.23401 71.1478 10.008 71.1478 11.277V11.556H68.2858V11.601C68.3128 12.312 68.7268 12.762 69.3658 12.762C69.8518 12.762 70.1848 12.591 70.3288 12.267H71.0938ZM68.2858 10.962H70.3288C70.3108 10.323 69.9238 9.90901 69.3298 9.90901C68.7358 9.90901 68.3308 10.332 68.2858 10.962Z"
      fill="#565C5A"
    />
    <path
      d="M74.4863 9.30603H76.3133C77.1053 9.30603 77.5733 9.71103 77.5733 10.341C77.5733 10.755 77.2853 11.124 76.8893 11.196V11.259C77.4113 11.322 77.7533 11.7 77.7533 12.195C77.7533 12.906 77.2223 13.365 76.3313 13.365H74.4863V9.30603ZM75.2783 9.92703V10.98H76.0703C76.5563 10.98 76.7813 10.791 76.7813 10.449C76.7813 10.134 76.5833 9.92703 76.1513 9.92703H75.2783ZM75.2783 11.565V12.753H76.2233C76.7003 12.753 76.9523 12.546 76.9523 12.159C76.9523 11.754 76.6643 11.565 76.1243 11.565H75.2783Z"
      fill="#565C5A"
    />
    <rect
      x="0.5"
      y="0.5"
      width="107"
      height="35"
      rx="8.5"
      stroke="#425750"
      stroke-opacity="0.5"
    />
  </svg>
</template>
<script>
export default {
  name: 'AppStoreIcon'
}
</script>
