<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="9" fill="white" />
    <path
      d="M10 19C5.032 19 1 14.968 1 10C1 5.032 5.032 1 10 1C14.968 1 19 5.032 19 10C19 14.968 14.968 19 10 19ZM10 11.0588C10.5263 11.0588 10.9529 10.6322 10.9529 10.1059V6.18823C10.9529 5.66194 10.5263 5.23529 10 5.23529C9.4737 5.23529 9.04706 5.66194 9.04706 6.18823V10.1059C9.04706 10.6322 9.47371 11.0588 10 11.0588ZM10 14.7647C10.5848 14.7647 11.0588 14.2907 11.0588 13.7059C11.0588 13.1211 10.5848 12.6471 10 12.6471C9.41523 12.6471 8.94118 13.1211 8.94118 13.7059C8.94118 14.2907 9.41523 14.7647 10 14.7647Z"
      fill="#FE9B38"
    />
  </svg>
</template>
<script>
export default {
  name: 'AlertIcon'
}
</script>
