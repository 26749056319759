import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'

import '@/config/plugins'
import '@/styles/style.scss'

import { YM_USER_ID, YM_USER_CONFIG, GA_USER_ID } from '@/models/tracker'

window.gtag('js', new Date())
window.gtag('config', GA_USER_ID)
window.ym(YM_USER_ID, 'init', YM_USER_CONFIG)

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
