export default {
  single: {
    data: null,
    error: null,
    loading: false
  },
  list: {
    data: null,
    error: null,
    loading: false
  },
  specializations: {
    data: null,
    error: null,
    loading: false
  },
  prevSpec: [],
  cancelLoading: false,
  createLoading: false,
  errorCode: null,
  errorMsg: ''
}
