<template>
  <div class="location">
    <p>Ваш город:</p>
    <div @click="showLocation">
      <span>{{ currentLocation.title }}</span>
      <ArrowDownBold />
    </div>
  </div>
</template>
<script>
import ArrowDownBold from '@/assets/icons/arrow-down-bold'
import SmSlideLocation from '@/components/slideOutComponents/location/location'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Location',
  components: { ArrowDownBold },
  created() {
    if (!this.citiesData) {
      this.getCities()
    }
  },
  methods: {
    ...mapActions('cities', ['getCities']),
    showLocation() {
      this.$showPanel({
        component: 'SmSlideoutPanel',
        props: {
          title: 'Выберите город',
          component: SmSlideLocation,
          properties: {
            value: this.currentLocation
          }
        },
        openOn: 'right',
        sync: true,
        keepAlive: false
      })
    }
  },
  computed: {
    ...mapGetters('cities', ['selectedCity', 'citiesData']),
    currentLocation() {
      return this.citiesData
        ? this.citiesData.find((city) => city.id === this.selectedCity) ?? {
            title: 'Город не найден'
          }
        : { title: 'Город не найден' }
    }
  }
}
</script>
<style scoped lang="scss">
@import 'style';
</style>
