<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 10.5455L20 4L12.7273 20L11.2727 12.7273L4 10.5455Z"
      fill="url(#paint0_linear_1565_278617)"
      stroke="url(#paint1_linear_1565_278617)"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1565_278617"
        x1="4.90962"
        y1="28.058"
        x2="26.0045"
        y2="16.5015"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1565_278617"
        x1="4.90962"
        y1="28.058"
        x2="26.0045"
        y2="16.5015"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'GeolocationIcon'
}
</script>
