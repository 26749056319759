<template>
  <section class="diagnostic">
    <div v-if="getSingleLoading || getSingleError">
      <Loader :loading="getSingleLoading" :error="getSingleError" />
    </div>
    <template v-else>
      <SmCrumbs />
      <div class="diagnostic__headline">
        <h2>Информация об исследовании</h2>
        <p @click="handleBackClick" class="go-back">
          <span><ArrowBackIcon /></span>
          К списку исследований
        </p>
      </div>
      <AnalysisInfoCard
        v-if="getSingleDiagnosticData"
        :isDiagnostic="true"
        :data="getSingleDiagnosticData"
        :userInfo="getPatientInfo"
        :totalAmount="totalAmount"
        :services="services"
        :servicesHandler="setServiceStatus"
      />
      <p @click="handleBackClick" class="go-back-mobile">
        К списку исследований
      </p>
    </template>
  </section>
</template>
<script>
import ArrowBackIcon from '@/assets/icons/arrow-back'
import { mapActions, mapGetters } from 'vuex'
import { AnalysisTypes, PaymentStatusTypes } from '@/core/constants'
import { getFullDate } from '@/core/utils'
import AnalysisInfoCard from '@/components/analysisInfoCard/analysisInfoCard'
import SmCrumbs from '@/components/breadcrumbs'
import Loader from '@/components/loader'

export default {
  name: 'DiagnosticsSingle',
  components: {
    Loader,
    SmCrumbs,
    ArrowBackIcon,
    AnalysisInfoCard
  },

  data() {
    return {
      getFullDate,
      AnalysisTypes: Object.freeze(AnalysisTypes),
      PaymentStatusTypes: Object.freeze(PaymentStatusTypes),
      analysisServices: []
    }
  },
  methods: {
    ...mapActions('diagnostics', [
      'getDiagnosticById',
      'clearDiagnosticsRequestState'
    ]),
    handleBackClick() {
      this.$router.go(-1)
    },
    setServiceStatus(value, id) {
      this.services = this.services.map((service) => {
        if (service.id === id) {
          service.isSelected = value
          return service
        }
        return service
      })
    }
  },

  async mounted() {
    const id = this.$route.query.id
    await this.getDiagnosticById({
      id,
      withDoctor: true,
      withClinic: true,
      withSubway: true
    })
  },

  destroyed() {
    this.clearDiagnosticsRequestState()
  },

  computed: {
    ...mapGetters('diagnostics', [
      'getSingleDiagnosticData',
      'getSingleLoading',
      'getSingleError'
    ]),
    ...mapGetters('account', ['getPatientInfo']),
    ...mapGetters('doctors', ['doctorsSingleData']),
    ...mapGetters('clinics', ['clinicsSingleData']),
    services: {
      get() {
        return this.analysisServices.length
          ? this.analysisServices
          : (this.getSingleAnalysisData?.services || []).map((service) => ({
              ...service,
              isSelected: true
            }))
      },
      set(newValue) {
        this.analysisServices = newValue
      }
    },
    totalAmount() {
      const servicesTotalAmount = this.services.reduce((total, service) => {
        if (service.isSelected) {
          return total + service.totalAmount
        } else return total
      }, 0)

      const extraServicesTotalAmount = (
        this.getSingleAnalysisData?.extraServices || []
      ).reduce((total, extraService) => {
        return total + extraService.totalAmount
      }, 0)
      return servicesTotalAmount + extraServicesTotalAmount
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style';
</style>
