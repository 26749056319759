import state from './biomaterialState'
import mutations from './biomaterialMutations'
import getters from './biomaterialGetters'
import actions from './biomaterialActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
