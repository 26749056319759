<template>
  <div class="profile-card-wrapper">
    <div class="personal-info ym-hide-content">
      <h2 class="name">
        {{ `${profile.surname} ${profile.name} ${profile.patronymic}` }}
      </h2>
      <PassportInfo
        :passport="profile.ambulatoryCards[0]"
        :birthday="profile.birthday"
        v-if="hasContract"
      />
      <div class="contract" v-if="!hasContract">
        <AlertIcon />
        <span>Оформите договор в клинике</span>
      </div>
      <p class="credential">{{ getFormatNumber(profile.phone) }}</p>
      <p :class="[profile.email ? 'credential' : 'credential empty']">
        {{ profile.email ? profile.email : 'email не указан' }}
      </p>
      <div class="green-text">
        <SmTooltip
          :trigger="windowWidth >= 1024 ? 'mouseenter' : ''"
          name="profile-tooltip"
        >
          <template v-slot:trigger>
            <InfoIcon />
          </template>
          <template v-slot:tooltip>
            <div class="discount-tooltip">
              <h3 class="discount-tooltip__title profile-info">
                Изменение личных данных
              </h3>
              <p class="discount-tooltip__text">
                Чтобы изменить имя, телефон и другие данные в профиле,
                пожалуйста обратитесь в любую клинику с паспортом.
              </p>
            </div>
          </template>
        </SmTooltip>
        <span>Изменить личные данные </span>
      </div>
    </div>
    <div class="financial-info">
      <p class="financial-info__label">Ваша скидка</p>
      <div class="discount-info">
        <span class="discount-text">{{ profile.discount }}</span>
        <span class="discount-icon">%</span>
        <span class="discount-label">Накопительная скидка</span>
      </div>
      <p class="financial-info__label">Баланс счета</p>
      <div class="balance-wrapper">
        <SmTooltip
          :trigger="windowWidth >= 1024 ? 'mouseenter' : ''"
          name="discount-payment"
          :interactive="true"
        >
          <template v-slot:trigger>
            <div>
              <p class="balance-label">Остаток</p>
              <h2 class="prepayment">
                {{ profile.prepaymentSum }} <RubIcon />
              </h2>
            </div>
          </template>
          <template v-slot:tooltip>
            Остаток денежных средств, внесенных на медицинские услуги
          </template>
        </SmTooltip>
        <div>
          <p class="balance-label">Долг</p>
          <h2 class="duty">{{ profile.debtSum }} <RubIcon /></h2>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InfoIcon from '@/assets/icons/info'
import SmTooltip from '@/components/tooltip'
import PassportInfo from '@/components/passportInfo'
import RubIcon from '@/assets/icons/rub'
import AlertIcon from '@/assets/icons/alert'
import { getFormatNumber } from '@/core/utils'

export default {
  name: 'ProfileCard',
  components: { AlertIcon, RubIcon, PassportInfo, SmTooltip, InfoIcon },
  data() {
    return { getFormatNumber }
  },
  props: {
    profile: Object,
    hasContract: Boolean
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
