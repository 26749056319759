<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.00078 15.2C11.9772 15.2 15.2008 11.9765 15.2008 8.00005C15.2008 4.0236 11.9772 0.800049 8.00078 0.800049C4.02433 0.800049 0.800781 4.0236 0.800781 8.00005C0.800781 11.9765 4.02433 15.2 8.00078 15.2ZM11.5652 6.49388C11.838 6.18215 11.8064 5.70833 11.4947 5.43557C11.183 5.16281 10.7091 5.19439 10.4364 5.50612L7.40894 8.96604L5.46932 7.41435C5.14588 7.15559 4.67391 7.20803 4.41515 7.53148C4.15639 7.85493 4.20883 8.32689 4.53228 8.58565L7.03228 10.5857C7.34569 10.8364 7.80094 10.7959 8.06523 10.4939L11.5652 6.49388Z"
      fill="#2DAB7F"
    />
  </svg>
</template>
<script>
export default {
  name: 'SuccessRoundedIcon'
}
</script>
