<template>
  <ymap-marker
    :properties="{ iconContent: markerLayout }"
    :coords="[coords[0], coords[1]]"
    :marker-id="coords[0]"
    :icon="icon"
  />
</template>
<script>
import { ymapMarker } from 'vue-yandex-maps'

export default {
  name: 'mapLocation',
  data() {
    return {
      icon: {
        layout: 'default#imageWithContent',
        imageHref: '/',
        imageSize: [70, 70]
      }
    }
  },
  props: {
    coords: { type: Array },
    map: { type: Object }
  },
  components: { ymapMarker },
  computed: {
    markerLayout() {
      return `<div class="map-location-marker" id="m${this.coords[0]}">
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<rect x="6" y="6" width="12" height="12" rx="6" fill="#F28B24"/>
<rect x="2" y="2" width="20" height="20" rx="10" stroke="#F28B24" stroke-width="2"/>
</svg>
</div>`
    }
  }
}
</script>
