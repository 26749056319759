<template>
  <svg
    width="16"
    height="21"
    viewBox="0 0 16 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 13.9414C5 13.5272 5.33579 13.1914 5.75 13.1914H10.25C10.6642 13.1914 11 13.5272 11 13.9414C11 14.3556 10.6642 14.6914 10.25 14.6914H5.75C5.33579 14.6914 5 14.3556 5 13.9414Z"
      fill="url(#paint0_linear_1684_241708)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 10.9414C5 10.5272 5.33579 10.1914 5.75 10.1914H10.25C10.6642 10.1914 11 10.5272 11 10.9414C11 11.3556 10.6642 11.6914 10.25 11.6914H5.75C5.33579 11.6914 5 11.3556 5 10.9414Z"
      fill="url(#paint1_linear_1684_241708)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 7.94141C5 7.52719 5.33579 7.19141 5.75 7.19141H6.5C6.91421 7.19141 7.25 7.52719 7.25 7.94141C7.25 8.35562 6.91421 8.69141 6.5 8.69141H5.75C5.33579 8.69141 5 8.35562 5 7.94141Z"
      fill="url(#paint2_linear_1684_241708)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 4.19141C4.17157 4.19141 3.5 4.86298 3.5 5.69141V14.6914C3.5 15.5198 4.17157 16.1914 5 16.1914H11C11.8284 16.1914 12.5 15.5198 12.5 14.6914V7.81273C12.5 7.61381 12.421 7.42305 12.2803 7.2824L9.40901 4.41108C9.26836 4.27042 9.07759 4.19141 8.87868 4.19141H5ZM2 5.69141C2 4.03455 3.34315 2.69141 5 2.69141H8.87868C9.47542 2.69141 10.0477 2.92846 10.4697 3.35042L13.341 6.22174C13.7629 6.64369 14 7.21599 14 7.81273V14.6914C14 16.3483 12.6569 17.6914 11 17.6914H5C3.34315 17.6914 2 16.3483 2 14.6914V5.69141Z"
      fill="url(#paint3_linear_1684_241708)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.5 3.44141V4.94141C9.5 6.18405 10.5074 7.19141 11.75 7.19141H13.25V8.69141H11.75C9.67893 8.69141 8 7.01247 8 4.94141V3.44141H9.5Z"
      fill="url(#paint4_linear_1684_241708)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1684_241708"
        x1="5.34111"
        y1="15.4468"
        x2="7.11373"
        y2="11.5624"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1684_241708"
        x1="5.34111"
        y1="12.4468"
        x2="7.11373"
        y2="8.56242"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1684_241708"
        x1="5.12792"
        y1="9.44684"
        x2="7.43008"
        y2="7.55503"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1684_241708"
        x1="2.68222"
        y1="25.2458"
        x2="19.9373"
        y2="17.6834"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1684_241708"
        x1="8.29847"
        y1="11.3354"
        x2="15.2202"
        y2="7.54345"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'DocumentIcon'
}
</script>
