<template>
  <div class="slideout-block">
    <div
      v-for="value of properties.items"
      @click="() => propertiesHandler(value)"
      class="slideout-block__button"
      :key="value.id"
    >
      <div class="slideout-block__inner subway">
        <SubwayIcon
          :color="
            value.color && value.color.length ? `#${value.color[0]}` : 'purple'
          "
        />
        <p>{{ value.title }}</p>
      </div>
      <ArrowDownIcon class="arrow" />
    </div>
  </div>
</template>
<script>
import ArrowDownIcon from '@/assets/icons/arrow-down'
import SubwayIcon from '@/assets/icons/subway'

export default {
  name: 'SmSlideSubways',
  components: { SubwayIcon, ArrowDownIcon },
  props: {
    properties: {
      items: []
    },
    close: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    propertiesHandler(val) {
      this.close(val)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../style';
</style>
