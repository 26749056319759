export default {
  getListLoading(state) {
    return state.list.loading
  },
  getSingleLoading(state) {
    return state.single.loading
  },
  getBiomaterialsData(state) {
    return state.list.data?.content || []
  },
  getSingleBiomaterialData(state) {
    return state.single.data
  },
  paginationInfo(state) {
    return {
      totalPages: state.list.pagination?.totalPages,
      size: state.list.pagination?.size
    }
  }
}
