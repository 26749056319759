import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

// const authStore = createPersistedState({
//   key: 'auth',
//   storage: window.sessionStorage,
//   paths: ['auth.loginSignIn', 'auth.phoneSignIn']
// })

const token = createPersistedState({
  key: 'token',
  paths: ['auth.token']
})

const account = createPersistedState({
  key: 'account',
  paths: ['account.account.id', 'account.patient.id']
})

const selectedCity = createPersistedState({
  key: 'city',
  paths: ['cities.selected']
})

const prevSpec = createPersistedState({
  key: 'prevSpec',
  paths: ['appointment.prevSpec']
})

export default new Vuex.Store({
  modules,
  plugins: [
    // authStore,
    selectedCity,
    token,
    account,
    prevSpec
  ],
  strict: process.env.NODE_ENV === 'development'
})
