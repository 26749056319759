<template>
  <div class="document-content">
    <p class="title">Об использовании личного кабинета</p>
    <p>
      Клиника настоящей публичной офертой предлагает заключить Договор об
      использовании личного кабинета. Акцептовав настоящую Оферту, Вы (далее -
      Пользователь) заключаете Договор на условиях, в порядке и объеме,
      определенных настоящей Офертой.
    </p>
    <p class="title">1. Термины и определения</p>
    <p>
      Информационные услуги - получение доступа к информации, связанной с
      предоставлением Клиникой платных медицинских услуг, в том числе к
      результатам лабораторных исследований.
    </p>
    <p class="bold">
      Клиника – клиника группы компаний «СМ-Клиника», а именно: ООО
      «СМ-Клиника», ООО «СМ-Доктор», ООО «СМ-МЕД», ООО «Клиника ТРИ ПОКОЛЕНИЯ»,
      ООО «Дэрайс», ООО «ДЕТЗДРАВ», ООО «СМ-Медика», ООО «СМ-Регионмед», ООО
      «Доктор 03», ООО «СМ-ЗДОРОВЬЕ», ООО «Меди Проф», ООО «Меди ком», ООО «Меди
      Лен», а также ООО «СМ-Косметология».
    </p>
    <p>
      Личный кабинет - специализированный раздел на домене lk.smclinic.ru и
      (или) мобильное приложение, посредством которых Пользователь получает
      информационные услуги.
    </p>
    <p>
      Пользователь – физическое лицо, заключившее или планирующее заключить с
      Клиникой договор на оказание платных медицинских услуг, безусловно
      акцептовавшее все условия настоящего Договора в порядке, предусмотренном
      настоящим Договором.
    </p>
    <p>Сайт – сайт Клиники <a href="www.smclinic.ru">www.smclinic.ru</a></p>
    <p>
      Сайт системы - web-сайт в сети Интернет lk.smclinic.ru или мобильное
      приложение, установленное на мобильном устройстве Пользователя.
    </p>
    <p class="title">2. Предмет Договора</p>
    <p>
      2.1. Пользователь поручает, а Клиника принимает на себя обязательства по
      оказанию информационных услуг путём предоставления доступа к данным,
      подготовленным и сформированным Клиникой, в том числе на основании
      договоров на оказание платных медицинских услуг (далее по тексту -
      услуги), на условиях, установленных настоящим Договором.
    </p>
    <p class="title">3. Заключение Договора</p>
    <p>
      3.1. Договор между Клиникой и Пользователем заключается путем акцепта
      Пользователем настоящей оферты. Пользователь считается акцептовавшим все
      условия настоящего Договора с момента входа в его личный кабинет, в том
      числе с момента совершения Пользователем действий в соответствии с п. п.
      3.2 - 3.3 настоящего Договора, которые рассматриваются как полное и
      безусловное согласие с условиями оферты. Акцептом условий настоящей оферты
      Пользователь безоговорочно соглашается с условиями настоящего Договора
      оферты, доступного на сайте и в мобильном приложении.
    </p>
    <p>
      3.2. После ознакомления Пользователя с офертой, содержащей условия
      договора, договор считается заключенным, а акцепт - полученным Клиникой в
      момент входа в личный кабинет Пользователя.
    </p>
    <p>
      3.2.1. Для входа посредством мобильного приложения Пользователь должен
      выполнить следующую последовательность действий:
    </p>
    <p>- Войти на мобильном устройстве в мобильное приложение «СМ-Клиника»;</p>
    <p>
      - Ввести в форму входа в Личный кабинет логин и пароль, предоставленный
      Клиникой посредством направления СМС-сообщения на номер мобильного
      телефона, указанный Пользователем, авторизоваться по номеру телефона или
      войти в мобильное приложение без авторизации.
    </p>
    <p>3.2.2. Для входа через сайт:</p>
    <p>- Ввести в адресной строке интернет-браузера электронный адрес сайта;</p>
    <p>
      - Ввести в форму входа в Личный кабинет логин и пароль, предоставленный
      Клиникой, либо авторизоваться по номеру телефона.
    </p>
    <p>
      3.3. Предоставление доступа к Личному кабинету осуществляется Клиникой
      исключительно в электронной форме путем направления логина и пароля, либо
      подтверждающего кода при авторизации по номеру телефона электронным
      сообщением на номер мобильного телефона, указанный Пользователем.
    </p>
    <p class="title">4. Права и обязанности сторон</p>
    <p>4.1. Клиника обязана:</p>
    <p>
      4.1.1. Предоставить Пользователю доступ к Личному кабинету после обращения
      в Клинику, идентификации Пользователя работниками Клиники и предоставления
      ему логина и пароля путем отправки их на номер мобильного телефона,
      указанный Пользователем.
    </p>
    <p>
      4.1.2. Оказывать Пользователю информационные услуги в соответствии с
      условиями настоящего Договора.
    </p>
    <p>4.2. Клиника имеет право:</p>
    <p>
      4.2.1. Самостоятельно определять формат размещаемой в Личном кабинете
      информации и документов, а также сроки ее размещения
    </p>
    <p>
      4.2.2. Обезличивать информацию при условии ссылки на номер амбулаторной
      медицинской карты Пользователя.
    </p>
    <p>
      4.2.3. Использовать указанные Пользователем при входе в личный кабинет или
      в договоре на оказание платных медицинских услуг номер мобильного телефона
      и (или) адрес электронной почты для передачи Пользователю уведомлений,
      содержащих информацию о Личном кабинете и порядке доступа к нему.
    </p>
    <p>
      4.2.4. Изменять и вводить новые условия настоящего Договора в
      одностороннем порядке без предварительного согласования с Пользователем.
      Уведомление Пользователя об изменении оферты осуществляется Клиникой не
      позднее чем за 5 (пять) дней до даты введения в действие изменений/новой
      редакции оферты путем размещения текста изменений/новой редакции оферты на
      сайте. Любые изменения оферты становятся обязательными для сторон с даты
      введения их в действие.
    </p>
    <p>
      4.2.5. Приостанавливать, но не более чем на 3 (три) рабочих дня, оказание
      информационных услуг в случае необходимости проведения профилактических
      или технологических работ, с уведомлением об этих работах на сайте.
    </p>
    <p>
      4.2.6. Удалять Личный кабинет Пользователя, если Пользователь не обращался
      за информационными услугами более 6 (шести) месяцев.
    </p>
    <p>
      4.2.7. Прекратить оказание информационных услуг по настоящему Договору в
      одностороннем порядке.
    </p>
    <p>4.3. Пользователь имеет право:</p>
    <p>
      4.3.1. Заключить с Клиникой настоящий Договор способами, предусмотренными
      п.п. 3.2-3.3 оферты, подтвердив данным действием, что Пользователь не
      ограничен в дееспособности, не состоит под опекой, попечительством, а
      также патронажем, по состоянию здоровья может самостоятельно осуществлять
      и защищать свои права и исполнять обязанности, не страдает заболеваниями,
      препятствующими осознавать суть заключаемого договора и обстоятельств его
      заключения.
    </p>
    <p>
      4.3.2. Получать информационные услуги посредством Личного кабинета на
      условиях настоящего Договора.
    </p>
    <p>
      4.3.3. Дать согласие на рекламную рассылку посредством выбора
      соответствующей настройки уведомлений в личном кабинете. Включая в
      настройках рекламные уведомления, Пользователь дает свое предварительное
      согласие на получение рекламы.
    </p>
    <p>4.4. Пользователь обязан:</p>
    <p>4.4.1. Соблюдать условия настоящего Договора.</p>
    <p>
      4.4.2. Предоставить Клинике необходимую актуальную информацию, в том числе
      при изменении номера мобильного телефона Пользователя и адреса его
      электронной почты, а также осуществлять иные действия, предусмотренные
      настоящим Договором.
    </p>
    <p>
      4.4.3. Своевременно и в полном объеме ознакомиться и соблюдать условия
      оферты. Принимать во внимание публикуемую на сайте системы (включая
      действующую редакцию настоящего Договора) информацию, имеющую отношение к
      использованию Личного кабинета, и руководствоваться ею.
    </p>
    <p>
      4.4.4. Предпринимать все возможные меры для предотвращения передачи
      третьим лицам логина и/или пароля доступа к Личному кабинету, не допускать
      доступа к Личному кабинету третьих лиц, за исключением случаев,
      предусмотренных законодательством Российской Федерации.
    </p>
    <p>
      4.4.5. В случае обнаружения незаконного доступа в Личный кабинет третьим
      лицом, а также в случае, если Пользователь подозревает возможность
      возникновения подобных ситуаций, незамедлительно уведомить об этом
      Клинику.
    </p>
    <p>4.4.6. Самостоятельно отлеживать изменения, внесенные в оферту.</p>
    <p class="title">5. Ответственность сторон</p>
    <p>
      5.1. За неисполнение или ненадлежащее исполнение обязательств по
      настоящему Договору стороны несут ответственность в соответствии с
      законодательством Российской Федерации.
    </p>
    <p>
      5.2. Пользователь самостоятельно несет ответственность за достоверность
      номера мобильного телефона/адреса электронной почты, указанного
      Пользователем в договоре на оказание платных медицинских услуг или в
      заявлении. В случае недостоверности указанного номера мобильного
      телефона/адреса электронной почты, а также в иных случаях, обусловленных
      причинами, не зависящими от Клиники (сообщение не отправлено оператором
      сотовой связи, номер мобильного телефона/адрес электронной почты
      физического лица заблокирован и т.п.), Клиника не несет ответственность за
      неполучение Пользователя кода доступа или иной информации в отношении
      Личного кабинета.
    </p>
    <p>
      5.3. Клиника не несет ответственность за любой ущерб и убытки, возникшие у
      Пользователя, в том числе в связи с тем, что Пользователь не ознакомился и
      (или) несвоевременно ознакомился с условиями оферты и (или) изменениями и
      дополнениями, внесенными в оферту.
    </p>
    <p>5.4. Клиника не несет ответственность в случае:</p>
    <p>
      - доступа третьих лиц к информации в результате передачи Пользователем
      пароля таким лицам как осознано, так и в случае несанкционированного
      получения пароля, а также противоправных действий третьих лиц;
    </p>
    <p>- не полного или несвоевременного размещения информации;</p>
    <p>
      - недоступности информации, в том числе из-за повреждения файлов, сбоев в
      программном обеспечении, ограничении доступа к сети Интернет,
      неисправности линий связи, неисправности оборудования, невыполнения
      обязательств третьими лицами и т.п.
    </p>
    <p>
      5.5. Стороны освобождаются от ответственности за неисполнение или
      ненадлежащее исполнение обязательств по настоящему Договору, если это
      неисполнение явилось следствием обстоятельств непреодолимой силы,
      возникших после заключения Договора в результате событий чрезвычайного
      характера, которые сторона(ы) не могла(и) ни предвидеть, ни предотвратить
      разумными мерами (форс-мажора). К таким обстоятельствам чрезвычайного
      характера относятся стихийные бедствия, аварии, наводнения, землетрясения,
      эпидемии, пожары, массовые беспорядки, забастовки, революции, военные
      действия, вступление в силу законодательных актов, постановлений и
      распоряжений государственных органов, а также любые другие обстоятельства,
      находящиеся вне разумного контроля сторон, препятствующие исполнению
      обязательств.
    </p>
    <p>
      5.6. Пользователь осознает, что для полноценного использования
      предоставляемой информации медицинского характера требуются специальные
      знания, и признает, что он использует информацию, предоставленную Клиникой
      в Личном кабинете, на свой собственный риск на основе своих знаний, опыта,
      мнения и оценки. Всю ответственность за применение информации, полученной
      посредством личного кабинета, Пользователь берет на себя. Тем самым
      Пользователь освобождает Клинику от любого убытка, ущерба или какой-либо
      ответственности, которые могут возникнуть непосредственно или косвенно при
      использовании предоставленной в личном кабинете информации.
    </p>
    <p>
      5.7. Ни при каких обстоятельствах Клиника не несет ответственности перед
      Пользователем за понесенные им прямые или косвенные убытки, если это было
      вызвано результатом небрежности Пользователя по отношению к любой
      предоставляемой Клиникой в Личном кабинете информации.
    </p>
    <p class="title">6. Срок действия договора</p>
    <p>
      6.1. Настоящий Договор считается заключенным с даты акцепта Пользователем
      оферты и действует в течение срока действия договора на оказание платных
      медицинских услуг, заключенного между Пользователем и Клиникой.
    </p>
    <p>
      6.2. Клиника вправе в одностороннем порядке отказаться от исполнения
      настоящего Договора, в том числе в случае нарушения Пользователем условий
      настоящего Договора, а также если Клинике станет известно об использовании
      Пользователем информационных услуг в незаконных целях или незаконным
      способом.
    </p>
    <p>
      6.3. Пользователь вправе расторгнуть Договор в одностороннем порядке и
      обратиться к Клинике с письменным требованием об удалении Личного
      кабинета. В требовании Пользователь должен сообщить Исполнителю свои
      фамилию, имя, отчество, адрес для отправки почты и контактный телефон.
    </p>
    <p class="title">7. Прочие условия</p>
    <p>
      7.1. Во всем, что прямо не предусмотрено настоящим Договором, стороны
      руководствуются законодательством Российской Федерации и обычаями делового
      оборота.
    </p>
    <p>
      7.2. Клиника предоставляет Пользователю информационную поддержку на сайте
      системы.
    </p>
    <p>
      7.3. При обращении Пользователя в Клинику по вопросу восстановления
      доступа к Личному кабинету Клиника направляет код доступа SMS-сообщением
      на номер мобильного телефона, указанный Пользователем. При этом Клиника
      имеет право провести дополнительную идентификацию Пользователя путем
      опроса по телефону.
    </p>
    <p>
      7.4. Заключением настоящего Договора на условиях оферты Пользователь,
      действуя своей волей и в своем интересе, дает свое согласие на обработку
      Клиникой его персональных данных (в т.ч. фамилия, имя, отчество, дата
      рождения, номер телефона, адрес электронной почты, сведения о состоянии
      здоровья и об обращениях к специалистам Клиники, сведения об оплатах и
      финансовых обязательствах, номера медицинских карт), а именно на
      совершение следующих действий: сбора, записи, систематизации, накопления,
      хранения, уточнения (обновления, изменения), извлечения, использования,
      предоставления доступа, обезличивания, блокирования, удаления и
      уничтожения любой информации, относящейся к персональным данным
      Пользователя, с целью заключения с Клиникой договора, исполнения
      заключенного договора. Указанные действия могут совершаться с
      использованием средств автоматизации. Пользователь также дает свое
      согласие на передачу Клинике в целях осуществления действий,
      предусмотренных настоящим пунктом оферты, его персональных данных третьим
      лицам при наличии надлежащим образом заключенных между Клиникой и такими
      третьими лицами договоров.
    </p>
    <p>
      7.5. Клиника обязуется при обработке персональных данных Пользователя в
      полном объеме соблюдать требования Федерального закона от 27 июля 2006 г.
      № 152-ФЗ "О персональных данных" (с учетом изменений и дополнений).
    </p>
    <p>
      7.6. Заключением настоящего Договора на условиях оферты Пользователь
      соглашается с направлением ему Клиникой любых сервисных сообщений.
    </p>
    <p class="title">8. Разрешение споров</p>
    <p>
      8.1. В случае возникновения споров и/или разногласий по настоящему
      Договору Стороны примут все меры к разрешению их путем переговоров между
      собой.
    </p>
    <p>
      8.2. Если Сторонам не удается разрешить споры и/или разногласия путем
      переговоров, то такие споры передаются на рассмотрение в суд в
      соответствии с законодательством Российской Федерации.
    </p>
    <p class="title">9. Реквизиты Клиник</p>
    <p>
      ООО «СМ-Клиника» ОГРН 5067746883309 ИНН 7743613031 КПП 774301001.
      Местонахождение: 125130, г. Москва, ул. Клары Цеткин, д. 33, корпус 28,
      пом.VII, комн. 7. р/с 40702810000014908671 в АО ЮниКредит Банк к/с
      30101810300000000545 БИК 044525545
    </p>
    <p>
      ООО «СМ-Доктор» ОГРН 1087746375386 ИНН 7743683906 КПП 774301001.
      Местонахождение:125130, г.Москва, Старопетровский проезд, д.7А, стр.19,
      этаж 1, пом. II, комн. 20. р/с 40702810800014908596 в АО ЮниКредит Банк
      к/с 30101810300000000545 БИК 044525545
    </p>
    <p>
      ООО «СМ-МЕД» ОГРН 1107746032415 ИНН 7713699948 КПП 773101001. Адрес:
      121351 г. Москва, ул. Ярцевская, д.8. р/с 40702810200014908617 в АО
      ЮниКредит Банк к/с 30101810300000000545 БИК 044525545
    </p>
    <p>
      ООО «Клиника ТРИ ПОКОЛЕНИЯ» ОГРН 1097746392897 ИНН 7717654810 КПП
      771701001. Адрес: 129164, г. Москва, ул. Ярославская, д.4, корп.2. р/с
      40702810500014908595 в АО ЮниКредит Банк к/с 30101810300000000545 БИК
      044525545
    </p>
    <p>
      ООО «Дэрайс» ОГРН 1037739596322 ИНН 7710421359 КПП 774301001.
      Местонахождение: 127299, г. Москва, ул.Космонавта Волкова, 9/2. р/с
      40702810400014908611 в АО ЮниКредит Банк к/с 30101810300000000545 БИК
      044525545
    </p>
    <p>
      ООО «СМ-Медика» ОГРН 1027739503912 ИНН 7709244970 КПП 770701001.
      Местонахождение: 103055, г.Москва, ул.Лесная, д.57, стр.1. р/с
      40702810100014929411 в АО ЮниКредит Банк к/с 30101810300000000545 БИК
      044525545
    </p>
    <p>
      ООО «ДЕТЗДРАВ» ОГРН 5117746046270 ИНН 7717715774, КПП 771401001.
      Местонахождение: 129164, г.Москва, пер. Расковой, д.14-22. р/с
      40702810700014929507 в АО ЮниКредит Банк к/с 30101810300000000545 БИК
      044525545
    </p>
    <p>
      ООО «СМ-Регионмед» ОГРН 5177746288374 ИНН 7743236175 КПП 774301001
      Местонахождение: 125130, г. Москва, Старопетровский проезд, д. 7А,
      строение 30, этаж 3, комн. 10. Адреса обособленных подразделений: 390048,
      Рязанская область, г. Рязань, ул. Васильевская, стр.3; 153000, Ивановская
      обл., г. о. Иваново, г. Иваново, пл. Революции, д. 7. р/с
      40702810140000030720 в ПАО Сбербанк БИК 044525225 к/с 30101810400000000225
    </p>
    <p>
      ООО «Доктор 03» ОГРН 1117746308173 ИНН 7722745206 КПП 774301001
      Местонахождение: 125130, г. Москва, ул. Клары Цеткин, д.33, корп.24. р/с
      40702810700014929510 в АО ЮниКредит Банк к/с 30101810300000000545 БИК
      044525545
    </p>
    <p>
      ООО «СМ-ЗДОРОВЬЕ» ОГРН 1217700250250 ИНН 7743361793 КПП 774301001
      Местонахождение: 127299, г. Москва, вн. тер. г. муниципальный округ
      Войковский, ул. Космонавта Волкова, д.9/2, помещ. 1, ком. 42. р/с
      40702810940000106346 в ПАО Сбербанк БИК 044525225 к/с 30101810400000000225
    </p>
    <p>
      ООО «СМ-Косметология» ОГРН 1127747110710 ИНН 7743868760 КПП 774301001.
      Местонахождение: 127299, г. Москва, ул. Космонавта Волкова, д.9/2. р/с
      40702810800014929494 в АО ЮниКредит Банк к/с 30101810300000000545 БИК
      044525545
    </p>
    <p>В г. Санкт-Петербурге:</p>
    <p>
      ООО «Меди Проф» ОГРН 1127847663800 ИНН 7806492270 КПП 781601001
      Местонахождение: 192288, г. Санкт-Петербург, Дунайский пр., д.47, литер А,
      помещение № 35 . р/ счет 40702810506000004024 в Санкт-Петербургский филиал
      ПАО "Промсвязьбанк" г.Санкт-Петербург к/счет 30101810000000000920 БИК
      044030920
    </p>
    <p>
      ООО «Меди ком» ОГРН 1079847076407 ИНН 7802411791 КПП 780601001
      Местонахождение: 195279, г. Санкт-Петербург, пр. Ударников, д. 19, корп.
      1, лит. А. р/с 40702810106000004288 в Санкт-Петербургский Ф-л ПАО
      «Промсвязьбанк» г. Санкт-Петербург к/с 30101810000000000920 БИК 044030920
    </p>
    <p>
      ООО «Меди Лен» ОГРН 1167847213522 ИНН 7806236621 КПП 780701001
      Местонахождение: 198332, г. Санкт-Петербург, ул. Маршала Захарова, д.20,
      литер Б, часть пом. 2Н, пом. № 39 р/с 40702810806000019159 в
      Санкт-Петербургском филиале ПАО «Промсвязьбанк» г. Санкт-Петербург к/с
      30101810000000000920 БИК 044030920
    </p>
  </div>
</template>
<script>
export default {
  name: 'OfferAgreement'
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
