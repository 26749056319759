<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.253 7.25606L6.292 4.21706C6.683 3.82706 7.316 3.82706 7.707 4.21706C8.097 4.60706 8.097 5.24106 7.707 5.63106L6.363 6.97506H12C12.551 6.97506 13 7.42306 13 7.97506C13 8.52606 12.551 8.97506 12 8.97506H6.363L7.707 10.3181C8.097 10.7091 8.097 11.3421 7.707 11.7331C7.316 12.1231 6.683 12.1231 6.292 11.7331L3.253 8.69306C3.249 8.69006 3.245 8.68606 3.242 8.68206C3.046 8.48706 2.949 8.23106 2.949 7.97506C2.949 7.71906 3.046 7.46306 3.242 7.26706C3.245 7.26406 3.249 7.26006 3.253 7.25606Z"
      fill="url(#paint0_linear_2135_5019)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2135_5019"
        x1="12.4286"
        y1="16.1054"
        x2="0.644314"
        y2="8.09557"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#27926D" />
        <stop offset="1" stop-color="#2DAB7F" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ArrowBackIcon'
}
</script>
