<template>
  <div class="auth-form">
    <p class="auth-form__title">Аккаунт заблокирован</p>
    <div class="auth-form__inner">
      <p class="auth-form__description">
        <template v-if="isRecovery">
          Превышено количество попыток ввода одноразового пароля. Для
          восстановления доступа обратитесь в регистратуру клиники.
        </template>
        <template v-else>
          Превышено количество попыток входа. Для восстановления доступа
          обратитесь в регистратуру клиники.</template
        >
      </p>
      <p class="auth-form__description">
        Чтобы записаться к врачу, позвоните по телефону
        <span class="phone">+7 495 154 10 82</span>
        <span>
          или
          <span class="phone cursor-pointer" @click="openWebim"
            >обратитесь в чат</span
          ></span
        >
      </p>
      <Button
        :onClick="() => this.$router.push('map')"
        :classes="'auth-form__btn'"
        icon="locate"
        secondary
        v-if="false"
      >
        Найти клинику
      </Button>
    </div>
  </div>
</template>
<script>
import Button from '@/components/button'
export default {
  name: 'AuthBlocked',
  components: { Button },
  computed: {
    isRecovery() {
      return this.$route.query.mode === 'recovery'
    }
  },
  methods: {
    openWebim() {
      window.webim.api.chat.start()
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
