<template>
  <div class="slideout-clinics">
    <ul class="mobile-navigation">
      <li
        :class="{ active: type === 'map' }"
        class="mobile-navigation__item"
        @click="() => selectType('map')"
      >
        Карта
      </li>
      <li
        :class="{ active: type === 'list' }"
        class="mobile-navigation__item"
        @click="() => selectType('list')"
      >
        Список
      </li>
    </ul>
    <div v-show="properties.loading">
      <Loader :loading="properties.loading" />
    </div>
    <div
      class="list-container"
      v-show="!properties.loading"
      v-if="type === 'list' || this.windowWidth >= 1024"
    >
      <div
        v-for="item of properties.items"
        class="slideout-clinics__button"
        @click="() => clickHandler(item)"
        :key="item.id"
      >
        <div class="slideout-clinics__inner">
          <p>{{ item.clinicName }}</p>
          <div
            class="slideout-clinics__subway"
            v-if="item.subwayStations && item.subwayStations.length"
          >
            <p v-for="subway in item.subwayStations" :key="subway.id">
              <SubwayIcon
                :color="
                  subway.color && subway.color.length
                    ? `#${subway.color[0]}`
                    : 'purple'
                "
              />
              {{ subway.title }}
            </p>
          </div>
        </div>
        <ArrowDownIcon class="arrow" />
      </div>
    </div>
    <div class="map-container" v-else>
      <SmMapComponent
        :markers="properties.items"
        :markerClickHandler="clickHandler"
      />
    </div>
    <BottomPopup name="clinic-info" v-if="selectedClinic">
      <template v-slot:title>
        <div class="popup-header">
          <LocateIcon />
          {{ selectedClinic.clinicName }}
        </div>
      </template>
      <template v-slot:body>
        <div class="popup-body">
          <p class="popup-body__clinic-name">
            <LocateIcon class="icon" />
            <span>{{ selectedClinic.clinicName }}</span>
          </p>
          <template v-if="selectedClinic['subway-stations'].length">
            <p
              class="popup-body__subway"
              v-for="subway in selectedClinic['subway-stations']"
              :key="subway.id"
            >
              <SubwayIcon
                v-if="subway.id"
                :color="subway.color[0] ? `#${subway.color[0]}` : 'purple'"
              />
              {{ subway.title }}
            </p>
          </template>
          <p class="popup-body__address">{{ selectedClinic.address }}</p>
          <p
            class="popup-body__more"
            @click="() => selectClinic(selectedClinicInPanel)"
          >
            Выбрать клинику
          </p>
        </div>
      </template>
    </BottomPopup>
  </div>
</template>
<script>
import ArrowDownIcon from '@/assets/icons/arrow-down'
import SmMapComponent from '@/components/mapComponents/map'
import BottomPopup from '@/components/bottomPopup'
import LocateIcon from '@/assets/icons/locate'
import SubwayIcon from '@/assets/icons/subway'
import { mapActions, mapGetters } from 'vuex'
import Loader from '@/components/loader'

export default {
  name: 'ClinicSlideOutComponent',
  components: {
    SubwayIcon,
    LocateIcon,
    BottomPopup,
    SmMapComponent,
    ArrowDownIcon,
    Loader
  },
  data() {
    return {
      type: 'map',
      selectedClinic: null,
      selectedClinicInPanel: null
    }
  },
  props: {
    properties: Object,
    close: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    ...mapActions('clinics', ['getClinicById']),
    clickHandler(val) {
      this.selectedClinicInPanel = val

      if (this.windowWidth > 1024) {
        this.selectClinic(val)
      } else {
        this.showPopup(val)
      }
    },
    selectClinic(data) {
      this.close(data)
      this.$modals.hide('clinic-info')
    },
    async showPopup(data) {
      await this.getClinicById({ id: data.id, withSubway: true })
      this.selectedClinic = this.clinicsSingleData
      this.$modals.show('clinic-info')
    },
    selectType(type) {
      this.type = type
    }
  },
  computed: {
    ...mapGetters('clinics', ['clinicsSingleData'])
  }
}
</script>
<style lang="scss" scoped>
@import 'style';
</style>
