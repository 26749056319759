<template>
  <SmModal
    size="md"
    name="appointmentCreated-modal"
    :title="`${patient.name}, вы записаны на прием в &quot;СМ-Клиника&quot;`"
    class="new-created-appointment"
  >
    <template v-slot:body>
      <div class="line"></div>
      <div class="sm-modal__body">
        <div class="sm-modal__body__icon"><CalendarIcon /></div>
        <div class="sm-modal__body__content">
          <div class="new-created-appointment__block">
            <p class="date">
              {{ getFullDate(selectedDoctor.slot.dateTime, true) }}
            </p>
            <p class="address">
              {{ selectedDoctor.clinics[0].address }}
            </p>
          </div>
          <div class="new-created-appointment__block">
            <p class="label">Пациент</p>
            <p class="patient-name">
              {{ fullName }}
            </p>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <Button :onClick="hideModal" icon="close" class="sm-modal__close-btn">
        Закрыть окно
      </Button>
    </template>
  </SmModal>
</template>

<script>
import SmModal from '@/components/modal'
import Button from '@/components/button'
import CalendarIcon from '@/assets/icons/calendar'
import { formatWithLocale, getFullDate } from '@/core/utils'
import { parseISO } from 'date-fns'

export default {
  name: 'ScheduleModal',
  components: { CalendarIcon, Button, SmModal },
  props: { selectedDoctor: Object, patient: Object },
  data() {
    return {
      getFullDate
    }
  },
  methods: {
    formatDay({ dateTime }) {
      return formatWithLocale(parseISO(dateTime), 'd MMMM')
    },
    formatTime({ dateTime }) {
      return formatWithLocale(parseISO(dateTime), 'HH:mm')
    },
    hideModal() {
      this.$modals.hide('sm-schedule')
    }
  },
  computed: {
    fullName() {
      return this.patient.name + this.patient.surname
    }
  }
}
</script>
