import state from './analysesState'
import mutations from './analysesMutations'
import getters from './analysesGetters'
import actions from './analysesActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
