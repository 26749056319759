<template>
  <div class="auth-form">
    <h2 class="auth-form__title">Личный кабинет пациента</h2>
    <div class="auth-form__inner">
      <Button :onClick="goToPhoneAuth" :classes="'auth-form__btn'" icon="phone"
        >Войти по номеру телефона
      </Button>
      <Button :onClick="goToLoginAuth" :classes="'auth-form__btn'" icon="key"
        >Войти с логином и паролем
      </Button>
    </div>
  </div>
</template>
<script>
import Button from '@/components/button'
import { TRACKER_SLUGS } from '@/models/tracker'

export default {
  name: 'AuthMain',
  components: { Button },
  methods: {
    goToPhoneAuth() {
      this.$tracker(TRACKER_SLUGS.PROCEED_AUTH_PHONE)
      this.$router.push('/auth/phone')
    },
    goToLoginAuth() {
      this.$tracker(TRACKER_SLUGS.PROCEED_AUTH_LOGIN)
      this.$router.push('/auth/login')
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
