import state from './visitsState'
import mutations from './visitsMutations'
import getters from './visitsGetters'
import actions from './visitsActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
