<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24 5.45455C24 2.44208 21.5579 0 18.5455 0H5.45455C2.44208 0 0 2.44208 0 5.45455V13.0909C0 16.1034 2.44208 18.5455 5.45455 18.5455H18.0211L22.0572 23.5906C22.3467 23.9524 22.833 24.0919 23.2702 23.9385C23.7074 23.7851 24 23.3724 24 22.9091V5.45455ZM4.36364 6.81818C4.36364 6.06507 4.97416 5.45455 5.72727 5.45455H13.9091C14.6622 5.45455 15.2727 6.06507 15.2727 6.81818C15.2727 7.5713 14.6622 8.18182 13.9091 8.18182H5.72727C4.97416 8.18182 4.36364 7.5713 4.36364 6.81818ZM5.72727 9.81818C4.97416 9.81818 4.36364 10.4287 4.36364 11.1818C4.36364 11.9349 4.97416 12.5455 5.72727 12.5455H9.54545C10.2986 12.5455 10.9091 11.9349 10.9091 11.1818C10.9091 10.4287 10.2986 9.81818 9.54545 9.81818H5.72727Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: 'OpenChatIcon'
}
</script>
