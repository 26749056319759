export default {
  setTimeslotsData(state, payload) {
    state.data = payload
  },
  updateTimeslotsData(state, payload) {
    state.data.doctors = [...state.data.doctors, ...payload.doctors]
  },
  setDoctorsFilter(state, payload) {
    state.doctorsFilter = payload
  },
  setTimeslotsLoading(state) {
    state.loading = !state.loading
  },
  setError(state, payload) {
    state.error = payload
  },
  disableTimeslot(state, { dateTime, clinicId, doctorId, id }) {
    try {
      const timeslot = state.data.doctors
        .find((obj) => obj.id === doctorId)
        .clinics.find((obj) => obj.id === clinicId)
        .dates.find((obj) => obj.date === dateTime)
        .timeslots.find((obj) => obj.id === id)
      timeslot.isAvailable = false
    } catch (e) {
      console.log(e)
    }
  }
}
