<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="color"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM5.38088 9.5H4.22888L4.47687 4.164H5.62088L7.01288 6.668L8.37288 4.164H9.52488L9.77287 9.5H8.56487L8.41288 6.012L7.30888 8.092H6.68488L5.51688 6.036L5.38088 9.5Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'SubwayIcon',
  props: {
    color: {
      type: String,
      default: () => '#BAD067'
    }
  }
}
</script>
