<template>
  <div class="medical-info-card" @click.stop="() => showDetail(info.id)">
    <div class="date">
      <CalendarIcon class="calendar-icon" />
      <h2 class="date__day">{{ info.day }}</h2>
      <p class="date__month">{{ info.month }}</p>
      <p class="date__year" v-if="info.year && history">{{ info.year }} г.</p>
      <p class="date__time" v-if="!history">{{ info.time }}</p>
      <p class="date__weekday" v-if="!history">{{ info.weekDay }}</p>
      <div v-if="info.status === 'FINISHED' && history" class="history-status">
        <IconSuccessRounded />
        <span>Завершен</span>
      </div>
      <div v-else-if="history" class="history-status">
        <IconCancelRounded />
        <span>Отменен</span>
      </div>
    </div>
    <div class="doctor" v-if="info.doctorInfo">
      <div class="doctor-inner">
        <div class="doctor-image">
          <img
            alt="doctor"
            :src="`${getBaseUrl}/${info.doctorInfo.photo}`"
            v-if="info.doctorInfo.photo"
          />
          <AvatarIcon v-else class="avatar" />
        </div>
        <div class="doctor-info">
          <p class="doctor-info__specialization" v-if="info.specialityInfo">
            {{ info.specialityInfo.title }}
          </p>
          <p class="doctor-info__fullName">{{ getDoctorFullName }}</p>
          <div class="doctor-info__experience">
            <span
              :class="{
                'hide-dot': !info.doctorInfo.totalExperience
              }"
              v-if="info.doctorInfo && info.doctorInfo.totalExperience"
              >{{
                `Стаж ${info.doctorInfo.totalExperience} ${declOfNum(
                  info.doctorInfo.totalExperience,
                  ['год', 'года', 'лет']
                )}`
              }}</span
            >
            <span
              :class="{
                'hide-dot': !info.doctorInfo.totalExperience
              }"
            >
              {{ getDoctorExp(info.doctorInfo.totalExperience) }}
            </span>
          </div>
          <div class="doctor-info__location">
            <div>
              <p class="clinic" v-if="info.clinicInfo.clinicName">
                <LocateIcon class="icon" />
                <span>{{ info.clinicInfo.clinicName }}</span>
              </p>
              <p
                class="subway"
                v-if="info.clinicInfo.subways && info.clinicInfo.subways.length"
              >
                <SubwayIcon
                  class="icon"
                  :color="
                    info.clinicInfo.subways[0].color &&
                    info.clinicInfo.subways[0].color.length
                      ? '#' + info.clinicInfo.subways[0].color[0]
                      : '#BAD067'
                  "
                />
                <span>
                  {{ info.clinicInfo.subways[0].title }}
                </span>
              </p>
            </div>
            <p class="address" v-if="info.clinicInfo.address">
              {{ info.clinicInfo.address }}
            </p>
          </div>
          <div class="doctor-info__location-link link">
            Подробнее о приёме
            <east-black-icon class="doctor-info__location-link--icon" />
          </div>
        </div>
      </div>
    </div>
    <div class="details">
      <div v-if="history" class="history">
        <div class="status">
          <template v-if="info.status === 'FINISHED'">
            <IconSuccessRounded />
            <span>Завершен</span>
          </template>
          <template v-else>
            <IconCancelRounded />
            <span>Отменен</span>
          </template>
        </div>
        <!--        временно скрыто -->
        <Button
          secondary
          icon="more"
          :onClick="() => modalHandler('retransfer', info)"
          v-if="false"
        >
          Записаться повторно
        </Button>
      </div>
      <div v-else class="upcoming">
        <div class="price-block">
          <p class="price">{{ info.price }} <IconRub /></p>
          <div class="price-info">
            <span>Стоимость и оплата</span>
            <SmTooltip
              :trigger="windowWidth >= 1024 ? 'mouseenter' : ''"
              name="meeting-discount"
              :interactive="true"
            >
              <template v-slot:trigger>
                <InfoIcon />
              </template>
              <template v-slot:tooltip>
                <div class="discount-tooltip">
                  <DiscountTooltip />
                </div>
              </template>
            </SmTooltip>
          </div>
        </div>
        <div class="button-wrapper">
          <Button
            secondary
            icon="change-time"
            :onClick="() => modalHandler('transfer', info)"
          >
            Перенести
          </Button>
          <Button
            classes="btn-light"
            icon="cancel"
            :onClick="() => modalHandler('cancel', info)"
          >
            Отменить
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DiscountTooltip from '@/components/discountTooltip'
import SmTooltip from '@/components/tooltip'
import AvatarIcon from '@/assets/icons/avatar'
import LocateIcon from '@/assets/icons/locate'
import { declOfNum, getDoctorExp } from '@/core/utils'
import CalendarIcon from '@/assets/icons/calendar'
import InfoIcon from '@/assets/icons/info'
import Button from '@/components/button'
import SubwayIcon from '@/assets/icons/subway'
import IconSuccessRounded from '@/assets/icons/success-rounded'
import IconCancelRounded from '@/assets/icons/cancel-rounded'
import IconRub from '@/assets/icons/rub'
import EastBlackIcon from '@/assets/icons/east_black'

export default {
  name: 'UpcomingMeeting',
  components: {
    EastBlackIcon,
    SubwayIcon,
    Button,
    CalendarIcon,
    LocateIcon,
    InfoIcon,
    SmTooltip,
    IconSuccessRounded,
    IconCancelRounded,
    IconRub,
    AvatarIcon,
    DiscountTooltip
  },
  data() {
    return {
      declOfNum,
      getDoctorExp
    }
  },
  props: {
    info: Object,
    history: Boolean,
    modalHandler: {
      type: Function,
      default: () => {}
    },
    showDetail: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    getDoctorFullName() {
      return `${this.info.doctorInfo.surname || ''} ${
        this.info.doctorInfo.name || ''
      } ${this.info.doctorInfo.patronymic || ''}`
    },
    getBaseUrl() {
      return process.env.VUE_APP_API_BASE_URL
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style';
.doctor-info__location-link {
  font-size: 0.875rem;
  font-family: 'Effra-Medium', sans-serif;
  color: #228462;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 1.25rem;
  ::v-deep svg {
    width: 16px;
    height: 16px;
  }
}
</style>
