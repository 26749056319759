<template>
  <div class="auth-form">
    <p class="auth-form__title">Мы выслали вам новый пароль</p>
    <div class="auth-form__inner">
      <p class="auth-form__description">
        Ваш новый пароль отправлен на номер
        <span class="phone"> {{ getFormatNumber(getRecoveryPhone) }}</span>
      </p>
      <Button :classes="'auth-form__btn'" icon="arrow" :onClick="goToLogin">
        Войти в личный кабинет
      </Button>
    </div>
  </div>
</template>
<script>
import Button from '@/components/button'
import { mapActions, mapGetters } from 'vuex'
import { getFormatNumber } from '@/core/utils'

export default {
  name: 'NewPassword',
  components: { Button },
  data() {
    return {
      getFormatNumber
    }
  },
  methods: {
    ...mapActions('auth', ['clearRecoveryData']),
    goToLogin() {
      this.clearRecoveryData()
      this.$router.push('/auth/login')
    }
  },
  computed: {
    ...mapGetters('auth', ['getRecoveryPhone'])
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
