<template>
  <svg
    width="204"
    height="94"
    viewBox="0 0 204 94"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_3223_6538"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="204"
      height="94"
    >
      <path
        d="M0 35C0 20.9554 0 13.9331 3.37061 8.8886C4.82978 6.70479 6.70479 4.82978 8.8886 3.37061C13.9331 0 20.9554 0 35 0H204V94H0V35Z"
        fill="#EAF0E7"
      />
      <path
        d="M0 35C0 20.9554 0 13.9331 3.37061 8.8886C4.82978 6.70479 6.70479 4.82978 8.8886 3.37061C13.9331 0 20.9554 0 35 0H204V94H0V35Z"
        fill="url(#paint0_linear_3223_6538)"
        fill-opacity="0.75"
      />
    </mask>
    <g mask="url(#mask0_3223_6538)">
      <path
        opacity="0.8"
        d="M0 0L204 0V54C204 72.8562 204 82.2843 198.142 88.1421C192.284 94 182.856 94 164 94H0L0 0Z"
        fill="#F2F7F6"
      />
      <path
        d="M88.4062 30.8664C88.4062 25.7371 88.4062 23.1765 90.0014 21.5882C91.5965 20 94.1763 20 99.3197 20H112.138C117.289 20 119.861 20 121.456 21.5882C123.051 23.1765 123.051 25.7452 123.051 30.8664V43.629C123.051 48.7584 123.051 51.319 121.456 52.9072C119.861 54.4955 117.281 54.4955 112.138 54.4955H99.3197C94.1682 54.4955 91.5965 54.4955 90.0014 52.9072C88.4062 51.319 88.4062 48.7503 88.4062 43.629V30.8664Z"
        fill="#07916A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M112.848 27.7224C112.848 27.9655 112.473 27.9736 111.708 27.9412C111.131 27.925 110.773 27.7548 110.862 27.4631C110.952 27.1714 111.456 27.139 112.001 27.2686C112.27 27.3254 112.848 27.4793 112.848 27.7224ZM106.02 48.0048C98.6872 48.0048 92.2824 43.2158 92.4207 38.1108C92.2417 34.3752 94.3332 30.4289 99.0534 28.3302C100.819 27.5442 103.822 26.8878 106.736 26.8554C107.379 26.8392 107.794 26.7581 108.364 26.6447C108.73 26.5718 109.153 26.4907 109.731 26.4016C110.691 26.2557 112.01 26.118 113.157 26.5799C114.5 27.2524 115.81 28.2491 115.81 28.7434C115.81 29.2377 114.931 30.0967 114.362 30.1696C113.377 30.2911 112.115 30.0967 110.569 29.8536C109.373 29.6672 107.997 29.4484 106.435 29.335C99.8347 29.2377 94.9843 33.6783 95.1389 37.973C95.2854 43.4589 102.187 45.8412 106.435 45.8412C118.284 45.9061 117.902 35.3962 115.127 34.8938C114.215 34.7317 113.499 36.2956 112.701 38.0216C111.782 40.0231 110.764 42.2353 109.234 42.2191C107.802 42.2029 106.671 39.8286 105.735 37.8515C105.076 36.4658 104.514 35.2746 104.002 35.2179C103.09 35.1207 102.39 37.268 101.78 39.148C101.365 40.4121 100.998 41.5627 100.624 41.822C99.7696 42.6729 97.5316 40.8253 97.849 40.1204C98.1582 39.4235 98.4431 39.6909 98.7605 39.9745C99.0209 40.2176 99.3057 40.4688 99.6313 40.1933C99.7533 40.088 99.9242 39.5774 100.168 38.8725C100.852 36.871 102.065 33.3055 104.124 33.1435C105.605 33.03 106.777 35.6798 107.713 37.7947C108.323 39.1642 108.828 40.3067 109.251 40.3067C109.731 40.2986 110.219 39.2371 110.805 37.9487C111.782 35.7932 113.059 32.9895 115.143 33.3299C120.433 34.205 120.856 48.0129 106.02 48.0048Z"
        fill="#F2F7F6"
      />
      <path
        d="M63.3021 69.608C62.5045 70.629 61.7883 71.0828 60.4781 71.0828C58.6551 71.0828 57.1088 69.7133 57.1088 67.8577C57.1088 66.0507 58.5819 64.665 60.4944 64.665C61.6337 64.665 62.5208 65.2404 63.1474 66.1236L65.1413 64.0816C63.9857 62.4204 62.2441 61.8289 60.2746 61.8289C56.767 61.8289 54 64.43 54 67.9468C54 71.488 56.7833 74 60.2746 74C62.3662 74 64.0508 73.1815 65.3285 71.5528L63.3021 69.608Z"
        fill="#07916A"
      />
      <path
        d="M66.8359 73.7325H69.7739L69.8715 66.9501L71.9875 70.5318H73.6233L75.7474 66.9582L75.8369 73.7325H78.7829L78.5876 62.1692H75.7311L72.8013 66.8367L69.8715 62.1692H67.0231L66.8359 73.7325Z"
        fill="#07916A"
      />
      <path
        d="M80.5078 69.689H83.3237H86.1314V67.3067H83.3237H80.5078V69.689Z"
        fill="#07916A"
      />
      <path
        d="M91.0701 68.984H91.7863C92.6245 68.984 93.3244 69.843 93.715 70.6614L95.2776 73.7406H98.7201L96.539 69.4054C96.01 68.4168 95.4322 67.8091 94.4068 67.4768C95.2206 67.1284 95.7496 66.626 96.2949 65.5158L98.069 62.1692H94.7486L93.3325 64.9081C92.9256 65.7914 92.4455 66.1722 91.5747 66.1722H91.0619V62.1692H88.0508V73.7325H91.0619V68.984H91.0701Z"
        fill="#07916A"
      />
      <path
        d="M101.779 65.2727C101.779 69.9402 101.283 71.2205 99.582 71.2205V73.7325C99.582 73.7325 99.9238 73.7325 100.111 73.7325C102.577 73.7325 104.286 71.9579 104.286 67.7361H105.629V73.7325H108.51V65.2727H101.779Z"
        fill="#07916A"
      />
      <path
        d="M118.696 73.7325V65.2727H116.214L113.162 69.5918V65.2727H110.428V73.7325H112.739L115.954 69.4135V73.7325H118.696Z"
        fill="#07916A"
      />
      <path
        d="M126.008 65.2727V68.2466H123.502V65.2727H120.629V73.7325H123.502V70.629H126.008V73.7325H128.881V65.2727H126.008Z"
        fill="#07916A"
      />
      <path
        d="M139.087 73.7325V65.2727H136.597L133.553 69.5918V65.2727H130.811V73.7325H133.122L136.345 69.3973V73.7325H139.087Z"
        fill="#07916A"
      />
      <path
        d="M143.829 70.5398H144.342C144.952 70.5398 145.327 71.1313 145.587 71.5932L146.629 73.7325H149.697L148.338 70.9125C147.809 69.9401 147.337 69.454 146.531 69.1947C147.198 68.9678 147.654 68.6274 148.118 67.7442L149.347 65.2646H146.417L145.465 67.258C145.172 67.8657 144.936 68.0683 144.342 68.0683H143.845V65.2646H141.021V73.7325H143.845V70.5398H143.829Z"
        fill="#07916A"
      />
      <path
        d="M155.193 70.6858C155.104 71.8041 154.371 72.0958 153.777 72.0958C153.15 72.0958 152.833 71.7068 152.833 71.0829C152.833 70.5076 153.46 70.0619 154.412 70.0619C154.786 70.0619 155.039 70.0781 155.193 70.1348V70.6858ZM158.001 73.7326V68.741C158.001 66.4316 156.487 65.2242 154.274 65.2242C152.369 65.2242 150.904 65.8158 150.31 66.5289L151.637 67.9631C152.117 67.6147 152.841 67.3554 153.867 67.3554C154.444 67.3554 155.193 67.5499 155.193 68.3926V68.5466C154.803 68.4979 154.583 68.4736 154.054 68.4736C151.995 68.4736 150.221 69.4946 150.221 71.5042C150.221 73.4814 151.669 74 152.727 74C153.818 74 154.461 73.7407 155.193 72.9628V73.7245H158.001V73.7326Z"
        fill="#07916A"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3223_6538"
        x1="204"
        y1="94.0002"
        x2="-2.46987e-06"
        y2="94.0002"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#E7EFDB" />
        <stop offset="1" stop-color="#ECEEE9" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'PromotionsIcon'
}
</script>
