export default {
  errorAuth(state) {
    return state.auth_error
  },
  redirectError(state) {
    return state.redirect_error
  },
  getRecoveryPhone(state) {
    return state.passwordRecovery?.phone || state.phoneSignIn.phone
  },
  getLoading(state) {
    return state.loading
  },
  getLoginData(state) {
    return state.loginSignIn
  },
  registrationData(state) {
    return state.registration
  },
  getPhoneSignIn(state) {
    return state.phoneSignIn
  },
  isAccessTokenExpired(state) {
    const accessTokenExpDate = state.token.exp - 10
    const nowTime = Math.floor(new Date().getTime() / 1000)

    return accessTokenExpDate <= nowTime
  }
}
