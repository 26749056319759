export default {
  setAnalysesData(state, payload) {
    state.list.data = payload
  },
  setAnalysesFilters(state, payload) {
    state.filters.data = payload
  },
  updateAnalysesData(state, payload) {
    state.list.data.content = [...state.list.data.content, ...payload.content]
  },
  setAnalysisById(state, payload) {
    state.single.data = payload
  },
  setAnalysesLoading(state) {
    state.list.loading = !state.list.loading
  },
  setListAnalysesError(state, payload) {
    state.list.error = payload
  },
  setSingleAnalysisLoading(state) {
    state.single.loading = !state.single.loading
  },
  setSingleAnalysisError(state, payload) {
    state.single.error = payload
  },
  setClinicsListByAnalysis(state, payload) {
    state.clinics.data = payload
  },
  setClinicsListByAnalysisLoading(state) {
    state.clinics.loading = !state.clinics.loading
  },
  setClinicsListByAnalysisError(state, payload) {
    state.clinics.error = payload
  },
  clearAnalysesRequestState(state) {
    state.single.error = null
    state.single.loading = false
  },
  setPaginationData(state, payload) {
    state.list.pagination = payload
  }
}
