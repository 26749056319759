<template>
  <yandex-map
    class="sm-map"
    :settings="settings"
    :options="options"
    :controls="[]"
    :coords="this.defaultCoords"
    :zoom="10"
    :cluster-options="clusterOptions"
    @map-was-initialized="localInitMap($event)"
  >
    <SmMarkers
      v-for="marker in markers"
      :key="marker.id"
      :item="marker"
      :map="map"
      :showClinicInfo="markerClickHandler"
      :balloon-callback="balloonClickHandler"
    />
    <map-location v-if="location" :coords="location" :map="map" />
  </yandex-map>
</template>
<script>
import { yandexMap } from 'vue-yandex-maps'
import SmMarkers from '@/components/mapComponents/mapMarkers'
import { mapGetters } from 'vuex'
import MapLocation from '@/components/mapComponents/mapMarkers/mapLocation'

export default {
  name: 'SmMapComponent',
  components: { MapLocation, SmMarkers, yandexMap },
  props: {
    markers: [],
    coords: Array,
    location: [],
    currentCity: {
      type: String,
      default: ''
    },
    markerClickHandler: {
      type: Function,
      default: () => {}
    },
    balloonClickHandler: {
      type: Function,
      default: () => {}
    },
    initMap: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      map: null,
      settings: {
        apiKey: process.env.VUE_APP_MAP_KEY,
        lang: 'ru_RU'
      },
      options: {
        suppressMapOpenBlock: true,
        yandexMapDisablePoiInteractivity: true
      },
      clusterOptions: {
        1: {
          clusterIcons: [
            {
              href: require('../../../assets/icons/cluster.png'),
              size: [40, 40],
              offset: [-20, -20]
            }
          ]
        }
      }
    }
  },
  computed: {
    defaultCoords() {
      if (this.coords) {
        return this.coords
      }

      const { latitude, longitude } = this.currentCity
        ? (this.citiesData || []).find(
            (city) => city.id === this.currentCity
          ) || this.selectedCityData
        : this.selectedCityData
      return [latitude, longitude]
    },
    ...mapGetters('cities', ['selectedCityData', 'citiesData'])
  },
  methods: {
    localInitMap(map) {
      this.map = map
      this.initMap(map)
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style';
</style>
