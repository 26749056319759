<template>
  <div class="locations">
    <div
      v-for="city of arrOfCities"
      @click="() => showModal(city)"
      class="locations__button"
      :key="city.id"
    >
      <div class="locations__inner">
        <LocateIcon v-if="city.selected" />
        <p>{{ city.title }} <span v-if="city.selected">(Вы здесь)</span></p>
      </div>
      <ArrowDownIcon class="arrow" />
    </div>
    <ApproveModal
      :action-handler="cityHandler"
      :action="`сменить город на <br/>${lastClickedCity.title}`"
    />
  </div>
</template>
<script>
import LocateIcon from '@/assets/icons/locate'
import ArrowDownIcon from '@/assets/icons/arrow-down'
import { mapActions, mapGetters } from 'vuex'
import ApproveModal from '@/components/modal/profileModals/approveModal'

export default {
  name: 'SmSlideLocation',
  components: { ApproveModal, ArrowDownIcon, LocateIcon },
  props: {
    close: {
      type: Function,
      default: () => {}
    },
    value: Object
  },
  data() {
    return {
      lastClickedCity: ''
    }
  },
  methods: {
    ...mapActions('cities', ['selectCity']),
    showModal(city) {
      this.lastClickedCity = city
      this.$modals.show('approve-modal')
    },
    cityHandler() {
      this.selectCity(this.lastClickedCity.id)
      this.$router.push({
        path: this.$route.fullPath,
        query: { changeCity: 'true' }
      })
      this.close()
      window.location.reload()
    }
  },
  computed: {
    ...mapGetters('cities', ['citiesData']),
    arrOfCities() {
      if (this.value) {
        return this.citiesData.map((el) =>
          el.id === this.value.id ? { ...el, selected: true } : el
        )
      }
      return this.citiesData
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style';
</style>
