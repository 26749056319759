import state from './appointmentState'
import mutations from './appointmentMutations'
import getters from './appointmentGetters'
import actions from './appointmentActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
