var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.crumbs.length)?_c('ul',{staticClass:"sm-crumbs"},_vm._l((_vm.crumbs),function(crumb,i){return _c('li',{key:i,staticClass:"sm-crumbs-item",style:({
      flexGrow: _vm.windowWidth >= 1024 ? 0 : _vm.grow(_vm.crumbs, i),
      width:
        _vm.windowWidth >= 1024
          ? 'fit-content'
          : _vm.crumbs.length > 2
          ? 0
          : 'fit-content'
    })},[(i < _vm.crumbs.length - 1)?_c('router-link',{staticClass:"link",attrs:{"to":crumb.path}},[_vm._v(" "+_vm._s(crumb.name)+" ")]):_c('span',{staticClass:"last link",style:({
        whiteSpace: 'nowrap'
      })},[_vm._v(" "+_vm._s(crumb.name)+" ")])],1)}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }