<template>
  <section class="detail-profile">
    <SmCrumbs />
    <h2 class="detail-profile__title">Настройки профиля</h2>
    <div class="need-contract" v-if="!hasContract">
      <InfoIcon class="orange-info-icon" />
      <div class="need-contract__info">
        <p class="need-contract__title">
          Пожалуйста, заключите договор и оформите медицинскую карту - они
          требуются для оказания услуг
        </p>
        <p class="need-contract__text">
          Это можно сделать, обратившись лично в регистратуру клиники с
          паспортом. При записи на приём ребёнка потребуется его свидетельство о
          рождении и паспорт сопровождающего законного представителя.
        </p>
      </div>
    </div>
    <ProfileCard :profile="getPatientInfo" :hasContract="hasContract" />
    <div class="login-details" v-if="hasContract">
      <div class="login-wrapper">
        <p class="label">Данные для входа</p>
        <p class="title">Ваш логин</p>
        <div class="login-info">
          <p class="login ym-hide-content">{{ getAccountInfo.login }}</p>
          <p class="green-text" @click="() => slideoutPanelHandler('login')">
            Изменить логин
          </p>
        </div>
      </div>
      <div class="password-wrapper">
        <Button
          secondary
          :onClick="() => slideoutPanelHandler('password')"
          v-tracker="TRACKER_SLUGS.USER_CHANGE_PASSWORD"
        >
          Сменить пароль
        </Button>
      </div>
    </div>
    <ChangeProfileMessageModal :messageType="messageType" />
  </section>
</template>

<script>
import ProfileCard from '@/pages/profile/profileCard'
import Button from '@/components/button'
import InfoIcon from '@/assets/icons/info'
import ChangeProfileDataForm from '@/components/slideOutComponents/changeProfileDataForm'
import { mapActions, mapGetters } from 'vuex'
import SmCrumbs from '@/components/breadcrumbs'
import ChangeProfileMessageModal from '@/components/modal/profileModals/changeProfileMessageModal'
import { TRACKER_SLUGS } from '@/models/tracker'
import pick from 'lodash/pick'

export default {
  name: 'ProfilePage',
  components: {
    ChangeProfileMessageModal,
    SmCrumbs,
    InfoIcon,
    Button,
    ProfileCard
  },
  data() {
    return {
      messageType: null
    }
  },
  created() {
    this.TRACKER_SLUGS = pick(TRACKER_SLUGS, ['USER_CHANGE_PASSWORD'])
    this.$tracker(TRACKER_SLUGS.ROUTE_PROFILE_MANAGEMENT)
  },
  methods: {
    ...mapActions('account', [
      'changeLogin',
      'changePassword',
      'getFullAccountInfo',
      'setError'
    ]),
    slideoutPanelHandler(type) {
      this.setError(null)
      this.$modals.hide('approve-modal')
      this.$showPanel({
        component: 'SmSlideoutPanel',
        props: {
          title: type === 'password' ? 'Смена пароля' : 'Смена логина',
          component: ChangeProfileDataForm,
          properties: {
            type,
            onChange: async (data, close) => {
              if (type === 'password') {
                await this.changePassword({
                  accountId: this.getAccountInfo.id,
                  patientId: this.getPatientInfo.id,
                  newPassword: data
                })
                if (!this.getErrors) {
                  this.messageType = 'password'
                  this.$modals.show('sm-change-profile-message')
                  close()
                } else {
                  this.$modals.hide('approve-modal')
                }
              } else {
                await this.changeLogin({
                  accountId: this.getAccountInfo.id,
                  patientId: this.getPatientInfo.id,
                  newLogin: data
                })
                if (!this.getErrors) {
                  this.messageType = 'login'
                  this.$modals.show('sm-change-profile-message')
                  close()
                } else {
                  this.$modals.hide('approve-modal')
                }
              }
            }
          }
        },
        openOn: 'right',
        width: '400',
        height: '80',
        sync: true,
        keepAlive: false
      }).promise.then(() => {})
    }
  },
  computed: {
    ...mapGetters('account', [
      'getPatientInfo',
      'getAccountInfo',
      'hasContract',
      'getErrors'
    ])
  }
}
</script>
<style lang="scss" scoped>
@import 'style.scss';
</style>
