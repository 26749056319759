<template>
  <div class="Loader">
    <canvas id="canvas" width="100" height="100"></canvas>
  </div>
</template>
<script>
import File from '@/assets/loader.riv'
const rive = require('@rive-app/canvas')

export default {
  props: {
    loading: Boolean,
    error: Boolean
  },
  watch: {
    error(v) {
      if (v) {
        this.animation.stop(['Loading'])
        this.animation.play(['Err'])
      }
    }
  },
  data() {
    return {
      animation: null
    }
  },
  mounted() {
    this.animation = new rive.Rive({
      src: File,
      canvas: document.getElementById('canvas'),
      autoplay: true,
      animations: ['Loading']
    })
  }
}
</script>

<style scoped lang="scss">
@import 'style';
</style>
