import { apiV1 } from '@/config/request'

export default {
  async getDoctorById(
    { commit, dispatch, rootState },
    { id, specialityId, withSpeciality, withClinic, withPrice, clinicId }
  ) {
    commit('setLoadingSingleDoctors')
    try {
      const { data } = await new apiV1({ auth: true }).get(`/doctors/${id}`)

      const setClinic = async (instance) => {
        let clinics = instance.specialityClinicIds

        if (clinicId) {
          clinics = clinics.filter((instance) => instance.clinicId === clinicId)
        }

        for (let obj of clinics) {
          await dispatch(
            'clinics/getClinicById',
            { id: obj.clinicId },
            { root: true }
          )

          const data = rootState.clinics.single.data
          data.subway = data['subway-stations']
            ? data['subway-stations'][0]
            : {}

          if (instance.clinicInfo) {
            instance.clinicInfo.push(data)
          } else {
            instance.clinicInfo = [data]
          }
        }
      }

      const setSpeciality = async (instance) => {
        await dispatch(
          'specialities/getSpecialityById',
          specialityId ?? instance.specialityClinicIds[0].specialityId,
          {
            root: true
          }
        )
        instance.specialityInfo = rootState.specialities?.single?.data
      }

      const setPrice = async (instance) => {
        const {
          id: doctorId,
          specialityInfo: { id: specialityId }
        } = instance

        const clinicId = instance.specialityClinicIds.find(
          (instance) => instance.specialityId === specialityId
        )?.clinicId

        const { priceInformation } = await dispatch(
          'appointment/getPrice',
          {
            clinicId,
            doctorId,
            specialityId
          },
          { root: true }
        )
        instance.priceInformation = priceInformation
      }

      const actions = []

      if (withSpeciality) actions.push(setSpeciality)
      if (withClinic) actions.push(setClinic)
      if (withPrice) actions.push(setPrice)

      if (actions.length) {
        try {
          for (const action of actions) {
            await action(data.doctor)
          }
        } catch (e) {
          console.log(e)
        }

        commit('setSingleDoctorsData', data?.doctor ?? null)
        commit('setLoadingSingleDoctors')
      } else {
        commit('setSingleDoctorsData', data?.doctor ?? null)
        commit('setLoadingSingleDoctors')
      }
    } catch (e) {
      commit('setSingleDoctorsError', e)
      commit('setLoadingSingleDoctors')
    }
  },
  clearDoctorRequestState({ commit }) {
    commit('clearDoctorRequestState')
  }
}
