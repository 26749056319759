export default {
  list: {
    data: null,
    error: null,
    loading: false
  },
  single: {
    data: null,
    error: null,
    loading: false
  }
}
