import { apiV1 } from '@/config/request'

export default {
  async getSubwaysList({ commit }, { cityId, clinicId }) {
    commit('setListLoading')
    try {
      const {
        data: { subwayStations }
      } = await new apiV1({ auth: true }).get('/subway-stations/', {
        params: {
          clinicId,
          cityId
        }
      })
      commit('setSubwayListData', subwayStations)
      commit('setListLoading')
      return subwayStations
    } catch (e) {
      commit('setListLoading')
      commit('setSingleError', `${e}`)
    }
  },
  async getSubwayById({ commit }, id) {
    commit('setSingleLoading')
    try {
      const {
        data: { subwayStation }
      } = await new apiV1({ auth: true }).get(`/subway-stations/${id}`)

      commit('setSubwaySingleData', subwayStation)
      commit('setSingleLoading')
    } catch (e) {
      commit('setSingleLoading')
      commit('setSingleError', `${e}`)
    }
  }
}
